import React, { Fragment, useEffect, useState } from "react";
import { ReasonIcon, ReasonContainer } from "./dialogStyle";
import {
  GreenWhiteBtn as SaveButton,
  BlueOutlinedBtn as ClearButton,
} from "../../button";
import Modal from "./modalBase";
import Input from "../../form";
import { Action } from "common/lineitem_actions/components/actions_container";
import {
  CERT_VARIATION_ITEM,
  CERT_VARIATION_CHILD_ITEM,
  VARIATION,
  CERT_VARIATION_ACTION_TYPES,
  STATUS_APPROVED,
  STATUS_PREAPPROVED,
  CHILDREN_ITEM,
} from "utils/constants";

export default function CustomizedDialogs(props) {
  const {
    lineType,
    data,
    openModal = false,
    onClose = false,
    saveButtonText = "SAVE",
    closeDropdown, // used for line item action version
  } = props;
  const [open, setOpen] = useState(openModal);
  const [reason, setReason] = useState("");
  const [showTextarea, setShowTextarea] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  useEffect(() => {
    openModal && setOpen(openModal);
  }, [openModal]);

  useEffect(() => {
    if (props.initialValue.reason !== "") {
      if (props.reasonOption.toJS().includes(props.initialValue.reason)) {
        setReason(props.initialValue.reason);
        setShowTextarea(false);
      } else {
        setReason("create_own_reason");
        setShowTextarea(true);
        setOtherReason(props.initialValue.reason);
      }
    }
  }, [open, props.initialValue.reason, props.reasonOption]);

  const handleOpen = () => {
    closeDropdown?.(); // call if exists
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) {
      onClose(reason, otherReason); // Call the onClose prop if it is provided
    }
    setOpen(false);
    setReason("");
    setOtherReason("");
    setShowTextarea(false);
  };

  const handleChangeReason = (value) => {
    setReason(value);
    setOtherReason("");
    if (value === "create_own_reason") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  };
  const handleClear = () => {
    setOtherReason("");
  };

  const updateReason = (e) => {
    reason === "create_own_reason"
      ? props?.inputReason(otherReason, e)
      : props?.inputReason(reason || "", e);
    handleClose();
  };

  let reasonOption = props?.reasonOption?.toJS()?.map((item) => ({
    value: item,
    label: item,
  }));
  if (reasonOption) {
    reasonOption.push({
      value: "create_own_reason",
      label: "Other reason (enter details below)",
    });
  }
  const { status, extra_info, current_status, parent_id } = data || {};

  if (
    (data &&
      [
        CERT_VARIATION_ITEM,
        CERT_VARIATION_CHILD_ITEM,
        VARIATION,
        CHILDREN_ITEM,
      ].includes(status)) ||
    parent_id !== "" ||
    (parent_id !== "" &&
      [STATUS_APPROVED, STATUS_PREAPPROVED].includes(current_status))
  ) {
    const showActionText =
      [CERT_VARIATION_ITEM, CERT_VARIATION_CHILD_ITEM, VARIATION].includes(
        status,
      ) ||
      (status === CHILDREN_ITEM && extra_info);

    return (
      <Fragment>
        {showActionText ? (
          <Action
            type={lineType}
            title="Variance Reason"
            handleClick={() => handleOpen()}
            isError={lineType === CERT_VARIATION_ACTION_TYPES.REASON_ERROR}
          />
        ) : props.initialValue.reason === "" ? (
          <div className="reason_button_wrap">
            <ReasonIcon
              className={props.initialValue.isRequiredReason ? "warning" : ""}
              onClick={handleOpen}
            />
            {props.initialValue.isRequiredReason && (
              <span className="warning_label">Required</span>
            )}
          </div>
        ) : (
          <div onClick={handleOpen} className="reason_label">
            {props.initialValue.reason}
          </div>
        )}
        <Modal handleClose={handleClose} open={open} title={props.title}>
          <ReasonContainer>
            <Input
              field="dropdown"
              options={reasonOption}
              width={678}
              noRadius
              label="Reason"
              placeholder="Select Reason ..."
              value={reason}
              usePortal={true}
              onChange={(e) => {
                !e ? handleChangeReason(null) : handleChangeReason(e.value);
              }}
              isClearable
            />
            {showTextarea && (
              <>
                <textarea
                  className="reason"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  autoFocus
                />
                {otherReason === "" && (
                  <div className="warning_text">
                    <div className="warning" />
                    Required
                  </div>
                )}
              </>
            )}
          </ReasonContainer>
          <Fragment>
            {showTextarea && (
              <ClearButton
                title="CLEAR"
                onClick={handleClear}
                padding="10px 42px"
                margin="0 30px 0 0"
              />
            )}
            <SaveButton
              title={saveButtonText}
              onClick={updateReason}
              padding="10px 48px"
              disabled={props.initialValue.isRequiredReason && reason === ""}
            />
          </Fragment>
        </Modal>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {props.initialValue.reason === "" ? (
        <div className="reason_button_wrap">
          <ReasonIcon
            className={props.initialValue.isRequiredReason ? "warning" : ""}
            onClick={handleOpen}
          />
          {props.initialValue.isRequiredReason && (
            <span className="warning_label">Required</span>
          )}
        </div>
      ) : (
        <div onClick={handleOpen} className="reason_label">
          {props.initialValue.reason}
        </div>
      )}

      <Modal handleClose={handleClose} open={open} title={props.title}>
        <ReasonContainer>
          <Input
            field="dropdown"
            options={reasonOption}
            width={678}
            noRadius
            label="Reason"
            placeholder="Select Reason ..."
            value={reason}
            usePortal={true}
            onChange={(e) => {
              !e ? handleChangeReason(null) : handleChangeReason(e.value);
            }}
            isClearable
          />
          {showTextarea && (
            <>
              <textarea
                className="reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                autoFocus
              />
              {otherReason === "" && (
                <div className="warning_text">
                  <div className="warning" />
                  Required
                </div>
              )}
            </>
          )}
        </ReasonContainer>
        <Fragment>
          {showTextarea && (
            <ClearButton
              title="CLEAR"
              onClick={handleClear}
              padding="10px 42px"
              margin="0 30px 0 0"
            />
          )}
          <SaveButton
            title={saveButtonText}
            onClick={updateReason}
            padding="10px 48px"
            disabled={props.initialValue.isRequiredReason && reason === ""}
          />
        </Fragment>
      </Modal>
    </Fragment>
  );
}
