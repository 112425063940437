import { httpRequest } from "../base/httpRequest";

const CLAIM_URL = `/api/payclaim/account/accId`;
const BASE_URL = `/api/claim/account/accId/contract_group`;

const sign_up = (data, params) =>
  httpRequest(
    "post",
    `/auth/invite/guest`,
    data,
    params ? { params } : undefined,
  );

//* account details
const upload_account_avatar = (data) =>
  httpRequest(
    "post",
    `${CLAIM_URL}/avatar`,
    data,
    {},
    { "Content-Type": "multipart/form-data" },
  );

const read_account_payee = () => httpRequest("get", `${CLAIM_URL}/readpayees`); //users

const read_account_users = () =>
  httpRequest("get", `${CLAIM_URL}/readpayees?user=true`);

const delete_account_user = (userId) =>
  httpRequest("delete", `${CLAIM_URL}/user/${userId}`); //users

const read_account_cert_reason = () =>
  httpRequest("get", `${CLAIM_URL}/reasons`); //reason
//* dashboard
const read_claims_received_summary = (params) =>
  httpRequest("get", `${CLAIM_URL}/dashboard/claims/summary`, null, { params }); //result

const read_active_projects = (params) =>
  httpRequest("get", `${CLAIM_URL}/dashboard/active/contracts`, null, {
    params,
  }); //result

const read_projects_summary = (params) =>
  httpRequest("get", `${CLAIM_URL}/contractlist?`, null, { params }); //contractlist

const read_upcoming_cert_summary = (params) =>
  httpRequest("get", `${CLAIM_URL}/dashboard/upcoming/cert/summary?`, null, {
    params,
  }); //result

const read_retention_summary = (params) =>
  httpRequest("get", `${CLAIM_URL}/dashboard/retention/summary`, null, {
    params,
  }); //result

const read_cert_claim_list = (data) =>
  httpRequest("post", `${CLAIM_URL}/search/claims/certs`, data); //result

const read_cert_claim_list_multi = (data) =>
  httpRequest("post", "/api/payclaim/dashboard/search/claims/certs", data); //result

const read_claims_received_summary_multi = (data) =>
  httpRequest("post", `/api/payclaim/dashboard/claims/summary`, data); //result

const read_active_projects_multi = (data) =>
  httpRequest("post", `/api/payclaim/dashboard/active/contracts`, data);

const read_projects_summary_multi = (data) =>
  httpRequest("post", `/api/payclaim/dashboard/contractlist`, data); //contractlist

const read_upcoming_cert_summary_multi = (data) =>
  httpRequest("post", `/api/payclaim/dashboard/upcoming/cert/summary`, data); //result

const read_retention_summary_multi = (data) =>
  httpRequest("post", `/api/payclaim/dashboard/retention/summary`, data); //result

// *contract info
const read_one_project = (contractId) =>
  httpRequest("get", `${CLAIM_URL}/contract/${contractId}`); //contract;

// * manage account

const invite_one_user = (data) =>
  httpRequest("post", `${CLAIM_URL}/inviteuser`, data);

const read_user_profile = () => httpRequest("get", `${CLAIM_URL}/getuser`);

const update_user_profile = (data) =>
  httpRequest("put", `${CLAIM_URL}/updateuser`, data); //result

const activate_user_account = (userId) =>
  httpRequest("post", `${CLAIM_URL}/user/${userId}`);

const resend_email_to_user = (userId) =>
  httpRequest("put", `${CLAIM_URL}/user/${userId}/resend/email`);

const set_user_email_preference = (data) =>
  httpRequest("put", `${CLAIM_URL}/user/email/preferences`, data);

const read_email_notice_time = () =>
  httpRequest("get", `${CLAIM_URL}/summary_email_time`);

const update_email_notice_time = (data) =>
  httpRequest("put", `${CLAIM_URL}/summary_email_time`, data);

// * attachment
const read_all_attachment = (contractId) =>
  httpRequest("get", `${CLAIM_URL}/contract/${contractId}/attachments`); //attachments

const delete_one_attachment = (contractId, fileId) =>
  httpRequest(
    "delete",
    `${CLAIM_URL}/contract/${contractId}/fileitem/${fileId}`,
  );

const download_one_file = (fileId, config) =>
  httpRequest("get", `${CLAIM_URL}/file/${fileId}`, null, config); //blob

const download_one_shared_file = (url, config) =>
  httpRequest("get", url, null, config); //blob

const download_one_shared_file_by_token = (token, config) =>
  httpRequest(
    "get",
    `/api/payclaim/file/token/${token}/download`,
    null,
    config,
  ); //blob

// * contract summary
const read_contract_summary = (contractId) =>
  httpRequest("get", `${CLAIM_URL}/contract/${contractId}/summary`); //contractsummary

// * claim payment record
const read_payment_history = (contractId) =>
  httpRequest("get", `${CLAIM_URL}/contract/${contractId}/payment-history`); //data

const create_claim_payment = (contractId, claimId, data) =>
  httpRequest(
    "post",
    `${CLAIM_URL}/contract/${contractId}/claim/${claimId}/payment-history/exec`,
    data,
    {},
    { "Content-Type": "multipart/form-data" },
  );

//*notice
const read_all_notice = () => httpRequest("get", `${CLAIM_URL}/notifications`); //notification
const read_notice_num = () =>
  httpRequest("get", `${CLAIM_URL}/notifications/num`); //note nums

const read_setup_process = () =>
  httpRequest("get", `${CLAIM_URL}/started/process`); //result

const delete_one_notice = (noticeId) =>
  httpRequest("delete", `${CLAIM_URL}/notifications/${noticeId}/delete`);

//*claim summary
const read_claim_summary = (data, params) =>
  httpRequest("post", `${CLAIM_URL}/claimsummary`, data, { params }); //claimsummary

const read_claim_summary_multi = (data) =>
  httpRequest("post", `/api/payclaim/dashboard/claimsummary`, data); //claimsummary

const read_three_month_claim_history = (contractId, itemId) =>
  httpRequest(
    "get",
    `${CLAIM_URL}/contract/${contractId}/lineitem/${itemId}/record`,
  ); //records

// * make a claim

const read_all_claim = (contractId) =>
  httpRequest("get", `${CLAIM_URL}/contract/${contractId}/claimlist`); //data

const read_one_claim = (proId, contractId, claimId) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}`,
  ); //readpayclaim

const read_contract_claim_period = (contractId, params) =>
  httpRequest("get", `${CLAIM_URL}/contract/${contractId}/claimperiod`, null, {
    params,
  }); //data

const read_claim_net_cert = (contractId, date) =>
  httpRequest(
    "get",
    `${CLAIM_URL}/contract/${contractId}/cert_to_date?date=${date}`,
  );

const read_claim_retention = (contractId, data) =>
  httpRequest("post", `${CLAIM_URL}/contract/${contractId}/randvvalue`, data); //data

const read_claim_notify = (contractId, claimId) =>
  httpRequest(
    "get",
    `${CLAIM_URL}/contract/${contractId}/claim/${claimId}/issue/auto_notify`,
  );

const create_one_claim = (proId, contractId, data) =>
  httpRequest(
    "post",
    `${BASE_URL}/${proId}/contract/${contractId}/claim`,
    data,
  );

const update_one_claim = (proId, contractId, claimId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}`,
    data,
  );

const delete_one_claim = (proId, contractId, claimId) =>
  httpRequest(
    "delete",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}`,
  );

const approve_one_claim = (proId, contractId, claimId) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/approve`,
  );

const upload_file_claim = (contractId, claimId, data, config) =>
  httpRequest(
    "post",
    `${CLAIM_URL}/contract/${contractId}/claim/${claimId}/upload`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const delete_file_claim = (contractId, fileId) =>
  httpRequest(
    "delete",
    `${CLAIM_URL}/contract/${contractId}/doc/${fileId}/delete`,
  );

const update_claim_shared_attachment = (contractId, claim_id, data) =>
  httpRequest(
    "post",
    `${CLAIM_URL}/contract/${contractId}/claim/${claim_id}/shared`,
    data,
  );

// * send payclaim email when issue
const send_payclaim_email = (proId, contractId, claimId, isSend, data) =>
  httpRequest(
    "post",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/email?has_email_service=${isSend}`,
    data,
  );

const upload_file_cert = (contractId, certId, data) =>
  httpRequest(
    "post",
    `${CLAIM_URL}/contract/${contractId}/cert/${certId}/upload`,
    data,
  );

const download_claim_pdf = (proId, contractId, claimId, config) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/pdf`,
    null,
    config,
  );

const issue_one_claim = (proId, contractId, claimId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/issue`,
    data,
  );

const issue_one_claim_integration = (proId, contractId, claimId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/integration/issue`,
    data,
  );

const revert_claim_to_draft = (proId, contractId, claimId) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/back/draft`,
  );

// * claim patch update
const update_claim_info = (proId, contractId, claim_id, data) =>
  httpRequest(
    "patch",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claim_id}`,
    data,
  );

const read_claim_workflow = (proId, contractId, claimId) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/workflow`,
  ); //data

const update_claim_workflow = (proId, contractId, claimId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/workflow`,
    data,
  ); //workflow

const approve_claim_step = (proId, contractId, claimId, stepId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/workflow/${stepId}/approve`,
    data,
  );
const revert_claim_workflow = (proId, contractId, claimId, stepId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/workflow/${stepId}/revert`,
    data,
  ); //data

const create_one_claim_lineitem = (proId, contractId, claimId, config, data) =>
  httpRequest(
    "post",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/item`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const delete_one_claim_lineitem = (proId, contractId, claimId, lineItemId) =>
  httpRequest(
    "delete",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/lineitem/${lineItemId}`,
  );

export default {
  sign_up,
  // * profile
  upload_account_avatar,
  // *  account
  read_account_payee,
  read_account_users,
  delete_account_user,
  read_account_cert_reason,
  // * dashboard
  read_claims_received_summary,
  read_active_projects,
  read_projects_summary,
  read_upcoming_cert_summary,
  read_retention_summary,
  read_cert_claim_list,
  read_claims_received_summary_multi,
  read_active_projects_multi,
  read_projects_summary_multi,
  read_upcoming_cert_summary_multi,
  read_cert_claim_list_multi,
  read_retention_summary_multi,
  // * projects
  read_one_project,
  // * account
  invite_one_user,
  read_user_profile,
  update_user_profile,
  activate_user_account,
  resend_email_to_user,
  set_user_email_preference,
  read_email_notice_time,
  update_email_notice_time,
  // * attachment
  read_all_attachment,
  delete_one_attachment,
  // * file
  download_one_file,
  download_one_shared_file,
  download_one_shared_file_by_token,
  // * contract summary
  read_contract_summary,
  // * payment claim
  read_payment_history,
  create_claim_payment,
  // * notice
  read_all_notice,
  read_notice_num,
  read_setup_process,
  delete_one_notice,
  // * claim summary
  read_claim_summary,
  read_claim_summary_multi,
  read_three_month_claim_history,
  // * make a claim
  read_all_claim,
  read_one_claim,
  read_claim_retention,
  read_claim_notify,
  approve_one_claim,
  update_one_claim,
  create_one_claim,
  delete_one_claim,
  read_contract_claim_period,
  read_claim_net_cert,

  upload_file_claim,
  delete_file_claim,
  update_claim_shared_attachment,

  // * send email
  send_payclaim_email,
  // *  make a cert
  upload_file_cert,
  download_claim_pdf,

  // * new apis
  revert_claim_to_draft,
  update_claim_info,
  issue_one_claim,
  issue_one_claim_integration,
  update_claim_workflow,
  approve_claim_step,
  read_claim_workflow,
  revert_claim_workflow,
  create_one_claim_lineitem,
  delete_one_claim_lineitem,
};
