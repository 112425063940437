import React, { PureComponent, Fragment } from "react";
import { OutLayerWrapper } from "../../makeclaim/styled";

import PayCertTable from "../../../common/table/pay_certificate_table";

export function getTableField(string) {
  switch (string) {
    case "Base Contract":
      return "base_contract";
    case "Variations":
      return "variation";
    case "Contra Charges":
      return "contra";
    default:
      return "";
  }
}

export default class index extends PureComponent {
  render() {
    const { table_title, updateStatus } = this.props;
    return (
      <Fragment>
        <OutLayerWrapper className="tableWidth">
          <PayCertTable
            {...this.props}
            tableField={getTableField(table_title)}
            updateStatus={updateStatus}
          />
        </OutLayerWrapper>
      </Fragment>
    );
  }
}
