import { fromJS } from "immutable";
import { constants } from ".";
import { format } from "date-fns";
import {
  findTreeNode,
  checkIsParent,
  addChildItemToParent,
  updateDescendantLineitem,
  getNestedLineitem,
} from "../../../utils/helper";
import { formatInTimeZone } from "../../../utils/dateTimeHelper";
import {
  STATUS_APPROVED,
  LINEITEM_STATUS,
  VARIATION_LINEITEM_STATUS,
  VARIATION_TYPES,
} from "utils/constants";
import { calculateSubunitToUnit } from "utils/currencyHelper";
import { getUpdatedVariationLineItems } from "common/lineitem/sharedComponent";

const defaultState = fromJS({
  loading: true,
  claimid: "",
  claim_status: "draft",
  claimLessPreviousOption: null,
  componentKey: 0,

  baseContractTable: [],
  baseContractTotal: 0,
  baseClaimList: [],
  baseClaimTotal: 0,
  baseClaimTotalExcludeRetention: 0,
  baseNetClaimTotal: 0,

  variationsTable: [],
  newVariationsItem: {},
  variationsTotal: 0,

  variationClaimList: [],
  variationClaimTotal: 0,
  variationClaimTotalExcludeRetention: 0,
  variationNetClaimTotal: 0,

  materialsTable: [],
  materialsClaimList: [],
  materialsTotal: { onSite: 0, offSite: 0 },
  materialsTotalExcludeRetention: { onSite: 0, offSite: 0 },

  paidValue: "",
  lessRetention: 0,
  previousType: "cert_to_date",
  claimDetail: {}, // dates in contract timezone
  claimlist: [],
  contractInfo: {},
  emailToList: [],
  emailSubject: "",
  autoSendEmails: {},
  attachedEmailFiles: [],
  nextPage: false,
  summary: {},
  var_approved_total: 0,
  base_approved_total: 0,
  base_claim_method: "value",
  variation_claim_method: "value",

  toClaimRetention: false,
  dlpReleaseValue: 0,
  pcdReleaseValue: 0,
  certRetention: { dlp: 0, pcd: 0, preNetTotal: 0 },

  isSendEmail: true,
  approvalList: [],
  leaveComment: false,
  approval_comment: "",
  claim_attachments: [],
  exceedAttachLimit: false,
  sharedAttachments: [],

  netCertTotal: 0,
  sendingEmail: false,
  approvingClaim: false,

  // P2C project and contract
  projectInfo: [],
  contractDetail: {},
  isAuditAttached: false,
  contractConfig: {},

  // New Claim Cert Summary
  claimDlpReleaseValue: 0,
  claimPcdReleaseValue: 0,
  previousRef: {
    claimed: "",
    certified: "",
  }, // Previous Ref used in DropDown for New Claim Cert Summary Component
  previous_option: "",
  previous_object_id: "",
  previous_gross: 0,
  previous_retention: 0,
  previous_retention_pcrr: 0,
  previous_retention_dlrr: 0,
  previous_labour_element: 0,
  labour_element: 0,
  // store pevious claim and cert data here (if exists) for easier access
  previousData: {
    claimed: null,
    certified: null,
  },
});

const addChildToClaimList = (item, claimList) => {
  let item_qty = parseFloat(item?.quantity) / 100;
  var list = {
    lineitem_id: item.id,
    percent: item.claimed_up_to_date_percent || 0,
    value: item.claimed_up_to_date_total || 0,
    claim_qty: item?.claimed_up_to_date_qty || 0,
    parent_id: item.parent_id,
    total: item.total,
    total_item_quantity: item_qty,
    total_item_unit: parseFloat(item.unit),
    item_rate: parseFloat(item.rate),
    item_description: item.description,
    action_field: item?.claimed_up_to_date_qty > 0 ? "qty" : "percent",
    claim_option: item?.claim_option || "percent",
    exclude_retention: item?.exclude_retention,
    current_status: item?.current_status,
    reject_reason: item?.reject_reason,
  };
  claimList.push(list);
  if (!checkIsParent(item)) {
    return claimList;
  } else {
    item.childitems.map((child) => {
      return (claimList = addChildToClaimList(child, claimList));
    });
    return claimList;
  }
};
const addChildToClaimReviewList = (item, claimList) => {
  var list = {
    lineitem_id: item.id,
    percent: item.claim_percent || 0,
    value: item.claim_value || 0,
    claim_qty: item?.claimed_up_to_date_qty || 0,
    parent_id: item.parent_id,
    total: item.total,
    total_item_quantity: item?.quantity || 0,
    total_item_unit: parseFloat(item.unit),
    item_rate: parseFloat(item.rate),
    item_description: item.description,
    action_field: item?.claimed_up_to_date_qty > 0 ? "qty" : "percent",
    claim_option: item?.claim_option || "percent",
    exclude_retention: item?.exclude_retention,
    current_status: item?.current_status,
    reject_reason: item?.reject_reason,
  };
  claimList.push(list);
  if (!checkIsParent(item)) {
    return claimList;
  } else {
    item.childitems.map((child) => {
      return (claimList = addChildToClaimReviewList(child, claimList));
    });
    return claimList;
  }
};

const countVariationLineItemChildren = (id, items) => {
  // Find the item by ID
  const findItem = (id, items) => items.find((item) => item.id === id);

  // Recursively count child items or return null if no child items
  const count = (id, items) => {
    const item = findItem(id, items);
    if (!item || !item.childitems) return null;

    const children = item.childitems;
    if (children.length === 0) return 0;

    return children.reduce((acc, childId) => {
      // const childItem = findItem(childId, items);
      const childCount = count(childId, items);
      return acc + (childCount === null ? 0 : childCount + 1);
    }, 0);
  };

  return count(id, items);
};

const calculateClaimTotal = (data) => {
  let claimList = [],
    claimTotal = 0,
    claimTotalExcludeRetention = 0;

  if (data) {
    data.forEach((item) => {
      claimTotal += item.claimed_up_to_date_total;
      claimList = addChildToClaimList(item, claimList);
    });
  }

  claimList.forEach((item) => {
    item.has_children = claimList.some(
      (otherItem) => otherItem.parent_id === item.lineitem_id,
    );
    item.children_count = countVariationLineItemChildren(
      item.lineitem_id,
      data,
    );
  });

  for (const obj of claimList) {
    if (!obj?.exclude_retention && !obj?.has_children) {
      claimTotalExcludeRetention += obj.value;
    }
  }

  return [claimList, claimTotal, claimTotalExcludeRetention];
};

const setContractInfo = (state, action) => {
  // no claim id
  const data = action.contractInfo;
  var hasRetention = data.retention ? true : false;
  let [baseClaimList, baseClaimTotal, baseClaimTotalExcludeRetention] =
    calculateClaimTotal(data.baseitems);
  let [
    variationClaimList,
    variationClaimTotal,
    variationClaimTotalExcludeRetention,
  ] = calculateClaimTotal(data.variation_items);

  let materialsTotal = { onSite: 0, offSite: 0 };
  let materialsTotalExcludeRetention = { onSite: 0, offSite: 0 };
  const materialsClaimList = setMaterialClaimList(data.materials, false);
  materialsClaimList.forEach((lineItem) => {
    if (lineItem.onSite) {
      materialsTotal.onSite += lineItem.value;

      if (!lineItem.exclude_retention) {
        materialsTotalExcludeRetention.onSite += lineItem.value;
      }
    } else {
      materialsTotal.offSite += lineItem.value;

      if (!lineItem.exclude_retention) {
        materialsTotalExcludeRetention.offSite += lineItem.value;
      }
    }
  });

  const result = data.claim_less_paid_to_date / 100;

  return state.merge(
    fromJS({
      // project detailed info
      contractInfo: data,
      loading: false,

      materialsTable: data.materials || [],
      materialsClaimList: materialsClaimList,
      materialsTotal: materialsTotal,
      materialsTotalExcludeRetention: materialsTotalExcludeRetention,

      baseContractTable: data.baseitems || [],
      baseContractTotal: data.total_baseitems / 100,
      baseClaimList: baseClaimList,
      baseClaimTotal: baseClaimTotal / 100,
      baseClaimTotalExcludeRetention: baseClaimTotalExcludeRetention / 100,
      baseNetClaimTotal: 0,

      variationsTable: data.variation_items || [],
      variationsTotal: data.total_variations / 100,
      variationClaimList: variationClaimList,
      variationClaimTotal: variationClaimTotal / 100,
      variationClaimTotalExcludeRetention:
        variationClaimTotalExcludeRetention / 100,
      variationNetClaimTotal: 0,
      paidValue: typeof result === "number" && !isNaN(result) ? result : "",
      claimDetail: {
        claim_ref: "",
        claim_from: formatInTimeZone(
          action.period.claim_from,
          "yyyy-MM-dd",
          data?.time_zone,
        ),
        claim_to: formatInTimeZone(
          action.period.claim_to,
          "yyyy-MM-dd",
          data?.time_zone,
        ),
        claim_due_date: formatInTimeZone(
          action.period.claim_due_date,
          "yyyy-MM-dd",
          data?.time_zone,
        ),
        cert_due_date: formatInTimeZone(
          action.period.cert_due_date,
          "yyyy-MM-dd",
          data?.time_zone,
        ),
        pay_due_date: formatInTimeZone(
          action.period.pay_due_date,
          "yyyy-MM-dd",
          data?.time_zone,
        ),
        claim_internal_notes: data.internal_nodes || "",
        claim_external_notes: data.external_nodes || "",
        has_retention: hasRetention,
        read_retention: data.retention || {},
        previous_claim_name: action.pre_claim_name,
        contract_timezone: data.time_zone,
      },
    }),
  );
};

const calculateClaimReviewTotal = (data) => {
  let claimList = [],
    netClaim = 0;
  if (data) {
    data.forEach((item) => {
      claimList = addChildToClaimReviewList(item, claimList);
      netClaim += item.claim_value - item.claimed_up_to_date_total;
    });
  }

  claimList.forEach((item) => {
    item.has_children = claimList.some(
      (otherItem) => otherItem.parent_id === item.lineitem_id,
    );
    item.children_count = countVariationLineItemChildren(
      item.lineitem_id,
      data,
    );
  });

  return [claimList, netClaim];
};

const setClaimInfo = (state, action) => {
  //claim id exist
  const data = action.claimInfo;

  let [baseClaimList, netClaim] = calculateClaimReviewTotal(data.baseitems);
  let [variationClaimList, varNetClaim] = calculateClaimReviewTotal(
    data.variation_items,
  );

  // update variation items with new child line Item if Multi-Select is used
  data.variation_items = getUpdatedVariationLineItems(data.variation_items);

  let detail = {
    claim_ref: data.payclaim_name,
    claim_from: formatInTimeZone(
      data?.claim_from,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_to: formatInTimeZone(
      data?.claim_to,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_due_date: formatInTimeZone(
      data?.claim_last,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    cert_due_date: formatInTimeZone(
      data?.claim_cert,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    pay_due_date: formatInTimeZone(
      data?.claim_due,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_internal_notes: data.claim_internal_notes || "",
    claim_external_notes: data.claim_external_notes || "",
    has_retention: data.has_retention,
    read_retention: {
      pcd_date: data.pcd_date,
      dlp_date: data.dlp_date,
    },
    previous_claim_name: action.pre_claim_name,
    contract_timezone: data.contract_timezone,
  };

  let contacts = data.email_info;
  let attachedEmailFiles = data.email_info.attachments || [];
  attachedEmailFiles.map((file) => {
    if (!file.attach_method) {
      file.attach_method = data.attach_method;
    }
    return file;
  });

  let toList = contacts.to_contacts || [];
  let isSendEmail = data.has_email_service || toList.length > 0;
  let is_invited_third_party = !action.third_party_info.to_contacts
    ? false
    : action.third_party_info.to_contacts.filter((item) =>
        item.contact_role.some((ele) => ele === "third_party"),
      ).length !== 0;
  if (data.status === "approved") {
    //if no 3rd parties, fix it to true
    isSendEmail = isSendEmail || !is_invited_third_party;
  }
  let autoSendEmails = action.third_party_info;
  autoSendEmails.is_invited_third_party = is_invited_third_party;

  // materials
  let materialsTotal = { onSite: 0, offSite: 0 };
  let materialsTotalExcludeRetention = { onSite: 0, offSite: 0 };
  const materialsClaimList = setMaterialClaimList(data.materials, true);
  materialsClaimList.forEach((lineItem) => {
    if (lineItem.onSite) {
      materialsTotal.onSite += lineItem.value;

      if (!lineItem?.exclude_retention) {
        materialsTotalExcludeRetention.onSite += lineItem.value;
      }
    } else {
      materialsTotal.offSite += lineItem.value;

      if (!lineItem?.exclude_retention) {
        materialsTotalExcludeRetention.offSite += lineItem.value;
      }
    }
  });

  const result = data.claim_less_paid_to_date / 100;
  return state.merge(
    fromJS({
      //project detailed info
      contractInfo: data,
      loading: false,
      claimDetail: detail,
      claim_status: data.status || "draft",
      claimLessPreviousOption: data.claim_less_previous_option,

      materialsTable: data.materials || [],
      materialsClaimList: materialsClaimList,
      materialsTotal: materialsTotal,
      materialsTotalExcludeRetention: materialsTotalExcludeRetention,

      baseClaimList: baseClaimList,
      baseNetClaimTotal: netClaim / 100,
      baseContractTable: data.baseitems || [],
      baseContractTotal: data.total_base_contract / 100,
      baseClaimTotal: data.total_claim_base_contract / 100,
      baseClaimTotalExcludeRetention:
        data.total_claim_base_contract_exclude_retention / 100,
      base_approved_total: data.total_base_approved_value / 100 || 0,

      variationClaimList: variationClaimList,
      variationNetClaimTotal: varNetClaim / 100,
      variationsTable: data.variation_items || [],
      variationsTotal: data.total_variation / 100,
      variationClaimTotal: data.total_claim_variation / 100,
      variationClaimTotalExcludeRetention:
        data.total_claim_variation_exclude_retention / 100,
      var_approved_total: data.total_vari_approved_value / 100 || 0,
      isAuditAttached: data.attach_audit_trail,

      paidValue: typeof result === "number" && !isNaN(result) ? result : "",
      lessRetention: data.less_retention / 100 || 0,
      previousType: data.previous_paid_to_date,

      claim_attachments: data.attachments || [],
      sharedAttachments: data.shared_attachments || [],

      isSendEmail: isSendEmail,
      autoSendEmails: autoSendEmails,
      emailToList: toList,
      attachedEmailFiles: attachedEmailFiles,
      previous_option: data.previous?.option,
      previous_gross: data.previous?.gross,
      previous_retention: data.previous?.retention,
      previous_retention_pcrr: data.previous?.retention_pcrr,
      previous_retention_dlrr: data.previous?.retention_dlrr,
      labour_element: data.claim_to_date_summary?.labour_element,
      previous_labour_element: data.previous?.labour_element,
      previous_cis_deduction: data.previous?.cis_deduction,
      cis_deduction: data?.claim_to_date_summary?.cis_deduction,
      cis_status: data?.cis_status,
      domestic_reverse_charge: data?.domestic_reverse_charge,
    }),
  );
};

function findParent(itemList, table, parent_id) {
  if (!parent_id) {
    return itemList;
  } else {
    let parent_obj = findTreeNode(parent_id, table.toJS());
    let parent_total = 0;
    parent_obj.childitems.map((item) => {
      var obj = itemList.find(function (obj) {
        return obj.lineitem_id === item.id;
      });
      obj.value && (parent_total += obj.value);
      return parent_total;
    });
    var parent_obj_claim = itemList.find(function (obj) {
      return obj.lineitem_id === parent_obj.id;
    });
    parent_obj_claim.percent =
      parent_obj_claim.total !== 0
        ? (parent_total / parent_obj_claim.total) * 100
        : 0;
    //since parent qty always be 1. so claim_qty = percent
    parent_obj_claim.claim_qty = parent_obj_claim.percent / 100;
    parent_obj_claim.value = parent_total;
    itemList = findParent(itemList, table, parent_obj_claim.parent_id);
    return itemList;
  }
}

const setClaimList = (state, action) => {
  //action.name : base/variation
  let claim_list_name = action.name + "ClaimList";
  let claim_table_name =
    action.name === "base" ? action.name + "ContractTable" : "variationsTable";
  let claim_total_name = action.name + "ClaimTotal";
  let claim_total_name_exclude_retention =
    action.name + "ClaimTotalExcludeRetention";
  let claim_net_total_name = action.name + "NetClaimTotal";

  let claimList = state.get(claim_list_name).toJS();
  let table = state.get(claim_table_name);
  let claimTotal = 0;
  let claimTotalExcludeRetention = 0;
  let netClaim = 0;
  let parent_id = "";

  claimList = claimList.map((item) => {
    if (item.lineitem_id === action.id) {
      let total_item_quantity = parseFloat(item.total_item_quantity);
      if (action.field === "value") {
        item.value = action.value * 100 || 0;
        item.percent =
          item.total !== 0 ? ((action.value * 100 || 0) / item.total) * 100 : 0;
        item.claim_qty =
          (parseFloat(item.value) / parseFloat(item.total)) *
          total_item_quantity;
      } else if (action.field === "qty") {
        //handle qty
        item.claim_option = action.field;
        item.action_field = action.field;

        //if user type qty from input box
        if (!action.fromPrctQty && !action.fromLoad) {
          item.claim_qty = parseFloat(action.value);
          item.percent =
            total_item_quantity !== 0
              ? (parseFloat(action.value) / total_item_quantity) * 100
              : 0;
          item.value =
            parseFloat(item.claim_qty) * parseFloat(item?.item_rate) || 0;
        }

        //if by clicking switch button, no need to care action value
        if (action.fromPrctQty && !action.fromLoad) {
          item.percent = item.total !== 0 ? (item.value / item.total) * 100 : 0;
          item.claim_qty = (item.percent * item.total_item_quantity) / 100;
        }
      } else {
        item.action_field = action.field;
        //handle percent
        //if user typed value
        if (!action.fromLoad && !action.fromPrctQty) {
          item.percent = parseFloat(action.value) || 0;
          item.value = Math.round((item.percent * item.total) / 100);
        }

        //if by clicking switch button, no need to care action value
        if (!action.fromLoad && action.fromPrctQty) {
          item.percent = item.total !== 0 ? (item.value / item.total) * 100 : 0;
        }

        item.claim_qty = (item.percent * item.total_item_quantity) / 100;
      }
      parent_id = item.parent_id;
    }
    return item;
  });

  claimList.forEach((item) => {
    item.has_children = claimList.some(
      (otherItem) => otherItem.parent_id === item.lineitem_id,
    );
  });

  if (parent_id) {
    claimList = findParent(claimList, table, parent_id);
  }

  table.map((item) => {
    var obj = claimList.find(function (obj) {
      return obj.lineitem_id === item.get("id");
    });

    claimTotal += obj?.value || 0;

    netClaim += item.get("claimed_up_to_date_total");
    return { claimTotal, netClaim };
  });

  for (const obj of claimList) {
    if (!obj?.exclude_retention && !obj?.has_children) {
      claimTotalExcludeRetention += obj.value;
    }
  }

  return state.merge({
    [claim_list_name]: fromJS(claimList),
    [claim_total_name]: claimTotal / 100,
    [claim_total_name_exclude_retention]: claimTotalExcludeRetention / 100,
    [claim_net_total_name]: (claimTotal - netClaim) / 100,
  });
};

const setClaimDateDetail = (state, action) => {
  let name = action.name,
    value = "";
  if (
    name === "claim_ref" ||
    name === "claim_internal_notes" ||
    name === "claim_external_notes"
  ) {
    value = action.value;
  } else {
    value = format(new Date(action.value), "yyyy-MM-dd");
  }
  return state.setIn(["claimDetail", name], value);
};

const setClaimEmailList = (state, action) => {
  let list = {},
    name = "",
    field = action.field.replace(/^\S/, (s) => s.toUpperCase());
  name = "email" + field + "List";
  list = state.get(name).toJS();
  list = list.filter(
    (chip) => chip.contact_email !== action.data.get("contact_email"),
  );
  return state.set(name, fromJS(list));
};

const addClaimEmailList = (state, action) => {
  let list = {},
    name = "",
    field = action.field.replace(/^\S/, (s) => s.toUpperCase());
  name = "email" + field + "List";
  let newContact = {};
  if (action.data.contact_name === "") {
    newContact.contact_name = action.data.contact_email;
  } else newContact.contact_name = action.data.contact_name;
  newContact.contact_email = action.data.contact_email;

  list = state.get(name).toJS();
  list = list.filter(
    (chip) => chip.contact_email !== action.data.contact_email,
  );
  list = list.concat(newContact);
  return state.set(name, fromJS(list));
};

const updateClaimDates = (state, action) => {
  let timezone = state.get("claimDetail")?.get("contract_timezone");
  let claimDetail = {
    ...state.get("claimDetail").toJS(),
    claim_from: formatInTimeZone(
      action.claimPeriod.claim_from,
      "yyyy-MM-dd",
      timezone,
    ),
    claim_to: formatInTimeZone(
      action.claimPeriod.claim_to,
      "yyyy-MM-dd",
      timezone,
    ),
    claim_due_date: formatInTimeZone(
      action.claimPeriod.claim_due_date,
      "yyyy-MM-dd",
      timezone,
    ),
    cert_due_date: formatInTimeZone(
      action.claimPeriod.cert_due_date,
      "yyyy-MM-dd",
      timezone,
    ),
    pay_due_date: formatInTimeZone(
      action.claimPeriod.pay_due_date,
      "yyyy-MM-dd",
      timezone,
    ),
  };
  return state.set("claimDetail", fromJS(claimDetail));
};

const setContractDetail = (state, action) => {
  let data = action.payload;
  let contractDetail = {
    claim_value: data.claimed_to_date_value,
    cert_value: data.cert_to_date_value,
    cert_date: data.cert_to_date,
    claim_date: data.to_date,
    remaining: data.total_contract_value - data.cert_to_date_value,
    total: data.total_contract_value,
    payer_info: {
      name: data?.payee?.name,
      email: data?.payee?.email,
      phone: data?.payee?.phone,
      account_name: data?.payer_company?.entity_name,
    },
    contract_name: data.name,
    contract_number: data.contract_number,
    ...data,
  };
  return state.set("contractDetail", fromJS(contractDetail));
};

const setLineitemComments = (state, action) => {
  let stateUpdate = { ...state.toJS() };
  let { variationItems, lineId } = action;

  // update variation comments while preserving any other redux only changes
  let itemUpdate = getNestedLineitem(variationItems, lineId);
  let found = false;
  stateUpdate.variationsTable = state
    .get("variationsTable")
    .toJS()
    .map((item) => {
      if (!found && (item.id === lineId || item.childitems?.length > 0)) {
        item = updateDescendantLineitem(item, lineId, (obj) => {
          return { ...obj, comments: itemUpdate.comments };
        });
      }
      return item;
    });

  stateUpdate.baseContractTable =
    action.baseItems || state.get("baseContractTable").toJS();
  stateUpdate.materialsTable =
    action.materialItems || state.get("materialsTable").toJS();
  return state.merge(fromJS(stateUpdate));
};

const setMaterialInput = (state, action) => {
  let materialsClaimList = state.get("materialsClaimList").toJS();
  let onSiteTotal = 0,
    offSiteTotal = 0;
  let onSiteTotalExcludeRetention = 0,
    offSiteTotalExcludeRetention = 0;

  if (action.lineId) {
    materialsClaimList = materialsClaimList.map((lineItem) => {
      if (lineItem.lineitem_id === action.lineId) {
        lineItem.value = action.value * 100 || 0;
      }
      if (lineItem.onSite) {
        onSiteTotal += lineItem.value;

        if (!lineItem.exclude_retention) {
          onSiteTotalExcludeRetention += lineItem.value;
        }
      } else {
        offSiteTotal += lineItem.value;

        if (!lineItem.exclude_retention) {
          offSiteTotalExcludeRetention += lineItem.value;
        }
      }
      return lineItem;
    });

    return state.merge({
      materialsClaimList: fromJS(materialsClaimList),
      materialsTotal: fromJS({ onSite: onSiteTotal, offSite: offSiteTotal }),
      materialsTotalExcludeRetention: fromJS({
        onSite: onSiteTotalExcludeRetention,
        offSite: offSiteTotalExcludeRetention,
      }),
    });
  }
  return state;
};

const setMaterialClaimList = (data, hasClaimId) => {
  const materialsClaimList = data
    ? data.map((item) => {
        return {
          lineitem_id: item.id,
          value: hasClaimId ? item.claim_value : item.claimed_up_to_date_total,
          claim_option: "",
          onSite: item.on_site,
          exclude_retention: item.exclude_retention,
        };
      })
    : [];
  return materialsClaimList;
};

const setPreviousClaimCert = (state, action) => {
  let stateUpdate = { ...state.toJS() };
  let claimStatus = ["issue", "certified"];
  const defaultValues = {
    previous_gross: 0,
    previous_retention: 0,
    previous_retention_pcrr: 0,
    previous_retention_dlrr: 0,
    previous_object_id: "",
    previous_labour_element: 0,
  };

  let previousRef = {
    claimed: "",
    certified: "",
  };

  if (action.currentClaimID && action.contractType) {
    let claimed = { ...defaultValues },
      certified = { ...defaultValues };

    // if contract is not a self claim or cert
    if (["claim", "self-cert"].includes(action.contractType)) {
      claimStatus.push("approved");
    }

    // summary details should be in order
    let i = action.data
      ?.map((e) => {
        return e.id;
      })
      .indexOf(action.currentClaimID);

    // create a slice that removes current claim and every claim/cert that comes after
    // if i of current claim exists and if there are entries before
    let history =
      i !== -1 && i + 1 < action.data?.length ? action.data?.slice(i + 1) : [];

    // if there are history entries
    if (history?.length > 0) {
      //if claim_to is not undefined and history has record. do a filter.
      if (action.claim_to !== undefined) {
        //filtering history record, history is order based on claim_to date DESC.
        //findindex if large than claim_to
        let filterIndex = history.findIndex(
          (e) => e.period_ending < action.claim_to,
        );
        history = history.slice(filterIndex);
      }
      // get previous cert
      // Find the index of the first entry in the history where the cert_status is "STATUS_APPROVED"
      let prevCertIndex = history.findIndex(
        (e) => e.cert_status === STATUS_APPROVED,
      );
      // get previous claim
      // Find the index of the first entry in the history where the claim_status is included in the claimStatus array
      let prevClaimIndex = history.findIndex((e) =>
        claimStatus.includes(e.claim_status),
      );
      // If a previous cert was found (index is not -1), retrieve the corresponding entry from the history array
      let prevCert = prevCertIndex !== -1 ? history[prevCertIndex] : null;
      // If a previous claim was found (index is not -1), retrieve the corresponding entry from the history array
      let prevClaim = prevClaimIndex !== -1 ? history[prevClaimIndex] : null;

      if (prevCert) {
        certified = {
          previous_gross: prevCert.gross_certified_value,
          previous_retention: prevCert.cert_less_retention,
          previous_retention_pcrr: prevCert.cert_pcrr,
          previous_retention_dlrr: prevCert.cert_dlrr,
          previous_object_id: prevCert.cert_id,
          previous_labour_element: prevCert.cert_labour_element,
        };

        previousRef.certified = prevCert.cert_name;
      }

      if (prevClaim) {
        claimed = {
          previous_gross: prevClaim.gross_claimed_value,
          previous_retention: prevClaim.claim_retention,
          previous_retention_pcrr: prevClaim.claim_pcrr,
          previous_retention_dlrr: prevClaim.claim_dlrr,
          previous_object_id: prevClaim.id,
          previous_labour_element: prevClaim.claim_labour_element,
        };

        previousRef.claimed = prevClaim.claim_name;
      }
    }
    stateUpdate.previousData = { claimed: claimed, certified: certified };
    stateUpdate.previousRef = previousRef;
  }

  return fromJS(stateUpdate);
};

const setPreviousOption = (state, action) => {
  let stateUpdate = { ...state.toJS() };
  const data = {
    claimed: stateUpdate.previousData.claimed,
    certified: stateUpdate.previousData.certified,
  };

  let previous = null;
  if (action.option && action.option !== "custom") {
    previous = data[action.option];
    stateUpdate = {
      ...stateUpdate,
      previous_object_id: previous?.previous_object_id,
      previous_gross: previous?.previous_gross,
      previous_retention: previous?.previous_retention,
      previous_retention_pcrr: previous?.previous_retention_pcrr,
      previous_retention_dlrr: previous?.previous_retention_dlrr,
      previous_labour_element: previous?.previous_labour_element,
    };
  }
  stateUpdate.previous_option = action.option;

  return fromJS(stateUpdate);
};

const updateVariationLineItem = (state, action) => {
  let updatedVariationsTable = [];
  const { lineitemId, lineItemDetails, current_status, reject_reason } = action;

  // Get the current variations table
  const currentVariationsTable = state.get("variationsTable").toJS();
  const currentVariationClaimList = state.get("variationClaimList").toJS();
  // update the status of the line items
  updatedVariationsTable = currentVariationsTable.map((lineItem) => {
    if (lineItem.id !== lineitemId) {
      return lineItem;
    }

    if (lineItemDetails) {
      return lineItemDetails;
    }

    // Update the status and reject reason of the matching line item
    // `isRejected` covers `REJECTED` status
    const isRejectedStatus = [VARIATION_LINEITEM_STATUS.REJECTED].includes(
      current_status,
    );

    // Check if the line item has a variation type of 'multi' and child items exist
    if (
      lineItem?.extra_info?.variation_type === VARIATION_TYPES.MULTI &&
      checkIsParent(lineItem)
    ) {
      // Update all child items with the current status and reject reason
      lineItem.childitems = lineItem.childitems.map((childItem) => ({
        ...childItem,
        current_status,
        reject_reason: isRejectedStatus ? reject_reason : undefined,
      }));
    }

    return {
      ...lineItem,
      current_status,
      reject_reason: isRejectedStatus ? reject_reason : undefined,
    };
  });

  // Update the variation claim list
  const updatedVariationClaimList = currentVariationClaimList.map(
    (claimItem) => {
      const matchingLineItem = updatedVariationsTable.find(
        (lineItem) => lineItem.id === claimItem.lineitem_id,
      );

      if (matchingLineItem) {
        return {
          ...claimItem,
          current_status: matchingLineItem.current_status,
        };
      }
      return claimItem;
    },
  );

  // Then, calculate the total variation amount after the status change
  let variationTotalsAfterStatus = 0;
  updatedVariationsTable.forEach((lineItem) => {
    if (
      [VARIATION_LINEITEM_STATUS.APPROVED].includes(lineItem.current_status)
    ) {
      variationTotalsAfterStatus += calculateSubunitToUnit(lineItem.total);
    }
  });

  return state.merge(
    fromJS({
      variationsTable: updatedVariationsTable,
      variationClaimList: updatedVariationClaimList,
      variationsTotal: variationTotalsAfterStatus,
    }),
  );
};

const updateLineItem = (lineItems, convertedObject) => {
  return lineItems.map((lineItem) => {
    // Update the current lineItem if it matches the ID
    if (lineItem.id === convertedObject.id) {
      return {
        ...lineItem,
        lineitem_options: convertedObject?.lineitem_options,
        quantity: convertedObject.quantity,
        rate: convertedObject.rate,
        total: convertedObject.total,
        on_site: convertedObject.on_site,
        is_provisional: convertedObject.is_provisional,
        detailed_description: convertedObject.detailed_description,
        label: convertedObject.label,
        uploaded_files: convertedObject.uploaded_files,
        submit_date: convertedObject.submit_date,
        approved_date: convertedObject.approved_date,
        description: convertedObject.description,
        extra_info: convertedObject.extra_info,
        variation_reference: convertedObject.variation_reference,
        unit: convertedObject.unit,
        exclude_retention: convertedObject.exclude_retention,
      };
    }

    // If childitems exist, recursively update them as well
    if (lineItem.childitems && Array.isArray(lineItem.childitems)) {
      return {
        ...lineItem,
        childitems: updateLineItem(lineItem.childitems, convertedObject),
      };
    }

    return lineItem;
  });
};

// Update the unsaved data structure of the variations table
const updateVariationTableDataStructure = (data) => {
  const updateItemRecursively = (item) => {
    let totalSum = 0;

    if (item.childitems && item.childitems.length > 0) {
      item.childitems.forEach((child) => {
        updateItemRecursively(child);
        totalSum += child.total;
      });

      item.quantity = 1;
      item.total = totalSum;
    } else {
      item.total = item.total || 0;
    }
  };

  const updatedData = JSON.parse(JSON.stringify(data));
  updatedData.forEach((item) => updateItemRecursively(item));

  return updatedData;
};

// To Retain the unsaved values in the claim list when performing add/delete operation
const retainClaimListValues = (currentData, newData) => {
  const currentDataMap = new Map(
    currentData.map((item) => [item.lineitem_id, item]),
  );

  return newData.map((newItem) => {
    const oldItem = currentDataMap.get(newItem.lineitem_id);

    if (oldItem) {
      return {
        ...newItem,
        claim_qty: oldItem.claim_qty,
        percent: oldItem.percent,
        value: oldItem.value,
      };
    }

    return newItem;
  });
};

const removeVariationItem = (data, targetId) => {
  if (Array.isArray(data)) {
    // Filter out the object with the matching id
    return data
      .map((item) => removeVariationItem(item, targetId)) // Recursively process each item
      .filter((item) => item !== null); // Remove null values from the result
  } else if (typeof data === "object" && data !== null) {
    if (data.id === targetId) {
      return null; // Remove this object
    }
    // Recursively process childitems if present
    if (Array.isArray(data.childitems)) {
      data.childitems = removeVariationItem(data.childitems, targetId);
    }
    return data;
  }
  return data;
};

// Sum the total of Claim List Child Items
const sumClaimList = (data) =>
  data.reduce(
    (acc, item) =>
      item.has_children || item?.children_count !== null
        ? acc
        : acc + item.value,
    0,
  );

const setNewVariationLineItem = (state, action) => {
  // Get the current variations table

  const originalObject = action.lineitemDetails;

  const convertedObject = {
    rate: originalObject?.rate ? originalObject?.rate : originalObject?.Rate,
    claimed_to_date_value: originalObject?.claimed_up_to_date_total,
    hide: false,
    claim_percent: originalObject?.claimed_up_to_date_percent,
    childitems: originalObject?.childitems,
    unit: originalObject?.unit,
    total: originalObject?.total
      ? calculateSubunitToUnit(originalObject?.total)
      : originalObject?.Total,
    created_date: originalObject?.created_date,
    claimed_to_date_qty: originalObject?.claimed_up_to_date_qty,
    lineitem_options: originalObject?.lineitem_options,
    on_site: originalObject?.on_site,
    cert_to_date_value: originalObject?.approved_up_to_date_total,
    cert_percent: originalObject?.approved_up_to_date_percent,
    approved_to_date: originalObject?.approved_up_to_date,
    is_provisional: originalObject?.is_provisional,
    quantity: originalObject?.quantity
      ? originalObject?.quantity / 100
      : originalObject?.Quantity / 100,
    variation_files: [],
    var_total: 0,
    previous_claim_values: null,
    detailed_description: originalObject?.detailed_description,
    cert_to_date_qty: originalObject?.approved_up_to_date_qty,
    claim_value: 0,
    parent_id: originalObject?.parent_id || "",
    cert_option: originalObject?.cert_option,
    status: LINEITEM_STATUS.CLAIM_VARI,
    claim_qty: 0,
    claimed_to_date_percent: originalObject?.claimed_up_to_date_percent,
    comments: [],
    label: originalObject?.label,
    claim_status: "false",
    previous_cert_values: null,
    submit_date: originalObject?.submit_date || "",
    approved_date: originalObject?.approved_date || "",
    current_claim: 0,
    cert_value: 0,
    claimed_up_to_date_total: originalObject?.claimed_up_to_date_total,
    current_status: VARIATION_LINEITEM_STATUS.PENDING,
    claim_option: originalObject?.claim_option,
    uploaded_files: originalObject?.uploaded_files,
    exclude_retention: originalObject?.exclude_retention,
    id: originalObject?.id,
    cert_qty: originalObject?.approved_up_to_date_qty,
    approved_total: originalObject?.approved_up_to_date_total,
    description: originalObject?.description,
    approved_percent: originalObject?.approved_up_to_date_percent,
    extra_info: originalObject?.extra_info,
    cert_to_date_percent: originalObject?.approved_up_to_date_percent,
    variation_reference: originalObject?.variation_reference,
    cert_reason: "",
    last_cert_value: 0,
  };

  let currentVariationsTable = state.get("variationsTable").toJS();
  let currentVariationClaimList = state.get("variationClaimList").toJS();
  let updatedVariationsTable = [];

  if (action.dbOperation === "ADD") {
    if (originalObject?.parent_id) {
      convertedObject.status = LINEITEM_STATUS.CLAIM_VARI_CHILD;
      let updatedParentItem = addChildItemToParent(
        currentVariationsTable,
        convertedObject?.parent_id,
        convertedObject,
      );

      updatedVariationsTable = currentVariationsTable.map((lineItem) => {
        if (lineItem.id === convertedObject.id) {
          return updatedParentItem;
        }
        return lineItem;
      });

      updatedVariationsTable = updateVariationTableDataStructure(
        updatedVariationsTable,
      );
      let [afterAddClaimList] = calculateClaimReviewTotal(
        updatedVariationsTable,
      );
      const updatedVariationClaimListData = retainClaimListValues(
        currentVariationClaimList,
        afterAddClaimList,
      );

      return state.merge(
        fromJS({
          variationsTable: updatedVariationsTable,
          variationClaimList: updatedVariationClaimListData,
        }),
      );
    } else {
      currentVariationsTable.push(convertedObject);
      currentVariationsTable = getUpdatedVariationLineItems(
        currentVariationsTable,
      );
    }
  } else if (action.dbOperation === "EDIT") {
    updatedVariationsTable = updateLineItem(
      currentVariationsTable,
      convertedObject,
    );
    return state.merge(fromJS({ variationsTable: updatedVariationsTable }));
  } else if (action.dbOperation === "DELETE") {
    updatedVariationsTable = removeVariationItem(
      currentVariationsTable,
      convertedObject.id,
    );
    updatedVariationsTable = getUpdatedVariationLineItems(
      updatedVariationsTable,
    );
    let [afterDeleteClaimList] = calculateClaimReviewTotal(
      updatedVariationsTable,
    );
    // to prevent overwriting unsaved values, prepare the last item and insert it into the claim list
    const updatedVariationClaimListData = retainClaimListValues(
      currentVariationClaimList,
      afterDeleteClaimList,
    );

    return state.merge(
      fromJS({
        variationsTable: updatedVariationsTable,
        variationClaimList: updatedVariationClaimListData,
        variationClaimTotal: calculateSubunitToUnit(
          sumClaimList(updatedVariationClaimListData),
        ),
        variationNetClaimTotal: calculateSubunitToUnit(
          sumClaimList(updatedVariationClaimListData),
        ),
      }),
    );
  }

  // To Prevent overwriting unsaved values, prepare the last item and insert it into the claim list
  let [newVariationClaimList] = calculateClaimReviewTotal(
    currentVariationsTable,
  );
  currentVariationClaimList.push(newVariationClaimList.at(-1));

  return state.merge(
    fromJS({
      variationsTable: currentVariationsTable,
      variationClaimList: currentVariationClaimList,
      variationClaimTotal: calculateSubunitToUnit(
        sumClaimList(currentVariationClaimList),
      ),
    }),
  );
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.REQUEST_CONTRACT_INFO:
      return setContractInfo(state, action);
    case constants.REQUEST_CLAIM_INFO:
      return setClaimInfo(state, action);
    case constants.SET_INPUT_VALUE:
      return setClaimList(state, action);
    case constants.SET_NAME_VALUE:
      return state.set(action.name, action.value);
    case constants.SET_CLAIM_DATES:
      return setClaimDateDetail(state, action);
    case constants.RESET_STAGE:
      return defaultState;
    case constants.REMOVE_EMAIL:
      return setClaimEmailList(state, action);
    case constants.ADD_EMAIL:
      return addClaimEmailList(state, action);
    case constants.SET_EMAIL_SUBJECT:
      return state.set(action.name, action.value);
    case constants.SET_TABLE_DATA:
      return state.set(
        action.name,
        action.data ? fromJS(action.data) : fromJS([]),
      );
    case constants.UPDATE_CLAIM_DATES:
      return updateClaimDates(state, action);
    case constants.SET_DATA:
      return state.set(action.payload.name, fromJS(action.payload.value));
    case constants.SET_CONTRACT_DETAIL:
      return setContractDetail(state, action);
    case constants.SET_LINE_COMMENTS:
      return setLineitemComments(state, action);
    case constants.SET_MATERIAL_INPUT:
      return setMaterialInput(state, action);
    case constants.DELETE_FILE:
      let files = state
        .get("claim_attachments")
        .filter((file) => file.get("file_id") !== action.payload);
      return state.set("claim_attachments", fromJS(files));
    case constants.SET_PREVIOUS_CLAIM_CERT:
      return setPreviousClaimCert(state, action);
    case constants.SET_PREVIOUS_OPTION:
      return setPreviousOption(state, action);
    case constants.UPDATE_VARIATION_LINE_ITEMS:
      return updateVariationLineItem(state, action);
    case constants.SET_NEW_VARIATION_LINE_ITEM:
      return setNewVariationLineItem(state, action);
    default:
      return state;
  }
};
