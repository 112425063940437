import * as theme from "../../theme";
import { IconButton } from "../button";
import styled from "styled-components";
import clearIcon from "../../statics/img/icon-clear-medium-grey.svg";
import shareIcon from "../../statics/img/icon-group-person.svg";
import notSharedIcon from "../../statics/img/icon-group-off.svg";
import deleteIcon from "../../statics/img/icon-delete-medium-grey.svg";
import editActionIcon from "../../statics/img/icon-edit-medium-grey.svg";

export const CommentContainer = styled.div`
  width: 624px;
  max-height: 90vh;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.104);
  z-index: 200;
  position: absolute;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  flex-direction: column;
  transition:
    opacity 0.3s ease 0s,
    transform 0.2s ease 0s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  font-size: 16px;
  bottom: 34px;
  right: -26px;

  .title {
    width: 100%;
    padding: 24px;

    .header {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 24px;
        font-weight: ${theme.font.mainFontWeight};
      }

      .cancelIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url(${clearIcon}) no-repeat;
        background-size: contain;

        &:hover {
          background-color: rgb(240, 242, 243);
          border-radius: 50%;
        }
      }
    }
  }

  .comments {
    width: 100%;
    max-height: calc(90vh - 230px);
    overflow-y: auto;

    .divider {
      width: 100%;
      height: 1px;
      background-color: ${theme.palette.wolfGrey};
    }

    .banner {
      height: 47px;
      width: 100%;
      padding: 12px 24px;
      border: 1px solid ${theme.palette.lightWolfGrey};
      border-left: 0px;
      border-right: 0px;
      background-color: ${theme.palette.greyWhite};
      font-size: 16px;
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .party-name {
        font-size: 12px;
        padding: 4px 14px;
        margin-left: 8px;
        border: solid 1px ${theme.palette.warmLightGrey};
        border-radius: 4px;
        background-color: ${theme.palette.warmLightGrey};
      }
    }
  }

  .tail {
    width: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid ${theme.palette.white};

    position: relative;
    top: 10px;
    left: 274px;
  }
`;

export const CommentImg = styled.div`
  padding: 2px 8px;
  font-size: 14px;
  color: white;
  width: 24px;
  height: 24px;
  background: url(${(props) => props.background}) no-repeat;

  position: absolute;
`;

export const InputCommentSection = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .inputSection {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .inputField {
      height: 32px;
      border-radius: 4px;
      width: 390px;
      &.error {
        border-color: ${theme.palette.watermelonRed};
        &:focus-visible {
          outline-color: ${theme.palette.watermelonRed};
        }
      }
    }

    .buttons {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 166px;

      .button {
        height: 32px;
        width: 79px;
        padding: 0px;
      }
    }
  }

  .errorMsg {
    margin-top: 8px;
    color: ${theme.palette.watermelonRed};
    font-size: 14px;
  }

  .visibility {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 44px auto;
    grid-column-gap: 12px;

    .description {
      display: flex;
      align-items: center;
    }
  }
`;

export const ShareButton = styled.div`
  height: 40px;
  width: 44px;
  border: 1px solid ${theme.palette.gunmetal};
  border-radius: 4px;
  padding: 8px 10px;
  cursor: pointer;
  &.shared {
    background-color: ${theme.palette.gunmetal};
  }

  .icon {
    height: 24px;
    width: 24px;
    mask: url(${notSharedIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    background-size: 24px 24px;

    &.shared {
      mask: url(${shareIcon}) no-repeat;
      background-color: ${theme.palette.white};
    }
  }
`;

export const CommentStyle = styled.div`
  width: 100%;
  padding: 24px;
  display: grid;
  grid-template-rows: 24px auto 24px;
  grid-row-gap: 16px;

  .container {
    display: flex;
    justify-content: space-between;

    &.top {
      height: 24px;
      padding: 0px;

      .userName {
        height: 100%;
        width: max-content;
        font-weight: 700;
        line-height: 1.5;
      }
    }
    &.bottom {
      color: ${theme.palette.veryMediumGrey};
    }

    &.action {
      width: 64px;
    }
  }
`;

export const CommentAction = styled(IconButton)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  padding: 0px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.editIcon {
      background: url(${editActionIcon}) no-repeat;
    }
    &.deleteIcon {
      background: url(${deleteIcon}) no-repeat;
    }
  }

  &.shareToggle {
    border-radius: 0%;
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }

    &.noEdit {
      .icon {
        cursor: default;
      }
    }

    .icon {
      mask: url(${notSharedIcon}) no-repeat;
      background-color: ${theme.palette.wolfGrey};

      &.shared {
        mask: url(${shareIcon}) no-repeat;
        background-color: ${theme.palette.gunmetal};
      }
    }
  }
`;
