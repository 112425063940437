import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  LineItemChip,
  CheckBoxWrap,
  ConvertModalButtons,
  ConvertModalContent,
} from "./styled";
import BootstrapTooltip from "../toolTip";
import Input from "../form";

import { checkIsParent } from "../../utils/helper";
import Modal from "../modal/component/modalBase";
import { BlueOutlinedBtn as Button } from "../button";
import {
  currencySubunitToUnit,
  convertToCurrencyUnit,
} from "../../utils/currencyHelper";
import {
  DEFAULT_CURRENCY,
  ON_SITE,
  OFF_SITE,
  LUMP,
  RATE,
  VARIATION_TYPES,
  COMPONENT_CLASS,
  VARIATION_LINEITEM_STATUS,
} from "../../utils/constants";
import {
  getParentItemById,
  isNewPlaceholderLineItem,
} from "pages/makeclaim/shared/claimCertHelper";

// Function to get the last variation object based on the variation type
export const getLastVariationObject = (variationItems, variationType) => {
  if (!Array.isArray(variationItems) || variationItems.length === 0) {
    return null;
  }

  const lastObject = variationItems.reduce((latest, current) => {
    const latestDate = latest?.created_date ? new Date(latest.created_date) : 0;
    const currentDate = current?.created_date
      ? new Date(current.created_date)
      : 0;

    return latestDate > currentDate ? latest : current;
  });

  if (lastObject?.extra_info?.variation_type === variationType) {
    return lastObject;
  }

  return null;
};

// set new temp child line item for the multi variation item
export const setNewTempChildLineItem = (item) => {
  // Check for variation_type 'MULTI' and empty child items
  if (
    item?.extra_info?.variation_type === VARIATION_TYPES.MULTI &&
    (!item?.childitems || item.childitems.length === 0)
  ) {
    return {
      ...item,
      childitems: [
        {
          id: `new_${item.id}`,
          childitems: null,
          depth: item.depth + 1,
          parentItemId: item.id,
        },
      ],
    };
  }
  return item;
};

export const getUpdatedVariationLineItems = (variation_line_items) => {
  return variation_line_items?.map(setNewTempChildLineItem) || [];
};

//get the expand item's all children key
export const getAllChildKeys = (arr, keys) => {
  if (arr !== null) {
    for (let value of arr) {
      keys.push(value.id);
      keys = getAllChildKeys(value.childitems, keys);
    }
  }
  return keys;
};

// get the last child tree keys of the expand item
export const getLastChildParentKeys = (parentItem, keys) => {
  if (checkIsParent(parentItem)) {
    keys.push(parentItem.id);
    keys = getLastChildParentKeys(
      parentItem.childitems[parentItem.childitems.length - 1],
      keys,
    );
  }
  return keys;
};

export const hasProvisionalChild = (children, hasChild) => {
  children?.forEach((child) => {
    if (child?.is_provisional) {
      hasChild = true;
    }
    // check if any of its descendants are provisional
    if (checkIsParent(child)) {
      hasChild = hasProvisionalChild(child?.childitems, hasChild);
    }
  });

  return hasChild;
};

// returns an array of parent keys (for tree expanding) that have provisional children/descendants
export const getExpandProvisionalParents = (line, expandedList) => {
  let newExpanded = expandedList;
  newExpanded.push(line.id);

  // check if any of its children have provisional children/descendants
  line.childitems?.forEach((child) => {
    if (hasProvisionalChild(child.childitems, false)) {
      getExpandProvisionalParents(child, newExpanded);
    }
  });

  return newExpanded;
};

export const ProvisionalDescription = ({
  data,
  isUpdateExpanded,
  isTreeExpanded,
  expandProvisionalParent,
}) => {
  // show description section with provisional chip
  let provisionalChip = "";
  if (data?.is_provisional) {
    provisionalChip = (
      <LineItemChip className={isUpdateExpanded(data.id) ? "edit" : ""}>
        Provisional
      </LineItemChip>
    );
  } else if (
    hasProvisionalChild(data?.childitems, false) &&
    !isTreeExpanded(data.id)
  ) {
    provisionalChip = (
      <BootstrapTooltip
        title="There are child items that are provisional"
        placement="bottom"
      >
        <LineItemChip
          className={isUpdateExpanded(data.id) ? "edit" : ""}
          onClick={() => expandProvisionalParent(data)}
        >
          P
        </LineItemChip>
      </BootstrapTooltip>
    );
  }

  return (
    <Fragment>
      {data?.description}
      <div className="space" />
      {provisionalChip}
    </Fragment>
  );
};

export const ConvertModal = ({ open, setOpen, data, convert }) => (
  <Modal
    handleClose={() => setOpen(false)}
    open={open}
    title="Convert to Fixed Value"
    divider={false}
  >
    <ConvertModalContent>
      <div className="blue-banner">
        <div className="top">
          <span>Value to be fixed</span>
          <span>{currencySubunitToUnit(data?.total)}</span>
        </div>
        <div>
          <span>Certified to date</span>
          <span>{currencySubunitToUnit(data?.approved_up_to_date_total)}</span>
        </div>
      </div>
      This will change this provisional item to a fixed value and cannot be
      reverted back.
    </ConvertModalContent>
    <ConvertModalButtons>
      <Button
        title="Convert to Fixed"
        className="margin-left"
        onClick={() => {
          setOpen(false);
          convert();
        }}
      />
      <Button title="Leave as Provisional" onClick={() => setOpen(false)} />
    </ConvertModalButtons>
  </Modal>
);

export const LineItemForm = ({
  onChange,
  form,
  status,
  isBlocked,
  lineitemType,
  accountConfig,
  isParent,
  field,
  variationLineItemType,
  componentClass,
}) => {
  const [displayTotal, setDisplayTotal] = useState("");

  let refWidth = 88;
  let descWidth =
    lineitemType === RATE && !isParent
      ? status === "overview"
        ? 482
        : 320
      : lineitemType === ON_SITE || lineitemType === OFF_SITE
        ? status === "overview"
          ? 966
          : 910
        : field && field === "dialog"
          ? 706
          : 730;
  let qtyWidth = 139;
  let unitWidth = 98;
  let rateWidth = status === "overview" ? 200 : 131;
  let totalWidth = status === "overview" ? 230 : 174;
  const adjustmentDescMap = {
    [COMPONENT_CLASS.CLAIM_VARI]: 1130,
    [COMPONENT_CLASS.CERT_VARI]: 1180,
  };
  if (
    [COMPONENT_CLASS.CLAIM_VARI, COMPONENT_CLASS.CERT_VARI].includes(
      componentClass,
    )
  ) {
    refWidth += 40;
    descWidth += 40;
    qtyWidth += 40;
    unitWidth += 40;
    rateWidth += 40;
    totalWidth += 40;

    // LUMP
    if (lineitemType === LUMP) {
      descWidth += 100;
      totalWidth += 60;
      refWidth += 60;
    }
    if (variationLineItemType === VARIATION_TYPES.MULTI) {
      descWidth = adjustmentDescMap[componentClass];
    }
  } else if (
    componentClass === COMPONENT_CLASS.CONTRACT_VARI &&
    variationLineItemType === VARIATION_TYPES.MULTI
  ) {
    descWidth = 925;
  }

  return (
    <div className="form_body">
      <Input
        label="Ref"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name="label"
        value={form.label}
        width={`${refWidth}px`}
      />
      <Input
        label="Description"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name="description"
        value={form.description}
        width={`${descWidth}px`}
      />
      {lineitemType === RATE &&
        !isParent &&
        variationLineItemType !== VARIATION_TYPES.MULTI && (
          <Fragment>
            <Input
              label="Qty"
              name="quantity"
              field="number"
              onValueChange={(e) => onChange("quantity", e.floatValue)}
              value={form.quantity}
              allowNegative={true}
              decimalScale={2}
              style={{ width: `${qtyWidth}px` }}
              disabled={isBlocked}
            />

            <Input
              label="Unit"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              name="unit"
              value={form.unit}
              width={`${unitWidth}px`}
            />
            <Input
              label="Rate"
              name="rate"
              field="number"
              onValueChange={(e) => onChange(RATE, e.floatValue)}
              value={form.rate}
              allowNegative={true}
              decimalScale={2}
              style={{ width: `${rateWidth}px` }}
              disabled={isBlocked}
            />
            {variationLineItemType !== VARIATION_TYPES.MULTI && (
              <Input
                label="Total"
                name="total"
                value={convertToCurrencyUnit(form.rate * form.quantity)}
                disabled
                width={`${totalWidth}px`}
              />
            )}
          </Fragment>
        )}
      {/* For a lumpsum lineitem, relabel "rate" as "total" */}
      {(lineitemType === LUMP || isParent) &&
        variationLineItemType !== VARIATION_TYPES.MULTI && ( // parent lineitems use this format regardless of type
          <Input
            label="Total"
            name="rate"
            field="number"
            onValueChange={(e) => {
              setDisplayTotal(e.floatValue);
              onChange(RATE, e.floatValue);
            }}
            placeholder={convertToCurrencyUnit(form.rate * form.quantity)}
            value={displayTotal}
            prefix={
              accountConfig?.getIn(["direction", "value"]) === "LTR"
                ? accountConfig?.getIn(["currency", "title"]) ||
                  DEFAULT_CURRENCY
                : ""
            }
            suffix={
              accountConfig?.getIn(["direction", "value"]) === "RTL"
                ? accountConfig?.getIn(["currency", "title"]) ||
                  DEFAULT_CURRENCY
                : ""
            }
            allowNegative={true}
            decimalScale={2}
            style={{ width: status === "overview" ? "230px" : "174px" }}
            disabled={isBlocked}
          />
        )}
    </div>
  );
};

// for getting previous value of a state
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const ExcludeRetentionBox = ({
  checked,
  onChange,
  disabled,
  className,
}) => {
  return (
    <CheckBoxWrap disabled={disabled} className={className}>
      <input
        type="checkbox"
        id="exclude_retention"
        checked={checked}
        onChange={onChange}
        className="checkBox"
        disabled={disabled}
      />
      <label htmlFor="exclude_retention">Exclude From Retention</label>
      <BootstrapTooltip
        title="Enabling this setting will exclude this line item from retention calculations."
        placement="bottom"
      >
        <div className="info-icon" />
      </BootstrapTooltip>
    </CheckBoxWrap>
  );
};

/**
 * Retrieves the variation status from data.
 *
 * @param {Object} d - The data (line item data) contain the variation status.
 * @returns {string|undefined} - Returns the current status if it exists, otherwise undefined.
 */
const getVariationStatus = (d) => d?.current_status;

/**
 * Below actions apply rules along with the status and level (contract or claim/cert) in variation tables.
 *
 * Rules:
 * - `is_provisional` overrides other rules: If the item is provisional, it allows the action by returning `false`.
 * - `certifiedVariation` is true if the variation has been certified (i.e., `approved_up_to_date_total !== 0`) or, for child items, if its parent is certified.
 * - `variationStatus`: If the status is not `PENDING`, actions are blocked to prevent unintended updates.
 *
 * Used At:
 * - Contract Level:
 *   - Contract Variations Table (`variationTable`)
 * - Claim/Cert Level:
 *   - Cert Variations Table (`pay_certificate_table`)
 *   - Claim Variations Table (`claim_percent_table`)
 */
const isActionBlocked = (d, tableContent, isContractLevel) => {
  // Allow action if the item is provisional
  if (d?.is_provisional) return false;

  // Check only at the contract level
  // Block action if the variation or its parent has been certified
  const certifiedVariation =
    d.approved_up_to_date_total !== 0 ||
    (d?.parent_id && d?.parent?.approved_up_to_date_total !== 0);
  if (isContractLevel && certifiedVariation) return true;

  // Handle cases where the variation is not certified
  // Get the parent item, if it exists based on contract level
  const parentItem = isContractLevel
    ? d?.parent
    : getParentItemById(d?.parent_id, tableContent.toJS());

  // Get the variation status of the parent item or the current data object
  const variationStatus = getVariationStatus(parentItem || d);

  // Block action if the variation status is approved or rejected
  return ![undefined, VARIATION_LINEITEM_STATUS.PENDING].includes(
    variationStatus,
  );
};

const isEditingBlocked = (d, tableContent, isContractLevel = false) =>
  isActionBlocked(d, tableContent, isContractLevel);
// Reverse the result to allow adding child item if not contract level
const notAbleToAddChild = (d, tableContent, isContractLevel = false) =>
  isContractLevel
    ? isActionBlocked(d, tableContent, isContractLevel)
    : !isActionBlocked(d, tableContent, isContractLevel);
// Reverse the result to allow deletion
const canDelete = (d, tableContent, isContractLevel = false) =>
  !isActionBlocked(d, tableContent, isContractLevel);

/**
 * Determines whether the "Exclude From Retention" checkbox should be displayed
 * and whether it should be disabled.
 *
 * @param {Object} [data={}] - The data object, defaults to an empty object if undefined.
 * @param {boolean} [hasRetention=false] - Flag indicating whether retention exists.
 * @param {Array} tableContent - The content of the entire table, used to find parent items.
 * @param {boolean} [isContractLevel=false] - Flag indicating if at contract level.
 * @returns {[boolean, boolean]} - Returns an array with two booleans:
 *   - The first boolean indicates if the checkbox should be displayed.
 *   - The second boolean indicates if the checkbox should be disabled (only if displayed).
 */
const canEditExcludeFromRetention = (
  data = {},
  hasRetention,
  tableContent,
  isContractLevel = false,
) => {
  // If there's no retention, don't display the checkbox and no need to disable it
  if (!hasRetention) {
    return [false, false];
  }

  // If data is empty ({}), so display the checkbox and enable it
  if (Object.keys(data).length === 0) {
    return [true, false];
  }

  // Prevent the checkbox from being visible if the variation type is MULTI or if the item is a parent
  if (
    data?.extra_info?.variation_type === VARIATION_TYPES.MULTI ||
    checkIsParent(data)
  ) {
    return [false, false];
  }

  // Check only at the contract level
  // if the variation or its parent has been certified, display but disabled
  const certifiedVariation =
    !isNewPlaceholderLineItem(data?.id) &&
    (data?.approved_up_to_date_total !== 0 ||
      (data?.parent_id && data?.parent?.approved_up_to_date_total !== 0));
  if (isContractLevel && certifiedVariation) return [true, true];

  // Get the parent item, depending on whether it's at the contract level or based on parent IDs
  const parentItem = isContractLevel
    ? data?.parent ||
      getParentItemById(data?.parentItemId, tableContent?.toJS())
    : getParentItemById(
        data?.parent_id || data?.parentItemId,
        tableContent?.toJS(),
      );

  // Get the variation status of the parent item or the current data object
  const variationStatus = getVariationStatus(parentItem || data);

  // Disable the checkbox if the status is approved, or rejected
  const shouldDisableCheckbox = [
    VARIATION_LINEITEM_STATUS.APPROVED,
    VARIATION_LINEITEM_STATUS.REJECTED,
  ].includes(variationStatus);

  // Display the checkbox if the status is pending, approved, or rejected
  const shouldDisplayCheckbox = [
    VARIATION_LINEITEM_STATUS.PENDING,
    VARIATION_LINEITEM_STATUS.APPROVED,
    VARIATION_LINEITEM_STATUS.REJECTED,
  ].includes(variationStatus);

  return [shouldDisplayCheckbox, shouldDisableCheckbox];
};

export {
  getVariationStatus,
  isEditingBlocked,
  notAbleToAddChild,
  canDelete,
  canEditExcludeFromRetention,
};
