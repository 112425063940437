import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";

import * as theme from "../../theme";
import BootstrapTooltip from "../toolTip";
import debounce from "lodash/debounce";
export const InputPercentComp = ({
  isParent,
  lineItem,
  onChange,
  value,
  isPercent,
}) => {
  const [disabled, setDisabled] = useState(true);

  const debounceChange = debounce((value) => onChange(value), 200);
  var props;

  if (disabled) {
    props = { value: value };
  } else {
    props = { defaultValue: value };
  }

  const lineItemBoundary = Math.abs(lineItem.quantity);

  return !isParent ? (
    <NumericFormat
      className="input percentInput"
      name={lineItem.id + "percent"}
      decimalScale={2}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        if (!isPercent) {
          return lineItem.is_provisional
            ? true
            : formattedValue === "" ||
                (floatValue >= -lineItemBoundary &&
                  floatValue <= lineItemBoundary);
        }
        return lineItem.is_provisional
          ? true
          : formattedValue === "" || Math.abs(floatValue) <= 100;
      }}
      onFocus={() => setDisabled(false)}
      onBlur={() => setDisabled(true)}
      {...props}
      onValueChange={(values) => {
        if (!disabled) {
          debounceChange(values.floatValue);
        }
      }}
    />
  ) : (
    <NumericFormat
      disabled
      className="input percentInput"
      name={lineItem.id + "percent"}
      value={value}
      decimalScale={2}
    />
  );
};
export const InputValueComp = ({ isParent, lineItem, onChange, value }) => {
  const [disabled, setDisabled] = useState(true);
  const debounceChange = debounce((value) => onChange(value), 300);
  var props;

  if (disabled) {
    props = { value: value };
  } else {
    props = { defaultValue: value };
  }

  return !isParent ? (
    <NumericFormat
      className="input valueInput"
      style={{ backgroundColor: theme.palette.white }}
      name={lineItem.id + "value"}
      thousandSeparator
      decimalScale={2}
      allowLeadingZeros={false}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        let max = Math.abs(lineItem.total / 100);
        return lineItem.is_provisional
          ? true
          : formattedValue === "" || Math.abs(floatValue) <= max;
      }}
      onFocus={() => setDisabled(false)}
      onBlur={() => setDisabled(true)}
      {...props}
      onValueChange={(values) => {
        if (!disabled) {
          debounceChange(values.floatValue);
        }
      }}
    />
  ) : (
    <NumericFormat
      name={lineItem.id + "value"}
      className="input valueInput"
      disabled
      thousandSeparator
      decimalScale={2}
      allowLeadingZeros={false}
      value={value}
    />
  );
};

export const NumericInput = ({
  isParent,
  lineItem,
  onChange,
  displayValue,
  isDisabled,
}) => {
  return (
    <BootstrapTooltip
      disableFocusListener={true}
      disableHoverListener={!isParent}
      title={"This total is calculated from the combined sum of child items."}
      placement="left"
    >
      <div className="value_container">
        <InputPercentComp
          isParent={isParent || isDisabled}
          lineItem={lineItem}
          isPercent={true}
          onChange={(value) =>
            onChange(lineItem.id, "percent", value, false, false)
          }
          value={displayValue(lineItem.id, "percent")}
        />
        <div className="input percentInput percent">%</div>
        <InputValueComp
          isParent={isParent || isDisabled}
          lineItem={lineItem}
          onChange={(value) =>
            onChange(lineItem.id, "value", value, false, false)
          }
          value={displayValue(lineItem.id, "value")}
        />
      </div>
    </BootstrapTooltip>
  );
};

export const NumericInputGroup = ({
  isParent,
  lineItem,
  onChange,
  displayValue,
  defaultOption,
  saveLineItemType,
  isDisabled,
}) => {
  const [percentOrQty, setPercentOrQty] = React.useState("percent");

  const performChange = (value, type) => {
    onChange(lineItem.id, type, value, false, false);
  };

  const changeType = (type) => {
    setPercentOrQty(type);
    onChange(lineItem.id, type, displayValue(lineItem.id, type), false, true);
    saveLineItemType(lineItem.id, type);
  };

  useEffect(() => {
    setPercentOrQty(defaultOption);
    onChange(
      lineItem.id,
      defaultOption,
      displayValue(lineItem.id, defaultOption),
      true,
      false,
    );
    // eslint-disable-next-line
  }, []);

  return (
    <BootstrapTooltip
      disableFocusListener={true}
      disableHoverListener={!isParent}
      title={"This total is calculated from the combined sum of child items."}
      placement="left"
    >
      <div className="value_container">
        <InputPercentComp
          isParent={isParent || isDisabled}
          lineItem={lineItem}
          onChange={(value) => performChange(value, percentOrQty)}
          isPercent={percentOrQty === "percent"}
          value={displayValue(lineItem.id, percentOrQty)}
        />
        <div className="input percentInput percentQty">
          <div className="percentAndQty">
            <BootstrapTooltip
              title={"Cumulative percentage"}
              placement="bottom"
            >
              <div
                disabled={isParent || isDisabled}
                onClick={() => {
                  changeType("percent");
                }}
                className={percentOrQty === "percent" ? "selected" : ""}
              >
                %
              </div>
            </BootstrapTooltip>
            <BootstrapTooltip title={"Cumulative quantity"} placement="bottom">
              <div
                disabled={isParent || isDisabled}
                onClick={() => {
                  changeType("qty");
                }}
                className={percentOrQty === "qty" ? "selected" : ""}
              >
                #
              </div>
            </BootstrapTooltip>
          </div>
        </div>
        <InputValueComp
          isParent={isParent || isDisabled}
          lineItem={lineItem}
          onChange={(value) => performChange(value, "value")}
          value={displayValue(lineItem.id, "value")}
        />
      </div>
    </BootstrapTooltip>
  );
};
