import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { PayCertTableWrapper, TableWarning } from "./styled";
import underscore from "lodash";

import { AddBtn } from "../button";
import TableComponent, { filterTableContent } from "./basic_table";
import { NumericInput, NumericInputGroup } from "./numeric_input_comp";
import {
  removeTrailingZeros,
  checkIsParent,
  isObjectEmpty,
} from "../../utils/helper";
import { convertToPercent, roundNearest } from "../../utils/numeralHelper";
import {
  convertToCurrencyUnit,
  currencySubunitToUnit,
  calculateUnitToSubunit,
} from "../../utils/currencyHelper";
import {
  dropDecimalColumns,
  dropDecimal,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import profile from "../../utils/profile";
import {
  CONTRACT,
  EDIT,
  VIEW,
  LUMP,
  CERT_ITEM_ACTION,
  LINEITEM_STATUS,
  VARIATION_LINEITEM_STATUS,
  CONTRACT_TYPE,
  VARIATION,
  VARIATION_TYPES,
  RATE,
  STATUS_DRAFT,
  VARIATION_LINEITEM_TYPES_NEW_OPTIONS,
  CERT_VARIATION_ACTION_TYPES,
} from "../../utils/constants";
import API from "../../server";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
  getAllChildKeys,
  getLastChildParentKeys,
  isEditingBlocked,
  notAbleToAddChild,
  canDelete,
} from "../lineitem/sharedComponent";
import { VariationsTotalDisplay } from "./shared_component";
import LineitemActions from "common/lineitem_actions/lineitem_actions";
import InputDialogue from "../lineitem/variationInputTable";
import NewVariationLineItemModal from "../../pages/variations/components/newVariationLineItemModal";
import {
  isVariation,
  getParentItemById,
  isNewPlaceholderLineItem,
} from "../../pages/makeclaim/shared/claimCertHelper";
const modalActionOptions = {
  EDIT: "editing_item",
  ADD: "add",
  ADD_CHILD: "adding_child_item",
};
class Table extends PureComponent {
  constructor() {
    super();
    this.state = {
      openDialog: false,
      input_type: modalActionOptions.ADD, //adding_item/editing_item/adding_child_item
      tableItem: {
        //store new to add lineitme
        label: "",
        description: "",
        quantity: 0,
        unit: "",
        rate: 0,
        detailed_description: "",
        variation_reference: "",
        submit_date: "",
        approved_date: "",
        exclude_retention: false,
        childitems: [],
      },
      isParent: false,
      editItem: {
        //store the edited item id
        id: "",
      },
      editItemFiles: [],
      expands: [], //expandTreeKeys
      allowEditContract: true,
      allowViewContract: false,
      prevLineType: "rate",
      type: "",

      expandKeys: [],
      expandEdit: false,
      expandAdd: false,
      expandChildKeys: [],
      parentId: "",
      lastChildKeys: [],
      expandAttach: false,
      addingItem: false,
      table: [],
      isVariationTypeDialogOpen: false,
      isVariationFormDialogOpen: false,
      lineitemVariationType: VARIATION_TYPES.BASIC,
      // for performing rolling back operations
      actionLineItem: {},
    };
  }
  returnValue = (itemId, list, field) => {
    let percent = 0,
      value = 0,
      qty = 0;
    var obj = list.find((obj) => obj.get("id") === itemId);
    if (!obj) {
      return null;
    }

    percent = obj.get("payment_percent");
    value = obj.get("payment_value");
    qty = obj.get("cert_qty");
    if (qty === 0) {
      qty = value / obj?.get("item_rate");
    }

    if (field === "percent") {
      return removeTrailingZeros(percent);
    }
    if (field === "qty") {
      return qty;
    } else {
      return value / 100;
    }
  };
  saveLineItemType = (itemId, type) => {
    const { proId, contractId } = this.props.match.params;
    API.update_one_lineitem(proId, contractId, itemId, { cert_option: type });
  };
  getReason = (itemId, list) => {
    let obj = list.find((ele) => ele.get("id") === itemId);
    if (!obj) {
      return { reason: "", isRequiredReason: null };
    }
    return {
      reason: obj.get("payment_comment") || "",
      isRequiredReason: obj.get("required_reason"),
      reason_status: obj.get("reason_status"),
    };
  };

  // This function rollback the variation type to basic in case if user cancels the operation
  rollbackLineItem = () => {
    const { actionLineItem } = this.state;
    if (!isObjectEmpty(actionLineItem)) {
      const { proId, contractId, claimId } = this.props.match.params;

      const callbackFunction = () => this.setState({ actionLineItem: {} });

      this.props.updateSingleCertVariationsItem(
        proId,
        contractId,
        claimId,
        "",
        actionLineItem?.id,
        {
          extra_info: {
            ...actionLineItem?.extra_info,
            variation_type: VARIATION_TYPES.BASIC,
          },
        },
        callbackFunction,
      );
    }
  };

  handleCloseEdit = () => {
    this.rollbackLineItem();
    return this.setState({
      expandKeys: [],
      expandEdit: false,
      expandAdd: false,
      parentId: "",
      expandChildKeys: [],
      tableItem: {
        label: "",
        description: "",
        quantity: 0,
        unit: "",
        rate: 0,
        detailed_description: "",
        variation_reference: "",
        submit_date: "",
        approved_date: "",
        exclude_retention: false,
        isVariationTypeDialogOpen: false,
        isVariationFormDialogOpen: false,
        childitems: [],
      },
    });
  };

  handleSave = (id, lineType, info, inputType) => {
    let lineItem = { ...info, lineitem_options: lineType };
    // add new child or parent item, edit item
    if (inputType === modalActionOptions.ADD) {
      this.props.handleItem(CERT_ITEM_ACTION.ADD, "", lineItem);
      this.setState({ prevLineType: lineType });
    } else if (inputType === modalActionOptions.ADD_CHILD) {
      this.props.handleItem(CERT_ITEM_ACTION.ADD, "", lineItem);
    } else {
      this.props.handleItem(CERT_ITEM_ACTION.EDIT, id, lineItem);
    }

    return this.closeVariationLineitemOperation();
  };

  handleDeleteItem = (id) => {
    //delete or clean input item
    if (this.state.input_type === modalActionOptions.EDIT) {
      this.props.handleItem(CERT_ITEM_ACTION.DELETE, id);
      return this.setState({ openDialog: false });
    } else {
      this.handleCloseEdit();
    }
  };

  getModalTitle = (description) => {
    let title =
      this.state.input_type === modalActionOptions.ADD
        ? "Add Variation"
        : this.state.input_type === modalActionOptions.ADD_CHILD
          ? "Add Child Item To " + description
          : "Edit Item - " + description;

    return title;
  };

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  // check if line item is being edited
  isUpdateExpanded = (id) => {
    return this.state.expandKeys.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: [...expanded] });
  };

  // Method definitions...
  closeVariationLineitemOperation = () => {
    this.setState({
      isVariationTypeDialogOpen: false,
      isVariationFormDialogOpen: false,
      lineitemVariationType: VARIATION_TYPES.BASIC,
      addingItem: false,
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
    });
  };

  handleCleanItem = (id) => {
    this.props.deleteItem(id);
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  setIntialValue = (initialFiles) => {
    this.props.setEditFiles(initialFiles);
  };

  handleExpand = (d, isExpand) => {
    const isParent = checkIsParent(d);
    const childKeys = isParent
      ? getAllChildKeys(d.childitems, []).concat(d.id)
      : [];
    const lastChildKeys = isParent ? getLastChildParentKeys(d, []) : [];

    this.setState({
      expandChildKeys: childKeys,
      expands: isParent
        ? this.state.expands.concat(childKeys)
        : this.state.expands,
      parentId: d.id,
      expandKeys: [
        childKeys.length > 1 ? childKeys[childKeys.length - 2] : d.id,
      ],
      lastChildKeys: lastChildKeys,
      expandEdit: false,
      expandAdd: true,
      input_type: modalActionOptions.ADD,
      addingItem: false,
      isVariationFormDialogOpen: false,
      expandAttach: false,
    });
  };

  handleEditExpand = (d, editFileList, isExpand) => {
    this.setState({ editItem: d });
    if (this.props.tableType !== VARIATION) {
      return null;
    }
    if (isNewPlaceholderLineItem(d?.id)) {
      return;
    }
    this.setState({
      parentId: d.id,
      expandKeys: [d.id],
      expandEdit: true,
      expandAdd: false,
      expandChildKeys: [],
      lastChildKeys: [],
      expandAttach: false,
      isVariationFormDialogOpen: false,
      input_type: modalActionOptions.EDIT,
    });
  };

  lineitemEditFiles = (id) => {
    const {
      editFileList,
      match: {
        params: { proId, contractId },
      },
    } = this.props;
    if (editFileList.size > 0) {
      this.setState({ editItemFiles: editFileList });
      return;
    }

    if (!id) {
      this.setState({ editItemFiles: fromJS([]) });
      return;
    }
    API.read_line_item_files(proId, contractId, id).then((res) => {
      const resFile = res?.data?.data?.file;
      if (resFile) {
        this.setState({ editItemFiles: fromJS(resFile) });
        this.props.handleFilesChange(fromJS(resFile));
      }
    });
  };

  handleClickAddLineItem = (d) => {
    const parentData = getParentItemById(d?.parentItemId, this.variableContent);
    this.handleExpand(parentData, true);
  };

  fireInitialExpand() {
    const noValidChildItems = this.variableContent
      .filter((item) =>
        item.childitems?.every((child) => isNewPlaceholderLineItem(child.id)),
      )
      .map((item) => item.id);
    this.setState(
      {
        expands: noValidChildItems,
      },
      this.triggerInitialExpand,
    );
  }

  triggerInitialExpand = () => {
    const { expands } = this.state;
    const { onTreeExpand } = this.props;

    if (onTreeExpand) {
      onTreeExpand(expands);
    }
  };

  render() {
    const {
      projectData,
      tableContent,
      table_title,
      certList,
      certTotal,
      totalValues,
      reasonOption,

      tableField, // variation/ base_contract/ contra same with the api parameter name

      inputCert,
      input_method,
      inputReason,
      isNewCert,
      updateComment,
      updateStatus,
      accountConfig,
      allowExcludeRetention,
      lineitemRefs,

      // cert variation attachments
      editFileList,
      uploadFiles,
      handleFilesChange,
      uploadAttachments,
      cancelAttachment,
      initialFileList,
      deleteAttachment,
      contractInfo,
      setEditFiles,
      handleFileCancel,
      setFileList,
      tableType,
      certInfo,
      certifyPendingVariation,
    } = this.props;

    const { isVariationTypeDialogOpen, isVariationFormDialogOpen } = this.state;

    const {
      expandKeys,
      parentId,
      expandChildKeys,
      lastChildKeys,
      expandEdit,
      previousLineType,
    } = this.state;

    const showCurrency = !accountConfig?.get("enable_header_currency");
    const CertWord = accountConfig?.getIn(["cert", "value"]),
      CertNounWord = accountConfig?.getIn(["cert", "noun"]);
    const claimTerm = accountConfig.getIn(["claim"]);

    let truncateColumn = dropDecimalColumns(tableContent.toJS(), [
      "total",
      "claim_to_date_value",
      "cert_to_date_value",
      "last_cert_value",
      "current_claim",
    ]);
    const {
      tableItem,
      editItem,
      expands,
      allowEditContract,
      allowViewContract,
    } = this.state;

    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        width: 53,
        colSpan: (d) => (isNewPlaceholderLineItem(d?.id) ? 11 : 1),
        render: (d) => {
          if (isNewPlaceholderLineItem(d?.id)) {
            return (
              <div
                className="placeholder-text"
                onClick={() => this.handleClickAddLineItem(d)}
              >
                Click here to start adding line items
              </div>
            );
          }
          return d.label;
        },
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        width: isVariation(tableField) ? 295 : 220,
        render: (d) => (
          <div>
            {lineitemRefs && <div ref={lineitemRefs[d?.id] || null} />}
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 113,
        render: (d) => {
          const formattedTotal = currencySubunitToUnit(
            truncateColumn["total"] ? roundNearest(d.total) : d.total,
            !accountConfig?.get("enable_header_currency"),
            false,
            showCurrency,
          );

          return (
            <VariationsTotalDisplay
              data={d}
              variTitle={tableField}
              formattedTotal={formattedTotal}
            />
          );
        },
      },
      {
        title: headerFormatter(
          `${claimTerm?.get("value")} to Date`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "group_header grey",
        render: (d) => {
          return (
            <div className="group_value">
              <div className="percent_value">
                {convertToPercent(d.claimed_to_date_percent / 100)}
              </div>
              <div>
                {currencySubunitToUnit(
                  truncateColumn["claim_to_date_value"]
                    ? roundNearest(d.claimed_to_date_value)
                    : d.claimed_to_date_value,
                  !accountConfig?.get("enable_header_currency"),
                  truncateColumn["claim_to_date_value"],
                  showCurrency,
                )}
              </div>
            </div>
          );
        },
        width: 168,
      },
      {
        title: headerFormatter(
          CertWord + " to Date",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            d.cert_to_date_value,
            !accountConfig?.get("enable_header_currency"),
            truncateColumn["cert_to_date_value"],
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          "Variance to Date",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            dropDecimal(d.claimed_to_date_value - d.cert_to_date_value)
              ? roundNearest(d.claimed_to_date_value - d.cert_to_date_value)
              : d.claimed_to_date_value - d.cert_to_date_value,
            !accountConfig?.get("enable_header_currency"),
            dropDecimal(d.claimed_to_date_value - d.cert_to_date_value),
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          "Previous Net " + CertWord,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            truncateColumn["last_cert_value"]
              ? roundNearest(d.last_cert_value)
              : d.last_cert_value,
            !accountConfig?.get("enable_header_currency"),
            truncateColumn["last_cert_value"],
            showCurrency,
          ),
        width: 113,
      },
      {
        className: " emptyColumn",
        render: (d) => "",
        width: 8,
      },
      {
        title: headerFormatter(
          `Current ${claimTerm?.get("noun")}`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        render: (d) =>
          currencySubunitToUnit(
            truncateColumn["current_claim"]
              ? roundNearest(d.current_claim)
              : d.current_claim,
            !accountConfig?.get("enable_header_currency"),
            truncateColumn["current_claim"],
            showCurrency,
          ),
        width: 113,
      },
      {
        title: headerFormatter(
          `Current ${CertNounWord} Value`,
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "group_header group_input",
        width: 240,
        render: (d) => {
          const currentStatus = isVariation(table_title)
            ? d?.current_status
            : undefined;
          const disableList = [VARIATION_LINEITEM_STATUS.REJECTED];
          if (!certifyPendingVariation) {
            disableList.push(VARIATION_LINEITEM_STATUS.PENDING);
          }
          const verifiedStatus = disableList.includes(currentStatus);
          return (
            <div className="certValue">
              {d.lineitem_options === LUMP || checkIsParent(d) ? (
                <NumericInput
                  isParent={checkIsParent(d)}
                  input_method={input_method}
                  lineItem={d}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputCert(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.returnValue(id, certList, field)
                  }
                  isDisabled={verifiedStatus}
                />
              ) : (
                <NumericInputGroup
                  isParent={checkIsParent(d)}
                  lineItem={d}
                  defaultOption={d?.cert_option}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputCert(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.returnValue(id, certList, field)
                  }
                  saveLineItemType={(id, type) =>
                    this.saveLineItemType(id, type)
                  }
                  isDisabled={verifiedStatus}
                />
              )}
            </div>
          );
        },
      },
      {
        title: isVariation(tableField) ? "" : "Reason",
        className: "claim-actions hidden reason",
        width: isVariation(tableField) ? 80 : 142,
        render: (d) => {
          return (
            <div className="inlineDiv">
              <LineitemActions
                data={d}
                expands={expands}
                onTreeExpand={(key) => {
                  return this.setState({
                    expands: [...this.state.expands, key],
                  });
                }}
                handleExpand={(d, isExpand) => {
                  this.rollbackLineItem();
                  if (
                    isExpand &&
                    d?.extra_info?.variation_type === VARIATION_TYPES.BASIC
                  ) {
                    this.setState({ actionLineItem: d });

                    const { proId, contractId, claimId } =
                      this.props.match.params;
                    let updatedExtraInfo = {
                      extra_info: {
                        ...d?.extra_info,
                        variation_type: VARIATION_TYPES.MULTI,
                      },
                    };

                    const callbackExpand = (d) => {
                      this.handleExpand(d, isExpand, (newState) =>
                        this.setState(newState),
                      );
                    };

                    this.props.updateSingleCertVariationsItem(
                      proId,
                      contractId,
                      claimId,
                      "",
                      d.id,
                      updatedExtraInfo,
                      callbackExpand,
                    );
                  }
                  return this.handleExpand(d, isExpand);
                }}
                handleEditExpand={(d, isExpand) =>
                  this.handleEditExpand(d, editFileList, isExpand)
                }
                showComment={
                  !isNewCert && (allowEditContract || allowViewContract)
                }
                updateComment={updateComment}
                showEdit={allowEditContract}
                showAdd={
                  allowEditContract && notAbleToAddChild(d, tableContent)
                }
                type={CONTRACT_TYPE.cert}
                viewOnly={allowViewContract}
                allowEditContract={allowEditContract}
                otherParty={projectData.get("payee_entity_name")}
                getReason={this.getReason}
                reasonInitValue={this.getReason(d.id, certList)}
                reasonOption={reasonOption}
                inputReason={(reason, e) => inputReason(d.id, reason)}
                accountConfig={accountConfig}
                tableField={tableField}
                updateStatus={updateStatus}
                isEmptyMulti={
                  d?.childitems?.length === 1 &&
                  isNewPlaceholderLineItem(d.childitems[0]?.id)
                }
              />
            </div>
          );
        },
      },
      {
        type: "expand",
        className: "all-actions emptyColumn hidden",
        width: "0%",
        onClick: (d, isExpand) => {
          return this.handleExpand(d, isExpand);
        },
        render: (d) => {
          if (tableType !== VARIATION || isVariation(d?.type)) {
            return null;
          }

          if (this.state.expandEdit) {
            let lineItem = fromJS(d);
            lineItem = lineItem.set(
              "quantity",
              calculateUnitToSubunit(lineItem.get("quantity")),
            );
            lineItem = lineItem.toJS();

            // Edit Item
            return () => (
              <>
                <div className="inputWrapper">
                  <InputDialogue
                    dateWidth="400px"
                    referenceWidth="400px"
                    detailedDescriptionWidth="644px"
                    componentClass="cert-vari"
                    id={parentId ? parentId : d?.id}
                    title={`Edit Variation: ${d.label}`}
                    // TODO - Do This Like Claim With Ticket PL-2537
                    /*{WE NEED TO HAVE VARIATION STATUS BUTTON}*/
                    handleClose={() => {
                      return this.handleCloseEdit();
                    }}
                    handleSaveItem={(lineType, form) => {
                      return this.handleSave(
                        d?.id,
                        lineType,
                        form,
                        modalActionOptions.EDIT,
                      );
                    }}
                    action="save"
                    previousLineType={previousLineType}
                    accountConfig={accountConfig}
                    isDraft={contractInfo?.get("status") === STATUS_DRAFT}
                    // Exclude From Retention Props
                    lineItemData={lineItem}
                    allowExcludeRetention={allowExcludeRetention}
                    tableContent={tableContent}
                    variationLineItemType={d?.extra_info?.variation_type}
                    tableList={lineItem}
                    files={
                      editFileList && editFileList.size > 0
                        ? editFileList
                        : this.state.editItemFiles
                    } //giving results
                    handleCleanItem={() => this.handleDeleteItem(editItem.id)}
                    setFileList={(files) => setFileList(files)}
                    handleFileCancel={(fileId) =>
                      deleteAttachment(fileId, d?.id)
                    }
                    handleFilesChange={(files) =>
                      uploadAttachments(files, d?.id)
                    }
                    uploadAttachments={uploadAttachments}
                    cancelAttachment={cancelAttachment}
                    deleteAttachment={deleteAttachment}
                    // prevent line item from editing quantity/rate
                    isBlocked={isEditingBlocked(d, tableContent)}
                    // can delete line item prop
                    canDelete={canDelete(d, tableContent)}
                    isChild={
                      ![VARIATION_TYPES.MULTI, VARIATION_TYPES.BASIC].includes(
                        d?.extra_info?.variation_type,
                      )
                    }
                  />
                </div>
              </>
            );
          }

          if (this.state.expandAdd) {
            // Add Child Item
            return () => (
              <>
                <div className="inputWrapper">
                  <InputDialogue
                    dateWidth="400px"
                    referenceWidth="400px"
                    detailedDescriptionWidth="614px"
                    componentClass="cert-vari"
                    id={parentId ? parentId : d?.id}
                    title="Add Item"
                    handleClose={() => {
                      return this.handleCloseEdit();
                    }}
                    handleSaveItem={(lineType, form) => {
                      this.setState({ actionLineItem: {}, expandAdd: false });
                      form.parent_id = parentId ? parentId : d?.id;
                      return this.handleSave(
                        editItem.id,
                        lineType,
                        form,
                        modalActionOptions.ADD_CHILD,
                      );
                    }}
                    action="add"
                    previousLineType={previousLineType}
                    accountConfig={accountConfig}
                    // Exclude From Retention Props
                    lineItemData={d}
                    allowExcludeRetention={allowExcludeRetention}
                    tableContent={tableContent}
                    handleFileCancel={handleFileCancel} // cancel upload file
                    files={
                      this.getModalTitle(editItem.description).includes("Edit")
                        ? editFileList && editFileList.size > 0
                          ? editFileList
                          : this.state.editItemFiles
                        : uploadFiles
                    }
                    handleFilesChange={(files) => {
                      handleFilesChange(files);
                    }}
                    uploadAttachments={uploadAttachments}
                    cancelAttachment={cancelAttachment}
                    deleteAttachment={deleteAttachment}
                    initialFileList={initialFileList}
                    setEditFiles={setEditFiles}
                    isChild
                  />
                </div>
              </>
            );
          }
          return undefined;
        },
      },
    ];

    const totalList = {
      total: currencySubunitToUnit(
        projectData.get(`total_${tableField}`),
        showCurrency,
      ),
      claim_to_date_total: currencySubunitToUnit(
        totalValues.get("claim_to_date_total"),
        showCurrency,
      ),
      cert_to_date_total: currencySubunitToUnit(
        totalValues.get("cert_to_date_total"),
        showCurrency,
      ),
      variance_to_date_total: currencySubunitToUnit(
        totalValues.get("claim_to_date_total") -
          totalValues.get("cert_to_date_total"),
        showCurrency,
      ),
      last_cert_total: currencySubunitToUnit(
        totalValues.get("last_cert_total"),
        showCurrency,
      ),
      current_claim_total: currencySubunitToUnit(
        totalValues.get("current_claim_total"),
        showCurrency,
      ),
      cert_total: convertToCurrencyUnit(certTotal, showCurrency),
    };

    // New Variation Modifications
    let { mainTableContent, variableContent } = filterTableContent(
      tableContent,
      tableField,
      "variations",
      LINEITEM_STATUS.CERT_VARI,
    );
    this.variableContent = variableContent;
    return (
      <Fragment>
        <PayCertTableWrapper>
          <TableComponent
            treeExpandKeys={expands}
            tableContent={mainTableContent}
            tableColumns={columns_dev}
            tableCaption={table_title}
            hasSort
            hasEnhancedTableHeader
            hasEnhancedTableFooter
            rowClassName={(record, index) => {
              if (record.id === parentId) {
                return "highlight";
              }
              if (
                expandChildKeys.indexOf(record.id) !== -1 &&
                !isNewPlaceholderLineItem(record?.id)
              ) {
                return "border-highlight";
              }
              if (isNewPlaceholderLineItem(record?.id)) {
                if (
                  this.isTreeExpanded(record?.parentItemId) &&
                  this.isUpdateExpanded(record?.id)
                ) {
                  return "placeholder-hide";
                }
                return "placeholder";
              }
            }}
            hasVariationClaimCertVari={isVariation(tableField)}
            tableVariContent={variableContent}
            variationClaimCertVariTitle={`Added In This ${CertNounWord}`}
            expandKeys={expandKeys}
            onTreeExpand={(keys) => {
              if (!expandEdit) {
                if (lastChildKeys.length !== 0) {
                  let res = lastChildKeys.filter((x) => !keys.includes(x));
                  if (res.length !== 0) {
                    this.setState({
                      expandKeys: [res[0]],
                    });
                  } else {
                    this.setState({
                      expandKeys: [expandChildKeys[expandChildKeys.length - 2]],
                    });
                  }
                }
              }
              return this.setState({ expands: keys });
            }}
          >
            <table className="header_table">
              <thead>
                <tr>
                  <td style={{ width: isVariation(tableField) ? 347 : 272 }} />
                  <td className="header_label progress">PROGRESS TO DATE</td>
                  <td style={{ width: 119 }} />
                  <td className="header_label cert">
                    {accountConfig?.getIn(["cert", "noun"])}
                  </td>
                  <td />
                </tr>
              </thead>
            </table>
            {/* Add Item */}
            {isVariationFormDialogOpen && (
              <>
                {variableContent.length === 0 && (
                  <div className="certLabel">Added In This {CertNounWord}</div>
                )}
                <InputDialogue
                  dateWidth="400px"
                  referenceWidth="400px"
                  detailedDescriptionWidth="644px"
                  componentClass="cert-vari"
                  title={this.getModalTitle(editItem.description)}
                  tableList={tableItem}
                  type="parentItem"
                  action="add"
                  subAction={LINEITEM_STATUS.CERT_VARI}
                  handleClose={this.closeVariationLineitemOperation}
                  handleSaveItem={(lineType, form) => {
                    this.setState({ actionLineItem: {} });
                    return this.handleSave(
                      editItem.id,
                      lineType,
                      form,
                      modalActionOptions.ADD,
                    );
                  }}
                  handleCleanItem={() => this.handleDeleteItem(editItem.id)}
                  previousLineType={RATE}
                  isDraft={certInfo?.get("status") === STATUS_DRAFT}
                  variationLineItemType={this.state.lineitemVariationType}
                  // Exclude From Retention Props
                  allowExcludeRetention={
                    this.state.lineitemVariationType ===
                      VARIATION_TYPES.BASIC && allowExcludeRetention
                  }
                  tableContent={tableContent}
                  // cert vari files
                  files={
                    this.getModalTitle(editItem.description).includes("Edit")
                      ? editFileList && editFileList.size > 0
                        ? editFileList
                        : this.state.editItemFiles
                      : uploadFiles
                  }
                  handleFilesChange={(files) => {
                    handleFilesChange(files);
                  }}
                  uploadAttachments={uploadAttachments}
                  cancelAttachment={cancelAttachment}
                  deleteAttachment={deleteAttachment}
                  initialFileList={initialFileList}
                  setEditFiles={setEditFiles}
                  handleFileCancel={handleFileCancel}
                />
              </>
            )}
            <table>
              <tfoot>
                <tr>
                  <td className="white start" />
                  <td
                    className="subtotal title"
                    style={{ width: isVariation(tableField) ? 295 : 220 }}
                  >
                    Sub Total
                  </td>
                  <td className="value">{totalList.total}</td>
                  <td className="grey">{totalList.claim_to_date_total}</td>
                  <td className="value">{totalList.cert_to_date_total}</td>
                  <td className="value">{totalList.variance_to_date_total}</td>
                  <td className="value">{totalList.last_cert_total}</td>
                  <td className="white between" />
                  <td className="light_blue claim">
                    {totalList.current_claim_total}
                  </td>
                  <td className="light_blue value">{totalList.cert_total}</td>
                  <td
                    className="white reason"
                    style={{ width: isVariation(tableField) ? 80 : 152 }}
                  />
                </tr>
              </tfoot>
            </table>
          </TableComponent>
        </PayCertTableWrapper>
        {isVariation(tableField) && (
          <>
            {isVariationFormDialogOpen && (
              <div>
                <br />
                These items will be added to the contract once this{" "}
                {CertNounWord?.toLowerCase()} has been issued.
              </div>
            )}
            <AddBtn
              onClick={() => {
                this.rollbackLineItem();
                return this.setState({
                  expandKeys: [],
                  input_type: modalActionOptions.ADD,
                  isVariationTypeDialogOpen: true,
                });
              }}
              disabled={isVariationFormDialogOpen}
              title="Add Variation"
              float="none"
              margin="20px 0 0"
            />
            <NewVariationLineItemModal
              optionsList={VARIATION_LINEITEM_TYPES_NEW_OPTIONS}
              handleContinue={(val) => {
                this.setState({
                  isVariationTypeDialogOpen: false,
                  isVariationFormDialogOpen: true,
                  lineitemVariationType: val,
                });
                this.handleCloseEdit();
              }}
              handleClose={this.closeVariationLineitemOperation}
              isOpen={isVariationTypeDialogOpen}
            />
          </>
        )}
        {certList.some(
          (item) =>
            item.get("reason_status") ===
            CERT_VARIATION_ACTION_TYPES.REASON_ERROR,
        ) && (
          <TableWarning>
            <div className="warningIcon" />
            <span>
              There are variances on some line items. Please ensure that line
              items with variances have a reason specified.
            </span>
          </TableWarning>
        )}
      </Fragment>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      this.setState({ allowViewContract: res });
    });
    this.fireInitialExpand();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.editItem && this.state.editItem !== prevState.editItem) {
      this.lineitemEditFiles(this.state.editItem?.id);
    }

    const { lastVariationObject } = this.props;
    if (
      prevProps?.lastVariationObject &&
      lastVariationObject &&
      !underscore.isEqual(prevProps?.lastVariationObject, lastVariationObject)
    ) {
      this.handleExpand(lastVariationObject, true);
    }

    if (
      prevProps?.uploadFiles &&
      this.props.uploadFiles &&
      !underscore.isEqual(prevProps?.uploadFiles, this.props.uploadFiles)
    ) {
      this.setState({ editItemFiles: this.props.uploadFiles });
    }
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default withRouter(connect(mapStateToProps, null)(Table));
