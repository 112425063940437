export const palette = {
  brightGreen: "#44bf57",
  lightGreen: "#c7eccd",
  warmLightGrey: "#e3e6e8",
  lightAzureGrey: "#edfafd",
  gunmetal: "#425563",
  lightPastelBlue: "#dbf5fa",
  veryColdGrey: "#eceeef",
  lightPastelGreen: "#def1e7",
  lightPastelPink: "#fdd9d4",
  tealBlue: "#0085ad",
  turquoiseBlue: "#00b8de",
  lighterBlue: "#2697b9",
  mediumBlue: "#339dbd",
  veryMediumGrey: "#8e99a1",
  veryWarmLightGrey: "#f0f2f3",
  slateGrey: "#595c5c",
  watermelonRed: "#f34228",
  white: "#ffffff",
  greyWhite: "#f8f8f9",
  wolfGrey: "#c6ccd0",
  lightWolfGrey: "#d8d8d8",
  veryLightBlue: "#e2ebf4",
  tangerineOrange: "#fbbd0b",
  paleYellow: "#fef2ce",
  goldenYellow: "#fdde85",
  darkBlue: "#00607c",
  lightGrey: "#d0d4d8",
  vaultGreen: "#009247",
  rareGrey: "#c9c9c9",
  highlightGrey: "#d9dde0",
  midGrey: "#868686",
  smokyBlack: "#0c0f0c",
  leafGreenSubtle: "#f4fff1",
  leafGreen: "#20a71e",
  carrotOrange: "#f69426",
  carrotOrangeSubtle: "#ffeddf",
  dangerRed: "#ba1b1b",
  dangerRedSubtle: "#ffede9",
  veryLightGrey: "#e8e8eb",
  hoverColor: "#72808a",
};
export const font = {
  mainFont: `Calibri,"Source Sans Pro", sans-serif`,
  mainFontWeight: "bold",
  normalFontWeight: 400,
};
