import React, { Component } from "react";
import { TableFooter, TableStyle } from "./styled";
import TableComponent from "../table/basic_table";
import InputTable from "./variationInputTable";
import { roundNearest } from "../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import { dropDecimal, dropDecimalColumns } from "../../utils/tableFormatHelper";
import { checkIsParent, isObjectEmpty } from "../../utils/helper";
import { skeletonCol, skeletonData } from "../skeleton/component/table";
import {
  VariationDetails,
  VariationsTotalDisplay,
} from "../table/shared_component";
import NewVariationLineItemModal from "pages/variations/components/newVariationLineItemModal";
import {
  getAllChildKeys,
  getExpandProvisionalParents,
  getLastChildParentKeys,
  ProvisionalDescription,
  getVariationStatus,
  isEditingBlocked,
  notAbleToAddChild,
  canDelete,
} from "./sharedComponent";
import Skeleton from "../skeleton";
import { AddBtn as AddButton } from "../button";
import {
  CONTRACT_TYPE,
  LUMP,
  LINEITEM_STATUS,
  STATUS_DRAFT,
  VARIATION_LINEITEM_STATUS,
  VARIATION_TYPES,
  RATE,
} from "../../utils/constants";
import VariationStatusButton from "pages/makeclaim/shared/variationStatusButton";
import {
  getParentItemById,
  isNewPlaceholderLineItem,
} from "pages/makeclaim/shared/claimCertHelper";
import { fromJS } from "immutable";
class Table extends Component {
  constructor() {
    super();
    this.state = {
      expandKeys: [],
      expandTreeKeys: [],
      expandEdit: false,
      expandChildKeys: [],
      parentId: "",
      lastChildKeys: [],
      expandAttach: false,
      addingItem: false,
      openCreateLineItemTypeModal: false,
      addingVariationLineItemType: VARIATION_TYPES.BASIC,
      previousLineType: RATE, // line item type
      hasLogged: false,
      actionLineItem: {},
    };
  }
  handleCloseEdit = () => {
    this.rollbackLineItem();
    return this.setState({
      expandKeys: [],
      expandEdit: false,
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };
  handleCleanItem = (id) => {
    this.props.deleteItem(id);
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  handleSaveEdit = (id, lineType, editInfo) => {
    // only copy objects with keys specified in edit fields or else saving will cause server errors
    const editFields = [
      "label",
      "description",
      "unit",
      "quantity",
      "rate",
      "total",
      "detailed_description",
      "variation_reference",
      "exclude_retention",
      "submit_date",
      "approved_date",
      "is_provisional",
    ];
    let edited = {};

    for (let [key, value] of Object.entries(editInfo)) {
      if (editFields.includes(key)) {
        edited[key] = value;
      }
    }
    edited.lineitem_options = lineType;

    this.props.handleSaveEdit(edited, id);
    //To do: post change to server
    return this.setState({
      expandKeys: [],
      expandEdit: false,
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  setIntialValue = (table) => {
    this.props.setEditFiles(table.uploaded_files);
  };

  checkGreyDash = (line) => {
    return line.lineitem_options === LUMP || line.childitems?.length > 0;
  };

  isTreeExpanded = (id) => {
    return this.state.expandTreeKeys.includes(id);
  };

  // check if line item is being edited
  isUpdateExpanded = (id) => {
    return this.state.expandKeys.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expandTreeKeys);
    this.setState({ expandTreeKeys: expanded });
  };

  updateExpandState = (parentItem) => {
    if (!parentItem) return;
    const childKeys = getAllChildKeys(parentItem.childitems, []);
    const lastChildKeys = getLastChildParentKeys(parentItem, []);
    childKeys.push(parentItem.id);
    this.setState((prevState) => ({
      parentId: parentItem.id,
      expandKeys: [childKeys[childKeys.length - 2]],
      expandEdit: false,
      lastChildKeys: lastChildKeys,
      expandAttach: false,
      openCreateLineItemTypeModal: false,
    }));
  };

  handleClickAddLineItem = (d) => {
    const parentItem = getParentItemById(
      d?.parentItemId,
      this.props?.tableContent,
    );
    this.updateExpandState(parentItem);
    this.setState({ addingItem: false });
  };

  fireInitialExpand() {
    const { tableContent } = this.props;
    // Filter out items with no valid child items
    const noValidChildItems = tableContent
      .filter(
        (item) =>
          isNewPlaceholderLineItem(item.id) ||
          item.childitems?.every((child) => isNewPlaceholderLineItem(child.id)),
      )
      .map((item) => item.id);
    this.setState(
      {
        expandTreeKeys: noValidChildItems,
      },
      this.triggerInitialExpand,
    );
  }

  triggerInitialExpand = () => {
    const { expandTreeKeys } = this.state;
    const { onTreeExpand } = this.props;

    if (onTreeExpand) {
      onTreeExpand(expandTreeKeys);
    }
  };

  // This function rollback the variation type to basic in case if user cancels the operation
  rollbackLineItem = () => {
    const { actionLineItem } = this.state;
    if (!isObjectEmpty(actionLineItem)) {
      const callbackFunction = () => this.setState({ actionLineItem: {} });
      this.props.updateVariationLineItem(
        actionLineItem.id,
        {
          extra_info: {
            ...actionLineItem?.extra_info,
            variation_type: VARIATION_TYPES.BASIC,
          },
        },
        callbackFunction,
      );
    }
  };

  handleExpansion = (d) => {
    const isParent = checkIsParent(d);
    const childKeys = isParent
      ? getAllChildKeys(d.childitems, []).concat(d.id)
      : [];
    const lastChildKeys = isParent ? getLastChildParentKeys(d, []) : [];

    this.setState((prevState) => ({
      expandChildKeys: childKeys,
      expandTreeKeys: isParent
        ? prevState.expandTreeKeys.concat(childKeys)
        : prevState.expandTreeKeys,
      parentId: d.id,
      expandKeys: [
        childKeys.length > 1 ? childKeys[childKeys.length - 2] : d.id,
      ],
      lastChildKeys: lastChildKeys,
      expandEdit: false,
      expandAttach: false,
      addingItem: false,
    }));
  };

  render() {
    const {
      tableContent,
      addRow,
      addChildRow,
      handleFileCancel,
      handleFilesChange,
      files,
      editFileList,
      handleUploadFile,
      handleFileDelete,
      UIpage,
      setFileList,
      tableSubtitle,
      tableCaption,
      loading,
      proId,
      contractId,
      downloadFile,
      accountConfig,
      variationsTotal,
      setEditFiles,
      contractInfo,
      headersConfig,
      handleUpdateVariationStatusChange,
      updateVariationLineItem,
    } = this.props;
    const {
      expandKeys,
      expandTreeKeys,
      parentId,
      expandChildKeys,
      lastChildKeys,
      expandEdit,
      addingItem,
      previousLineType,
      openCreateLineItemTypeModal,
      selectedVariationLineItemType,
    } = this.state;

    const maxDepth = headersConfig.payclaim?.max_lineitem_child_level || 0;
    let columnDecimal = dropDecimalColumns(tableContent, ["rate", "total"]);
    const Actions = <span className="actionHeader">Action</span>;
    const isClaimerForAnotherContract =
      contractInfo?.get("contract_type") === CONTRACT_TYPE.claim;
    const isLinked = contractInfo.get("integration") ? true : false;
    const allowExcludeRetention = contractInfo.get("retention") ? true : false;

    const columns = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        colSpan: (d) => (isNewPlaceholderLineItem(d?.id) ? 10 : 1),
        render: (d) => {
          if (isNewPlaceholderLineItem(d?.id)) {
            if (
              this.isTreeExpanded(d?.parentItemId) &&
              this.isUpdateExpanded(d?.id)
            ) {
              return null;
            }
            return (
              <div
                className="placeholder-text"
                onClick={() => this.handleClickAddLineItem(d)}
              >
                Click here to start adding line items
              </div>
            );
          }
          return <div>{d.label}</div>;
        },
        width: "8%",
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: "31%",
        render: (d) => {
          return (
            <div>
              <ProvisionalDescription
                data={d}
                isUpdateExpanded={this.isUpdateExpanded}
                isTreeExpanded={this.isTreeExpanded}
                expandProvisionalParent={this.expandProvisionalParent}
              />
            </div>
          );
        },
      },
      {
        title: "Qty",
        width: "14%",
        render: (d) => {
          let qty =
            this.checkGreyDash(d) || d.quantity === 0 ? (
              <div className="grey-dash" />
            ) : (
              currencySubunitToUnit(
                dropDecimal(d.quantity) ? roundNearest(d.quantity) : d.quantity,
                false,
                false,
              )
            );
          return qty;
        },
      },
      {
        title: "Unit",
        width: "9%",
        render: (d) => {
          return this.checkGreyDash(d) || !d.unit ? (
            <div className="grey-dash" />
          ) : (
            d.unit
          );
        },
      },
      {
        title:
          "Rate" +
          (accountConfig?.get("enable_header_currency")
            ? ` (${accountConfig?.getIn(["currency", "code"])})`
            : ""),
        width: "15%",
        render: (d) => {
          let rate =
            this.checkGreyDash(d) || d.rate === 0 ? (
              <div className="grey-dash" />
            ) : (
              currencySubunitToUnit(
                columnDecimal["rate"] ? roundNearest(d.rate) : d.rate,
                !accountConfig?.get("enable_header_currency"),
                false,
                false,
              )
            );
          return rate;
        },
      },
      {
        title:
          "Total" +
          (accountConfig?.get("enable_header_currency")
            ? ` (${accountConfig?.getIn(["currency", "code"])})`
            : ""),
        width: "15%",
        render: (d) => {
          const formattedTotal = currencySubunitToUnit(
            columnDecimal["total"] ? roundNearest(d.total) : d.total,
            !accountConfig?.get("enable_header_currency"),
            false,
            false,
          );

          return (
            <VariationsTotalDisplay
              data={d}
              variTitle="variations"
              formattedTotal={formattedTotal}
              isContractLevel
            />
          );
        },
      },
      {
        type: "expand",
        className: "edit hidden",
        width: "4%",
        onClick: (d, isExpand) => {
          this.setState({
            parentId: d.id,
            expandKeys: [d.id],
            expandEdit: true,
            expandChildKeys: [],
            lastChildKeys: [],
            expandAttach: false,
          });
          this.setIntialValue(d);
          this.setState({ addingItem: false });
        },
        render: (d) => {
          if (this.state.expandAttach) {
            return () => {
              return (
                <VariationDetails
                  data={d}
                  downloadFile={downloadFile}
                  proId={proId}
                  contractId={contractId}
                  readOnlyExpandKeys={expandKeys}
                  handleClose={() => this.handleCloseEdit()}
                  timeZone={contractInfo?.get("time_zone")}
                />
              );
            };
          }
          if (this.state.expandEdit) {
            let variationStatus;
            let canEditVariationStatus = false;
            if (!d?.parent_id) {
              // if the variation has been certified based on the approved total.
              const certifiedVariation = d.approved_up_to_date_total !== 0;
              variationStatus = getVariationStatus(d);
              /*
               * Prevent editing of the variation status if:
               * 1. The variation is certified (i.e., `certifiedVariation` is true).
               * 2. The variation status is 'Approved' (i.e., `variationStatus` equals `VARIATION_LINEITEM_STATUS.APPROVED`).
               *
               * If both conditions are met, set `canEditVariationStatus` to true,
               * which will disable further edits to the variation status.
               */
              canEditVariationStatus =
                variationStatus === VARIATION_LINEITEM_STATUS.APPROVED &&
                certifiedVariation;
            }
            return () => (
              //edit item
              <div className="inputWrapper">
                <InputTable
                  id={d.id}
                  proId={proId}
                  contractId={contractId}
                  detailedDescriptionWidth="470px"
                  dateWidth="320px"
                  variationStatus={variationStatus}
                  // If `canEditVariationStatus` is true, set it to `undefined` to prevent any updates.
                  // else set it to `handleUpdateVariationStatusChange` to allow editing.
                  handleUpdateVariationStatusChange={
                    canEditVariationStatus
                      ? undefined
                      : handleUpdateVariationStatusChange
                  }
                  title={"Edit Item : " + d.description}
                  handleSaveItem={(lineType, editRow) =>
                    this.handleSaveEdit(d.id, lineType, editRow)
                  }
                  handleCleanItem={() => this.handleCleanItem(d.id)}
                  handleClose={() => this.handleCloseEdit()}
                  tableList={d}
                  files={editFileList} //file list
                  handleFilesChange={(files, uploadingFileField) =>
                    handleUploadFile(d.id, files, uploadingFileField)
                  } //upload file
                  handleFileCancel={(fileid) => handleFileDelete(d.id, fileid)}
                  status={UIpage}
                  action="save"
                  setFileList={(files) => setFileList(files)}
                  // prevent line item from editing quantity/rate
                  isBlocked={isEditingBlocked(d, tableContent, true)}
                  // can delete line item prop
                  canDelete={canDelete(d, tableContent, true)}
                  accountConfig={accountConfig}
                  isDraft={contractInfo.get("status") === STATUS_DRAFT}
                  // Exclude From Retention Props
                  lineItemData={d}
                  allowExcludeRetention={allowExcludeRetention}
                  tableContent={fromJS(tableContent)}
                  isContractLevel={true}
                  onlyAllowRevertToPending={isClaimerForAnotherContract}
                  isLinked={isLinked}
                  variationLineItemType={d?.extra_info?.variation_type}
                  isChild={
                    ![VARIATION_TYPES.MULTI, VARIATION_TYPES.BASIC].includes(
                      d?.extra_info?.variation_type,
                    )
                  }
                  isEmptyMulti={
                    d?.childitems?.length === 1 &&
                    isNewPlaceholderLineItem(d.childitems[0]?.id)
                  }
                />
              </div>
            );
          } else {
            return () => (
              <div className="inputWrapper">
                <InputTable
                  id={parentId ? parentId : d.id}
                  title="Add Item"
                  detailedDescriptionWidth="470px"
                  dateWidth="320px"
                  handleClose={() => this.handleCloseEdit()}
                  handleSaveItem={(lineType, newRow) => {
                    this.setState(
                      {
                        actionLineItem: {},
                        previousLineType: lineType,
                      },
                      () => {
                        // This callback will run after the state has been updated
                        this.handleCloseEdit();
                        addChildRow(
                          parentId ? parentId : d.id,
                          lineType,
                          newRow,
                        );
                      },
                    );
                  }}
                  files={files} // file list
                  handleFileCancel={handleFileCancel} // cancel upload file
                  handleFilesChange={handleFilesChange} // upload file
                  status={UIpage}
                  action="add"
                  previousLineType={previousLineType}
                  accountConfig={accountConfig}
                  setEditFiles={setEditFiles}
                  isDraft={contractInfo.get("status") === STATUS_DRAFT}
                  // Exclude From Retention Props
                  lineItemData={d}
                  allowExcludeRetention={allowExcludeRetention}
                  tableContent={fromJS(tableContent)}
                  isContractLevel={true}
                  isChild
                />
              </div>
            );
          }
        },
        group: Actions,
      },
      {
        type: "expand",
        className: "add hidden",
        width: "4%",
        onClick: (d, isExpand) => {
          this.rollbackLineItem();
          if (
            isExpand &&
            d?.extra_info?.variation_type === VARIATION_TYPES.BASIC
          ) {
            this.setState({ actionLineItem: d });

            let updatedExtraInfo = {
              extra_info: {
                ...d?.extra_info,
                variation_type: VARIATION_TYPES.MULTI,
              },
            };
            const callbackExpand = (d) => {
              this.handleExpansion(d);
            };
            updateVariationLineItem(d.id, updatedExtraInfo, callbackExpand);
          } else {
            this.handleExpansion(d);
          }
        },
        render: (d) => {
          if (notAbleToAddChild(d, tableContent, true)) {
            return undefined;
          }
          if (
            isNewPlaceholderLineItem(d?.id) ||
            (d.approved_up_to_date_total !== 0 && !checkIsParent(d)) ||
            d.depth >= maxDepth
          ) {
            return undefined;
          }
          return () => <div />;
        },
        group: Actions,
      },
      {
        width: "4%",
        className: "variation hidden so-table-checkbox",
        render: (d) => {
          if (
            !d ||
            [VARIATION_LINEITEM_STATUS.APPROVED, undefined].includes(
              d.current_status,
            ) ||
            d.parent_id
          ) {
            return undefined;
          }

          if (
            // Do not show variation status for a multi variation with no valid children.
            d.extra_info?.variation_type === VARIATION_TYPES.MULTI &&
            d.childitems?.filter(({ id }) => !isNewPlaceholderLineItem(id))
              ?.length === 0
          ) {
            return undefined;
          }

          const status = getVariationStatus(d);

          const setStatus =
            expandKeys.length > 0 // When edit modal open, disable dropdown.
              ? undefined
              : (status, reason) => {
                  handleUpdateVariationStatusChange(
                    proId,
                    contractId,
                    d.id,
                    status,
                    reason,
                  );
                };

          return (
            <VariationStatusButton
              status={status}
              setStatus={!isLinked && setStatus}
              onlyRevertToPending={isClaimerForAnotherContract}
            />
          );
        },
        group: Actions,
      },
      {
        type: "expand",
        className: "file file-not-show",
        group: Actions,
        width: "2%",
        onClick: (d, isExpand) => {
          if (d.uploaded_files) {
            this.setState({
              expandChildKeys: [],
              parentId: d.id,
              expandKeys: [d.id],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: true,
            });
          } else {
            this.setState({
              expandChildKeys: [],
              parentId: "",
              expandKeys: [],
              expandEdit: false,
              lastChildKeys: [],
              expandAttach: false,
            });
          }
        },
        render: (d) => {
          if (!d.uploaded_files || isNewPlaceholderLineItem(d?.id)) {
            return undefined;
          }
          return () => <div />;
        },
      },
    ];

    return (
      <TableStyle>
        <NewVariationLineItemModal
          isOpen={openCreateLineItemTypeModal}
          handleClose={() => {
            this.setState({
              openCreateLineItemTypeModal: false,
            });
          }}
          handleContinue={(lineItemType) => {
            this.setState({
              openCreateLineItemTypeModal: false,
              selectedVariationLineItemType: lineItemType,
              addingItem: true,
            });
          }}
        />
        <TableComponent
          tableCaption={tableCaption}
          tableContent={loading ? skeletonData : tableContent}
          tableColumns={loading ? skeletonCol : columns}
          expandKeys={expandKeys}
          rowClassName={(record, index) => {
            if (record.id === parentId) {
              return "highlight";
            }
            if (
              expandChildKeys.indexOf(record.id) !== -1 &&
              !isNewPlaceholderLineItem(record?.id)
            ) {
              return "border-highlight";
            }
            if (isNewPlaceholderLineItem(record?.id)) {
              if (
                this.isTreeExpanded(record?.parentItemId) &&
                this.isUpdateExpanded(record?.id)
              ) {
                return "placeholder-hide";
              }
              return "placeholder";
            }
          }}
          onTreeExpand={(keys) => {
            if (!expandEdit) {
              if (lastChildKeys.length !== 0) {
                let res = lastChildKeys.filter((x) => !keys.includes(x));
                if (res.length !== 0) {
                  this.setState({
                    expandKeys: [res[0]],
                  });
                } else {
                  this.setState({
                    expandKeys: [expandChildKeys[expandChildKeys.length - 2]],
                  });
                }
              }
            }
            return this.setState({ expandTreeKeys: keys });
          }}
          treeExpandKeys={expandTreeKeys}
          hasSort
          hasEnhancedTableToolbar
          hasTableSubtitle={tableSubtitle ? true : false}
          tableSubtitle={tableSubtitle}
          selectedVariationLineItemType={selectedVariationLineItemType}
        >
          {this.props.children}
        </TableComponent>

        {addingItem && (
          <InputTable
            type="parentItem"
            title="Add Variation"
            detailedDescriptionWidth="470px"
            dateWidth="320px"
            handleClose={() => this.setState({ addingItem: false })}
            files={files}
            handleFilesChange={handleFilesChange}
            handleFileCancel={handleFileCancel}
            accountConfig={accountConfig}
            handleSaveItem={(lineType, newRow) => {
              this.setState(
                {
                  actionLineItem: {},
                  previousLineType: lineType,
                  addingItem: false,
                },
                () => {
                  // This callback will run after the state has been updated
                  addRow(lineType, newRow);
                },
              );
            }}
            action="add"
            subAction={LINEITEM_STATUS.CONTRACT_VARI}
            previousLineType={previousLineType}
            setEditFiles={setEditFiles}
            // Exclude From Retention Props
            allowExcludeRetention={
              selectedVariationLineItemType === VARIATION_TYPES.BASIC &&
              allowExcludeRetention
            }
            tableContent={fromJS(tableContent)}
            isContractLevel={true}
            variationLineItemType={selectedVariationLineItemType}
            componentClass={LINEITEM_STATUS.CONTRACT_VARI}
          />
        )}
        <TableFooter>
          {loading ? (
            <Skeleton.TextLine />
          ) : (
            <AddButton
              onClick={() => {
                this.rollbackLineItem();
                return this.setState({ openCreateLineItemTypeModal: true });
              }}
              disabled={addingItem || openCreateLineItemTypeModal}
              title="ADD VARIATION"
              float="none"
            />
          )}
          <div className="totalDiv">
            <div className="blueBox">
              <div className="titleLeft">Total Approved Variations</div>
              <div className="titleRight">
                {loading ? (
                  <Skeleton.TextLine theme="secondary" />
                ) : (
                  currencySubunitToUnit(
                    dropDecimal(variationsTotal)
                      ? roundNearest(variationsTotal)
                      : variationsTotal,
                    true,
                    dropDecimal(variationsTotal),
                  )
                )}
              </div>
            </div>
            {dropDecimal(variationsTotal) && (
              <div className="notice">
                Note: Values have been rounded for display purposes. All
                calculations use unrounded values.
              </div>
            )}
          </div>
        </TableFooter>
      </TableStyle>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addingItem !== prevState.addingItem &&
      this.state.addingItem
    ) {
      this.handleCloseEdit();
    }
    if (
      !this.state.hasLogged &&
      this.props.tableContent &&
      this.props.tableContent.length > 0
    ) {
      if (prevProps.tableContent !== this.props.tableContent) {
        this.fireInitialExpand();
        this.setState({ hasLogged: true });
      }
    }

    // deep compare lastVariationObject
    if (
      !_.isEqual(
        prevProps?.lastVariationObject,
        this.props?.lastVariationObject,
      )
    ) {
      this.handleExpansion(this.props?.lastVariationObject);
    }
  }
}
export default Table;
