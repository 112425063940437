import React, { forwardRef } from "react";
import { ActionsContainerStyle, ActionOption } from "../styled";

/*
  lineitem_actions.js and certReasonDialog.js are two ways this
  component can be used (using passed ref and no passed ref)
*/
export const Action = forwardRef(function Action(props, ref) {
  const { type, title, isError, handleClick, customIcon } = props;

  return (
    <ActionOption
      ref={ref}
      className={isError ? "error" : ""}
      onClick={handleClick}
    >
      <div className={`icon ${customIcon ? "" : type || ""}`}>
        {customIcon || null}
      </div>
      <div>{title}</div>
    </ActionOption>
  );
});

export const ActionsContainer = (props) => {
  const { children, open } = props;
  return (
    <>
      {children?.length > 0 && (
        <ActionsContainerStyle open={open}>{children}</ActionsContainerStyle>
      )}
    </>
  );
};
