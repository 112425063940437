// country + timezone
export const DEFAULT_COUNTRY = "NZ";
export const DEFAULT_CURRENCY = "$";

export const COUNTRY_NZ = DEFAULT_COUNTRY;
export const COUNTRY_AU = "AU";
export const COUNTRY_SA = "SA";
export const COUNTRY_GB = "GB";

export const COUNTRY_TIMEZONES = {
  NZ: ["Auckland", "Chatham"],
  AU: ["Australia"],
  UK: ["London"],
  SA: ["Riyadh"],
};

// project + contract statuses
export const STATUS_DRAFT = "draft";
export const STATUS_COMPLETED = "completed";
export const STATUS_ARCHIVE = "archived";
export const STATUS_ACTIVE = "active";
export const STATUS_CURRENT = "current";
export const STATUS_APPROVED = "approved";
export const STATUS_ISSUE = "issue";
export const STATUS_CERTIFIED = "certified";
export const STATUS_PENDING = "pending";
export const STATUS_PREAPPROVED = "pre_approve";

// filter statuses
export const HIDE_EMPTY = "hide_empty";
export const HIDE_ACTIVE = "hide_active";
export const SHOW_ARCHIVED = "show_archived";
export const HIDE_DRAFT = "hide_draft";
export const HIDE_COMPLETE = "hide_complete";
export const HIDE_CURRENT = "hide_current";

// organization type
export const CLAIM_ONLY = "claiming";
export const CERT_ONLY = "certifying";
export const CLAIM_AND_CERT = "claim_cert";

// cert due types
export const OVERDUE = "overdue";
export const DUE_IN_3_DAYS = "due_in_3_days";
export const DUE_IN_7_DAYS = "due_in_7_days";

// account role
export const OWNER_ROLE = "owner";
export const ADMIN_ROLE = "admin";
export const NONE_ROLE = "none";
export const VIEW_ROLE = "view";
export const HELP_DESK = "helpdesk";

// project + contract roles
export const PROJECT_ADMIN = "project_admin";
export const PROJECT_VIEW = "project_view";
export const CONTRACT_ADMIN = "contract_admin";
export const CONTRACT_VIEW = "contract_view";

//permission action
export const CREATE = "create";
export const EDIT = "edit";
export const ACCESS = "access";
export const VIEW = "view";
export const CLAIM_CERT = "claim_or_cert";

//page
export const PROJECT = "project";
export const CONTRACT = "contract";
export const CONTACT = "contact";
export const ACCOUNT = "account";

export const CONTRACT_TYPE = {
  claim: "claim",
  selfClaim: "self-claim",
  cert: "cert",
  selfCert: "self-cert",
};

// email file attachment types
export const DOWNLOAD_LINK = "link";
export const FILE_ATTACHMENT = "file";

// lineitem_options - LUMP
export const LUMP = "lump";
export const RATE = "rate";

//lineitem type
export const MATERIAL = "material";
export const BASE_CONTRACT = "base_contract";
export const VARIATION = "variations";
export const CHILDREN_ITEM = "childitem";

export const ON_SITE = "on_site";
export const OFF_SITE = "off_site";

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// due date terms
export const OF_CLAIM_MONTH = "of_claim_month";
export const CALENDAR_DAYS_FOLLOWING_MONTH = "calendar_days_of_following_month";
export const WORKING_DAYS_FOLLOWING_MONTH = "working_days_of_following_month";
export const WORKING_DAYS_OF_THE_FOLLOWING_MONTH =
  "working_days_of_the_following_month";
export const CALENDAR_DAYS_AFTER_CLAIM = "calendar_days_after_claim_date";
export const WORKING_DAYS_AFTER_CLAIM = "working_days_after_claim_date";
export const WORKING_DAYS_AFTER_THE_CLAIM_DATE =
  "working_days_after_the_claim_date";
export const LAST_DAY_FOLLOWING_MONTH = "the_last_day_of_following_month";
export const CALENDAR_DAYS_BEFORE_CLAIM_MONTH_END =
  "calendar_days_before_claim_month_end";
export const WORKING_DAYS_BEFORE_CLAIM_MONTH_END =
  "working_days_before_claim_month_end";

export const DEFAULT_DUE_DATE_TERMS = {
  claim: { days: 1, terms: WORKING_DAYS_BEFORE_CLAIM_MONTH_END },
  cert: { days: 15, terms: CALENDAR_DAYS_FOLLOWING_MONTH },
  pay: { days: 20, terms: CALENDAR_DAYS_FOLLOWING_MONTH },
};

export const CERT_VARIATION_ITEM = "cert_vari";
export const CERT_VARIATION_CHILD_ITEM = "cert_vari_child";
export const CERT_MATERIAL_ITEM = "cert_material";
export const CERT_ITEM_ACTION = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
};

export const LESS_PREVIOUS_OPTION = {
  USE_PREVIOUS: "use_previous",
  ENTER_MANUAL: "enter_manually",
};

export const SUMMARY_TYPE = {
  claim: "claim",
  cert: "cert",
};

export const CLAIM_CERT_SUMMARY = {
  SUMMARY_CUSTOM: "custom",
  SUMMARY_CERTIFIED: "certified",
  SUMMARY_CLAIMED: "claimed",
};

export const RETENTION_VALUE_NO_RETENTION = "no_retention";
export const RETENTION_VALUE_ACCRUED_RETENTION = "accrued_retention";

export const RETENTION_OPTIONS = {
  NO_RETENTION: {
    label: "No Retention",
    value: RETENTION_VALUE_NO_RETENTION,
    description: "No Retention",
  },
  CONTRACT: {
    label: "Percentage of Contract Value",
    value: "of_the_contract_value",
    description: "of the Contract Value",
  },
  FIXED: {
    label: "Percentage of Fixed Amount",
    value: "of_the_fixed",
    description: "of the Fixed Value",
  },
  SCALE: {
    label: "Scaled Percentages",
    value: "of_the_first",
    description: "of the first",
  },
};
export const RETENTION_SCALE = ["first", "second", "third"];

export const LINEITEM_STATUS = {
  CONTRACT_VARI: "contract_vari",
  CLAIM_VARI: "claim_vari",
  CLAIM_VARI_CHILD: "claim_vari_child",
  CERT_VARI: CERT_VARIATION_ITEM,
  CERT_VARI_CHILD: CERT_VARIATION_CHILD_ITEM,
};

export const COMPONENT_CLASS = {
  CLAIM_VARI: "claim-vari",
  CERT_VARI: "cert-vari",
  CONTRACT_VARI: "contract_vari",
};

export const VARIATION_LINEITEM_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const VARIATION_TYPES = {
  BASIC: "basic",
  MULTI: "multi",
};

export const VARIATION_LINEITEM_TYPES_NEW_OPTIONS = [
  {
    label: "Basic Variation",
    description:
      "Use this when the variation has only a single line-item amount. More lines can be added later if needed.",
    value: VARIATION_TYPES.BASIC,
  },
  {
    label: "Multi-line Variation",
    description:
      "If the variation is comprised of several line-items, use this option so that all the variation details are grouped inside a single variation item.",
    value: VARIATION_TYPES.MULTI,
  },
];

export const CERT_VARIATION_ACTION_TYPES = {
  REASON: "variance-reason",
  REASON_EMPTY: "variance-empty",
  REASON_ERROR: "variance-error",
  COMMENT: "comment", // uses custom icons so only needs one
};
