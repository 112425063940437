import { getLastDateOfMonth } from "./dateTimeHelper";
import { MONTH_NAMES } from "./constants";
import { isNewPlaceholderLineItem } from "pages/makeclaim/shared/claimCertHelper";

export function capitalizeAddress(
  street_address,
  suburb,
  city,
  postal_code,
  state,
  country,
) {
  let data = [];
  street_address && data.push(street_address);
  suburb && data.push(suburb);
  city && data.push(city);
  postal_code && data.push(postal_code);
  state && data.push(state);
  country && data.push(country);
  let str = data.join(", ");
  return str.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
}

export function bytesToSize(bytes) {
  if (bytes === 0) return "0 B";
  if (bytes === null || bytes === undefined || bytes === "") return "";
  var k = 1024;
  let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  let size = Math.round(bytes / Math.pow(k, i));
  return size + " " + sizes[i];
}

export function removeTrailingZeros(num) {
  // remove trailing zeros from a number and at most 2 precision
  return (+num).toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
}

export const toTitleCase = (str) => {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function findTreeNode(
  value,
  tree,
  attr = "id",
  childAttr = "childitems",
) {
  if (!(tree instanceof Array)) {
    //not a tree
    return null;
  }
  if (tree.length === 0) return;

  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];

    if (item[attr] === value) {
      // if found return
      return item;
    }
    if (item[childAttr] && item[childAttr].length !== 0) {
      const node = findTreeNode(value, item[childAttr], attr, childAttr);
      if (node) {
        // return
        return node;
      }
    }
  }
  return null;
}

export function findTreeParentIds(tree, leafId, currentPath = []) {
  for (const node of tree) {
    const newPath = [node.id, ...currentPath];
    if (node.id === leafId) {
      return newPath;
    }
    if (node.childitems && node.childitems.length > 0) {
      const parentPath = findTreeParentIds(node.childitems, leafId, newPath);
      if (parentPath) {
        return parentPath; // Return the parent path if found
      }
    }
  }
  return null;
}

export function sortByLabel(a, b) {
  return a.label.toString().toLowerCase() > b.label.toString().toLowerCase()
    ? 1
    : b.label.toString().toLowerCase() > a.label.toString().toLowerCase()
      ? -1
      : 0;
}

export function sortByOption(name) {
  return function (a, b) {
    var result =
      a[name].toString().toLowerCase().trim() >
      b[name].toString().toLowerCase().trim()
        ? 1
        : b[name].toString().toLowerCase().trim() >
            a[name].toString().toLowerCase().trim()
          ? -1
          : 0;
    return result;
  };
}

export function alphabeticalSort(a, b) {
  return a.toString().toLowerCase() > b.toString().toLowerCase()
    ? 1
    : b.toString().toLowerCase() > a.toString().toLowerCase()
      ? -1
      : 0;
}

export function handleTableSorter(name, order) {
  const string = ["unit", "label", "description", "created_date"];
  if (string.includes(name)) {
    return sorter["string"](order, name);
  } else {
    return sorter["number"](order, name);
  }
}
const sorter = {
  number: (order, name) => (a, b) => {
    if (Object.keys(a).includes(name) && a[name] !== null)
      return order === "asc" ? a[name] - b[name] : b[name] - a[name];
  },
  string: (order, name) => (a, b) => {
    if (Object.keys(a).includes(name) && a[name] !== null)
      return order === "asc"
        ? a[name].localeCompare(b[name])
        : b[name].localeCompare(a[name]);
  },
};

export function checkIsParent(item) {
  if (item.childitems && item.childitems.length !== 0) {
    return true;
  } else {
    return false;
  }
}

export const addChildItemToParent = (arr, targetId, newChildItem) => {
  for (let item of arr) {
    if (item.id === targetId) {
      // Found the target item, add the new child item
      if (!item.childitems) {
        item.childitems = []; // Initialize childitems array if it's null or undefined
      }

      // Check if there's only one child item and it's a placeholder
      if (
        item.childitems.length === 1 &&
        isNewPlaceholderLineItem(item.childitems[0].id)
      ) {
        // Remove the only placeholder child item
        item.childitems = [];
      }

      // Now childitems is either an empty array [] or contains valid items.
      item.childitems.push(newChildItem);
      return item; // Return the updated item
    }
    if (item.childitems) {
      // Recursively search in the child items
      const updatedItem = addChildItemToParent(
        item.childitems,
        targetId,
        newChildItem,
      );
      if (updatedItem) return updatedItem; // Return the updated item if found
    }
  }
  return null; // Return null if the item with targetId is not found
};

export function removeElementsByClass(className) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}
export function validateEmailAddress(email) {
  var re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return re.test(email);
}

export const getAutoReferenceNumber = (MonthAndCounts) => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();

  // If no stat available
  if (!MonthAndCounts || Object.keys(MonthAndCounts).length === 0) {
    return MONTH_NAMES[month];
  }

  // Get the latest month and year
  const latestMonthYear = Object.keys(MonthAndCounts).sort((a, b) => {
    const date1 = getLastDateOfMonth(a);
    const date2 = getLastDateOfMonth(b);
    return date2 - date1;
  })[0];

  const lastDayOfCurrentMonth = new Date(year, month + 1, 0);

  // If the latest month and year is greater
  if (lastDayOfCurrentMonth > getLastDateOfMonth(latestMonthYear)) {
    return MONTH_NAMES[month];
  } else {
    const monthNumber = parseInt(latestMonthYear.split("-")[0]);

    let lastCount = MonthAndCounts[latestMonthYear] + 1;
    return `${MONTH_NAMES[monthNumber - 1]} (${lastCount})`;
  }
};

export const caseInsensitiveIncludes = (source, target) => {
  const sourceString = String(source ?? "").toLowerCase();
  const targetString = String(target).toLowerCase();

  return sourceString.includes(targetString);
};
/**
 * Function to find the key in an object by a given value.
 *
 * @param {Object} obj - The object to search within.
 * @param {any} findKey - The value to find the corresponding key for.
 * @returns {string|null} - The key corresponding to the given value, or null if not found.
 * */

export const findKeyByValue = (obj, findKey) => {
  if (!obj) {
    return null;
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key].value;
      if (value === findKey) {
        return key;
      }
    }
  }
  return null;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

/**
 * Searches a nested line item structure for a line item with a specific ID.
 *
 * @param {Array} items - Array of line items to search in.
 * @param {string} lineId - Id of the line item that's being looked for.
 * @returns {Object/null} - The line item with the same Id as the passed lineId, or null if not found.
 */
export const getNestedLineitem = (items, lineId) => {
  if (!items || !Array.isArray(items)) return null;

  let stack = [...items];

  while (stack.length > 0) {
    const current = stack.pop();
    if (current.id === lineId) {
      return current;
    }
    // if not found push current childitems to stack
    if (current?.childitems?.length > 0) {
      stack.push(...current.childitems);
    }
  }

  return null;
};

/**
 * Recursively searches through a nested line item structure to find and update the
 * specific item with the passed ID.
 *
 * @param {Object} lineitem - Line item object being searched.
 * @param {string} lineId - UUID of the line item where we want to make edits to.
 * @param {function(Object): Object} updateCallback - Function to apply the changes to the line item being searched.
 * @returns {Object} - Original passed line item, potentially updated.
 */
export const updateDescendantLineitem = (lineitem, lineId, updateCallback) => {
  if (lineitem?.id === lineId) {
    return updateCallback({ ...lineitem });
  }

  if (lineitem?.childitems?.length > 0) {
    let newLineitem = { ...lineitem, childitems: [...lineitem.childitems] };
    let found = false;

    newLineitem.childitems = newLineitem.childitems.map((child) => {
      if (found) return child; // prevent any more searching when item has been found
      const updateChild = updateDescendantLineitem(
        child,
        lineId,
        updateCallback,
      );
      if (updateChild !== child) {
        // if an update has been made mark as found
        found = true;
      }
      return updateChild;
    });
    return newLineitem;
  }

  return lineitem;
};
