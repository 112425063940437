import React, { forwardRef, useState, useEffect } from "react";
import { CommentImg } from "./styled";
import commentEditIcon from "../../statics/img/icon-add-comment-medium-grey.svg";
import { calcSize } from "./utils";
import { checkIsParent } from "utils/helper";

const CommentIcon = forwardRef(function CommentIcon(props, ref) {
  const { viewOnly, data, expands, handleOnClick } = props;
  const [isCountView, setIsCountView] = useState(false);
  const [countIcon, setCountIcon] = useState({
    count: 0,
    background: "",
  });

  useEffect(() => {
    let [background, count] = calcSize(data, expands, viewOnly);
    let isCountView =
      (checkIsParent(data) && expands.includes(data?.id)) || count > 0;
    setCountIcon({ count: count, background: background });
    setIsCountView(isCountView);
  }, [data, expands, viewOnly]);

  return (
    <>
      {viewOnly ? (
        <CommentImg
          background={countIcon.background}
          onClick={() => handleOnClick()}
          ref={ref}
        >
          {countIcon.count}
        </CommentImg>
      ) : (
        <CommentImg
          background={isCountView ? countIcon.background : commentEditIcon}
          onClick={() => handleOnClick()}
          ref={ref}
        >
          {isCountView && countIcon.count}
        </CommentImg>
      )}
    </>
  );
});

export default CommentIcon;
