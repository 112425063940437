import React, { useState, useEffect } from "react";
import Modal from "./modalBase";
import Input from "../../form";
import { RejectionModalWrapper } from "./dialogStyle";
import { GreenPrimaryButton } from "common/button/style";

/**
 * A modal component for capturing user input with a textarea and save button.
 *
 * @param {object} props
 * @param {string} props.title - The title of the modal.
 * @param {() => void} props.handleClose - Function to call when the user clicks the close button.
 * @param {(value: string) => void} props.handleSave - Function to call with the textarea value when the Save button is clicked.
 * @param {string} [props.textareaValue] - The initial value for the textarea.
 * @param {function} [props.onTextareaChange] - Function to call when the textarea value changes.
 *
 * @returns {React.JSX.Element} The modal component with a textarea and save button.
 *
 * @example
 * <VariationRejectionDialog
 *  title="Reason for Rejection Variation"
 *  handleSave={(reason) => console.log(reason)}
 *  handleClose={() => console.log("closed")}
 *  textareaValue={reason}
 *  onTextareaChange={handleTextareaChange}
 * />
 */
export default function VariationRejectionDialog({
  title,
  handleSave,
  handleClose,
  textareaValue,
  onTextareaChange,
}) {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Check if textarea value is empty or not
    if (textareaValue.trim() === "") {
      setError("Reason is required.");
      setIsButtonEnabled(false);
    } else {
      setError("");
      setIsButtonEnabled(true);
    }
  }, [textareaValue]);

  // Handle changes to the textarea
  const handleTextareaChange = (event) => {
    const { value } = event.target;
    onTextareaChange(value);
  };

  // Handle save button click
  const handleSaveClick = () => {
    if (isButtonEnabled) {
      handleSave(textareaValue);
    }
  };

  return (
    <Modal handleClose={handleClose} open title={title} divider={false}>
      <RejectionModalWrapper>
        <Input
          label="Reason"
          name="reason"
          className={`reason-field ${error ? "has-error" : ""}`}
          field="textarea"
          value={textareaValue}
          onChange={handleTextareaChange}
          width="610px"
          error={error}
        />
      </RejectionModalWrapper>
      <GreenPrimaryButton onClick={handleSaveClick} disabled={!isButtonEnabled}>
        Done
      </GreenPrimaryButton>
    </Modal>
  );
}
