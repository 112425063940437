import * as theme from "../../theme";
import styled from "styled-components";
import actionsIcon from "../../statics/img/icon-more-horizontal.svg";
import actionsErrIcon from "../../statics/img/icon-more-horizontal-red.svg";
import addReasonIcon from "../../statics/img/icon-add-reason.svg";
import addReasonErrIcon from "../../statics/img/icon-add-reason-red.svg";
import addIcon from "../../statics/img/icon-add-medium-grey.svg";
import editIcon from "../../statics/img/icon-edit-medium-grey.svg";
import hasReasonIcon from "../../statics/img/icon-has-reason.svg";

export const ActionsContainerStyle = styled.div`
  width: 220px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 200;
  cursor: default;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  left: -196px;
  top: 24px;
  background-color: white;

  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 8px;
  padding: 8px 8px;
`;

export const ActionsButton = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;

  .action-icon {
    width: 100%;
    height: 100%;
    background: url(${actionsIcon}) no-repeat;

    &.vari-error {
      background: url(${actionsErrIcon}) no-repeat;
    }

    &.inline {
      display: inline-block;
    }
  }
`;

export const ActionsIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .comment-section {
    height: 24px;
    width: 24px;
  }

  .comments-anchor {
    height: 0;
    margin-top: 24px;
  }

  &.inline {
    display: inline-block;
  }
`;

export const ActionOption = styled.div`
  width: 100%;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  font-size: 14px;

  &.error {
    color: ${theme.palette.watermelonRed};
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    &.variance-reason {
      background: url(${hasReasonIcon}) no-repeat;
    }
    &.variance-empty {
      background: url(${addReasonIcon}) no-repeat;
    }
    &.variance-error {
      background: url(${addReasonErrIcon}) no-repeat;
    }
    &.variance-add {
      background: url(${addIcon}) no-repeat;
    }
    &.variance-edit {
      background: url(${editIcon}) no-repeat;
    }
  }
`;

export const ActionImg = styled.div`
  padding: 2px 8px;
  font-size: 14px;
  color: white;
  width: 24px;
  height: 24px;
  background: url(${(props) => props.background}) no-repeat;

  position: absolute;
`;
