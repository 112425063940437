import { VARIATION, BASE_CONTRACT } from "../../../utils/constants";

export function getTableField(string) {
  switch (string) {
    case "Base Contract":
      return BASE_CONTRACT;
    case "Variations":
      return VARIATION;
    case "Contra Charges":
      return "contra";
    default:
      return "";
  }
}

export function isVariation(string) {
  return [VARIATION, "variation"].includes(string?.toLowerCase());
}

export function getParentItemById(itemId, itemData) {
  return itemData.find((item) => item.id === itemId) || null;
}

export const isNewPlaceholderLineItem = (id) => `${id}`.startsWith("new_");
