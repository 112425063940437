import styled from "styled-components";
import * as theme from "../../theme";
export const ClaimTableWrapper = styled.div`
  .so-table {
    tbody {
      .claimTotal {
        padding-right: 16px;
        .inlineDiv {
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .action_container {
            position: relative;
            width: 44px;
            height: 24px;
            padding: 0 10px;
            margin-right: -15px;
          }
        }
      }

      .reviewClaimComment {
        .commentDiv {
          height: 24px;
          width: 70px;
          display: flex;
          align-items: center;
          gap: 8px;
          .action_container {
            position: relative;
            width: 24px;
            height: 24px;
          }
        }
      }

      .percent_qty {
        display: grid;
        grid-template-columns: 50% 50%;
        text-align: right;
      }
    }
  }

  tfoot {
    td {
      background-color: ${theme.palette.tealBlue};
      &.empty {
        width: 400px;
        background-color: ${theme.palette.white};
      }
      &.title {
        width: 184px;
      }
      &.rate {
        width: 266px;
      }
      &.claimed {
        width: 339px;
      }
      &.claim-actions {
        width: 70px;
        background-color: ${theme.palette.white};
      }
    }
  }

  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }
`;

export const MaterialClaimTableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 16px;

  .title-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .materials {
    width: 100%;
    max-width: 1260px;
    .sub-title {
      font-size: 20px;
      font-weight: bold;
    }
    table {
      max-width: 1260px;
      .commentDiv {
        width: 24px;
        height: 24px;
        position: relative;
      }
      .materials-footer {
        td {
          background-color: ${theme.palette.tealBlue};
          &.empty {
            width: 689px;
            background-color: ${theme.palette.white};
          }
          &.title {
            width: 184px;
          }
          &.claimed {
            width: 346px;
          }
          &.claim-actions {
            width: 40px;
            background-color: ${theme.palette.white};
          }
        }
      }
    }
  }
`;
