import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as theme from "../../theme";
import { FormGroup } from "../form/styled";
import { AddaVariationWrapper, AttachLeft, ImgClose } from "./styled";

import { DeleteBtn, AddGreenBtn, GreenWhiteBtn as SaveBtn } from "../button";
import Input from "../form";
import { LineItemForm } from "./materialsForm";
import FileUpload from "./uploadItemFile";
import Modal from "../modal/component/modalBase";
import { ExcludeRetentionBox } from "./sharedComponent";
import { ToggleMaterialButtons as ToggleButtons } from "./toggleButtons";
import { actionCreators as actionCreatorsFile } from "../../common/upload_file_progress/store";
import { CERT_MATERIAL_ITEM } from "../../utils/constants";

// field == dialog : add variation when making cert; will show in a dialog
// status == overview :project/contract overview page
// type === "parentItem" : add vari for parent items

const InputFields = (props) => {
  const {
    title,
    handleClose, //close input frame
    handleSaveItem, //create or update
    handleCleanItem, //clean input or delete item
    status,
    type,
    action,
    field,
    isBlocked,
    canDelete,
    accountConfig,
    onSite,
    setOnSite,
    formInfo,
    handleChange,
    allowExcludeRetention,
    // attachment uploading
    uploadAttachments,
    handleFilesChange,
    cancelAttachment,
    deleteAttachment,
  } = props;

  const handleMaterialOnsite = (value) => {
    setOnSite(value);
    handleChange("on_site", value);
  };

  const ExcludeRetention = () => (
    <>
      {allowExcludeRetention && (
        <ExcludeRetentionBox
          checked={formInfo?.exclude_retention}
          onChange={(e) => handleChange("exclude_retention", e.target.checked)}
          disabled={formInfo?.approved_up_to_date_total}
        />
      )}
    </>
  );

  return (
    <AddaVariationWrapper
      style={
        type === "parentItem" && field !== "dialog"
          ? {
              border: `1px solid ${theme.palette.tangerineOrange}`,
              margin: " 12px auto",
            }
          : field === "dialog"
            ? { marginTop: "0px", width: "1060px" }
            : { marginTop: "5px" }
      }
    >
      <FormGroup>
        {field !== "dialog" ? (
          <div className="form_title">
            {title}
            <div className="buttons">
              <Fragment>
                <ToggleButtons
                  buttonWidth="137px"
                  buttons={[
                    { label: "On Site", value: true },
                    { label: "Off Site", value: false },
                  ]}
                  setValue={(value) => setOnSite(value)}
                  defaultValue={onSite}
                  isBlocked={isBlocked}
                />
              </Fragment>
              <ImgClose onClick={handleClose} />
            </div>
          </div>
        ) : (
          <div className="buttons-dialog">
            {type !== "parentItem" && (
              <ToggleButtons
                buttonWidth="137px"
                buttons={[
                  { label: "On Site", value: true },
                  { label: "Off Site", value: false },
                ]}
                setValue={(value) => handleMaterialOnsite(value)}
                defaultValue={onSite}
                isBlocked={isBlocked}
              />
            )}
          </div>
        )}
        <LineItemForm
          onChange={handleChange}
          form={formInfo}
          status={status}
          isBlocked={isBlocked}
          lineitemType={onSite}
          accountConfig={accountConfig}
          field={field}
        />
        <div>&nbsp;</div>

        <div className="form_body cert_vari">
          <div className="large_container">
            <Input field="child">
              <AttachLeft>
                <div className="title">Attachments</div>
                <div className="uploadFileWrapper">
                  {field === "dialog" ? (
                    <FileUpload
                      title={title}
                      files={props.files}
                      setFileList={props.setFileList}
                      handleFilesChange={(files) =>
                        title.includes("Edit")
                          ? uploadAttachments(files, props.tableList.id)
                          : handleFilesChange(files)
                      }
                      handleFileCancel={(file) =>
                        title.includes("Edit")
                          ? deleteAttachment(file, props.tableList.id)
                          : cancelAttachment(file)
                      }
                    />
                  ) : (
                    <FileUpload {...props} />
                  )}
                </div>
              </AttachLeft>
            </Input>
            <Input
              label="Detailed Description"
              name="detailed_description"
              value={formInfo?.detailed_description}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              width={status === "overview" ? "600px" : "438px"}
              field="textarea"
              height="180px"
            />
          </div>
          {field === "dialog" && <ExcludeRetention />}
        </div>
        {field !== "dialog" && (
          <div className="form_footer">
            <ExcludeRetention />
            {/* avoid to delete parent / certified items */}
            {canDelete && (
              <DeleteBtn
                title="Delete"
                onClick={handleCleanItem}
                margin="0 30px 0 0"
              />
            )}
            {action === "add" && (
              <AddGreenBtn
                title="Add"
                type="submit"
                onClick={() => handleSaveItem(onSite, formInfo)}
              />
            )}
            {action === "save" && (
              <SaveBtn
                title="Save"
                type="submit"
                onClick={() => handleSaveItem(onSite, formInfo)}
              />
            )}
          </div>
        )}
      </FormGroup>
    </AddaVariationWrapper>
  );
};

const InputTable = (props) => {
  const {
    tableList, //new input list
    title,
    handleClose, //close input frame
    handleSaveItem, //create or update
    handleCleanItem, //clean input or delete item
    action,
    field,
    setEditFiles,
    initialFileList,
    uploadFiles,
    uploadAttachments,
    // modal related
    modalOpen,
  } = props;
  const [onSite, setOnSite] = useState(false);

  const [formInfo, setFormInfo] = useState({
    label: "",
    description: "",
    quantity: 0,
    unit: "",
    rate: 0,
    detailed_description: "",
    variation_reference: "",
    submit_date: "",
    approved_date: "",
    on_site: false,
    total: 0,
    exclude_retention: false,
  });

  useEffect(() => {
    if (action === "add") {
      // copy previous added lineitem option/type (if there's one)
      setEditFiles();
    } else {
      // initialise values
      let newInfo = {};
      for (let [key, value] of Object.entries(tableList)) {
        if (key === "on_site") {
          setOnSite(value);
        } else {
          newInfo[key] = value;
        }
      }
      setFormInfo(newInfo);
      if (tableList.status === CERT_MATERIAL_ITEM) {
        initialFileList(tableList.uploaded_files);
      }
    }
  }, [tableList, action]); // eslint-disable-line

  const handleChange = (name, value) => {
    setFormInfo({ ...formInfo, [name]: value });
  };

  if (field === "dialog")
    return (
      <Modal
        handleClose={() => {
          handleClose();
          // clean up redux file list when closing
          initialFileList();
        }}
        title={title}
        open={modalOpen}
        width="1060px"
      >
        <InputFields
          {...props}
          onSite={onSite}
          setOnSite={setOnSite}
          formInfo={formInfo}
          handleChange={handleChange}
        />
        <div>
          {(action === "adding_item" || action === "adding_child_item") && (
            <AddGreenBtn
              title="Add"
              type="submit"
              onClick={() => {
                uploadAttachments(uploadFiles.toJS(), tableList.id);
                handleSaveItem(onSite, formInfo);
              }}
            />
          )}
          {action === "editing_item" && (
            <AddGreenBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(onSite, formInfo)}
            />
          )}
          {action === "save" && (
            <SaveBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(onSite, formInfo)}
            />
          )}
          <DeleteBtn
            title="Delete"
            onClick={handleCleanItem}
            margin="0 30px 0 0"
          />
        </div>
      </Modal>
    );

  return (
    <Fragment>
      <InputFields
        {...props}
        onSite={onSite}
        setOnSite={setOnSite}
        formInfo={formInfo}
        handleChange={handleChange}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  uploadFiles: state.getIn(["makeacert", "uploadFiles"]),
  editFileList: state.getIn(["makeacert", "editFileList"]),
});

const mapDispatchToProps = (dispatch) => ({
  setFileList(files) {
    dispatch(actionCreatorsFile.setUploadFile(files));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InputTable);
