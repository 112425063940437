import React, { PureComponent } from "react";
import { fromJS } from "immutable";
import { ClaimTableWrapper, NoticeIconTransparent, NetClaim } from "./styled";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import BootstrapTooltip from "../toolTip";
import Tooltip from "../toolTip/toolTipTable";
import CommentList from "./comment_table";
import { AddBtn } from "../button";
import { NumericInput, NumericInputGroup } from "./numeric_input_comp";
import TableComponent, { filterTableContent } from "./basic_table";

import {
  removeTrailingZeros,
  checkIsParent,
  isObjectEmpty,
} from "../../utils/helper";
import { roundNearest } from "../../utils/numeralHelper";
import {
  currencySubunitToUnit,
  convertToCurrencyUnit,
} from "../../utils/currencyHelper";
import {
  calculateNetClaim,
  dropDecimalColumns,
  headerFormatter,
} from "../../utils/tableFormatHelper";
import profile from "../../utils/profile";
import {
  CONTRACT,
  EDIT,
  LUMP,
  VIEW,
  LINEITEM_STATUS,
  VARIATION_LINEITEM_STATUS,
  VARIATION,
  VARIATION_TYPES,
  RATE,
  STATUS_DRAFT,
  VARIATION_LINEITEM_TYPES_NEW_OPTIONS,
  CONTRACT_TYPE,
} from "../../utils/constants";

import API from "../../server";
import {
  ProvisionalDescription,
  getExpandProvisionalParents,
  getAllChildKeys,
  getLastChildParentKeys,
  isEditingBlocked,
  notAbleToAddChild,
  canDelete,
} from "../lineitem/sharedComponent";
import InputDialogue from "../lineitem/variationInputTable";

import NewVariationLineItemModal from "../../pages/variations/components/newVariationLineItemModal";
import LineitemActions from "common/lineitem_actions/lineitem_actions";

import {
  VariationsTotalDisplay,
  VariationsClaimStatusDisplay,
} from "./shared_component";
import {
  getParentItemById,
  isNewPlaceholderLineItem,
} from "pages/makeclaim/shared/claimCertHelper";

class Table extends PureComponent {
  constructor() {
    super();
    this.state = {
      table: [],
      expands: [], // same as expandTreeKeys
      allowEditContract: true,
      allowViewContract: false,
      isVariationTypeDialogOpen: false,
      isVariationFormDialogOpen: false,
      lineitemVariationType: VARIATION_TYPES.BASIC,
      // @todo: PL-2421 Move these item editing related states to redux
      // Added from VariationTable.js
      expandKeys: [],
      expandEdit: false,
      expandChildKeys: [],
      parentId: "",
      lastChildKeys: [],
      expandAttach: false,
      addingItem: false,
      // for performing rolling back operations
      actionLineItem: {},
    };
  }

  getClaimHistory = (contractId, itemId) => {
    //read last three months claim history for lineitem
    API.read_three_month_claim_history(contractId, itemId)
      .then((res) => res.data)
      .then((res) => res.data.data.records)
      .then((res) => {
        return !res
          ? this.setState({
              table: [],
            })
          : this.setState({
              table: res,
            });
      });
  };

  inputValue = (itemId, list, field) => {
    let percent = 0;
    let value = 0;
    let claim_qty = 0;
    var obj = list.find(function (obj) {
      return obj.get("lineitem_id") === itemId;
    });

    if (obj) {
      percent = obj.get("percent");
      value = obj.get("value");
      claim_qty = obj.get("claim_qty");
      if (claim_qty === 0) {
        claim_qty = value / obj?.get("item_rate");
      }
    }
    if (field === "percent") {
      return removeTrailingZeros(percent);
    }
    if (field === "qty") {
      return claim_qty;
    } else {
      return (value / 100).toFixed(2);
    }
  };

  saveLineItemType = (itemId, type) => {
    const { proId, contractId } = this.props.match.params;
    API.update_one_lineitem(proId, contractId, itemId, { claim_option: type });
  };

  isTreeExpanded = (id) => {
    return this.state.expands.includes(id);
  };

  // check if line item is being edited
  isUpdateExpanded = (id) => {
    return this.state.expandKeys.includes(id);
  };

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(line, this.state.expands);
    this.setState({ expands: [...expanded] });
  };

  // Method definitions...
  closeVariationLineitemOperation = () => {
    this.setState({
      isVariationTypeDialogOpen: false,
      isVariationFormDialogOpen: false,
      lineitemVariationType: VARIATION_TYPES.BASIC,
      addingItem: false,
    });
  };

  // This function rollback the variation type to basic in case if user cancels the operation
  rollbackLineItem = () => {
    const { actionLineItem } = this.state;
    if (!isObjectEmpty(actionLineItem)) {
      const {
        proId,
        contractId,
        updateSingleClaimVariationsItem,
        tableClaimId,
        match: {
          params: { contractType },
        },
      } = this.props;

      const callbackFunction = () => this.setState({ actionLineItem: {} });

      updateSingleClaimVariationsItem(
        proId,
        contractId,
        tableClaimId,
        contractType,
        actionLineItem?.id,
        {
          extra_info: {
            ...actionLineItem?.extra_info,
            variation_type: VARIATION_TYPES.BASIC,
          },
        },
        callbackFunction,
      );
    }
  };

  handleCloseEdit = () => {
    this.rollbackLineItem();
    return this.setState({
      expandKeys: [],
      expandEdit: false,
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  handleCleanItem = (id) => {
    this.props.deleteItem(id);
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  handleSaveEdit = (id, lineType, editInfo) => {
    // only copy objects with keys specified in edit fields or else saving will cause server errors
    const editFields = [
      "label",
      "description",
      "unit",
      "quantity",
      "rate",
      "total",
      "detailed_description",
      "variation_reference",
      "exclude_retention",
      "submit_date",
      "approved_date",
      "is_provisional",
    ];
    let edited = {};

    for (let [key, value] of Object.entries(editInfo)) {
      if (editFields.includes(key)) {
        edited[key] = value;
      }
    }
    edited.lineitem_options = lineType;

    this.props.handleSaveEdit(edited, id);

    //To do: post change to server
    return this.setState({
      expandKeys: [],
      parentId: "",
      expandChildKeys: [],
      lastChildKeys: [],
    });
  };

  setIntialValue = (initialFiles) => {
    this.props.setEditFiles(initialFiles);
  };

  handleExpand = (d, isExpand) => {
    const isParent = checkIsParent(d);
    const childKeys = isParent
      ? getAllChildKeys(d.childitems, []).concat(d.id)
      : [];
    const lastChildKeys = isParent ? getLastChildParentKeys(d, []) : [];

    this.setState((prevState) => ({
      expandChildKeys: childKeys,
      expands: isParent
        ? prevState.expands.concat(childKeys)
        : prevState.expands,
      parentId: d.id,
      expandKeys: [
        childKeys.length > 1 ? childKeys[childKeys.length - 2] : d.id,
      ],
      lastChildKeys: lastChildKeys,
      expandEdit: false,
      expandAttach: false,
      addingItem: false,
      isVariationFormDialogOpen: false,
    }));
  };

  handleEditExpand = (d, editFileList, isExpand) => {
    if (this.props.tableType !== VARIATION) {
      return null;
    }
    if (isNewPlaceholderLineItem(d?.id)) {
      return;
    }
    this.setState({
      parentId: d.id,
      expandKeys: [d.id],
      expandEdit: true,
      expandChildKeys: [],
      lastChildKeys: [],
      expandAttach: false,
      isVariationFormDialogOpen: false,
    });
    const { proId, contractId } = this.props.match.params;
    const variationFiles = d?.variation_files
      ? fromJS(d?.variation_files.map((item) => item.file_storage))
      : editFileList;
    if (variationFiles.size === 0) {
      API.read_line_item_files(proId, contractId, d?.id).then((res) => {
        this.setIntialValue(fromJS(res?.data?.data?.file));
      });
    }
    this.setIntialValue(variationFiles);
    this.setState({ addingItem: false });
  };

  handleClickAddLineItem = (d) => {
    const parentData = getParentItemById(d?.parentItemId, this.variableContent);
    this.handleExpand(parentData, true);
  };

  fireInitialExpand() {
    const noValidChildItems = this.variableContent
      .filter((item) =>
        item.childitems?.every((child) => isNewPlaceholderLineItem(child.id)),
      )
      .map((item) => item.id);
    this.setState(
      {
        expands: noValidChildItems,
      },
      this.triggerInitialExpand,
    );
  }

  triggerInitialExpand = () => {
    const { expands } = this.state;
    const { onTreeExpand } = this.props;

    if (onTreeExpand) {
      onTreeExpand(expands);
    }
  };

  render() {
    const {
      expands,
      allowEditContract,
      allowViewContract,
      isVariationTypeDialogOpen,
      isVariationFormDialogOpen,
      expandKeys,
      parentId,
      expandChildKeys,
      lastChildKeys,
      expandEdit,
      previousLineType,
    } = this.state;

    const {
      tableContent,
      totalTable,
      inputClaim,
      claimList,
      calculateClaimTotal,
      contractId,
      calNetClaim,
      updateComment,
      title,
      accountConfig,
      lineitemRefs,

      // variation status update
      updateStatus,
      // Contract Type
      contractType,
      tableField,
      contractInfo,
      projectInfo,
      setEditFiles,
      handleUploadFile,
      handleFilesChange,
      files,
      handleFileCancel,
      addRow,
      addChildRow,
      maxLineItemLevel,
      editFileList,
      setFileList,
      handleFileDelete,
      headersConfig,
      tableType,
      projectData,
      tableClaimId,
      allowExcludeRetention,
    } = this.props;

    const maxDepth = headersConfig?.payclaim?.max_lineitem_child_level || 0;
    const isLinked = contractInfo?.get("integration") ? true : false;
    const claimWord = accountConfig?.getIn(["claim", "noun"]);
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
      "claimed_up_to_date_total",
    ]);
    const showCurrency = !accountConfig?.get("enable_header_currency");

    const NetClaimTotal = () => (
      <NetClaim>
        <span
          className={
            accountConfig?.get("enable_header_currency") ? "center" : ""
          }
        >
          {headerFormatter(
            "Net",
            accountConfig?.getIn(["currency", "code"]),
            accountConfig?.get("enable_header_currency"),
          )}
        </span>
        <BootstrapTooltip
          title={`This ${claimWord?.toLowerCase()} less previous ${claimWord?.toLowerCase()}.`}
          placement="bottom"
        >
          <NoticeIconTransparent />
        </BootstrapTooltip>
      </NetClaim>
    );

    const columns_dev = [
      {
        title: "Ref",
        className: "align_left lineitem-ref",
        width: 80,
        colSpan: (d) => (isNewPlaceholderLineItem(d?.id) ? 10 : 1),
        render: (d, isExpand) => {
          if (isNewPlaceholderLineItem(d?.id)) {
            return (
              <div
                className="placeholder-text"
                onClick={() => this.handleClickAddLineItem(d)}
              >
                Click here to start adding line items
              </div>
            );
          }
          return d.label;
        },
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: 270,
        render: (d) => (
          <div>
            {lineitemRefs && <div ref={lineitemRefs[d?.id] || null} />}
            <ProvisionalDescription
              data={d}
              isUpdateExpanded={() => {
                return false;
              }}
              isTreeExpanded={this.isTreeExpanded}
              expandProvisionalParent={this.expandProvisionalParent}
            />
          </div>
        ),
      },
      {
        title: "Qty",
        width: 124,
        render: (d) => {
          //quick fix for when read lineitem from contract api, quantity return in cent
          //read from claim api, quantity return in dollar
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            if (!tableClaimId) {
              return currencySubunitToUnit(
                dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
                false,
              );
            } else {
              return convertToCurrencyUnit(
                dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
                false,
              );
            }
          }
        },
      },
      {
        title: "Unit",
        width: 86,
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return d.unit;
          }
        },
      },
      {
        title: headerFormatter(
          "Rate",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 136,
        render: (d) => {
          if (d.lineitem_options === LUMP || d.childitems?.length > 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
              showCurrency,
            );
          }
        },
      },
      {
        title: headerFormatter(
          "Total",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        width: 154,
        render: (d) => {
          const formattedTotal = currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(d.total) : d.total,
            showCurrency,
          );

          return (
            <VariationsTotalDisplay
              data={d}
              variTitle={title}
              formattedTotal={formattedTotal}
            />
          );
        },
      },
      {
        title: headerFormatter(
          "Value",
          accountConfig?.getIn(["currency", "code"]),
          accountConfig?.get("enable_header_currency"),
        ),
        className: "th_align_left group_input group_header",
        width: 290,
        render: (d) => {
          const currentStatus = ["variation", "variations"].includes(
            title.toLowerCase(),
          )
            ? d?.current_status
            : undefined;
          const verifiedStatus = [VARIATION_LINEITEM_STATUS.REJECTED].includes(
            currentStatus,
          );
          return (
            <div style={{ display: "flex" }} className="claimValue">
              {d.lineitem_options === LUMP || checkIsParent(d) ? (
                <NumericInput
                  isParent={checkIsParent(d)}
                  lineItem={d}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputClaim(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.inputValue(id, claimList, field)
                  }
                  isDisabled={verifiedStatus}
                />
              ) : (
                <NumericInputGroup
                  isParent={checkIsParent(d)}
                  lineItem={d}
                  defaultOption={d?.claim_option}
                  onChange={(id, field, value, onLoad, fromPrctQty) =>
                    inputClaim(id, field, value, onLoad, fromPrctQty)
                  }
                  displayValue={(id, field) =>
                    this.inputValue(id, claimList, field)
                  }
                  saveLineItemType={(id, type) =>
                    this.saveLineItemType(id, type)
                  }
                  isDisabled={verifiedStatus}
                />
              )}
              <Tooltip
                status="lineitem"
                getTable={() => this.getClaimHistory(contractId, d.id)}
                title={this.state.table}
                id={d.id}
                isParent={checkIsParent(d)}
                accountConfig={accountConfig}
              />
            </div>
          );
        },
      },
      {
        title: <NetClaimTotal />,
        className: "hidden net-claim",
        width: 140,
        render: (d) => (
          <div className="netValue">
            {calculateNetClaim(
              d.id,
              claimList.toJS(),
              d.claimed_up_to_date_total,
              dropDecimal["claimed_up_to_date_total"],
              !accountConfig?.get("enable_header_currency"),
            )}
          </div>
        ),
      },
      {
        title: "",
        className: "claim-actions hidden",
        width: 80,
        render: (d) => {
          const canAddChildItem =
            allowEditContract &&
            notAbleToAddChild(d, tableContent) &&
            (typeof maxLineItemLevel !== "number" ||
              d.depth < maxLineItemLevel);

          // when the parent id is the same as the current line item id
          // it means the line item is being updated and since now we have expanded status inside
          // the input table, we need to disable the dropdown
          // So when edit modal open, disable line item chip dropdown;
          const checkIfParentEditing =
            parentId === d?.id || expandKeys.length > 0;

          return (
            <div
              className={`inlineDiv ${tableField === VARIATION ? "" : "baseinline"}`}
            >
              <VariationsClaimStatusDisplay
                data={d}
                contractType={contractType}
                variTitle={title}
                updateStatus={!checkIfParentEditing && updateStatus}
                allowViewContract={allowViewContract}
                allowEditContract={allowEditContract}
                isLinked={isLinked}
                isEmptyMulti={
                  d?.childitems?.length === 1 &&
                  isNewPlaceholderLineItem(d.childitems[0]?.id)
                }
              />
              {[
                LINEITEM_STATUS.CLAIM_VARI,
                LINEITEM_STATUS.CLAIM_VARI_CHILD,
              ].includes(d?.status) ? (
                <LineitemActions
                  data={d}
                  expands={expands}
                  onTreeExpand={(key) => {
                    return this.setState({
                      expands: [...this.state.expands, key],
                    });
                  }}
                  handleExpand={(d, isExpand) => {
                    this.rollbackLineItem();
                    if (
                      isExpand &&
                      d?.extra_info?.variation_type === VARIATION_TYPES.BASIC
                    ) {
                      this.setState({ actionLineItem: d });

                      const { proId, contractId } = this.props.match.params;
                      let updatedExtraInfo = {
                        extra_info: {
                          ...d?.extra_info,
                          variation_type: VARIATION_TYPES.MULTI,
                        },
                      };

                      const callbackExpand = (d) => {
                        this.handleExpand(d, isExpand, (newState) =>
                          this.setState(newState),
                        );
                      };

                      this.props.updateSingleClaimVariationsItem(
                        proId,
                        contractId,
                        tableClaimId,
                        contractType,
                        d.id,
                        updatedExtraInfo,
                        callbackExpand,
                      );
                    }
                    return this.handleExpand(d, isExpand);
                  }}
                  handleEditExpand={(d, isExpand) =>
                    this.handleEditExpand(d, editFileList, isExpand)
                  }
                  showComment={allowEditContract || allowViewContract}
                  showEdit={allowEditContract}
                  showAdd={canAddChildItem}
                  updateComment={updateComment}
                  type={CONTRACT_TYPE.claim}
                  viewOnly={allowViewContract}
                  otherParty={projectData?.get("payee_entity_name")}
                  accountConfig={accountConfig}
                  tableField={tableField}
                />
              ) : (
                tableClaimId &&
                (allowEditContract || allowViewContract) && (
                  <CommentList
                    data={d}
                    expands={expands}
                    onTreeExpand={(key) => {
                      return this.setState({
                        expands: [...this.state.expands, key],
                      });
                    }}
                    updateComment={updateComment}
                    type={CONTRACT_TYPE.claim}
                    viewOnly={allowViewContract}
                  />
                )
              )}
            </div>
          );
        },
      },

      {
        type: "expand",
        className: "all-actions hidden",
        width: "0%",
        onClick: (d, isExpand) => {
          return this.handleExpand(d, isExpand);
        },
        render: (d) => {
          if (
            tableType !== VARIATION ||
            d?.type === "variation" ||
            d?.type === VARIATION
          ) {
            return null;
          }

          if (this.state.expandEdit) {
            let lineItem = fromJS(d);
            if (!tableClaimId) {
              lineItem = lineItem.set(
                "quantity",
                currencySubunitToUnit(
                  dropDecimal["quantity"]
                    ? roundNearest(lineItem.get("quantity"))
                    : lineItem.get("quantity"),
                  false,
                ),
              );
            } else {
              lineItem = lineItem.set(
                "quantity",
                convertToCurrencyUnit(
                  dropDecimal["quantity"]
                    ? roundNearest(lineItem.get("quantity"))
                    : lineItem.get("quantity"),
                  false,
                ),
              );
            }
            lineItem = lineItem.toJS();

            // Get current contract info
            // since existing contractInfo variable contains claimInfo
            const projectInfoJS = projectInfo.toJS();
            const currentContractInfo = projectInfoJS?.claim_contracts?.find(
              (contract) => contract.id === contractId,
            );

            // Edit Item
            return () => (
              <>
                <div className="inputWrapper">
                  <InputDialogue
                    formInfo={{}}
                    dateWidth="400px"
                    referenceWidth="400px"
                    detailedDescriptionWidth="599px"
                    componentClass="claim-vari"
                    id={parentId ? parentId : d?.id}
                    title={`Edit Variation: ${d.label}`}
                    tableList={lineItem}
                    accountConfig={accountConfig}
                    action="save"
                    subAction={LINEITEM_STATUS.CLAIM_VARI}
                    previousLineType={previousLineType}
                    /*{Expanded VARIATION STATUS BUTTON}*/
                    variationStatusDisplay={
                      <VariationsClaimStatusDisplay
                        data={d}
                        contractType={contractType}
                        variTitle={title}
                        updateStatus={updateStatus}
                        allowViewContract={allowViewContract}
                        allowEditContract={allowEditContract}
                        isLinked={isLinked}
                        isExpandedForm
                      />
                    }
                    // Exclude From Retention Props
                    lineItemData={lineItem}
                    allowExcludeRetention={allowExcludeRetention}
                    tableContent={tableContent}
                    handleSaveItem={(lineType, editRow) =>
                      this.handleSaveEdit(d.id, lineType, editRow)
                    }
                    handleCleanItem={() => this.handleCleanItem(d.id)}
                    handleClose={() => this.handleCloseEdit()}
                    files={editFileList} //file list
                    handleFilesChange={(files, uploadingFileField) => {
                      return handleUploadFile(d.id, files, uploadingFileField);
                    }} //upload file
                    handleFileCancel={(fileid) => {
                      handleFileDelete(d.id, fileid);
                    }}
                    setFileList={(files) => setFileList(files)}
                    variationLineItemType={d?.extra_info?.variation_type}
                    IgnoreQtyAsNumber
                    // prevent line item from editing quantity/rate
                    isBlocked={isEditingBlocked(d, tableContent)}
                    // can delete line item prop
                    canDelete={canDelete(d, tableContent)}
                    isDraft={currentContractInfo?.status === STATUS_DRAFT}
                    isChild={
                      ![VARIATION_TYPES.MULTI, VARIATION_TYPES.BASIC].includes(
                        d?.extra_info?.variation_type,
                      )
                    }
                  />
                </div>
              </>
            );
          }

          // Add Child item
          if (d.depth >= maxDepth) {
            return undefined;
          }

          return () => (
            <>
              <div className="inputWrapper">
                <InputDialogue
                  dateWidth="400px"
                  referenceWidth="400px"
                  detailedDescriptionWidth="599px"
                  componentClass="claim-vari"
                  id={parentId ? parentId : d?.id}
                  title="Add Item"
                  handleClose={() => this.handleCloseEdit()}
                  handleSaveItem={(lineType, newRow) => {
                    this.setState(
                      {
                        actionLineItem: {},
                        previousLineType: lineType,
                      },
                      () => {
                        // This callback will run after the state has been updated
                        this.handleCloseEdit();
                        addChildRow(
                          parentId ? parentId : d.id,
                          lineType,
                          newRow,
                        );
                      },
                    );
                  }}
                  files={files} // file list
                  handleFileCancel={handleFileCancel} // cancel upload file
                  handleFilesChange={handleFilesChange} // upload file
                  // status={UIpage}
                  action="add"
                  previousLineType={previousLineType}
                  accountConfig={accountConfig}
                  setEditFiles={setEditFiles}
                  // Exclude From Retention Props
                  lineItemData={d}
                  allowExcludeRetention={allowExcludeRetention}
                  tableContent={tableContent}
                  isChild
                />
              </div>
            </>
          );
        },
      },
    ];

    // New Variation Modifications
    let { mainTableContent, variableContent } = filterTableContent(
      tableContent,
      title.toLowerCase(),
      "variations",
      LINEITEM_STATUS.CLAIM_VARI,
    );
    this.variableContent = variableContent;

    return (
      <>
        <ClaimTableWrapper>
          <TableComponent
            // same as expands
            treeExpandKeys={expands}
            tableContent={mainTableContent}
            tableColumns={columns_dev}
            tableCaption={title}
            hasSort
            hasEnhancedTableHeader
            hasEnhancedTableFooter
            rowClassName={(record, index) => {
              if (record.id === parentId) {
                return "highlight";
              }
              if (
                expandChildKeys.indexOf(record.id) !== -1 &&
                !isNewPlaceholderLineItem(record?.id)
              ) {
                return "border-highlight";
              }
              if (isNewPlaceholderLineItem(record?.id)) {
                if (
                  this.isTreeExpanded(record?.parentItemId) &&
                  this.isUpdateExpanded(record?.id)
                ) {
                  return "placeholder-hide";
                }
                return "placeholder";
              }
            }}
            // New Variation Modifications: claim_vari props
            hasVariationClaimCertVari={["variations", "variation"].includes(
              title.toLowerCase(),
            )}
            tableVariContent={variableContent}
            variationClaimCertVariTitle={`Added In This ${claimWord}`}
            expandKeys={expandKeys}
            onTreeExpand={(keys) => {
              if (!expandEdit) {
                if (lastChildKeys.length !== 0) {
                  let res = lastChildKeys.filter((x) => !keys.includes(x));
                  if (res.length !== 0) {
                    this.setState({
                      expandKeys: [res[0]],
                    });
                  } else {
                    this.setState({
                      expandKeys: [expandChildKeys[expandChildKeys.length - 2]],
                    });
                  }
                }
              }
              return this.setState({ expands: keys });
            }}
          >
            <table className="header_table">
              <tbody>
                <tr>
                  <td style={{ width: 850 }} />
                  <td className="label-claim">{claimWord?.toUpperCase()}</td>
                  <td style={{ width: 223 }} />
                </tr>
              </tbody>
            </table>
            {/* Add Variation */}
            {isVariationFormDialogOpen && (
              <>
                {variableContent.length === 0 && (
                  <div className="claimLabel">Added In This {claimWord}</div>
                )}
                <InputDialogue
                  dateWidth="400px"
                  referenceWidth="400px"
                  detailedDescriptionWidth="614px"
                  componentClass="claim-vari"
                  type="parentItem"
                  title="Add Variation"
                  accountConfig={accountConfig}
                  action="add"
                  subAction={LINEITEM_STATUS.CLAIM_VARI}
                  previousLineType={RATE}
                  // Exclude From Retention Props
                  allowExcludeRetention={
                    this.state.lineitemVariationType ===
                      VARIATION_TYPES.BASIC && allowExcludeRetention
                  }
                  tableContent={tableContent}
                  variationLineItemType={this.state.lineitemVariationType}
                  handleClose={this.closeVariationLineitemOperation}
                  files={files}
                  handleFilesChange={handleFilesChange}
                  handleFileCancel={handleFileCancel}
                  handleSaveItem={(lineType, newRow) => {
                    this.setState(
                      {
                        actionLineItem: {},
                        previousLineType: lineType,
                        addingItem: false,
                      },
                      () => {
                        // This callback will run after the state has been updated
                        addRow(lineType, newRow);
                        this.closeVariationLineitemOperation();
                      },
                    );
                  }}
                  setEditFiles={setEditFiles}
                />
              </>
            )}
          </TableComponent>
          <table>
            <tfoot>
              <tr>
                <td className="footer empty" style={{ width: 350 }} />
                <td className="footer title">Sub Total</td>
                <td className="footer rate">
                  {convertToCurrencyUnit(totalTable, showCurrency)}
                </td>
                <td className="footer claim">
                  {convertToCurrencyUnit(calculateClaimTotal, showCurrency)}
                </td>
                <td className="footer net">
                  {" "}
                  {convertToCurrencyUnit(calNetClaim, showCurrency)}
                </td>
                <td className="footer empty-end" style={{ width: 80 }}></td>
              </tr>
            </tfoot>
          </table>
        </ClaimTableWrapper>
        {tableField === VARIATION && (
          <>
            {isVariationFormDialogOpen && (
              <div>
                <br />
                These items will be added to the contract once this{" "}
                {claimWord?.toLowerCase()} has been issued.
              </div>
            )}
            <AddBtn
              onClick={() => {
                this.rollbackLineItem();
                return this.setState({
                  isVariationTypeDialogOpen: true,
                });
              }}
              disabled={isVariationFormDialogOpen}
              title="Add Variation"
              float="none"
              margin="20px 0 0"
            />
            <NewVariationLineItemModal
              optionsList={VARIATION_LINEITEM_TYPES_NEW_OPTIONS}
              handleContinue={(val) => {
                this.setState({
                  isVariationTypeDialogOpen: false,
                  isVariationFormDialogOpen: true,
                  lineitemVariationType: val,
                });
                this.handleCloseEdit();
              }}
              handleClose={this.closeVariationLineitemOperation}
              isOpen={isVariationTypeDialogOpen}
            />
          </>
        )}
      </>
    );
  }
  componentDidMount() {
    const { proId, contractId } = this.props.match.params;

    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      this.setState({ allowEditContract: res });
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      this.setState({ allowViewContract: res });
    });
    this.fireInitialExpand();
  }

  componentDidUpdate(prevProps) {
    const { lastVariationObject } = this.props;
    if (
      prevProps?.lastVariationObject &&
      lastVariationObject &&
      !_.isEqual(prevProps?.lastVariationObject, lastVariationObject)
    ) {
      this.handleExpand(lastVariationObject, true);
    }
  }
}

export default withRouter(Table);
