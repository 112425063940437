import React, { Fragment, useEffect, useState } from "react";
import * as theme from "../../theme";
import {
  AttachLeft,
  InputTableWrapper,
  ImgClose,
  ConvertButton,
} from "./styled";
import { FormGroup, ProvisionalToggle } from "../form/styled";
import { DeleteBtn, AddGreenBtn, GreenWhiteBtn as SaveBtn } from "../button";
import Input from "../form";
import FileUpload from "./uploadItemFile";
import { LUMP, RATE } from "../../utils/constants";
import ToggleButtons from "./toggleButtons";
import { AntSwitch } from "../switch";
import BootstrapTooltip from "../toolTip";
import {
  usePrevious,
  LineItemForm,
  ExcludeRetentionBox,
  ConvertModal,
} from "./sharedComponent";

const InputTable = (props) => {
  const {
    title,
    tableList,
    handleClose,
    handleCleanItem,
    handleSaveItem,
    status,
    type,
    action,
    isBlocked,
    canDelete,
    accountConfig,
    previousLineType, // line type of latest added line item
    setEditFiles,
    isDraft,
    allowExcludeRetention,
  } = props;
  const [lineType, setLineType] = useState(RATE);
  const prevLineType = usePrevious(lineType); // previous value of lineType
  const [formInfo, setFormInfo] = useState({
    label: "",
    description: "",
    unit: "",
    quantity: 0,
    rate: 0,
    total: 0,
    detailed_description: "",
    is_provisional: false,
    exclude_retention: false,
  });
  const numValues = ["quantity", "rate", "total"];
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (action === "add") {
      // copy previous added lineitem option/type (if there's one)
      setLineType(previousLineType ? previousLineType : RATE);
      setEditFiles();
    } else {
      // initialise values
      let newInfo = {};
      for (let [key, value] of Object.entries(tableList)) {
        if (key === "lineitem_options") {
          setLineType(value);
        } else if (numValues.includes(key)) {
          // for display
          newInfo[key] = value / 100;
        } else {
          newInfo[key] = value;
        }
      }
      setFormInfo(newInfo);
    }
  }, [tableList, action]); // eslint-disable-line

  useEffect(() => {
    // only works for lineType changes made by user and not from initialisation
    if (prevLineType && prevLineType !== lineType) {
      let recalculate = { ...formInfo };
      if (lineType === RATE) {
        recalculate.quantity = 1;
      } else if (lineType === LUMP) {
        recalculate.quantity = 1;
        recalculate.rate = formInfo.quantity * formInfo.rate;
      }

      setFormInfo({ ...recalculate });
    }
  }, [lineType]); // eslint-disable-line

  const handleChange = (name, value) => {
    setFormInfo({ ...formInfo, [name]: value });
  };

  return (
    <InputTableWrapper
      style={
        type === "parentItem"
          ? {
              border: `1px solid ${theme.palette.tangerineOrange}`,
              margin: "12px auto",
            }
          : { marginTop: "5px" }
      }
    >
      <FormGroup>
        <div className="form_title">
          {title}
          <div className="buttons">
            {(tableList === undefined ||
              tableList?.childitems?.length === 0) && (
              <Fragment>
                {/*
                  - Show provisional toggle only when contract is in draft or when adding in new line items.
                  - Show switch to fixed button when editing provisionals.
                */}
                {isDraft || (!isDraft && action === "add") ? (
                  <ProvisionalToggle>
                    <AntSwitch
                      checked={formInfo.is_provisional}
                      onChange={() =>
                        handleChange("is_provisional", !formInfo.is_provisional)
                      }
                      name="provisional-toggle"
                    />
                    <div>Provisional</div>
                    <BootstrapTooltip
                      title="Enabling this setting will allow the line item to be editable when the contract has become active."
                      placement="bottom"
                    >
                      <div className="info-icon" />
                    </BootstrapTooltip>
                  </ProvisionalToggle>
                ) : (
                  tableList.is_provisional && (
                    <ConvertButton onClick={() => setModalOpen(true)}>
                      <div className="convert-icon" />
                      Convert to Fixed
                    </ConvertButton>
                  )
                )}
                <ToggleButtons
                  buttonWidth="137px"
                  buttons={[
                    { label: "Lump Sum", value: LUMP },
                    { label: "Qty & Rate", value: RATE },
                  ]}
                  setValue={(type) => setLineType(type)}
                  defaultValue={lineType}
                  isBlocked={isBlocked}
                />
              </Fragment>
            )}
            <ImgClose onClick={handleClose} />
          </div>
        </div>
        <LineItemForm
          onChange={handleChange}
          form={formInfo || {}}
          status={status}
          isBlocked={isBlocked}
          lineitemType={lineType}
          accountConfig={accountConfig}
          isParent={tableList?.childitems?.length > 0}
        />
        <div className="form_body" style={{ margin: "40px auto" }}>
          <Input field="child">
            <AttachLeft>
              <div className="title">Attachments</div>
              <div className="uploadFileWrapper">
                <FileUpload {...props} />
              </div>
            </AttachLeft>
          </Input>
          <Input
            label="Detailed Description"
            name="detailed_description"
            value={formInfo?.detailed_description || ""}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            width={status === "overview" ? "600px" : "438px"}
            field="textarea"
            height="180px"
          />
        </div>
        <div className="form_footer">
          {allowExcludeRetention && (
            <ExcludeRetentionBox
              checked={formInfo.exclude_retention}
              onChange={(e) =>
                handleChange("exclude_retention", e.target.checked)
              }
              disabled={formInfo?.approved_up_to_date_total}
            />
          )}
          {/* avoid to delete parent / certified items */}
          {canDelete && (
            <DeleteBtn
              title="Delete"
              onClick={handleCleanItem}
              margin="0 30px 0 0"
            />
          )}
          {action === "add" && (
            <AddGreenBtn
              title="Add"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          {action === "save" && (
            <SaveBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
        </div>
      </FormGroup>
      {/* Only shows up when converting non draft provisional line items */}
      <ConvertModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={tableList}
        convert={() => {
          handleChange("is_provisional", false);
          handleSaveItem(lineType, { ...formInfo, is_provisional: false });
        }}
      />
    </InputTableWrapper>
  );
};

export default InputTable;
