import React, { useState } from "react";
import Modal from "./modalBase";
import { GreenPrimaryButton } from "common/button/style";
import { palette } from "theme";
import {
  RadioButtonOptionWrapper,
  SelectionStepBodyWrapper,
} from "./dialogStyle";

/**
 * A base modal that allows the user prompt to select an option before continuing.
 *
 * @param {object} props
 * @param {string} props.title - String to show for the title of the modal.
 * @param {string} [props.subTitle] - String to show for the subtitle of the modal. No subtitle if not defined.
 * @param {() => void} props.handleClose - Trigger function for when user clicks the close button on top right.
 * @param {(index: number) => void} props.handleContinue - Trigger when the user clicks on the Okay button.
 * @param {React.JSX.Element[]} props.optionsList - Render the content section of the option. The radio button should not be included in the component.
 * @param {number} [props.defaultOptionIndex] - Set the default option index. If none is set, defaults to -1 which means no option selected.
 * @param {string} [props.okayButtonText] - Set the text for the Okay button. Defaults to "Continue" if none is set.
 * @param {boolean} [props.wholeOptionComponentUpdatesSelected] - Sets the whole option modal to select option. Defaults to false.
 *
 * @returns {React.JSX.Element} The modal with the rendered options available for the user to select one from.
 *
 * @example
 * const exampleOptionComponents = [1,2,3].map(number => <span key={number}>
 *  {number}
 * </span>)
 *
 * <SelectionStepDialog
 *  title="Sample Title"
 *  subTitle="Sample Sub Title"
 *  handleClose={() => null}
 *  handleContinue={(index) => console.log(index)}
 *  optionsList={exampleOptionComponents}
 * />
 */
export default function SingleSelectionStepDialog(props) {
  const {
    handleContinue = (_) => null,
    handleClose = () => null,
    subTitle,
    title = "",
    defaultOptionIndex = -1,
    optionsList = [],
    okayButtonText = "Continue",
    wholeOptionComponentUpdatesSelected = false,
  } = props;

  const [selectedOptionIndex, setSelectedOptionIndex] =
    useState(defaultOptionIndex);

  return (
    <Modal handleClose={handleClose} open title={title}>
      <SelectionStepBodyWrapper>
        {subTitle && <span>{subTitle}</span>}
        {optionsList.map((OptionComponent, index) => {
          const selected = index === selectedOptionIndex;
          const handleSelectOptionIndex = () => setSelectedOptionIndex(index);
          const shouldUpdate = wholeOptionComponentUpdatesSelected && !selected;

          return (
            <RadioButtonOptionWrapper
              key={index}
              backgroundColor={selected ? palette.greyWhite : undefined}
              borderColor={selected ? palette.gunmetal : undefined}
              onClick={shouldUpdate ? handleSelectOptionIndex : undefined}
            >
              <input
                type="radio"
                checked={selected}
                name="selectOption"
                onChange={shouldUpdate ? undefined : handleSelectOptionIndex}
              />
              <div>{OptionComponent}</div>
            </RadioButtonOptionWrapper>
          );
        })}
      </SelectionStepBodyWrapper>
      <GreenPrimaryButton
        onClick={() => handleContinue(selectedOptionIndex)}
        submitting
        disabled={selectedOptionIndex < 0}
      >
        {okayButtonText}
      </GreenPrimaryButton>
    </Modal>
  );
}
