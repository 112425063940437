import * as theme from "../../theme";
import styled from "styled-components";

import radioOnIcon from "../../statics/img/icon-radio-on.svg";
import radioOffIcon from "../../statics/img/icon-radio-off.svg";
import downDirIcon from "../../statics/img/icon-dropdown-down-dark-grey.svg";

export const DropdownWrapper = styled.div`
  width: 240px;
  height: 40px;
  margin-right: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px ${theme.palette.wolfGrey};
  float: right;

  position: relative;
  cursor: pointer;
  hr {
    margin: 0 !important;
  }
  .dropdown-title {
    display: flex;
    align-items: center;
    width: 100%;
    label {
      font-size: 14px;
      font-weight: bold;
      margin-right: 4px;
    }
    .icon {
      width: 24px;
      height: 24px;
      background: url(${downDirIcon});
      object-fit: contain;
      margin: 0 0 0 auto;
      opacity: ${(props) => (props.active ? 1 : 0.2)};
      :hover {
        opacity: ${(props) => (props.active ? 1 : 0.5)};
      }
    }
  }

  .dropdown-list {
    z-index: 100;
    position: relative;
    top: 8px;
    background: white;
    width: 240px;
    left: -12px;
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  }
  ul {
    list-style: none;
    li {
      height: 40px;
      display: flex;
      align-items: center;
      input[type="radio"] {
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        background: url(${radioOffIcon}) no-repeat;
        background-size: contain;
        margin: 0 8px 0 12px;
        cursor: pointer;
        :checked {
          background: url(${radioOnIcon}) no-repeat;
          background-size: contain;
        }
      }
    }
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .tableCaptionSubtitle {
    margin-top: 20px;
  }
`;

export const EnhancedTableToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tableCaption {
    font-size: 24px;
    font-weight: bold;
  }
  .tableToolbar {
    display: flex;
    align-items: center;
  }
`;

export const TableContainer = styled.div`
  chrome @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .inputWrapper {
      margin-right: -1px;
      margin-left: 0px;
    }
  }
  firefox @-moz-document url-prefix() {
    .inputWrapper {
      margin-right: 0px;
      margin-left: -1px;
    }
  }

  margin-top: ${(props) =>
    props?.marginTop !== undefined ? props?.marginTop : "20"}px;

  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    table-layout: fixed;
  }

  //tables in general
  table {
    th,
    td {
      height: 44px;
      padding: 8px 16px;
      border-left: solid 1px ${theme.palette.white};
      border-right: solid 1px ${theme.palette.white};
      box-sizing: border-box;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
      &.so-table-checkbox {
        border: none;
      }
    }
    .align_left {
      text-align: left;
      .so-table-expand-wrapped {
        display: inline-flex;
        .space {
          width: 16px;
          display: inline-block;
        }
      }
    }
    .align_center {
      text-align: center;
      padding: 0;
    }

    th {
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
      font-weight: ${theme.font.mainFontWeight};
      text-align: right;
      &.th_align_center {
        padding: 8px 5px;
        text-align: center;
      }
      &.th_align_left {
        text-align: left;
      }
    }

    td {
      padding: 8px;
      text-align: right;
      color: ${theme.palette.gunmetal};
      &.td_align_center {
        padding: 8px 5px;
        text-align: center;
      }
      &.td_align_left {
        text-align: left;
      }
    }
    thead {
      .hidden {
        height: 0px;
        border: none;
      }
    }

    tfoot {
      td {
        color: ${theme.palette.white};
        font-weight: bold;
        padding: 8px 15px;
      }
    }
  }
`;

export const TableSubtitle = styled.div`
  margin-top: 40px;
  &.form_label {
    font-weight: bold;
    font-size: 20px;
  }
  &.normal-margin {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const VariationsModalVariTitle = styled.div`
  margin: 10px;
  margin-below: 0;
  width: 100%;
  height: 56px;
  opacity: 1;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  justify-content: flex-start;
`;
