import styled from "styled-components";
import * as theme from "../../theme";
import LockImage from "../../statics/img/icon-lock.svg";
import errorIcon from "../../statics/img/icon-information-red.svg";
import infoIcon from "../../statics/img/icon-information-transparent.svg";
import linkIcon from "../../statics/img/icon-link.svg";
import noticeIcon from "../../statics/img/icon-Information-transparent-blue.svg";
import { makeStyles } from "@material-ui/core/styles";

export const VariationStatusWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  font-weight: normal;
  margin: 0;
`;

export const FormGroup = styled.div`
  text-align: left;
  .form_title {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;

    .buttons {
      display: flex;
      align-items: center;
    }
  }
  .form_body {
    display: flex;
    justify-content: space-between;

    &.cert_vari {
      display: grid;
      grid-template-rows: auto 40px;
      grid-row-gap: 16px;

      .large_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 4rem;
        &.claim-vari {
          width: 1287px;
        }

        &.cert-vari {
          width: 1287px; //todo: change this when doing width ticket
        }
      }
    }
  }
  .form_footer {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .variation-placeholder {
    display: flex;
    width: 125px;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: normal;
  }

  .claim-vari {
    &.date {
      width: 400px;
    }
  }

  .cert-vari {
    &.date {
      width: 400px; //todo adjust when doing width ticket
    }
  }

  .overview-vari {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
    margin: 0 0 24px auto;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || "auto"};
  .form_label {
    margin-bottom: 8px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;
  }
  .form_input {
    color: ${theme.palette.gunmetal};
    height: 44px;
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    padding: 10px 16px;
    box-sizing: border-box;
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.wolfGrey};
    font-family: ${theme.font.mainFont};
    border-radius: 4px;
    &:disabled {
      outline: none;
      color: ${theme.palette.veryMediumGrey};
      background-color: ${theme.palette.greyWhite};
    }

    &.invalid {
      border: 1px solid ${theme.palette.watermelonRed};
    }
  }
  .required {
    color: ${theme.palette.watermelonRed};
  }
`;

export const InputRender = styled.input`
  ::placeholder {
    color: ${theme.palette.wolfGrey};
  }
  :read-only {
    outline: none;
    background-color: ${theme.palette.greyWhite};
  }
  width: ${(props) => props.width || "446px"};
  margin-bottom: ${(props) => props.marginBottom || "inherit"};
  color: ${theme.palette.gunmetal};
  height: 44px;
  font-size: 16px;
  font-weight: ${theme.font.normalFontWeight};
  padding: 10px 16px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.lightWolfGrey};
  font-family: ${theme.font.mainFont};
  &:disabled {
    color: ${theme.palette.veryMediumGrey};
    outline: none;
    background-color: ${theme.palette.greyWhite};
  }
  &.error {
    margin-bottom: 0px !important;
    border-color: ${theme.palette.watermelonRed} !important;
  }
`;
export const LinkInput = styled.div`
  width: ${(props) => props.width || "446px"};
  margin-bottom: ${(props) => props.marginBottom || "inherit"};
  color: ${theme.palette.gunmetal};
  min-height: 44px;
  font-size: 16px;
  font-weight: ${theme.font.normalFontWeight};
  padding: 10px 16px;
  box-sizing: border-box;
  background-color: ${theme.palette.veryWarmLightGrey};
  border: 1px solid ${theme.palette.veryLightGrey};
  font-family: ${theme.font.mainFont};
  display: grid;
  grid-template-columns: auto 24px;
  border-radius: 4px;
  .link-icon {
    width: 24px;
    height: 24px;
    background: url(${linkIcon}) no-repeat;
  }
  div {
    align-self: center;
  }
`;
export const LockInputWrap = styled.div`
  width: ${(props) => props.width || "446px"};
  height: 44px;
  padding: 10px 16px;
  box-sizing: border-box;
  border: 1px solid ${theme.palette.lightWolfGrey};
  outline: none;
  background-color: #f2f2f2;
  position: relative;
  border-radius: 4px;
  .lockIcon {
    width: 24px;
    height: 24px;
    mask: url(${LockImage}) no-repeat;
    background-color: ${theme.palette.rareGrey};
    position: absolute;
    right: 10px;
  }
  input {
    color: ${theme.palette.gunmetal};
    font-size: 16px;
    font-weight: ${theme.font.normalFontWeight};
    font-family: ${theme.font.mainFont};
    outline: none;
    background-color: #f2f2f2;
    border: none;
    float: left;
    width: 80% !important;
  }

  .flag {
    width: 32px;
    height: 24px;
    margin-right: 16px;
  }
`;

export const Textarea = styled.textarea`
  ::placeholder {
    color: ${theme.palette.wolfGrey};
  }
  :read-only {
    outline: none;
    background-color: ${theme.palette.greyWhite};
  }
  height: ${(props) => props.height || "100%"};
  width: ${(props) => props.width || "438px"};
  color: ${theme.palette.gunmetal};
  font-size: 16px;
  font-weight: ${theme.font.normalFontWeight};
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.wolfGrey};
  font-family: ${theme.font.mainFont};
  resize: none;
  &:disabled {
    outline: none;
    color: ${theme.palette.veryMediumGrey};
    background-color: ${theme.palette.greyWhite};
  }
  &.claim-vari {
    height: 172px;
  }

  &.cert-vari {
    height: 172px; //todo adjust when doing width ticket
  }
`;

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  .toggle-label {
    font-size: 16px;
    color: ${theme.palette.gunmetal};
    margin-left: 18px;
    &.unchecked {
      color: ${theme.palette.wolfGrey};
    }
  }
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${theme.palette.watermelonRed};
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: ${(props) => props.marginBottom || "inherit"};
  justify-content: ${(props) => props.justifyContent || ""};
  font-weight: ${theme.font.mainFontWeight};
  .error_img {
    background: url(${errorIcon});
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

export const ProvisionalToggle = styled.div`
  width: 154px;
  height: 40px;
  display: grid;
  font-size: 16px;
  grid-template-columns: 40px 74px 24px;
  grid-column-gap: 8px;
  align-items: center;
  margin-right: 24px;

  .info-icon {
    width: 24px;
    height: 24px;
    mask: url(${infoIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
  }
`;

export const CheckBoxStyle = makeStyles((muiTheme) => ({
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginLeft: muiTheme.spacing(1),
    width: 20,
    height: 20,
    backgroundImage: `url(${noticeIcon})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  checkbox: {
    width: 24,
    padding: 0,
    marginRight: muiTheme.spacing(1),
    "& .MuiSvgIcon-root": {
      backgroundColor: "transparent",
      color: theme.palette.gunmetal,
    },
  },
  checkBoxWrap: {
    display: "flex",
    alignItems: "center",
    direction: "row",
    marginBottom: "16px",
  },
}));
