import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { ProjectInfoWrapper, ProjectWrapper } from "../styled";
import { BgWrapper, HeaderTitle } from "../../../common/shared_style";

import Menu from "../../../common/breadcrumbs";
import Attachment from "../../../common/claim_cert_attachment";
import ApprovalChart from "../../../common/approval_flow";

import Notes from "../shared/note";
import ExternalNotes from "../../makecert/pages/components/notes";

import ProjectInfo from "./components/projectInfo";
import Table from "./components/claimCertifiedTable";
import SummaryPart from "./components/summaryData";
import EmailService from "./components/contactInfo";
import NavBarBottom from "./components/navBarBottom";
import { getMenuLink } from "../components/pageMenu";
import { CONTRACT_TYPE } from "../../../utils/constants";

import { actionCreators } from "../store";

// certified claim info page
// this page can be viewed by claimer/ self-claim / self-cert / certifier

class ApprovedClaim extends PureComponent {
  updateComment = (action, content, comment_id, lineId) => {
    this.props.updateComment(
      this.props.match.params.proId,
      this.props.match.params.contractId,
      this.props.match.params.claimId,
      action,
      content,
      comment_id,
      lineId,
    );
  };

  render() {
    const {
      accountId,
      contractInfo,
      projectInfo,
      claimDetail,
      claim_attachments,

      baseContractTable,
      baseTotalValue,
      baseClaimTotal,
      base_approved_total,

      variationsTable,
      variationsTotal,
      variationClaimTotal,
      var_approved_total,
      approvalList,

      materialsTable,

      emailToList,
      emailSubject,
      attachedEmailFiles,
      isSendEmail,
      sendingEmail,
      readOnly,
      exceedAttachLimit,

      handleSendEmail,

      deleteFile,
      downloadFile,
      downloadPdf,
      changeSharedFiles,
      sharedAttachments,

      setPdfAudit,
      isAuditAttached,
      timeZone,
      accountConfig,
      requestContractInfo,
    } = this.props;

    const { proId, contractId, claimId, contractType } =
      this.props.match.params;

    return (
      <BgWrapper className="medium">
        <Menu
          link={getMenuLink(
            projectInfo,
            contractId,
            contractInfo.get("contract_name"),
            contractType,
          )}
          content={claimDetail.get("claim_ref")}
        />
        <HeaderTitle>{claimDetail.get("claim_ref")}</HeaderTitle>
        <ProjectWrapper>
          <ProjectInfoWrapper>
            <ProjectInfo claimDetail={claimDetail} claimInfo={contractInfo} />
            <Table
              tableContent={baseContractTable}
              totalTable={baseTotalValue}
              claimTotal={baseClaimTotal}
              approvedTotal={base_approved_total}
              title="Base Contract"
              bottom="0"
              updateComment={this.updateComment}
              //only allow claimer to add comment
              isClaim={contractType === CONTRACT_TYPE.claim}
              accountConfig={accountConfig}
              claimInfo={contractInfo}
            />
            <Table
              tableContent={variationsTable}
              totalTable={variationsTotal}
              claimTotal={variationClaimTotal}
              approvedTotal={var_approved_total}
              title="Variations"
              bottom="56px"
              updateComment={this.updateComment}
              isClaim={contractType === CONTRACT_TYPE.claim}
              accountConfig={accountConfig}
              claimInfo={contractInfo}
              contractType={contractType}
            />
            {materialsTable.size > 0 && (
              <Table
                tableContent={materialsTable}
                title="Materials On/Off Site"
                bottom="56px"
                updateComment={this.updateComment}
                isClaim={contractType === CONTRACT_TYPE.claim}
                accountConfig={accountConfig}
                claimInfo={contractInfo}
                isMaterial
              />
            )}
            <div className="bottomWrapper">
              <SummaryPart
                data={contractInfo}
                claimDetail={claimDetail}
                type="review"
              />
              <div className="line" />
              {/* only display external notes for certifier */}
              {contractType === CONTRACT_TYPE.cert ? (
                <ExternalNotes claimInfo={claimDetail} isReadOnly />
              ) : (
                <Notes claimInfo={claimDetail} isReview isReadOnly />
              )}

              {contractType !== CONTRACT_TYPE.cert ? (
                <>
                  <div className="line" />
                  {/* hide attachments for cert contract */}
                  <Attachment
                    title={"Claim"}
                    data={claim_attachments}
                    readOnly={readOnly}
                    uploadFileApi={{
                      name: "upload_file_claim",
                      config: [contractId, claimId],
                    }}
                    readInfo={() =>
                      requestContractInfo(proId, contractId, claimId)
                    }
                    deleteFile={(fileId) =>
                      deleteFile(proId, contractId, claimId, fileId)
                    }
                    downloadFile={downloadFile}
                    changeSharedFiles={(fileId, isAdd) =>
                      changeSharedFiles(
                        proId,
                        contractId,
                        claimId,
                        fileId,
                        isAdd,
                      )
                    }
                  />
                </>
              ) : (
                // show claimer shared attachments for certifier
                <>
                  <div className="line" />
                  <Attachment
                    title={"Claim"}
                    displayOnly
                    noMargin
                    hasSharedFiles
                    sharedFiles={sharedAttachments?.filter(
                      (file) => file.get("type") === "claim",
                    )}
                    downloadFile={downloadFile}
                    otherPartyName={contractInfo?.get("payee_entity_name")}
                  />
                </>
              )}
              {/* display workflow for claim/self-cert contract*/}
              {(contractType === CONTRACT_TYPE.claim ||
                contractType === CONTRACT_TYPE.selfCert) && (
                <>
                  <ApprovalChart
                    data={approvalList}
                    editable={false}
                    isComplete={true}
                    timezone={timeZone}
                    isAuditAttached={isAuditAttached}
                    setPdfAudit={(e) =>
                      setPdfAudit(proId, contractId, claimId, e.target.checked)
                    }
                  />
                </>
              )}
              {/* hide email service for cert contract */}
              {contractType !== CONTRACT_TYPE.cert && !readOnly && (
                <>
                  <div className="line" />
                  <EmailService {...this.props} claimStatus={"certified"} />
                </>
              )}
            </div>
          </ProjectInfoWrapper>
          <NavBarBottom
            readOnly={readOnly}
            proId={proId}
            contractId={contractId}
            claimId={claimId}
            contractType={contractType}
            accountId={accountId}
            options={[
              {
                label: "View file in new tab",
                value: () => downloadPdf(proId, contractId, claimId, false),
              },
              {
                label: "Save file to your device",
                value: () => downloadPdf(proId, contractId, claimId, true),
              },
            ]}
            status="certified"
            exceedAttachLimit={exceedAttachLimit}
            isSendEmail={isSendEmail}
            sendingEmail={sendingEmail}
            handleResendEmail={() =>
              handleSendEmail(
                proId,
                contractId,
                claimId,
                isSendEmail,
                emailToList,
                emailSubject,
                attachedEmailFiles,
              )
            }
            accountConfig={accountConfig}
          />
        </ProjectWrapper>
      </BgWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  var_approved_total: state.getIn(["makeaclaim", "var_approved_total"]),
  base_approved_total: state.getIn(["makeaclaim", "base_approved_total"]),
  sharedAttachments: state.getIn(["makeaclaim", "sharedAttachments"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleSendEmail(
      proId,
      contractId,
      claimId,
      isSendEmail,
      emailToList,
      subject,
      attachedEmailFiles,
    ) {
      dispatch(
        actionCreators.sendEmail(
          proId,
          contractId,
          claimId,
          isSendEmail,
          emailToList,
          subject,
          attachedEmailFiles,
        ),
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApprovedClaim);
