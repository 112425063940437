import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Table } from "shineout";

import { MaterialsSection, OutLayerWrapper } from "../styled";
import { TableContainer } from "../../../common/table/basic_table_style";
import { Sort } from "../../../common/table/basic_table";
import Input from "../../../common/form";
import {
  calculateNetClaim,
  dropDecimalColumns,
  headerFormatter,
} from "../../../utils/tableFormatHelper";
import { NetClaim, NoticeIconTransparent } from "../../../common/table/styled";
import BootstrapTooltip from "../../../common/toolTip";
import CommentList from "../../../common/table/comment_table";
import { currencySubunitToUnit } from "../../../utils/currencyHelper";
import { handleTableSorter } from "../../../utils/helper";
import { toKebabCase } from "../../../utils/stringHelper";
import API from "../../../server";
import Tooltip from "../../../common/toolTip/toolTipTable";
import profile from "../../../utils/profile";
import { CONTRACT, EDIT, VIEW } from "../../../utils/constants";

const MaterialsTable = (props) => {
  const {
    title,
    data,
    claims,
    accountConfig,
    updateComment,
    handleInput,
    total,
    contractId,
    permissions,
    claimId,
  } = props;
  const [previousClaimTotal, setPreviousClaimTotal] = useState(0);
  const [historyTable, setHistoryTable] = useState([]);

  const dropDecimal = dropDecimalColumns(data, ["claimed_up_to_date_total"]);
  const showCurrency = !accountConfig?.get("enable_header_currency");
  const claimWord = accountConfig?.getIn(["claim", "noun"]);

  const getClaimValue = (lineItemId) => {
    let value = 0;
    var obj = claims.find((obj) => obj.lineitem_id === lineItemId);
    if (!obj) {
      return null;
    }
    value = obj?.value;
    return value;
  };

  const NetClaimTotal = () => (
    <NetClaim>
      {headerFormatter(
        "Net",
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      )}
      <BootstrapTooltip
        title={`This ${claimWord?.toLowerCase()} less previous ${claimWord?.toLowerCase()}.`}
        placement="bottom"
      >
        <NoticeIconTransparent />
      </BootstrapTooltip>
    </NetClaim>
  );

  const columns = [
    {
      title: "Ref",
      className: "align_left lineitem-ref",
      width: 81,
      render: "label",
    },
    {
      title: "Description",
      className: "align_left",
      width: 842,
      render: "description",
    },
    {
      title: headerFormatter(
        "Value",
        accountConfig?.getIn(["currency", "code"]),
        accountConfig?.get("enable_header_currency"),
      ),
      className: "align_center",
      width: 290,
      render: (d) => (
        <div className="inlineDiv">
          <div className="claiming-cell">
            <Input
              name="materials-claim"
              field="number"
              value={formatValue(d.claim_value || getClaimValue(d.id))}
              onValueChange={(e) => {
                handleInput(d.id, e.floatValue);
              }}
              decimalScale={2}
              style={{ width: "217px", textAlign: "right" }}
            />

            <Tooltip
              status="lineitem"
              getTable={() => getClaimHistory(contractId, d.id)}
              title={historyTable}
              id={d.id}
              isParent={false}
              accountConfig={accountConfig}
            />
          </div>
        </div>
      ),
    },
    {
      title: <NetClaimTotal />,
      className: "hidden net-claim",
      width: 104,
      render: (d) => {
        return (
          <div className="inlineDiv">
            {calculateNetClaim(
              d.id,
              claims,
              d.claimed_up_to_date_total,
              dropDecimal["claimed_up_to_date_total"],
              !accountConfig?.get("enable_header_currency"),
            )}
          </div>
        );
      },
    },
    {
      className: "hidden net-claim",
      width: 60,
      render: (d) => {
        return (
          <div className="inlineDiv">
            {claimId && (permissions.edit || permissions.view) && (
              <CommentList
                data={d}
                type={"claim"}
                expands={[]}
                viewOnly={permissions.view}
                updateComment={updateComment}
              />
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    let previousClaimTotal = 0;
    data.map((item) => {
      previousClaimTotal += item.claimed_up_to_date_total;
      return null;
    });
    setPreviousClaimTotal(previousClaimTotal);
  }, [data]);

  const formatValue = (value) => {
    let format = value || 0;
    return (format / 100).toFixed(2);
  };

  const getClaimHistory = async (contractId, itemId) => {
    //read last three months claim history for lineitem
    API.read_three_month_claim_history(contractId, itemId)
      .then((res) => res.data.data.data.records)
      .then((res) => {
        return !res ? setHistoryTable([]) : setHistoryTable(res);
      });
  };

  return (
    <div className="materials">
      <span className="sub-title">{title}</span>
      <TableContainer className={"table " + toKebabCase(title)}>
        <table className="header_table">
          <tbody>
            <tr>
              <td style={{ width: 923 }} />
              <td className="label-claim">{claimWord?.toUpperCase()}</td>
              <td style={{ width: 164 }} />
            </tr>
          </tbody>
        </table>
        <Table data={data} columns={columns}>
          <table>
            <tfoot>
              <tr>
                <td className="empty" />
                <td className="claim">
                  {currencySubunitToUnit(total, showCurrency)}
                </td>
                <td className="net">
                  {currencySubunitToUnit(
                    total - previousClaimTotal,
                    showCurrency,
                  )}
                </td>
                <td className="claim-actions" />
              </tr>
            </tfoot>
          </table>
        </Table>
      </TableContainer>
    </div>
  );
};

const MakeClaimMaterials = (props) => {
  const {
    data,
    accountConfig,
    updateComment,
    handleInput,
    materialsClaimList,
    totals,
    contractId,
    proId,
    claimId,
  } = props;
  const [permissions, setPermissions] = useState({
    edit: true,
    view: false,
  });
  const [onSite, setOnsite] = useState([]);
  const [offSite, setOffSite] = useState([]);
  const [claimList, setClaimList] = useState({
    onSite: [],
    offSite: [],
  });
  const [sortOpen, setSortOpen] = useState(false);
  const [sortOption, setSortOption] = useState({
    field: "created_date",
    sep: "asc",
  });
  const sortingOptions = {
    options: {
      created_date: "Date Created",
      label: "Reference",
      description: "Description",
    },
    sep: {
      asc: "Ascending",
      desc: "Descending",
    },
  };

  // get permissions
  useEffect(() => {
    let edit = permissions.edit,
      view = permissions.view;
    profile.checkPermission(CONTRACT, EDIT, proId, contractId).then((res) => {
      edit = res;
    });
    profile.checkPermission(CONTRACT, VIEW, proId, contractId).then((res) => {
      view = res;
    });
    setPermissions({ view: view, edit: edit });
  }, []); // eslint-disable-line

  // separate on/off site items
  useEffect(() => {
    let matOnSite = [],
      matOffSite = [];
    data?.toJS().forEach((line) => {
      if (line.on_site) {
        matOnSite.push(line);
      } else {
        matOffSite.push(line);
      }
    });
    setOnsite(handleSort(matOnSite, sortOption));
    setOffSite(handleSort(matOffSite, sortOption));
  }, [data]); // eslint-disable-line

  useEffect(() => {
    let materialsOnSite = [];
    let materialsOffSite = [];

    materialsClaimList.toJS().forEach((value) => {
      if (value.onSite) {
        materialsOnSite.push(value);
      } else {
        materialsOffSite.push(value);
      }
    });

    setClaimList({
      onSite: materialsOnSite,
      offSite: materialsOffSite,
    });
  }, [materialsClaimList]);

  const setSort = (option) => {
    let newOption = { ...sortOption };
    if (sortingOptions.options[option]) {
      newOption = { ...newOption, field: option };
      setSortOption(newOption);
    } else if (sortingOptions.sep[option]) {
      newOption = { ...newOption, sep: option };
      setSortOption({ ...newOption, sep: option });
    }
    setOnsite(handleSort(onSite, newOption));
    setOffSite(handleSort(offSite, newOption));
  };

  const handleSort = (list, sortOptions) => {
    let newList = [...list];
    newList.sort(handleTableSorter(sortOptions.field, sortOptions.sep));
    return newList;
  };

  return data?.size > 0 ? (
    <OutLayerWrapper>
      <MaterialsSection>
        <div className="title-field">
          <span>Materials On/Off Site</span>
          <ClickAwayListener onClickAway={() => setSortOpen(false)}>
            <div>
              <Sort
                open={sortOpen}
                openDropdown={() => setSortOpen(true)}
                setSort={(e) => setSort(e.target.value)}
                sortField={sortOption.field}
                sortSep={sortOption.sep}
                sortOptions={sortingOptions}
              />
            </div>
          </ClickAwayListener>
        </div>

        {onSite.length > 0 && (
          <MaterialsTable
            title="On Site"
            data={onSite}
            claims={claimList.onSite}
            accountConfig={accountConfig}
            updateComment={updateComment}
            handleInput={(lineId, value) => handleInput(lineId, value, true)}
            total={totals.get("onSite")}
            contractId={contractId}
            permissions={permissions}
            claimId={claimId}
          />
        )}
        {offSite.length > 0 && (
          <MaterialsTable
            title="Off Site"
            data={offSite}
            claims={claimList.offSite}
            accountConfig={accountConfig}
            updateComment={updateComment}
            handleInput={(lineId, value) => handleInput(lineId, value, false)}
            total={totals.get("offSite")}
            contractId={contractId}
            permissions={permissions}
            claimId={claimId}
          />
        )}
      </MaterialsSection>
    </OutLayerWrapper>
  ) : null;
};

export default MakeClaimMaterials;
