import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import * as themeStyle from "../../theme";

const greyBootstrap = makeStyles((theme) => ({
  arrow: {
    color: themeStyle.palette.gunmetal,
    position: "absolute",
    fontSize: 8,
  },

  tooltip: {
    position: "relative",
    padding: (props) => props.padding || "12px 24px",
    fontSize: "14px",
    backgroundColor: themeStyle.palette.gunmetal,
    fontFamily: themeStyle.font.mainFont,
    boxSizing: "border-box",
  },
}));

const colorfulBootstrap = makeStyles((theme) => ({
  arrow: {
    color: (props) => props.color,
    fontSize: 8,
  },
  tooltip: {
    position: "relative",
    backgroundColor: (props) =>
      props.themecolor ? themeStyle.palette.themecolor : props.color,
    padding: "12px 24px",
    fontSize: (props) => props.size || "16px",
    fontFamily: themeStyle.font.mainFont,
    fontWeight: (props) => props.fontWeight || themeStyle.font.mainFontWeight,
    boxSizing: "border-box",
    cursor: "pointer",
    color: (props) => props.fontcolor,
    height: (props) => props.height || "auto",
    maxWidth: (props) => props.width || "auto",
  },
}));

function BootstrapTooltip(props) {
  if (props.isHide) {
    return props.children;
  }
  const classes =
    props.color || props.themecolor
      ? colorfulBootstrap(props)
      : greyBootstrap(props);
  return (
    <Tooltip
      arrow
      classes={classes}
      {...props}
      title={<React.Fragment>{props.title}</React.Fragment>}
      placement={props.placement || "top"}
    />
  );
}

BootstrapTooltip.propTypes = {
  title: PropTypes.node,
  padding: PropTypes.string,
};

export default BootstrapTooltip;
