import React, { PureComponent } from "react";
import { OutLayerWrapper } from "../styled";
import ClaimTable from "../../../common/table/claim_percent_table";

import { getTableField } from "../shared/claimCertHelper";

export default class ClaimTableComponent extends PureComponent {
  render() {
    return (
      <OutLayerWrapper>
        <ClaimTable
          {...this.props}
          tableField={getTableField(this.props?.title)}
        />
      </OutLayerWrapper>
    );
  }
}
