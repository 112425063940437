import React, { Fragment, useEffect, useRef, useState } from "react";
import { CommentContainer } from "./styled";
import { checkIsParent } from "utils/helper";
import { calcSize } from "./utils";
import CommentItem from "./comment_item";

const CommentList = (props) => {
  const {
    open,
    setOpen,
    isIconClicked,
    data,
    updateComment,
    timezone,
    profile,
    viewOnly,
    otherParty,
    expands,
  } = props;
  const [externalComments, setExternalComments] = useState([]);
  const [internalComments, setInternalComments] = useState([]);
  const [isIssuedClaimCert, setIsIssuedClaimCert] = useState(false);

  const listRef = useRef(null);

  useEffect(() => {
    const handleClickAway = (e) => {
      // close on click away only happens if user clicks outside comment list and comment action option
      if (listRef.current) {
        const rect = listRef.current.getBoundingClientRect();
        const isOutside =
          e.clientX < rect.left ||
          e.clientX > rect.right ||
          e.clientY < rect.top ||
          e.clientY > rect.bottom;

        if (open && !isIconClicked(e) && isOutside) {
          setOpen(false);
        }
      }
    };
    window.addEventListener("click", handleClickAway);
    return () => {
      window.removeEventListener("click", handleClickAway);
    };
  }, [open, isIconClicked, setOpen]);

  useEffect(() => {
    if (data.claim_status === "approved") {
      setIsIssuedClaimCert(true);
    }
  }, [data]);

  // separate external shared and internal comments
  useEffect(() => {
    let external = [],
      internal = [];
    data?.comments?.forEach((comment) => {
      if (comment.is_other_party) {
        external.push(comment);
      } else {
        internal.push(comment);
      }
    });

    setExternalComments(external);
    setInternalComments(internal);
  }, [data?.comments]);

  let commentCount = calcSize(data, expands, viewOnly)[1];

  return (
    <>
      {(!checkIsParent(data) ||
        expands.includes(data.id) ||
        (checkIsParent(data) && data.comments?.length === commentCount)) && (
        <CommentContainer ref={listRef} show={open}>
          {!isIssuedClaimCert && !viewOnly && (
            <div className="title">
              <div className="header">
                <span>Add a comment</span>
                <div className="cancelIcon" onClick={() => setOpen(false)} />
              </div>
              <CommentItem
                isAdd
                addComment={(comment) =>
                  updateComment("post", comment, "", data.id)
                }
                open={open}
              />
            </div>
          )}
          <div className="comments">
            {internalComments.map((comment, i) => (
              <Fragment key={i}>
                {(i > 0 || !viewOnly) && <div className="divider" />}
                <CommentItem
                  data={comment}
                  profile={profile}
                  timezone={timezone}
                  viewOnly={viewOnly || isIssuedClaimCert}
                  commentUpdate={(commentId, update) =>
                    updateComment("put", update, commentId, data.id)
                  }
                  deleteComment={(commentId) =>
                    updateComment("delete", "", commentId, data.id)
                  }
                />
              </Fragment>
            ))}
            {externalComments?.length > 0 && (
              <div className="banner">
                Other Party <div className="party-name">{otherParty}</div>
              </div>
            )}
            {externalComments.map((comment, i) => (
              <Fragment key={i}>
                {i > 0 && <div className="divider" />}
                <CommentItem
                  data={comment}
                  profile={profile}
                  timezone={timezone}
                  viewOnly={true}
                />
              </Fragment>
            ))}
          </div>
          <div className="tail" />
        </CommentContainer>
      )}
    </>
  );
};

export default CommentList;
