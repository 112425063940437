import React, { useEffect, useState, Fragment } from "react";
import {
  CommentAction,
  CommentStyle,
  InputCommentSection,
  ShareButton,
} from "./styled";
import Input from "common/form";
import {
  GreenWhiteBtn as SaveBtn,
  GreyOutlinedBtn as CancelBtn,
} from "../../common/button";
import { COMMENT_CHAR_LIMIT } from "utils/numberConstants";
import { convertUTCToTime } from "utils/dateTimeHelper";
import BootstrapTooltip from "common/toolTip";

const CommentItem = (props) => {
  const {
    data,
    profile,
    timezone,
    addComment,
    commentUpdate,
    deleteComment,
    isAdd,
    viewOnly,
    open,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState("");
  const [shared, setShared] = useState(false);
  const [error, setError] = useState({ limitExceeded: "" });

  useEffect(() => {
    if (!open) {
      setContent("");
    }
  }, [open]);

  // initialise content and shared for comment edit or viewing mode
  useEffect(() => {
    if (!isAdd || editMode) {
      setContent(data.content);
      setShared(data.shared);
      setError({ limitExceeded: "" });
    }
  }, [data, editMode]); // eslint-disable-line

  const handleInput = (text) => {
    if (text.length > COMMENT_CHAR_LIMIT) {
      setError({ ...error, limitExceeded: "Character limit exceeded" });
    } else {
      setError({ ...error, limitExceeded: "" });
    }
    setContent(text);
  };

  const performAddComment = (content, shared) => {
    addComment({ comment: content, shared: shared });
    setContent("");
    setError({ ...error, limitExceeded: "" });
  };

  if (isAdd)
    return (
      <InputCommentSection>
        <div className="inputSection">
          <Input
            className={`inputField${error.limitExceeded ? " error" : ""}`}
            label="Comment"
            value={content}
            onChange={(e) => handleInput(e.target.value)}
          />

          <div className="buttons">
            <SaveBtn
              title="Add"
              className="button"
              disabled={content === "" || error.limitExceeded}
              onClick={() => performAddComment(content, shared)}
            />
            <CancelBtn
              title="Clear"
              className="button"
              onClick={() => {
                setContent("");
                setError({ ...error, limitExceeded: "" });
              }}
            />
          </div>
        </div>
        {error.limitExceeded && (
          <div className="errorMsg">Character limit exceeded</div>
        )}
        <div className="visibility">
          <ShareButton
            className={`${shared ? " shared" : ""}`}
            onClick={() => setShared(shared ? false : true)}
          >
            <div className={`icon ${shared ? " shared" : ""}`} />
          </ShareButton>
          <div className="description">
            {shared
              ? "Visible to other party"
              : "Not visible to other party, only people in your organisation can see this"}
          </div>
        </div>
      </InputCommentSection>
    );

  return (
    <CommentStyle>
      <div className="container top">
        <div className="userName">{data.user_name}</div>
        {!viewOnly && !editMode && data.user_email === profile.get("email") && (
          <div className="container action">
            <CommentAction onClick={() => setEditMode(true)}>
              <div className="icon editIcon" />
            </CommentAction>
            <CommentAction onClick={() => deleteComment(data.comment_id)}>
              <div className="icon deleteIcon" />
            </CommentAction>
          </div>
        )}
      </div>
      <div className="container content">
        {editMode ? (
          <InputCommentSection>
            <div className="inputSection">
              <Input
                className={`inputField${error.limitExceeded ? " error" : ""}`}
                label="Comment"
                value={content}
                onChange={(e) => handleInput(e.target.value)}
              />
              <div className="buttons">
                <SaveBtn
                  title="Save"
                  className="button"
                  disabled={content === "" || error.limitExceeded}
                  onClick={() => {
                    commentUpdate(data.comment_id, {
                      comment: content,
                      shared: shared,
                    });
                    setEditMode(false);
                  }}
                />
                <CancelBtn
                  title="Cancel"
                  className="button"
                  onClick={() => {
                    setContent(data.content);
                    setEditMode(false);
                  }}
                />
              </div>
            </div>
            {error.limitExceeded && (
              <div className="errorMsg">Character limit exceeded</div>
            )}
          </InputCommentSection>
        ) : (
          <Fragment>{content}</Fragment>
        )}
      </div>
      <div className="container bottom">
        <BootstrapTooltip
          title={
            shared
              ? "This comment is visible to the other party"
              : "This comment is only visible to people in your organisation"
          }
          placement="bottom"
        >
          {!data.is_other_party ? (
            !viewOnly && data.user_email === profile.get("email") ? (
              <CommentAction
                className="shareToggle"
                onClick={() =>
                  commentUpdate(data.comment_id, {
                    comment: data.content,
                    shared: !shared,
                  })
                }
              >
                <div className={`icon ${shared ? "shared" : ""}`} />
              </CommentAction>
            ) : (
              <CommentAction className="shareToggle noEdit">
                <div className={`icon ${shared ? "shared" : ""}`} />
              </CommentAction>
            )
          ) : (
            <div />
          )}
        </BootstrapTooltip>
        {convertUTCToTime(data.update_date, timezone, true)}
      </div>
    </CommentStyle>
  );
};

export default CommentItem;
