import { checkIsParent } from "utils/helper";
import commentIcon from "../../statics/img/icon-add-comment-yellow.svg";
import commentEditIcon from "../../statics/img/icon-add-comment-medium-grey.svg";

export const getChildCommentSize = (data, sum) => {
  if (checkIsParent(data)) {
    data.childitems.forEach((item) => {
      sum += !item.comments ? 0 : item?.comments?.length;
      sum = getChildCommentSize(item, sum);
    });
  }
  return sum;
};

export const calcSize = (d, expands, viewOnly) => {
  let num = !d.comments ? 0 : d.comments?.length;
  if (!expands.includes(d.id)) {
    let sum = 0;
    sum = getChildCommentSize(d, sum);
    num += sum;
  }
  if (num === 0) {
    if (!viewOnly) {
      return [commentEditIcon, ""];
    }
    return ["", ""];
  }
  return [commentIcon, num];
};
