import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  ReviewWrapper,
  Comment,
  SubDetail,
  DetailWrapper,
  ColumnItemWrapper,
} from "../../../makeclaim/styled";

import { capitalizeAddress } from "../../../../utils/helper";
import { convertUTCToTime } from "../../../../utils/dateTimeHelper";
import * as constants from "../../../../utils/constants";

const marginStyle = { marginLeft: 230 };

const Info = ({ label, value, child, hasMargin }) => (
  <div className="item_wrapper">
    <div className="attribute_name">{label}</div>
    {value && (
      <div className="value" style={hasMargin ? marginStyle : {}}>
        {value}
      </div>
    )}
    {child}
  </div>
);

const InfoPart = ({ title, styleName, list, info, claimWord }) => {
  return (
    <SubDetail className="review">
      <div className="titleS">{title}</div>
      <ColumnItemWrapper className={styleName}>
        {title.includes(claimWord) && (
          <>
            {Object.keys(list).map((key, index) => {
              if (Array.isArray(list[key])) {
                return (
                  info.get("has_retention") && (
                    <Info
                      key={index}
                      label={key}
                      hasMargin
                      value={info.getIn(list[key])}
                    />
                  )
                );
              } else if (typeof list[key] !== "string") {
                return <Info label={key} child={list[key]} key={index} />;
              } else {
                if (info.get(list[key])) {
                  return (
                    <Info label={key} value={info.get(list[key])} key={index} />
                  );
                } else {
                  return <Info label={key} value={list[key]} key={index} />;
                }
              }
            })}
          </>
        )}

        {!title.includes(claimWord) &&
          Object.keys(list).map((key, index) => {
            if (key === "Address" || key === "Contract Addr.") {
              return (
                <Info
                  key={index}
                  label={key}
                  value={capitalizeAddress(
                    info.get(list[key][0]),
                    info.get(list[key][1]),
                    info.get(list[key][2]),
                    info.get(list[key][3]),
                  )}
                />
              );
            } else {
              return (
                <Info label={key} value={info.get(list[key])} key={index} />
              );
            }
          })}
      </ColumnItemWrapper>
    </SubDetail>
  );
};

class ProjectDetail extends PureComponent {
  render() {
    const { accountConfig, contractConfig, certInfo, claimDetail } = this.props;
    //if has code to use code other than title
    let gstNumberTitle =
      contractConfig?.getIn(["gst_number", "code"]) ||
      contractConfig?.getIn(["gst_number", "title"]) ||
      "Gst Number";
    let enableComments =
      contractConfig?.getIn(["legislation", "status"]) ===
      constants.STATUS_ACTIVE
        ? true
        : false;

    let certWord = accountConfig?.getIn(["cert", "noun"]) || "Certificate";
    const claimWord = accountConfig?.getIn(["claim", "noun"]);

    const Period = () => (
      <div className="period">
        <div className="from">
          <div className="fromS">From</div>
          {claimDetail.get("claim_from")}
        </div>
        <div className="from">
          <div className="fromS">To</div>
          {claimDetail.get("claim_to")}
        </div>
      </div>
    );

    return (
      <ReviewWrapper className="project">
        {enableComments ? (
          <Comment>
            {contractConfig?.getIn(["legislation", "json", "cert__pdf"])}
          </Comment>
        ) : (
          <></>
        )}
        <DetailWrapper>
          <InfoPart
            title="To / Payee"
            info={certInfo}
            options=""
            claimWord={claimWord}
            list={{
              Name: "payee_entity_name",
              Address: [
                "payee_entity_streetaddress",
                "payee_entity_suburb",
                "payee_entity_city",
                "payee_entity_postal_code",
              ],
              Email: "payee_contact_email",
              [gstNumberTitle]: "payee_gst_number",
            }}
          />
          <InfoPart
            title="From / Payer"
            info={certInfo}
            options=""
            claimWord={claimWord}
            list={{
              Name: "payer_entity_name",
              Address: [
                "payer_entity_streetaddress",
                "payer_entity_suburb",
                "payer_entity_city",
                "payer_entity_postal_code",
              ],
              Email: "payer_contact_email",
              [gstNumberTitle]: "payer_gst_number",
            }}
          />
          <InfoPart
            title="Contract Details"
            info={certInfo}
            options=""
            claimWord={claimWord}
            list={{
              Contract: "contract_name",
              "Contract No.": "contract_number",
              "Contract Addr.": [
                "contract_street_address",
                "contract_suburb",
                "contract_city",
                "contract_postal_code",
              ],
              Trade: "contract_trade",
              "Payee Ref": "payee_ref",
              "Payer Ref": "payer_ref",
            }}
          />
          <InfoPart
            title={`${claimWord} Details`}
            info={claimDetail}
            claimWord={claimWord}
            list={{
              [`${claimWord} Ref`]: "claim_ref",
              [`${claimWord} Period`]: <Period />,
              [`${claimWord} Issued`]: certInfo?.get("claim_issued_date")
                ? convertUTCToTime(
                    certInfo?.get("claim_issued_date"),
                    certInfo?.get("contract_timezone"),
                    false,
                    "yyyy-MM-dd",
                  )[0]
                : "",
              [`${claimWord} Due`]: "claim_due_date",
              [`${certWord} Due`]: "cert_due_date",
              "Payment Due": "pay_due_date",
              "Practical Completion Date": ["read_retention", "pcd_date"],
              "Defects Liability Period": ["read_retention", "dlp_date"],
            }}
          />
        </DetailWrapper>
      </ReviewWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  contractConfig: state.getIn(["makeacert", "contractConfig"]),
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(ProjectDetail);
