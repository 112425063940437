import React, { useEffect, useState, useRef, useMemo } from "react";
import { palette } from "theme";
import { VariationStatusButtonWrapper } from "./styled";

import pendingIcon from "statics/img/icon-variations-pending.svg";
import rejectedIcon from "statics/img/icon-variation-close.svg";
import arrowDownButton from "statics/img/icon-dropdown-down-dark-grey.svg";
import revertIcon from "statics/img/icon-revert.svg";
import greenTickIcon from "statics/img/icon-tick-green.svg";
import redCrossIcon from "statics/img/icon-clear-red.svg";

import ReasonForRejectionModal from "pages/variations/components/reasonForRejectionModal";
import { VARIATION_LINEITEM_STATUS } from "utils/constants";
/**
 * @typedef VariationStatusOptionKey
 * @type {"pending"|"approved"|"rejected"}
 */

/**
 * Renders a variation status button along with the option(s) to select the variation status via a dropdown.
 *
 * @param {Object} props
 * @param {boolean} [props.expandedButtonByDefault] - Option to set the button to be expanded by default.
 * @param {VariationStatusOptionKey} props.status - Provides the displayed status value.
 * @param {(status: VariationStatusOptionKey) => void} [props.setStatus] - Function to set the variation status value. Takes the status as an argument. If no function is provided, the component is set to read only.
 * @param {boolean} [props.onlyRevertToPending] - Option to only allow the option to be reverted to pending.
 *
 * @returns {React.JSX.Element} - The variation status button with dropdown component.
 *
 * @example
 * <VariationStatusButton
 *   expandedButtonByDefault
 *   status={"pending"}
 *   setStatus={(val) => console.log(val)}
 *   onlyRevertToPending
 * />
 */
export default function VariationStatusButton(props) {
  const {
    expandedButtonByDefault = false,
    status = "",
    setStatus,
    onlyRevertToPending = false,
    isVarianceRequired = false,
  } = props;

  const ref = useRef();

  const [expandedButton, setExpandedButton] = useState(expandedButtonByDefault);
  const [includeDropDown, setIncludeDropDown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const closeDropDown = useMemo(
    () => () => {
      setIncludeDropDown(false);
      if (!expandedButtonByDefault) setExpandedButton(false);
    },
    [expandedButtonByDefault],
  );

  useEffect(() => {
    const handleClickOutsideElement = (event) => {
      if (ref.current && !ref.current.contains(event.target)) closeDropDown();
    };

    document.addEventListener("click", handleClickOutsideElement);
    return () => {
      document.removeEventListener("click", handleClickOutsideElement);
    };
  }, [closeDropDown]);

  const variation = useMemo(
    () => getVariationStatus(status, onlyRevertToPending),
    [status, onlyRevertToPending],
  );
  if (!variation) return <></>;

  const readOnly = !setStatus || variation.options?.length === 0;

  const handleStatusChange = (newStatus) => {
    if (
      !isVarianceRequired &&
      newStatus === VARIATION_LINEITEM_STATUS.REJECTED
    ) {
      setUpdatedStatus(newStatus);
      setIsModalOpen(true);
    } else {
      setStatus(newStatus);
    }
  };

  const handleSave = () => {
    if (setStatus) {
      setStatus(
        updatedStatus || VARIATION_LINEITEM_STATUS.REJECTED,
        rejectionReason,
      );
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <VariationStatusButtonWrapper
        ref={ref}
        chipBackgroundImage={variation.chipBackgroundImage}
        buttonBackgroundColor={variation.backgroundColor}
        buttonZIndex={includeDropDown ? 1 : 0}
      >
        <div
          className={`button${expandedButton ? "" : " chip"}`}
          onClick={() => {
            if (readOnly) return;
            setIncludeDropDown(!includeDropDown);
          }}
          onMouseEnter={() => setExpandedButton(true)}
          onMouseLeave={() => {
            if (includeDropDown || expandedButtonByDefault) return;
            setExpandedButton(false);
          }}
        >
          {expandedButton && (
            <>
              <div className="title">{variation.title}</div>
              {!readOnly && (
                <img src={arrowDownButton} className="dropdown-icon" />
              )}
            </>
          )}
        </div>
        {includeDropDown && (
          <div className="dropdown">
            {variation.options?.map((option) => {
              return (
                <div
                  className="option"
                  key={option.value}
                  onClick={() => {
                    handleStatusChange(option.value);
                    closeDropDown();
                  }}
                >
                  <img className="icon" src={option.iconUrl} />
                  <span>{option.title}</span>
                </div>
              );
            })}
          </div>
        )}
      </VariationStatusButtonWrapper>
      {isModalOpen && (
        <ReasonForRejectionModal
          handleSave={handleSave}
          handleClose={() => setIsModalOpen(false)}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
        />
      )}
    </>
  );
}

/**
 * Type for variation status dropdown option.
 *
 * @typedef IVariationStatusChoiceSettingOption
 * @type {object}
 * @property {string} title - The value to display as text on dropdown.
 * @property {string} iconUrl - The URL to display the image beside text.
 * @property {string} value - The value used for the setStatus function.
 */

/** @type {IVariationStatusChoiceSettingOption} */
const pendingVariationOption = {
  title: "Revert to Pending",
  iconUrl: revertIcon,
  value: "pending",
};

/** @type {IVariationStatusChoiceSettingOption} */
const approveVariationOption = {
  title: "Approve Variation",
  iconUrl: greenTickIcon,
  value: "approved",
};

/** @type {IVariationStatusChoiceSettingOption} */
const rejectVariationOption = {
  title: "Reject Variation",
  iconUrl: redCrossIcon,
  value: "rejected",
};

/**
 * Value for setting the status dropdown button options based on the option key.
 *
 * @typedef IVariationStatusSetting
 * @type {object}
 * @property {string} title - The value to display on button at expanded state.
 * @property {string} backgroundColor - The hex color used for the color of the button.
 * @property {string} [chipBackgroundImage] - The icon URL used when the button is on chip mode.
 * @property {IVariationStatusChoiceSettingOption[]} options - The list of dropdown options.
 */

/**
 * Makes the setting object for variation status button component to use.
 *
 * @param {VariationStatusOptionKey} [status]
 * @param {boolean} [onlyPending] - Option to display only the pending status.
 * @returns {Readonly<IVariationStatusSetting> | null}
 */
const getVariationStatus = (status, onlyPending = false) => {
  /** @type {IVariationStatusSetting | null} */
  let variationStatus = null;

  switch (status) {
    case "pending": {
      variationStatus = {
        title: "Pending",
        backgroundColor: palette.goldenYellow,
        chipBackgroundImage: `url(${pendingIcon})`,
        options: [],
      };

      if (!onlyPending) {
        variationStatus.options.push(
          approveVariationOption,
          rejectVariationOption,
        );
      }
      break;
    }
    case "approved": {
      variationStatus = {
        title: "Approved",
        backgroundColor: palette.lightGreen,
        options: [pendingVariationOption],
      };

      if (!onlyPending) {
        variationStatus.options.push(rejectVariationOption);
      }
      break;
    }
    case "rejected": {
      variationStatus = {
        title: "Rejected",
        backgroundColor: palette.lightPastelPink,
        chipBackgroundImage: `url(${rejectedIcon})`,
        options: [pendingVariationOption],
      };

      if (!onlyPending) {
        variationStatus.options.push(approveVariationOption);
      }
      break;
    }
  }

  return variationStatus;
};
