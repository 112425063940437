import styled from "styled-components";
import * as theme from "theme";
import userIcon from "statics/img/icon-avatar.svg";
import arrow from "statics/img/icon-arrow-forward.svg";
import historyIcon from "statics/img//icon-history.svg";

export const Container = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  .align-right {
    align-self: end;
  }
  .no-data {
    margin: 30px auto;
    font-size: 20px;
    font-weight: bold;
  }
`;
export const Header = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 220px;
  grid-column-gap: 40px;
  font-weight: bold;
  width: 100%;
  margin: 40px 0 16px;
}
`;

export const LogItemContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 220px;
  grid-column-gap: 40px;
  border-top: 1px solid ${theme.palette.wolfGrey};
  padding: 26px 0;
  width: 100%;

  .user {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 16px;
    .icon {
      width: 40px;
      height: 40px;
      background: url(${userIcon}) no-repeat;
      background-size: contain;
      margin-right: 16px;
    }
    .name {
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    a {
      color: inherit;
      text-decoration: inherit;
    }
    .link_title {
      display: inline;
      font-weight: bold;
      &:hover {
        color: ${theme.palette.hoverColor};
        cursor: pointer;
      }
    }
    li {
      color: ${theme.palette.veryMediumGrey};
      list-style: none;
      margin: 2px 0;
      display: flex;
      &.fixed-width {
        width: 670px;
      }
      .has-margin {
        margin-right: 8px;
      }
    }
  }
  .arrow {
    background: url(${arrow}) no-repeat;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    flex-shrink: 0;
  }
  .light-grey-label {
    color: ${theme.palette.veryMediumGrey};
  }
  .time {
    color: ${theme.palette.veryMediumGrey};
  }

  &.skeleton {
    .image-skeleton {
      width: 40px;
      height: 40px;
      display: block;
    }
    .grid-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .line-skeleton {
      width: 136px;
      height: 20px;
      border-radius: 4px;
    }
    .line-skeleton-1 {
      width: 100%;
      height: 20px;
      border-radius: 4px;
    }
  }
`;
export const HistoryIcon = styled.div`
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${theme.palette.gunmetal};
  background: url(${historyIcon}) no-repeat center center;
  background-size: 50%; /* Background image size */
  display: flex;
  width: 52px;
  margin: ${({ noMargin }) => (noMargin ? "0" : "0 0 24px auto")};
  background-color: ${theme.palette.white};
  cursor: pointer;
  &:hover {
    background-color: ${theme.palette.veryWarmLightGrey};
  }
`;
