import React, { Component } from "react";
import { connect } from "react-redux";
import { TableStyle, CellSubtitle } from "./styled";
import { roundNearest } from "../../utils/numeralHelper";
import { currencySubunitToUnit } from "../../utils/currencyHelper";
import {
  dropDecimalColumns,
  dropDecimal as dropDecimalA,
} from "../../utils/tableFormatHelper";
import TableComponent from "../table/basic_table";
import {
  BaseDetails,
  VariationDetails,
  VariationsTotalDisplay,
} from "../table/shared_component";
import {
  LUMP,
  VARIATION_LINEITEM_STATUS,
  VARIATION_TYPES,
} from "../../utils/constants";
import {
  getExpandProvisionalParents,
  ProvisionalDescription,
  getVariationStatus,
} from "./sharedComponent";
import Tooltip from "../../common/toolTip";
import * as theme from "../../theme";
import VariationStatusButton from "pages/makeclaim/shared/variationStatusButton";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandItemDetailKey: [],
    };
  }

  checkGreyDash = (line) => {
    return line.lineitem_options === LUMP || line.childitems?.length > 0;
  };

  isTreeExpanded = (id) => this.props?.expandTreeKeys?.includes(id);

  isItemFileExpanded = (id) => this.state.expandItemDetailKey.includes(id);

  expandProvisionalParent = (line) => {
    let expanded = getExpandProvisionalParents(
      line,
      this.props?.expandTreeKeys || [],
    );
    this.props.setExpandKeys(expanded);
  };

  render() {
    const { expandItemDetailKey } = this.state;
    const {
      tableContent,
      tableCaption,
      noAdditionToolbar,
      total,
      sectionTitle,
      downloadFile,
      proId,
      contractId,
      accountConfig,
      hasSort,
      tableSubtitle,
      hasTableSubtitle,
      isTableSubtitleBold,
      isProvisional,
      expandTreeKeys,
      setExpandKeys,
      hasRetention,
      contractInfo,
      ...otherProps
    } = this.props;
    let dropDecimal = dropDecimalColumns(tableContent.toJS(), [
      "quantity",
      "rate",
      "total",
    ]);
    const showCurrency = !accountConfig.get("enable_header_currency");
    const isBaseContract =
      sectionTitle.includes("Base") || tableSubtitle?.includes("Base");
    const columns = [
      {
        title: "Ref",
        className: "item-not-show lineitem-ref",
        render: "label",
        width: "7%",
      },
      {
        type: "row-expand",
        width: 0,
        className: "file-not-show",
        onClick: (d, isExpand) => {
          if (isExpand) {
            this.setState({
              expandItemDetailKey: [d.id],
            });
          } else {
            this.setState({
              expandItemDetailKey: [],
            });
          }
        },
        render: (d) => {
          return () => {
            if (isBaseContract) {
              return (
                <div className="inputWrapper">
                  <BaseDetails
                    data={d}
                    downloadFile={downloadFile}
                    proId={proId}
                    contractId={contractId}
                    readOnlyExpandKeys={expandItemDetailKey}
                    hasRetention={hasRetention}
                    timeZone={contractInfo?.get("time_zone")}
                  />
                </div>
              );
            } else {
              return (
                <div className="inputWrapper">
                  <VariationDetails
                    data={d}
                    downloadFile={downloadFile}
                    proId={proId}
                    contractId={contractId}
                    readOnlyExpandKeys={expandItemDetailKey}
                    hasRetention={hasRetention}
                    timeZone={contractInfo?.get("time_zone")}
                  />
                </div>
              );
            }
          };
        },
        // },
      },
      {
        title: "Description",
        className: "align_left",
        treeColumnsName: "childitems",
        treeIndent: 10,
        width: "31%",
        render: (d) => {
          if (isProvisional) {
            return (
              <div>
                {d.description}
                {d?.subtitle && <CellSubtitle>{d?.subtitle}</CellSubtitle>}
              </div>
            );
          }
          return (
            <div
              id={`${isBaseContract ? "base_contract" : "variation"}_name_${
                d.id
              }`}
            >
              <ProvisionalDescription
                data={d}
                isUpdateExpanded={this.isItemFileExpanded}
                isTreeExpanded={this.isTreeExpanded}
                expandProvisionalParent={this.expandProvisionalParent}
              />
            </div>
          );
        },
      },
      {
        title: "Qty",
        width: "14%",
        render: (d) => {
          if (this.checkGreyDash(d) || d.quantity === 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["quantity"] ? roundNearest(d.quantity) : d.quantity,
              false,
              false,
            );
          }
        },
      },
      {
        title: "Unit",
        render: (d) => {
          if (this.checkGreyDash(d) || d.unit === "") {
            return <div className="grey-dash"></div>;
          } else {
            return d.unit;
          }
        },
        width: "9%",
      },
      {
        title:
          "Rate" +
          (accountConfig?.get("enable_header_currency")
            ? ` (${accountConfig?.getIn(["currency", "code"])})`
            : ""),
        width: "15%",
        render: (d) => {
          if (this.checkGreyDash(d) || d.rate === 0) {
            return <div className="grey-dash"></div>;
          } else {
            return currencySubunitToUnit(
              dropDecimal["rate"] ? roundNearest(d.rate) : d.rate,
              showCurrency,
              false,
              false,
            );
          }
        },
      },
      {
        title:
          "Total" +
          (accountConfig?.get("enable_header_currency")
            ? ` (${accountConfig?.getIn(["currency", "code"])})`
            : ""),
        width: "15%",
        render: (d) => {
          const formattedTotal = currencySubunitToUnit(
            dropDecimal["total"] ? roundNearest(d.total) : d.total,
            showCurrency,
            false,
            false,
          );

          return (
            <VariationsTotalDisplay
              data={d}
              variTitle={sectionTitle}
              formattedTotal={formattedTotal}
              isContractLevel
            />
          );
        },
      },
    ];
    //if it's variation table
    if (!isBaseContract) {
      columns.push({
        title: "",
        className: "align_left",
        width: "4%",
        render: (d) => {
          if (
            !d ||
            [VARIATION_LINEITEM_STATUS.APPROVED, undefined].includes(
              d.current_status,
            ) ||
            d.parent_id
          ) {
            return undefined;
          }

          if (
            // Do not show variation status for a multi variation with no valid children.
            d.extra_info?.variation_type === VARIATION_TYPES.MULTI &&
            d.childitems?.length === 0
          ) {
            return undefined;
          }

          return (
            <VariationStatusButton
              status={getVariationStatus(d)}
              setStatus={false}
              onlyRevertToPending={false}
            />
          );
        },
      });
    }

    return (
      <TableStyle>
        <TableComponent
          tableContent={tableContent.toJS()}
          tableCaption={tableCaption}
          tableColumns={columns}
          hasSort={hasSort === undefined ? true : hasSort}
          hasEnhancedTableToolbar={!noAdditionToolbar}
          hasEnhancedTableFooter
          rowClassName={(record, index) => {
            if (expandItemDetailKey[0] === record.id) {
              return "highlight";
            }
          }}
          expandKeys={expandItemDetailKey}
          treeExpandKeys={expandTreeKeys}
          onTreeExpand={(keys) => {
            return setExpandKeys(keys);
          }}
          tableSubtitle={tableSubtitle}
          hasTableSubtitle={hasTableSubtitle}
          isTableSubtitleBold={isTableSubtitleBold}
          {...otherProps}
        >
          {this.props.children}
          <table>
            <tfoot className="table-foot">
              <tr>
                <td className="blank" colSpan={3} />
                <td className="summary" colSpan={2}>
                  {isProvisional ? (
                    <>
                      {" "}
                      <Tooltip
                        placement="bottom"
                        color={theme.palette.slateGrey}
                        size={"12px"}
                        title={`This is the sum of the provisional items. These items form part of the total ${tableSubtitle.toLowerCase()}.`}
                      >
                        <div className="img_icon" />
                      </Tooltip>{" "}
                      Total Provisional ({tableSubtitle})
                    </>
                  ) : (
                    <>{"Total " + sectionTitle}</>
                  )}
                </td>
                <td className="summary">
                  {currencySubunitToUnit(
                    dropDecimalA(total) ? roundNearest(total) : total,
                    showCurrency,
                    dropDecimalA(total),
                  )}
                </td>
              </tr>
              <tr>
                {dropDecimalA(total) && (
                  <td className="notice" colSpan={6}>
                    Note: Values have been rounded for display purposes. All
                    calculations use unrounded values.
                  </td>
                )}
              </tr>
            </tfoot>
          </table>
        </TableComponent>
      </TableStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
});

export default connect(mapStateToProps, null)(Table);
