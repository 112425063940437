import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import { calculateUnitToSubunit } from "../../../utils/currencyHelper";

import { actionCreators } from "../store";
import API from "../../../server";
import { SUMMARY_TYPE } from "utils/constants";

// new imports for newSummaryComponent
import ClaimCertSummaryComponent from "../shared/newSummaryComponent";
import { calculateTotalAndGst } from "../utils/calculationHelper";
import { findKeyByValue } from "../../../utils/helper";

const ClaimSummary = (props) => {
  const {
    proId,
    contractId,
    gst,
    retentionExist,
    baseClaimTotal,
    baseClaimTotalExcludeRetention,
    variationClaimTotal,
    variationClaimTotalExcludeRetention,
    materialsTotal,
    materialsTotalExcludeRetention,
    lessRetention,
    claimPcdReleaseValue,
    claimDlpReleaseValue,
    toClaimRetention,
    certRetention,
    setData,
    setPreviousOption,
    previous_option,
    previous_gross,
    previous_retention,
    previous_retention_pcrr,
    previous_retention_dlrr,
    labour_element,
    previous_labour_element,
    cis_status,
    domestic_reverse_charge,
    accountConfig,
  } = props;
  const [pcdMax, setPcdMax] = useState(0);
  const [dlpMax, setDlpMax] = useState(0);
  const [previousData, setPreviousData] = useState({});
  const [currentToDateData, setCurrentToDateData] = useState({});
  const [thisCalculatedData, setThisCalculatedData] = useState({});

  const debounceChange = debounce((field, value) => setData(field, value), 300);

  const requestRetention = debounce(
    (proId, contractId, total) => getLessRetention(proId, contractId, total),
    750,
  );

  const getLessRetention = (proId, contractId, total) => {
    API.calculate_retention(proId, contractId, {
      gross: Math.round(total),
    })
      .then((res) => res.data.data.data)
      .then((res) => {
        setData("lessRetention", res.retention / 100);
        setPcdMax(Math.floor(res.pcd_value + 0.5));
        setDlpMax(Math.floor(res.dlp_value + 0.5));
      });
  };

  const calculateCurrentGrossTotal = useCallback(() => {
    const materialTotals = materialsTotal.toJS();
    let grossTotal = calculateUnitToSubunit(
      baseClaimTotal + variationClaimTotal,
    );
    grossTotal += materialTotals.onSite;
    grossTotal += materialTotals.offSite;
    return grossTotal;
  }, [baseClaimTotal, variationClaimTotal, materialsTotal]);

  const cisJson = accountConfig?.getIn(["cis", "json", "status"])?.toJS();
  const cisStatus = findKeyByValue(cisJson, cis_status);

  // get retention
  useEffect(() => {
    if (proId && contractId) {
      const { onSite, offSite } = materialsTotalExcludeRetention.toJS();
      let total =
        calculateUnitToSubunit(baseClaimTotalExcludeRetention) +
        calculateUnitToSubunit(variationClaimTotalExcludeRetention) +
        onSite +
        offSite;
      requestRetention(proId, contractId, total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseClaimTotalExcludeRetention,
    variationClaimTotalExcludeRetention,
    materialsTotalExcludeRetention,
    proId,
    contractId,
  ]);

  // set previous
  useEffect(() => {
    const previousCisDeduction = calculateCISDeduction(
      previous_labour_element,
      previous_gross,
      previous_retention,
      previous_retention_pcrr,
      previous_retention_dlrr,
      cisStatus,
    );
    let newPreviousData = {
      option: previous_option,
      gross: previous_gross,
      retention: previous_retention,
      retention_pcrr: previous_retention_pcrr,
      retention_dlrr: previous_retention_dlrr,
      labour_element: previous_labour_element,
      cis_deduction: previousCisDeduction,
    };
    let [total, gstTotal] = calculateTotalAndGst(newPreviousData, gst);
    newPreviousData.total = total;
    newPreviousData.gst = gstTotal;
    setPreviousData(newPreviousData);
  }, [
    gst,
    previous_option,
    previous_gross,
    previous_retention,
    previous_retention_pcrr,
    previous_retention_dlrr,
    previous_labour_element,
    cisStatus,
  ]);

  // Inherit Retention Values From certRetention if toClaimRetention is false
  // Otherwise, use the values from the input fields
  // this is same as old summary component
  useEffect(() => {
    if (toClaimRetention) return;
    const pcdReleaseValue = certRetention.get("pcd");
    setData("claimPcdReleaseValue", calculateUnitToSubunit(pcdReleaseValue));
    const dlpReleaseValue = certRetention.get("dlp");
    setData("claimDlpReleaseValue", calculateUnitToSubunit(dlpReleaseValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certRetention, toClaimRetention]);

  const calculateCISDeduction = (
    gross,
    claimGross,
    retention,
    pcrr,
    dlrr,
    status,
  ) => {
    // Treat null, undefined, and NaN as zero
    const toValidNumber = (value) =>
      value === null || value === undefined || isNaN(value) ? 0 : value;

    gross = toValidNumber(gross);
    claimGross = toValidNumber(claimGross);
    retention = toValidNumber(retention);
    pcrr = toValidNumber(pcrr);
    dlrr = toValidNumber(dlrr);
    status = toValidNumber(status);

    if (claimGross === 0) {
      return {
        gross,
        retention: 0,
        pcrr: 0,
        dlrr: 0,
        value: 0,
      };
    }
    const cis_retention = (gross / claimGross) * retention;
    const cis_retention_pcrr = (gross / claimGross) * pcrr;
    const cis_retention_dlrr = (gross / claimGross) * dlrr;
    const cis_value =
      ((gross - cis_retention + cis_retention_dlrr + cis_retention_pcrr) *
        status) /
      100;

    return {
      gross,
      retention: cis_retention,
      pcrr: cis_retention_pcrr,
      dlrr: cis_retention_dlrr,
      value: cis_value,
    };
  };

  // set to date
  useEffect(() => {
    const claimToDateGross = calculateCurrentGrossTotal();
    const cisDeduction = calculateCISDeduction(
      labour_element,
      claimToDateGross,
      calculateUnitToSubunit(lessRetention),
      claimPcdReleaseValue,
      claimDlpReleaseValue,
      cisStatus,
    );
    let newCurrentToDateData = {
      gross: claimToDateGross,
      retention: calculateUnitToSubunit(lessRetention),
      retention_pcrr: claimPcdReleaseValue,
      retention_dlrr: claimDlpReleaseValue,
      maxPcd: pcdMax,
      maxDlp: dlpMax,
      labour_element: labour_element,
      cis_deduction: cisDeduction,
    };
    const [total, gstTotal] = calculateTotalAndGst(newCurrentToDateData, gst);
    newCurrentToDateData.total = total;
    newCurrentToDateData.gst = gstTotal;
    setCurrentToDateData(newCurrentToDateData);
  }, [
    gst,
    lessRetention,
    claimPcdReleaseValue,
    claimDlpReleaseValue,
    pcdMax,
    dlpMax,
    baseClaimTotal,
    variationClaimTotal,
    materialsTotal,
    calculateCurrentGrossTotal,
    labour_element,
    cisStatus,
  ]);
  // set this
  useEffect(() => {
    const cis = {
      value:
        (currentToDateData?.cis_deduction?.value ?? 0) -
        (previousData?.cis_deduction?.value ?? 0),
      gross:
        (currentToDateData?.cis_deduction?.gross ?? 0) -
        (previousData?.cis_deduction?.gross ?? 0),
      retention:
        (currentToDateData?.cis_deduction?.retention ?? 0) -
        (previousData?.cis_deduction?.retention ?? 0),
      pcrr:
        (currentToDateData?.cis_deduction?.pcrr ?? 0) -
        (previousData?.cis_deduction?.pcrr ?? 0),
      dlrr:
        (currentToDateData?.cis_deduction?.dlrr ?? 0) -
        (previousData?.cis_deduction?.dlrr ?? 0),
    };
    let thisData = {
      gross:
        currentToDateData.gross - (previousData.gross ? previousData.gross : 0),
      retention:
        currentToDateData.retention -
        (previousData.retention ? previousData.retention : 0),
      retention_pcrr:
        currentToDateData.retention_pcrr -
        (previousData.retention_pcrr ? previousData.retention_pcrr : 0),
      retention_dlrr:
        currentToDateData.retention_dlrr -
        (previousData.retention_dlrr ? previousData.retention_dlrr : 0),
      total: currentToDateData.total - previousData.total || 0,
      gst: currentToDateData.gst - previousData.gst || 0,
      labour_element:
        currentToDateData.labour_element -
        (previousData.labour_element ? previousData.labour_element : 0),
      cis_deduction: cis,
    };
    thisData.gst = domestic_reverse_charge ? 0 : thisData.gst;
    thisData.value =
      thisData.total + thisData.gst - (thisData?.cis_deduction?.value ?? 0);
    setThisCalculatedData(thisData);
  }, [
    currentToDateData.gross,
    previousData.gross,
    currentToDateData.retention,
    previousData.retention,
    currentToDateData.retention_pcrr,
    previousData.retention_pcrr,
    currentToDateData.retention_dlrr,
    previousData.retention_dlrr,
    currentToDateData.total,
    previousData.total,
    currentToDateData.gst,
    previousData.gst,
    labour_element,
    currentToDateData?.cis_deduction,
    previousData.cis_deduction,
    currentToDateData.labour_element,
    previousData.labour_element,
    domestic_reverse_charge,
  ]);

  return (
    <ClaimCertSummaryComponent
      readOnly={false}
      summaryType={SUMMARY_TYPE.claim}
      retentionExists={retentionExist}
      claimReleaseRetention={toClaimRetention} //Used for the release retention checkbox for the claim
      changeClaimReleaseRetentionCheckbox={(checked) =>
        debounceChange("toClaimRetention", checked)
      }
      handleDropdownChange={(value) => setPreviousOption(value)}
      handleNumberChange={(field, value) =>
        setData(field, calculateUnitToSubunit(value))
      }
      currentToDateData={currentToDateData}
      previousData={previousData}
      calculatedCisStatus={cisStatus}
      thisCalculatedData={thisCalculatedData}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  accountConfig: state.getIn(["config", "accountConfig"]),
  previous_gross: state.getIn(["makeaclaim", "previous_gross"]),
  previous_retention: state.getIn(["makeaclaim", "previous_retention"]),
  previous_retention_pcrr: state.getIn([
    "makeaclaim",
    "previous_retention_pcrr",
  ]),
  previous_retention_dlrr: state.getIn([
    "makeaclaim",
    "previous_retention_dlrr",
  ]),
  claimDlpReleaseValue: state.getIn(["makeaclaim", "claimDlpReleaseValue"]),
  claimPcdReleaseValue: state.getIn(["makeaclaim", "claimPcdReleaseValue"]),
  labour_element: state.getIn(["makeaclaim", "labour_element"]),
  previous_labour_element: state.getIn([
    "makeaclaim",
    "previous_labour_element",
  ]),
  previous_cis_deduction: state.getIn(["makeaclaim", "previous_cis_deduction"]),
  cis_deduction: state.getIn(["makeaclaim", "cis_deduction"]),
  cis_status: state.getIn(["makeaclaim", "cis_status"]),
  domestic_reverse_charge: state.getIn([
    "makeaclaim",
    "domestic_reverse_charge",
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  setData(name, val) {
    dispatch(actionCreators.setData(name, val));
  },
  setPreviousOption(previousOption) {
    dispatch(actionCreators.setPreviousOption(previousOption));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSummary);
