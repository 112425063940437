import React, { Fragment } from "react";
import { ProjectBottomWrapper } from "../styled";
import {
  RedOutlinedBtn as Discard,
  BlueOutlinedBtn as BlueButton,
  NextGreenBtn as Next,
} from "../../../common/button";
import Link from "../../../common/linkWithQuery";
import { CERT_VARIATION_ACTION_TYPES } from "../../../../src/utils/constants";
const NavBarBottom = ({
  handleSaveReview,
  handleSave,
  certData,
  previousPath,
  readOnly,
  requireRetentionValue,
}) => (
  <ProjectBottomWrapper>
    <Link to={previousPath}>
      <BlueButton
        float="left"
        title="EXIT"
        margin="0 30px 0 0"
        padding="10px 32px"
      />
    </Link>
    {!readOnly && (
      <Fragment>
        <Discard
          //todo delete draft cert
          disabled={true}
          title="DISCARD"
          float="left"
        />
        <Next
          onClick={handleSaveReview}
          title="NEXT"
          disabled={
            certData.some(
              (item) =>
                item.get("reason_status") ===
                CERT_VARIATION_ACTION_TYPES.REASON_ERROR,
            ) || requireRetentionValue
          }
        />
        <BlueButton
          title="SAVE"
          onClick={handleSave}
          margin="0 30px 0 0"
          padding="10px 32px"
        />
      </Fragment>
    )}
  </ProjectBottomWrapper>
);
export default NavBarBottom;
