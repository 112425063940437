import React, { Fragment, useState, useEffect, memo } from "react";
import { format, isValid } from "date-fns";
import { connect } from "react-redux";

import * as theme from "../../theme";
import { FormGroup, ProvisionalToggle } from "../form/styled";
import {
  AddaVariationWrapper,
  AttachLeft,
  ImgClose,
  ConvertButton,
} from "./styled";

import { DeleteBtn, AddGreenBtn, GreenWhiteBtn as SaveBtn } from "../button";
import Input from "../form";
import ToggleButtons from "./toggleButtons";
import Date from "../datePicker";
import Modal from "../modal/component/modalBase";
import { AntSwitch } from "../switch";
import BootstrapTooltip from "../toolTip";
import {
  LUMP,
  RATE,
  VARIATION_TYPES,
  LINEITEM_STATUS,
} from "../../utils/constants";
import FileUpload from "./uploadItemFile";
import {
  usePrevious,
  LineItemForm,
  ExcludeRetentionBox,
  ConvertModal,
  canEditExcludeFromRetention,
} from "./sharedComponent";
import { actionCreators as actionCreatorsFile } from "../upload_file_progress/store";
import VariationStatusButton from "pages/makeclaim/shared/variationStatusButton";

// field == dialog : add variation when making cert; will show in a dialog
// status == overview :project/contract overview page
// type === "parentItem" : add vari for parent items

const InputFields = (props) => {
  const {
    tableList, //new input list
    title,
    handleClose, //close input frame
    handleSaveItem, //create or update
    handleCleanItem, //clean input or delete item
    status,
    variationStatus,
    // expanded display for claim/cert variation
    variationStatusDisplay,
    handleUpdateVariationStatusChange,
    id,
    proId,
    contractId,
    onlyAllowRevertToPending,
    type,
    action,
    subAction,
    field,
    isBlocked,
    canDelete,
    accountConfig,
    lineType,
    setLineType,
    formInfo,
    handleChange,
    handleChangeDate,
    isProvisional,
    handleSwitch,
    isDraft,
    openConvertModal,
    // Exclude From Retention Props
    lineItemData, // line item data
    allowExcludeRetention, // main condition to whether display or not exclude retention
    tableContent, // whole table content
    isContractLevel, // is contract level

    variationLineItemType,
    isChild,
    // cert vari line item files
    cancelAttachment,
    setFileList,
    deleteAttachment,
    dateWidth,
    referenceWidth,
    detailedDescriptionWidth,
    componentClass,
    isLinked,
    isEmptyMulti,
  } = props;

  const { CLAIM_VARI, CONTRACT_VARI, CERT_VARI } = LINEITEM_STATUS;
  const componentDateWidth =
    status === "overview" ? "350px" : dateWidth || "250px";
  const componentVarReferenceWidth =
    status === "overview" ? "387px" : referenceWidth || "287px";
  const componentDetailedDescriptionWidth =
    status === "overview" ? "600px" : detailedDescriptionWidth || "387px";

  // - if the item is not linked (`!isLinked`)
  // - And if `handleUpdateVariationStatusChange` is provided
  // - Then call `handleUpdateVariationStatusChange` with the params
  // - If not, `setStatusHandler` is undefined
  const setStatusHandler =
    !isLinked && handleUpdateVariationStatusChange
      ? (status, reason) =>
          handleUpdateVariationStatusChange(
            proId,
            contractId,
            id,
            status,
            reason,
          )
      : undefined;

  const ExcludeRetention = () => {
    const [shouldDisplayCheckbox, shouldDisableCheckbox] =
      canEditExcludeFromRetention(
        lineItemData ?? undefined,
        allowExcludeRetention,
        tableContent,
        isContractLevel,
      );
    if (!shouldDisplayCheckbox) {
      return <></>;
    }
    return (
      <ExcludeRetentionBox
        checked={formInfo?.exclude_retention}
        onChange={(e) => handleChange("exclude_retention", e.target.checked)}
        disabled={shouldDisableCheckbox}
      />
    );
  };

  const ShowProvisionalToggle = memo(() => {
    return (
      <ProvisionalToggle>
        <AntSwitch
          checked={isProvisional}
          onChange={handleSwitch}
          name="provisional-toggle"
        />
        <div>Provisional</div>
        <BootstrapTooltip
          title="Enabling this setting will allow the line item to be editable when the contract has become active."
          placement="bottom"
        >
          <div className="info-icon" />
        </BootstrapTooltip>
      </ProvisionalToggle>
    );
  });

  const ShowConvertProvisionalToFixed = memo(() => {
    return (
      tableList?.is_provisional && (
        <ConvertButton onClick={() => openConvertModal()}>
          <div className="convert-icon" />
          Convert to Fixed
        </ConvertButton>
      )
    );
  });

  const ShowLumpSumRateToggle = memo(() => {
    return (
      <ToggleButtons
        buttonWidth="137px"
        buttons={[
          { label: "Lump Sum", value: LUMP },
          { label: "Qty & Rate", value: RATE },
        ]}
        setValue={(type) => setLineType(type)}
        defaultValue={lineType}
        isBlocked={isBlocked}
      />
    );
  });

  return (
    <AddaVariationWrapper
      style={
        type === "parentItem" && field !== "dialog"
          ? {
              border: `1px solid ${theme.palette.tangerineOrange}`,
              margin: " 0px auto",
            }
          : field === "dialog"
            ? { marginTop: "0px", width: "1060px" }
            : { marginTop: "5px" }
      }
    >
      <FormGroup>
        {field !== "dialog" ? (
          <div className="form_title">
            {title}
            <div className="buttons">
              {action === "add" &&
                variationLineItemType !== VARIATION_TYPES.MULTI && (
                  <Fragment>
                    {/*
                    - Show provisional toggle when adding in new line items.
                    - regardless of draft or not, claim or cert.
                  */}
                    <ShowProvisionalToggle />
                    <ShowLumpSumRateToggle />
                  </Fragment>
                )}
              {action === "save" &&
                variationLineItemType !== VARIATION_TYPES.MULTI && (
                  <Fragment>
                    {/*
                    - Show provisional toggle when editing only if contract is in draft.
                    - else show convert to fixed button.
                    - the condition in the button is to show the button only if the line item is provisional.
                    - so if its not provisional, the button will not show.
                  */}
                    {isDraft ? (
                      <ShowProvisionalToggle />
                    ) : (
                      <ShowConvertProvisionalToFixed />
                    )}
                    <ShowLumpSumRateToggle />
                  </Fragment>
                )}
              {variationStatusDisplay && <>{variationStatusDisplay}</>}
              {variationStatus && !isEmptyMulti && (
                <div className="variation-placeholder">
                  <VariationStatusButton
                    expandedButtonByDefault
                    status={variationStatus}
                    setStatus={setStatusHandler}
                    onlyRevertToPending={onlyAllowRevertToPending}
                  />
                </div>
              )}
              <ImgClose onClick={handleClose} />
            </div>
          </div>
        ) : (
          <div className="buttons-dialog">
            {type !== "parentItem" &&
              variationLineItemType !== VARIATION_TYPES.MULTI && (
                <>
                  <ShowLumpSumRateToggle />

                  {!tableList.childitems?.length > 0 && (
                    <ShowProvisionalToggle />
                  )}
                </>
              )}
          </div>
        )}
        <LineItemForm
          onChange={handleChange}
          form={formInfo}
          status={status}
          isBlocked={isBlocked}
          lineitemType={lineType}
          accountConfig={accountConfig}
          isParent={tableList?.childitems?.length > 0}
          field={field}
          variationLineItemType={variationLineItemType}
          componentClass={componentClass}
        />

        {isChild ? (
          <div className="form_body" style={{ margin: "20px auto" }} />
        ) : (
          <div className="form_body" style={{ margin: "40px auto" }}>
            <Input label="Submit Date" field="child">
              <Date
                handleChange={(e) => handleChangeDate("submit_date", e)}
                initialValue={formInfo?.submit_date}
                width={componentDateWidth}
              />
            </Input>
            <Input label="Approved Date" field="child">
              <Date
                handleChange={(e) => handleChangeDate("approved_date", e)}
                initialValue={formInfo?.approved_date}
                width={componentDateWidth}
              />
            </Input>
            <Input
              label="Variation Reference"
              name="variation_reference"
              value={formInfo?.variation_reference}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              width={componentVarReferenceWidth}
            />
          </div>
        )}

        <div className="form_body cert_vari">
          <div className={`large_container ${componentClass || ""}`}>
            <Input field="child">
              <AttachLeft className={componentClass || ""}>
                <div className="title">Variation Attachments</div>
                <div className="uploadFileWrapper">
                  {field !== "dialog" ? (
                    <FileUpload {...props} />
                  ) : (
                    <FileUpload
                      title={title}
                      files={props.files}
                      setFileList={setFileList}
                      handleFilesChange={(files) => {
                        title.includes("Edit")
                          ? props.uploadAttachments(files, tableList.id)
                          : props.handleFilesChange(files);
                      }}
                      handleFileCancel={(file) => {
                        title.includes("Edit")
                          ? deleteAttachment(file, tableList.id)
                          : cancelAttachment(file);
                      }}
                    />
                  )}
                </div>
              </AttachLeft>
            </Input>
            <Input
              label="Detailed Description"
              name="detailed_description"
              className={componentClass || ""}
              value={formInfo?.detailed_description}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              width={componentDetailedDescriptionWidth}
              field="textarea"
              height="180px"
            />
          </div>
          {field === "dialog" && <ExcludeRetention />}
        </div>
        {field !== "dialog" && (
          <div className="form_footer">
            <ExcludeRetention />
            {/* avoid to delete parent / certified items */}
            {canDelete && (
              <DeleteBtn
                title="Delete"
                onClick={handleCleanItem}
                margin="0 30px 0 0"
              />
            )}
            {action === "add" && (
              <>
                {[CLAIM_VARI, CONTRACT_VARI, CERT_VARI].includes(subAction) &&
                variationLineItemType === VARIATION_TYPES.MULTI ? (
                  <SaveBtn
                    title="Continue to Add Items"
                    type="submit"
                    onClick={() => handleSaveItem(lineType, formInfo)}
                  />
                ) : (
                  <AddGreenBtn
                    title="Add"
                    type="submit"
                    onClick={() => handleSaveItem(lineType, formInfo)}
                  />
                )}
              </>
            )}
            {action === "save" && (
              <SaveBtn
                title="Save"
                type="submit"
                onClick={() => handleSaveItem(lineType, formInfo)}
              />
            )}
          </div>
        )}
      </FormGroup>
    </AddaVariationWrapper>
  );
};

const InputTable = (props) => {
  const {
    tableList, //new input list
    title,
    handleClose, //close input frame
    handleSaveItem, //create or update
    handleCleanItem, //clean input or delete item
    action,
    field,
    previousLineType, // line type of latest added line item
    setEditFiles,
    variationLineItemType,
    // modal related
    modalOpen,
    IgnoreQtyAsNumber,
  } = props;
  const [lineType, setLineType] = useState(RATE);
  const prevLineType = usePrevious(lineType); // previous value of lineType

  const [formInfo, setFormInfo] = useState({
    label: "",
    description: "",
    quantity: 0,
    unit: "",
    rate: 0,
    detailed_description: "",
    variation_reference: "",
    submit_date: "",
    approved_date: "",
    lineitem_options: RATE,
    total: 0,
    is_provisional: false,
    exclude_retention: false,
    extra_info: {
      variation_type: variationLineItemType,
      reject_reason: "",
    },
  });

  const numValues = ["quantity", "rate", "total"];
  const [convertModalOpen, setConvertModalOpen] = useState(false);

  useEffect(() => {
    if (action === "add") {
      // copy previous added lineitem option/type (if there's one)
      setLineType(previousLineType ? previousLineType : RATE);
      setEditFiles();
    } else {
      // initialise values
      let newInfo = {};
      for (let [key, value] of Object.entries(tableList)) {
        if (key === "lineitem_options") {
          setLineType(value);
        } else if (numValues.includes(key)) {
          // for display
          newInfo[key] =
            key === "quantity" && IgnoreQtyAsNumber ? value : value / 100;
        } else {
          newInfo[key] = value;
        }
      }
      setFormInfo(newInfo);
    }
  }, [tableList, action]); // eslint-disable-line

  useEffect(() => {
    // only works for lineType changes made by user and not from initialisation
    if (prevLineType && prevLineType !== lineType) {
      let recalculate = { ...formInfo };
      if (lineType === RATE) {
        recalculate.quantity = 1;
      } else if (lineType === LUMP) {
        recalculate.quantity = 1;
        recalculate.rate = formInfo.quantity * formInfo.rate;
      }

      setFormInfo({ ...recalculate });
    }
  }, [lineType]); // eslint-disable-line

  const handleChange = (name, value) => {
    setFormInfo({ ...formInfo, [name]: value });
  };

  const handleChangeDate = (name, e) => {
    if (e && isValid(e)) {
      let date = format(e, "yyyy-MM-dd");
      setFormInfo({ ...formInfo, [name]: date });
    }
  };

  if (field === "dialog")
    return (
      <Modal
        handleClose={() => {
          handleClose();
          // clean up redux file list when closing
          props.initialFileList();
        }}
        open={modalOpen}
        title={title}
        width="1060px"
      >
        <InputFields
          {...props}
          lineType={lineType}
          setLineType={setLineType}
          formInfo={formInfo}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          isProvisional={formInfo.is_provisional}
          handleSwitch={() =>
            handleChange("is_provisional", !formInfo.is_provisional)
          }
        />
        <div>
          {(action === "adding_item" || action === "adding_child_item") && (
            <AddGreenBtn
              title="Add"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          {action === "editing_item" && (
            <AddGreenBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          {action === "save" && (
            <SaveBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(lineType, formInfo)}
            />
          )}
          <DeleteBtn
            title="Delete"
            onClick={handleCleanItem}
            margin="0 30px 0 0"
          />
        </div>
      </Modal>
    );

  return (
    <Fragment>
      <InputFields
        {...props}
        lineType={lineType}
        setLineType={setLineType}
        formInfo={formInfo}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        isProvisional={formInfo?.is_provisional}
        handleSwitch={() =>
          handleChange("is_provisional", !formInfo?.is_provisional)
        }
        openConvertModal={() => setConvertModalOpen(true)}
      />
      <ConvertModal
        open={convertModalOpen}
        setOpen={setConvertModalOpen}
        data={tableList}
        convert={() => {
          handleChange("is_provisional", false);
          handleSaveItem(lineType, { ...formInfo, is_provisional: false });
        }}
      />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFileList(files) {
    dispatch(actionCreatorsFile.setUploadFile(files));
  },
});

export default connect(null, mapDispatchToProps)(InputTable);
