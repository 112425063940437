import styled from "styled-components";
import { IconButton } from "../button";
import { BlueSecondaryButton } from "../button/style";
import * as theme from "../../theme";
import clearFileIcon from "../../statics/img/icon-clear-medium-grey.svg";
import uploadCloudIcon from "../../statics/img/icon-upload-cloud-light-grey.svg";
import fileItemIcon from "../../statics/img/icon-tick-green.svg";
import attachIcon from "../../statics/img/icon-file-pin-light-grey.svg";
import convertIcon from "../../statics/img/icon-switch-access.svg";
import noticeIcon from "../../statics/img/icon-information-transparent.svg";
import templateImg from "../../statics/img/lineitem-template.png";
import checkIcon from "../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../statics/img/icon-check-box-un-check-dark-grey.svg";

export const Title = styled.div`
  line-height: 40px;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
`;

export const UploadCSV = styled.div`
  padding: 40px;
  .actions {
    font-weight: bold;
    color: ${theme.palette.turquoiseBlue};
    text-decoration-line: underline;
    margin-top: 16px;
    cursor: pointer;
  }
  .instruction {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    min-width: 960px;
    margin: 0 0 20px 0;
  }
  .description {
    line-height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    .item {
      &.table {
        margin: 32px 0;
      }
      &.add-on {
        margin: 16px 0;
      }
      .link {
        color: ${theme.palette.turquoiseBlue};
        cursor: pointer;
      }
      .browser {
        font-weight: ${theme.font.mainFontWeight};
        color: ${theme.palette.gunmetal};
      }
    }
    table {
      width: 100%;
      thead {
        height: 44px;
        background-color: ${theme.palette.gunmetal};
        color: white;
        text-align: left;
      }
      th {
        height: 44px;
        text-align: center;
      }
    }
    .note {
      padding: 20px 24px;
      background-color: ${theme.palette.lightPastelBlue};
      border-radius: 4px;
      color: ${theme.palette.tealBlue};
      .content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 40px;
      }
      table {
        color: ${theme.palette.gunmetal};
        width: 300px;
        table-layout: fixed;
        border-collapse: collapse;
        td {
          height: 44px;
          border: 1px solid ${theme.palette.gunmetal};
          padding: 4px 10px;
          &.ref {
            width: 60px;
          }
        }
      }
    }
    .template-img {
      width: 380px;
      height: 220px;
      background: url(${templateImg}) no-repeat;
      background-size: cover;
      margin-left: 40px;
    }
  }
`;

export const ImgClose = styled(IconButton)`
  background: url(${clearFileIcon}) no-repeat;
  margin-left: 24px;
`;

export const AddaVariationWrapper = styled.div`
  margin-top: 5px;
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${theme.palette.veryColdGrey};
  position: relative;
  text-align: left;
  .title_comment {
    font-size: 20px;
    font-weight: ${theme.font.mainFontWeight};
  }

  .buttons-dialog {
    display: flex;
    flex-direction: row-reverse;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 30px;
  }
`;

export const FileFrame = styled.div`
  border: 1px solid rgb(198, 204, 208);
  .dropzone {
    padding: 12px 16px;
    .Highlight {
      background-color: ${theme.palette.veryMediumGrey};
    }
  }
  .dragFrame {
    border-radius: 4px;
    border: 2px dashed rgb(198, 204, 208);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    padding: 25px 0;
  }
  .uploadText {
    display: flex;
    justify-content: center;
  }
  .uploadCloudImg {
    height: 30px;
    width: 30px;
    background: url(${uploadCloudIcon}) no-repeat;
    background-size: contain;
    display: block;
    margin-right: 16px;
  }

  .drag {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.wolfGrey};
  }
  .upload {
    margin: 0 8px;
    text-align: center;
    color: ${theme.palette.wolfGrey};
  }
  .browser {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.gunmetal};
  }
  .uploadfiles {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }
  .filelist {
    height: 44px;
    line-height: 24px;
    font-size: 14px;
    padding: 10px 0;
    &.csv {
      padding: 10px 16px;
    }
    &.files {
      height: auto;
      border-bottom: 1px solid ${theme.palette.lightWolfGrey};
    }
    .uploadIcon {
      height: 24px;
      width: 24px;
      background: url(${fileItemIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: left;
      margin-right: 10px;
    }
    .attachIcon {
      height: 24px;
      width: 24px;
      background: url(${attachIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: left;
      margin-right: 10px;
    }
    .name {
      font-weight: ${theme.font.mainFontWeight};
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.palette.gunmetal};
      &.small {
        max-width: 200px;
      }
    }
    .size {
      float: left;
      margin-left: 8px;
      color: ${theme.palette.veryMediumGrey};
    }
    .action {
      height: 24px;
      width: 24px;
      background: url(${clearFileIcon}) no-repeat;
      background-size: contain;
      display: block;
      float: right;
      border: none;
      cursor: pointer;
    }
    .status {
      float: right;
      margin-right: 12px;
      color: ${theme.palette.veryMediumGrey};
    }
  }
`;
export const AttachLeft = styled.div`
  .title {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 8px;
  }
  .uploadFileWrapper {
    height: auto;
    width: 480px;
    background-color: ${theme.palette.white};
  }

  &.claim-vari {
    .uploadFileWrapper {
      width: 620px;
    }
  }

  //todo adjust when doing width ticket
  &.cert-vari {
    .uploadFileWrapper {
      width: 620px;
    }
  }
`;

export const InputTableWrapper = styled.div`
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.veryColdGrey};
  position: relative;
`;

export const TableStyle = styled.div`
  .img_icon {
    height: 20px;
    width: 20px;
    background: url(${noticeIcon});
    float: left;
    margin-top: -2px;
  }
  .inputWrapper {
    padding-top: 10px;
    border: 1px solid ${theme.palette.tangerineOrange};
    border-top: none;
    margin-bottom: 20px;
    margin-left: -1px;
  }
  // chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .inputWrapper {
      margin-right: -1px;
      margin-left: 0px;
    }
  }
  //firefox
  @-moz-document url-prefix() {
    .inputWrapper {
      margin-right: 0px;
      margin-left: -1px;
    }
  }
  thead {
    th {
      text-align: left !important;
    }
  }
  .so-table {
    thead {
      .item-not-show,
      .file-not-show {
        height: 0px;
      }
      .otherEmptyTr {
        height: 300px;
        width: 100%;
        background-color: ${theme.palette.white};
      }
    }
    .item-not-show {
      border-right: none;
      padding: 0 0 0 15px;
      text-align: left;
    }
    .file-not-show {
      border-left: none;
      padding: 0;
    }
    .space {
      width: 16px;
      display: inline-block;
    }
    tr {
      cursor: pointer;
    }
  }
  .actionHeader {
    padding: 0 16px;
  }

  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }

  .table-foot {
    .summary {
      background-color: ${theme.palette.tealBlue};
    }
    .notice {
      color: ${theme.palette.gunmetal};
      font-size: 14px;
      font-weight: 400;
    }
  }

  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }

  .grey-text {
    color: ${theme.palette.veryMediumGrey};
  }
`;

export const TableFooter = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .totalDiv {
    margin-top: 40px;
    margin-left: auto;
    .blueBox {
      height: 72px;
      width: 560px;
      background-color: ${theme.palette.tealBlue};
      padding: 9px 16px;
      box-sizing: border-box;
      color: ${theme.palette.white};
      font-weight: ${theme.font.mainFontWeight};
    }
    .titleLeft {
      font-size: 16px;
      line-height: 19px;
    }
    .titleRight {
      font-size: 22px;
      float: right;
      line-height: 25px;
    }
  }
  .notice {
    margin-top: 20px;
    font-size: 14px;
    margin-left: auto;
  }
`;

export const ToggleButtonStyle = styled.div`
  height: ${(props) => props.height || "40px"};
  display: flex;
  font-size: 16px;

  .button {
    height: 100%;
    width: ${(props) => props.buttonWidth || "50%"};
    padding: 8px 24px;
    background-color: ${theme.palette.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px ${theme.palette.gunmetal};
    cursor: pointer;

    &.left {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    &.right {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }

    &.active {
      background-color: ${theme.palette.gunmetal};
      color: ${theme.palette.white};
    }

    &.disabled {
      cursor: none;
      pointer-events: none
      background-color: ${theme.palette.white};
      color: ${theme.palette.lightGrey};
      border: solid 1px ${theme.palette.lightGrey};
    }

    &.disabled-active {
      background-color: ${theme.palette.lightGrey} !important;
      color: ${theme.palette.white} !important;
    }
  }
`;

export const LineItemChip = styled.span`
  padding: 4px 10px;
  border-radius: 4px;
  display: inline-flex;
  width: max-content;
  line-height: 15px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  background-color: ${theme.palette.paleYellow};

  &.edit {
    border: solid 1px ${theme.palette.gunmetal};
  }
`;

export const ConvertButton = styled(BlueSecondaryButton)`
  margin-right: 24px;
  min-width: max-content;
  display: flex;
  padding: 8px 24px;
  align-items: center;

  .convert-icon {
    width: 24px;
    height: 24px;
    display: inline;
    margin-right: 8px;
    background: url(${convertIcon}) no-repeat;
  }
`;

export const ConvertModalButtons = styled.div`
  .margin-left {
    margin-left: 12px;
  }
`;

export const ConvertModalContent = styled.div`
  padding: 20px 40px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;

  .blue-banner {
    margin-bottom: 32px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: ${theme.palette.lightPastelBlue};
    min-width: 560px;
    display: grid;
    grid-row-gap: 8px;

    div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &.top {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
`;

export const CellSubtitle = styled.div`
  color: ${theme.palette.veryMediumGrey};
`;

export const CheckBoxWrap = styled.div`
&.has-margin{
  margin-top:20px;
}
  margin-right: auto;
  width: 234px;
  height: 40px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  color: ${(props) =>
    props.disabled ? theme.palette.veryMediumGrey : theme.palette.gunmetal};
  input[type="checkbox"] {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    mask: url(${uncheckIcon}) no-repeat;
    background-color: ${(props) =>
      props.disabled ? theme.palette.veryMediumGrey : theme.palette.gunmetal}
    background-size: contain;
    cursor: pointer;
    :checked {
      mask: url(${checkIcon}) no-repeat;
      background-color: ${(props) =>
        props.disabled ? theme.palette.veryMediumGrey : theme.palette.gunmetal}
    }
  }

  .info-icon {
    width: 24px;
    height: 24px;
    mask: url(${noticeIcon}) no-repeat;
    background-color: ${(props) =>
      props.disabled ? theme.palette.veryMediumGrey : theme.palette.gunmetal}
  }
`;
