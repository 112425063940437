import { fromJS } from "immutable";
import { constants } from ".";
import {
  findTreeNode,
  checkIsParent,
  getNestedLineitem,
  updateDescendantLineitem,
} from "../../../utils/helper";
import { formatInTimeZone } from "../../../utils/dateTimeHelper";
import { calculateTotalAndGst } from "pages/makeclaim/utils/calculationHelper";
import {
  VARIATION_LINEITEM_STATUS,
  CERT_VARIATION_ACTION_TYPES,
} from "utils/constants";
import { calculateSubunitToUnit } from "../../../utils/currencyHelper";
import { getUpdatedVariationLineItems } from "common/lineitem/sharedComponent";
function formatDate(date) {
  if (date) {
    return date.split("T")[0];
  } else {
    return "";
  }
}

const defaultState = fromJS({
  loading: true,
  projectlist: {},
  certificate_number: "",
  componentKey: 0,

  baseContractTable: [],
  baseCertTotal: 0,
  baseCertTotalExcludeRetention: 0,
  baseCertList: [],
  baseThisCertTotal: 0,
  baseTotalValues: {},
  base_cert_method: "value",

  variationsTable: [],
  variationCertList: [],
  variationCertTotal: 0,
  variationCertTotalExcludeRetention: 0,
  variationThisCertTotal: 0,
  variationsTotalValues: {},
  variation_cert_method: "value",

  materialsTable: [],
  materialsCertList: [],
  materialsTotal: { onSite: 0, offSite: 0, onSiteClaim: 0, offSiteClaim: 0 },
  materialsTotalExcludeRetention: {
    onSite: 0,
    offSite: 0,
    onSiteClaim: 0,
    offSiteClaim: 0,
  },

  dlpReleaseValue: 0,
  pcdReleaseValue: 0,
  claimRetentionValue: {
    dlp: 0,
    pcd: 0,
  },
  certRetentionValue: {
    preNetTotal: 0,
    maxDlp: 0,
    maxPcd: 0,
  },
  retentionOption: null,

  summaryComponent: {
    previous: {},
    claimedToDate: {},
    thisCert: {},
    certToDate: {},
  },
  // previous cert ref title
  previousRef: {
    certified: "",
  },
  // original details of previously certified (from history)
  previousCertified: null,

  paidValue: 0,
  lessRetention: 0,
  previousType: "cert_to_date",
  claimDetail: {},

  emailToList: [],
  emailSubject: "",
  isSendEmail: true,
  sendingEmail: false,
  approvingCert: false,

  approvalList: [],
  leaveComment: false,
  approval_comment: "",
  isAuditAttached: false,

  cert_attachments: [],
  shared_cert_attachments: [],
  attachedEmailFiles: [],
  exceedAttachLimit: false,

  reasonOption: [],

  projectInfo: [],
  certId: "",
  contractConfig: {},

  uploadFiles: [],
  editFileList: [],
  contractLinked: false,
  certifyPendingVariation: false,
  cis_status: "",
});

const addChildToCertList = (item, claimList) => {
  let isParent = checkIsParent(item);
  let item_qty = parseFloat(item?.quantity);
  let data = {
    id: item.id,
    payment_percent: item.cert_percent || 0,
    payment_value: item.cert_value || 0,
    payment_comment: item.cert_reason || "",
    parent_id: item.parent_id,
    total: item.total,
    claim_total: item.claim_value,
    has_child: isParent,
    required_reason:
      item.claim_value !== item.cert_value &&
      item.cert_reason === "" &&
      !isParent,
    claim_qty: item?.claim_qty || 0,
    item_description: item.description,
    item_rate: parseFloat(item.rate),
    total_item_quantity: item_qty,
    total_item_unit: parseFloat(item.unit),
    action_field: item?.cert_option || "percent",
    cert_option: item?.cert_option || "percent",
    exclude_retention: item?.exclude_retention,
    cert_qty: item?.cert_qty === null ? item?.claim_qty : item?.cert_qty || 0,
    current_status: item?.current_status || "",
  };
  data.reason_status =
    getReasonStatus(data) || CERT_VARIATION_ACTION_TYPES.REASON_EMPTY;
  claimList.push(data);
  if (!isParent) {
    return claimList;
  } else {
    item.childitems.map((child) => {
      return (claimList = addChildToCertList(child, claimList));
    });
    return claimList;
  }
};

const calculateCertTotal = (data) => {
  let certList = [],
    certTotal = 0,
    certTotalExcludeRetention = 0,
    thisCertTotal = 0,
    claim_to_date_total = 0,
    cert_to_date_total = 0,
    last_cert_total = 0,
    current_claim_total = 0;
  if (data) {
    data.forEach((item) => {
      certTotal += item.cert_value;
      if (!item?.exclude_retention) {
        certTotalExcludeRetention += item.cert_value;
      }
      thisCertTotal += item.cert_value - item.cert_to_date_value;
      claim_to_date_total += item.claimed_to_date_value;
      cert_to_date_total += item.cert_to_date_value;
      last_cert_total += item.last_cert_value;
      current_claim_total += item.current_claim;
      certList = addChildToCertList(item, certList);
    });
  }
  return [
    certList,
    certTotal,
    certTotalExcludeRetention,
    thisCertTotal,
    {
      claim_to_date_total,
      cert_to_date_total,
      last_cert_total,
      current_claim_total,
    },
  ];
};

const setInitialCertInfo = (state, action) => {
  //cert id and claim id exist
  const data = action.certInfo;
  let [
    baseCertList,
    baseCertTotal,
    baseCertTotalExcludeRetention,
    baseThisCertTotal,
    baseTotalValues,
  ] = calculateCertTotal(data.baseitems);
  let [
    variationCertList,
    variationCertTotal,
    variationCertTotalExcludeRetention,
    variationThisCertTotal,
    variationsTotalValues,
  ] = calculateCertTotal(data.variation_items);

  // update variation items with new child line Item if Multi-Select is used
  data.variation_items = getUpdatedVariationLineItems(data.variation_items);

  let claim_detail = {
    claim_ref: data.payclaim_name,
    claim_from: formatInTimeZone(
      data?.claim_from,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_to: formatInTimeZone(
      data?.claim_to,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_due_date: formatInTimeZone(
      data?.claim_last,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    cert_due_date: formatInTimeZone(
      data?.claim_cert,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    pay_due_date: formatInTimeZone(
      data?.claim_due,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_internal_notes: data.claim_internal_notes,
    claim_external_notes: data.claim_external_notes,
    has_retention: data.has_retention,
    read_retention: {
      pcd_date: formatDate(data.pcd_date),
      dlp_date: formatDate(data.dlp_date),
    },
    claimer_name: data.payee_entity_name,
  };
  let contacts = data.email_info;
  let attachedEmailFiles = data.email_info.attachments || [];
  attachedEmailFiles.map((file) => {
    if (!file.attach_method) {
      file.attach_method = data.attach_method;
    }
    return file;
  });

  const [materialsCertList, materialsTotal, materialsTotalExcludeRetention] =
    setMaterialCertListWithTotal(data.materials, true);

  let toList = contacts.to_contacts || [];

  let summary = {
    previous: data?.previous || {},
    claimedToDate: data?.claim_to_date_summary || {},
    thisCert: data?.this || {},
    certToDate: data?.cert_to_date_summary || {},
  };

  return state.merge(
    fromJS({
      //project detailed info
      projectlist: fromJS(data),
      certificate_number: data.cert_certificate_ref,

      baseTotalValues: baseTotalValues,
      variationsTotalValues: variationsTotalValues,

      baseContractTable: data.baseitems || [],
      baseCertList: baseCertList,
      baseCertTotal: baseCertTotal / 100,
      baseCertTotalExcludeRetention: baseCertTotalExcludeRetention / 100,
      baseThisCertTotal: baseThisCertTotal / 100,

      variationsTable: data.variation_items || [],
      variationCertList: variationCertList,
      variationCertTotal: variationCertTotal / 100,
      variationCertTotalExcludeRetention:
        variationCertTotalExcludeRetention / 100,
      variationThisCertTotal: variationThisCertTotal / 100,

      materialsTable: data.materials || [],
      materialsCertList: materialsCertList || [],
      materialsTotal: materialsTotal,
      materialsTotalExcludeRetention: materialsTotalExcludeRetention,

      paidValue: data.less_previous_paid_to_date / 100 || "",
      lessRetention: data.less_retention / 100 || "",
      dlpReleaseValue: data.dlp_release_value / 100,
      pcdReleaseValue: data.pcd_release_value / 100,
      claimDetail: claim_detail,
      loading: false,
      emailToList: toList,
      previousType: data.previous_paid_to_date,
      isSendEmail: data.has_email_service || toList.length > 0,
      cert_attachments: data.attachments || [],
      shared_cert_attachments: fromJS(data.shared_attachments || []),
      isAuditAttached: data.attach_audit_trail,
      attachedEmailFiles: fromJS(attachedEmailFiles),
      retentionOption: data.retention_option,
      cis_status: data?.cis_status,
      domestic_reverse_charge: data?.domestic_reverse_charge,
      summaryComponent: summary,
    }),
  );
};

const addChildToClaimReviewList = (item, claimList) => {
  let isParent = checkIsParent(item);

  let item_qty = parseFloat(item?.quantity);
  let list = {
    id: item.id,
    payment_percent: item.claim_percent,
    payment_value: item.claim_value,
    total: item.total,
    parent_id: item.parent_id,
    payment_comment: "",
    required_reason: false,
    has_child: isParent,
    claim_total: item.claim_value,
    claim_qty: item?.claim_qty || 0,
    item_description: item.description,
    exclude_retention: item?.exclude_retention,
    item_rate: parseFloat(item.rate),
    total_item_quantity: item_qty,
    total_item_unit: parseFloat(item.unit),
    action_field: item?.cert_option || "percent",
    cert_option: item?.cert_option || "percent",
    cert_qty: item?.cert_qty === null ? item?.claim_qty : item?.cert_qty || 0,
  };
  claimList.push(list);
  if (!isParent) {
    return claimList;
  } else {
    item.childitems.map((child) => {
      return (claimList = addChildToClaimReviewList(child, claimList));
    });
    return claimList;
  }
};

const calculateTotal = (data) => {
  let certList = [],
    claim_to_date_total = 0,
    cert_to_date_total = 0,
    last_cert_total = 0,
    current_claim_total = 0;
  if (data) {
    data.forEach((item) => {
      claim_to_date_total += item.claimed_to_date_value;
      cert_to_date_total += item.cert_to_date_value;
      last_cert_total += item.last_cert_value;
      current_claim_total += item.current_claim;
      certList = addChildToClaimReviewList(item, certList);
    });
  }

  certList.forEach((item) => {
    item.has_children = certList.some(
      (otherItem) => otherItem?.parent_id === item?.id,
    );
  });

  return [
    certList,
    {
      claim_to_date_total,
      cert_to_date_total,
      last_cert_total,
      current_claim_total,
    },
  ];
};

const setClaimInfo = (state, action) => {
  //cert-id not exist & claim-id exist
  const data = action.projectinfo;
  let [baseCertList, baseTotalValues] = calculateTotal(data.baseitems);
  let [variationCertList, variationsTotalValues] = calculateTotal(
    data.variation_items,
  );

  const [materialsCertList, materialsTotal, materialsTotalExcludeRetention] =
    setMaterialCertListWithTotal(data.materials);

  let claim_detail = {
    claim_ref: data.payclaim_name,
    claim_from: formatInTimeZone(
      data?.claim_from,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_to: formatInTimeZone(
      data?.claim_to,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_due_date: formatInTimeZone(
      data?.claim_last,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    cert_due_date: formatInTimeZone(
      data?.claim_cert,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    pay_due_date: formatInTimeZone(
      data?.claim_due,
      "yyyy-MM-dd",
      data?.contract_timezone,
    ),
    claim_external_notes: data.claim_external_notes,
    has_retention: data.has_retention,
    read_retention: {
      pcd_date: formatDate(data.pcd_date),
      dlp_date: formatDate(data.dlp_date),
    },
    claimer_name: data.payee_entity_name,
  };

  return state.merge(
    fromJS({
      //project detailed info
      certificate_number: "",
      projectlist: data,

      baseContractTable: data.baseitems || [],
      baseCertTotal: data.total_claim_base_contract / 100,
      baseCertTotalExcludeRetention:
        data.total_claim_base_contract_exclude_retention / 100,
      baseCertList: baseCertList,

      baseTotalValues: baseTotalValues,
      variationsTotalValues: variationsTotalValues,

      variationsTable: data.variation_items || [],
      variationCertList: variationCertList,
      variationCertTotal: data.total_claim_variation / 100,
      variationCertTotalExcludeRetention:
        data.total_claim_variation_exclude_retention / 100,

      materialsTable: data.materials || [],
      materialsCertList: materialsCertList || [],
      materialsTotal: materialsTotal,
      materialsTotalExcludeRetention: materialsTotalExcludeRetention,

      paidValue: data.claim_less_paid_to_date / 100 || "",
      claimDetail: claim_detail,
      loading: false,
      previousType: data.previous_paid_to_date,
      shared_cert_attachments: fromJS(data.shared_attachments || []),
      dlpReleaseValue: data.dlp_release_value / 100 || 0,
      pcdReleaseValue: data.pcd_release_value / 100 || 0,
      labour_element: data.cert_to_date_summary?.labour_element,
      previous_labour_element: data.previous?.labour_element,
      previous_cis_deduction: data.previous?.cis_deduction,
      cis_deduction: data?.cert_to_date_summary?.cis_deduction,
      cis_status: data?.cis_status,
    }),
  );
};

function findParent(itemList, table, parent_id) {
  if (parent_id === "") {
    return itemList;
  } else {
    let parent_obj = findTreeNode(parent_id, table.toJS());
    let parent_total = 0;
    parent_obj.childitems.map((item) => {
      var obj = itemList.find((ele) => ele.id === item.id);
      parent_total += obj.payment_value;
      return parent_total;
    });
    var parent_obj_cert = itemList.find((ele) => ele.id === parent_obj.id);
    parent_obj_cert.payment_percent =
      parent_obj_cert.total !== 0
        ? (parent_total / parent_obj_cert.total) * 100
        : 0;
    parent_obj_cert.payment_value = parent_total;
    //since parent qty always be 1. so cert_qty = payment_percent
    parent_obj_cert.cert_qty = parent_obj_cert.payment_percent / 100;
    parent_obj_cert.required_reason = checkIfRequired(parent_obj_cert);
    parent_obj_cert.reason_status = getReasonStatus(parent_obj_cert);
    itemList = findParent(itemList, table, parent_obj_cert.parent_id);
    return itemList;
  }
}

function checkIfRequired(item) {
  return (
    item?.payment_comment === "" &&
    item?.claim_total !== item?.payment_value &&
    !item.has_child
  );
}

const getReasonStatus = (item) => {
  const { payment_comment, current_status, claim_total, payment_value } = item;

  if (payment_comment === "") {
    switch (current_status) {
      // if pending and has a claim, only show error on root level
      case VARIATION_LINEITEM_STATUS.PENDING:
        return claim_total > 0 &&
          !item.parent_id &&
          claim_total !== payment_value
          ? CERT_VARIATION_ACTION_TYPES.REASON_ERROR
          : CERT_VARIATION_ACTION_TYPES.REASON_EMPTY;
      // if approved
      case VARIATION_LINEITEM_STATUS.APPROVED:
        // if there’s variance for child line item, reason for variance is only mandatory for that child line item and not mandatory for the parent.
        // so only to show error on base vari and child line items instead of parent line item or root level
        return claim_total !== payment_value && !item.has_child
          ? CERT_VARIATION_ACTION_TYPES.REASON_ERROR
          : CERT_VARIATION_ACTION_TYPES.REASON_EMPTY;
      // if rejected
      case VARIATION_LINEITEM_STATUS.REJECTED:
        return claim_total > 0
          ? CERT_VARIATION_ACTION_TYPES.REASON
          : CERT_VARIATION_ACTION_TYPES.REASON_EMPTY;
    }
  }
  return CERT_VARIATION_ACTION_TYPES.REASON;
};

const setCertList = (state, action) => {
  //action.name : base/variation
  let cert_list_name = action.name + "CertList",
    cert_table_name =
      action.name === "base"
        ? action.name + "ContractTable"
        : action.name === "variation"
          ? action.name + "sTable"
          : action.name + "Table";

  let certList = state.get(cert_list_name).toJS(),
    table = state.get(cert_table_name),
    certTotal = 0,
    certTotalExcludeRetention = 0,
    parent_id = "";

  // set reason_status here
  if (action.field === "value") {
    certList = certList.map((item) => {
      if (item.id === action.id) {
        item.payment_value = action.value * 100 || 0;
        item.payment_percent =
          item.total !== 0 ? (item.payment_value / item.total) * 100 : 0;
        item.cert_qty =
          (parseFloat(item.payment_value) / parseFloat(item.total)) *
          parseFloat(item.total_item_quantity);
        parent_id = item.parent_id;
        item.required_reason = checkIfRequired(item);
        item.reason_status = getReasonStatus(item);
      }
      return item;
    });
  } else if (action.field === "qty") {
    //handle qty
    certList = certList.map((item) => {
      if (item.id === action.id) {
        //if user type qty from input box
        if (!action.fromPrctQty && !action.fromLoad) {
          item.cert_qty = action.value;
          item.payment_value =
            parseFloat(item.cert_qty) * parseFloat(item?.item_rate) || 0;
        }

        //if by clicking switch button, no need to care action value
        if (action.fromPrctQty && !action.fromLoad) {
          item.cert_qty =
            (item.payment_percent * item.total_item_quantity) / 100;
        }

        item.payment_percent =
          item.total !== 0 ? (item.payment_value / item.total) * 100 : 0;
        parent_id = item.parent_id;
        item.required_reason = checkIfRequired(item);
        item.reason_status = getReasonStatus(item);
      }
      return item;
    });
  } else {
    //handle percent
    certList = certList.map((item) => {
      if (item.id === action.id) {
        //if user typed value
        if (!action.fromLoad && !action.fromPrctQty) {
          item.payment_percent = parseFloat(action.value) || 0;
          item.payment_value = Math.round(
            (item.payment_percent * item.total) / 100,
          );
        }

        //if by clicking switch button, no need to care action value
        if (!action.fromLoad && action.fromPrctQty) {
          item.payment_percent =
            item.total !== 0 ? (item.payment_value / item.total) * 100 : 0;
        }
        item.cert_qty = (item.payment_percent * item.total_item_quantity) / 100;
        parent_id = item.parent_id;
        item.required_reason = checkIfRequired(item);
        item.reason_status = getReasonStatus(item);
      }
      return item;
    });
  }

  certList.forEach((item) => {
    item.has_children = certList.some(
      (otherItem) => otherItem?.parent_id === item?.id,
    );
  });

  if (parent_id !== "") {
    certList = findParent(certList, table, parent_id);
  }

  table.map((item) => {
    var obj = certList.find((ele) => ele.id === item.get("id"));
    certTotal += obj.payment_value;
    return { certTotal };
  });

  for (const obj of certList) {
    if (!obj?.exclude_retention && !obj?.has_children) {
      certTotalExcludeRetention += obj.payment_value;
    }
  }

  return state.merge({
    [cert_list_name]: fromJS(certList),
    [action.name + "CertTotal"]: certTotal / 100,
    [action.name + "CertTotalExcludeRetention"]:
      certTotalExcludeRetention / 100,
  });
};

const set_reason = (state, action) => {
  let name = action.field + "CertList",
    list = state.get(name).toJS();
  let obj = list.find((item) => item.id === action.id);
  obj.payment_comment = action.reason;
  if (
    action.reason === "" &&
    obj.claim_total !== obj.payment_value &&
    obj.current_status === VARIATION_LINEITEM_STATUS.PENDING &&
    !obj.has_child
  ) {
    obj.required_reason = true;
  } else {
    obj.required_reason = false;
  }
  obj.reason_status = getReasonStatus(obj);
  obj.reject_reason = action.reason;

  //Update children's required_reason if the parent has children
  if (obj.has_child) {
    list.forEach((childItem) => {
      if (childItem.parent_id === obj.id) {
        // Apply the same logic to the children
        const isRequiredReason =
          action.reason === "" &&
          childItem?.claim_total !== childItem?.payment_value &&
          childItem?.current_status === VARIATION_LINEITEM_STATUS.PENDING &&
          !childItem.has_child;

        childItem.required_reason = isRequiredReason;
      }
    });
  }
  return state.merge({
    [name]: fromJS(list),
  });
};

const setClaimEmailList = (state, action) => {
  let list = {},
    name = "",
    field = action.field.replace(/^\S/, (s) => s.toUpperCase());
  name = "email" + field + "List";
  list = state.get(name).toJS();
  list = list.filter(
    (chip) => chip.contact_email !== action.data.get("contact_email"),
  );
  return state.set(name, fromJS(list));
};

const addClaimEmailList = (state, action) => {
  let list = {},
    name = "",
    field = action.field.replace(/^\S/, (s) => s.toUpperCase());
  name = "email" + field + "List";
  let newContact = {};
  if (action.data.contact_name === "") {
    newContact.contact_name = action.data.contact_email;
  } else newContact.contact_name = action.data.contact_name;
  newContact.contact_email = action.data.contact_email;

  list = state.get(name).toJS();
  list = list.filter(
    (chip) => chip.contact_email !== action.data.contact_email,
  );
  list = list.concat(newContact);
  return state.set(name, fromJS(list));
};

const setLineitemComments = (state, action) => {
  const { lineId, variationItems } = action;
  let stateUpdate = { ...state.toJS() };

  // Update only the comments for the specific lineId in variationsTable
  let itemUpdate = getNestedLineitem(variationItems, lineId);
  let found = false;
  stateUpdate.variationsTable = state
    .get("variationsTable")
    .toJS()
    .map((item) => {
      if (!found && (item.id === lineId || item.childitems?.length > 0)) {
        item = updateDescendantLineitem(item, lineId, (obj) => {
          return { ...obj, comments: itemUpdate.comments };
        });
      }

      // Return the original item if it doesn't match the lineId
      return item;
    });

  stateUpdate.baseContractTable =
    action.baseItems || state.get("baseContractTable").toJS();

  stateUpdate.materialsTable =
    action.materialItems || state.get("materialsTable").toJS();

  return state.merge(fromJS(stateUpdate));
};

const setMaterialInput = (state, action) => {
  let materialsCertList = state.get("materialsCertList").toJS();
  let materialsTotal = state.get("materialsTotal").toJS();
  let materialsTotalExcludeRetention = state
    .get("materialsTotalExcludeRetention")
    .toJS();
  let onSiteCertTotal = 0,
    offSiteCertTotal = 0;
  let onSiteCertTotalExcludeRetention = 0,
    offSiteCertTotalExcludeRetention = 0;

  materialsCertList = materialsCertList.map((lineItem) => {
    if (lineItem.id === action.lineId) {
      lineItem.payment_value = action.value * 100 || 0;
      lineItem.required_reason =
        lineItem.claim_total !== action.value * 100 &&
        lineItem.payment_comment === "";
    }
    if (lineItem.onSite) {
      onSiteCertTotal += lineItem.payment_value;

      if (!lineItem.exclude_retention) {
        onSiteCertTotalExcludeRetention += lineItem.payment_value;
      }
    } else {
      offSiteCertTotal += lineItem.payment_value;

      if (!lineItem.exclude_retention) {
        offSiteCertTotalExcludeRetention += lineItem.payment_value;
      }
    }
    return lineItem;
  });

  return state.merge({
    materialsCertList: fromJS(materialsCertList),
    materialsTotal: fromJS({
      ...materialsTotal,
      onSite: onSiteCertTotal,
      offSite: offSiteCertTotal,
    }),
    materialsTotalExcludeRetention: fromJS({
      ...materialsTotalExcludeRetention,
      onSite: onSiteCertTotalExcludeRetention,
      offSite: offSiteCertTotalExcludeRetention,
    }),
  });
};

const setMaterialCertListWithTotal = (data, isCert = false) => {
  const materialsCertList = data
    ? data.map((item) => ({
        id: item.id,
        payment_value: isCert ? item.cert_value : item.claim_value,
        current_claim: item.current_claim,
        onSite: item.on_site,
        payment_comment: item.cert_reason || "",
        claim_total: item.claim_value,
        exclude_retention: item?.exclude_retention,
        required_reason:
          item.claim_value !== item.cert_value && item.cert_reason === "",
      }))
    : [];

  const materialsTotal = {
    onSiteClaim: 0,
    onSite: 0,
    offSiteClaim: 0,
    offSite: 0,
  };

  const materialsTotalExcludeRetention = {
    onSiteClaim: 0,
    onSite: 0,
    offSiteClaim: 0,
    offSite: 0,
  };

  materialsCertList?.forEach((value) => {
    if (value.onSite) {
      materialsTotal.onSiteClaim += value.current_claim;
      materialsTotal.onSite += value.payment_value;

      if (!value.exclude_retention) {
        materialsTotalExcludeRetention.onSiteClaim += value.current_claim;
        materialsTotalExcludeRetention.onSite += value.payment_value;
      }
    } else {
      materialsTotal.offSiteClaim += value.current_claim;
      materialsTotal.offSite += value.payment_value;

      if (!value.exclude_retention) {
        materialsTotalExcludeRetention.offSiteClaim += value.current_claim;
        materialsTotalExcludeRetention.offSite += value.payment_value;
      }
    }
  });

  return [materialsCertList, materialsTotal, materialsTotalExcludeRetention];
};

const removeFileFromList = (state, action) => {
  const fileList = state.get("uploadFiles");

  let fList1 = fileList.filter((o) => o?.name !== action.fileName);
  let fList2 = fList1.filter((o) => o?.file_name !== action.fileName);

  return state.set("uploadFiles", fList2);
};

export const certSummaryCalculatedValues = (
  certToDateData,
  previousData,
  thisData,
  gst,
  cisStatus,
  domestic_reverse_charge,
) => {
  const calculateAndAssignTotals = (data) => {
    const [total, gstTotal] = calculateTotalAndGst(data, gst);
    data.total = total;
    data.gst = gstTotal;
  };

  calculateAndAssignTotals(certToDateData);
  calculateAndAssignTotals(previousData);
  const calculateCISValues = (data, cisStatus) => {
    const cis_gross = data?.labour_element ?? 0;
    const gross = data?.gross ?? 0;
    if (cis_gross === 0) {
      return { gross: cis_gross, retention: 0, pcrr: 0, dlrr: 0, value: 0 };
    }
    const safeValue = (value) => (isNaN(value) || !isFinite(value) ? 0 : value);
    const retention = safeValue((cis_gross / gross) * data.retention);
    const retention_pcrr = safeValue((cis_gross / gross) * data.retention_pcrr);
    const retention_dlrr = safeValue((cis_gross / gross) * data.retention_dlrr);
    const value = safeValue(
      ((cis_gross - retention + retention_dlrr + retention_pcrr) * cisStatus) /
        100,
    );
    return {
      gross: cis_gross,
      retention,
      pcrr: retention_pcrr,
      dlrr: retention_dlrr,
      value,
    };
  };

  certToDateData.cis_deduction = calculateCISValues(certToDateData, cisStatus);
  previousData.cis_deduction = calculateCISValues(previousData, cisStatus);

  thisData = {
    gross: (certToDateData.gross ?? 0) - (previousData.gross ?? 0),
    retention: (certToDateData.retention ?? 0) - (previousData.retention ?? 0),
    retention_pcrr:
      (certToDateData.retention_pcrr ?? 0) - (previousData.retention_pcrr ?? 0),
    retention_dlrr:
      (certToDateData.retention_dlrr ?? 0) - (previousData.retention_dlrr ?? 0),
    labour_element:
      (certToDateData.labour_element ?? 0) - (previousData.labour_element ?? 0),
    cis_deduction: {
      value:
        (certToDateData?.cis_deduction?.value ?? 0) -
        (previousData.cis_deduction?.value ?? 0),
      gross:
        (certToDateData?.cis_deduction?.gross ?? 0) -
        (previousData.cis_deduction?.gross ?? 0),
      dlrr:
        (certToDateData?.cis_deduction?.dlrr ?? 0) -
        (previousData.cis_deduction?.dlrr ?? 0),
      pcrr:
        (certToDateData?.cis_deduction?.pcrr ?? 0) -
        (previousData.cis_deduction?.pcrr ?? 0),
      retention:
        (certToDateData?.cis_deduction?.retention ?? 0) -
        (previousData.cis_deduction?.retention ?? 0),
    },
  };

  calculateAndAssignTotals(thisData);
  thisData.gst = domestic_reverse_charge ? 0 : thisData.gst;
  thisData.value =
    thisData.total + thisData.gst - (thisData?.cis_deduction?.value ?? 0);
  return [previousData, thisData, certToDateData];
};

const updateCertSummary = (state, action) => {
  let update = action.data;
  let cisStatus = action.cisStatus;
  let newState = { ...state.toJS() };
  let previousUpdate = {};
  let thisUpdate = {};
  let certToDateUpdate = {};
  const gst = newState.projectlist.gst;
  const previousCertified = newState.previousCertified;
  const oldSummary = newState.summaryComponent;
  const certToDate = newState.summaryComponent.certToDate;
  const domestic_reverse_charge = newState?.domestic_reverse_charge;
  previousUpdate.option =
    update?.previous_option || oldSummary.previous?.option;
  if (update?.previous_option === "certified") {
    previousUpdate = {
      ...previousUpdate,
      object_id: previousCertified?.object_id || "",
      gross: previousCertified?.gross || 0,
      retention: previousCertified?.retention || 0,
      retention_pcrr: previousCertified?.retention_pcrr || 0,
      retention_dlrr: previousCertified?.retention_dlrr || 0,
      labour_element: previousCertified?.labour_element || 0,
    };
  } else {
    previousUpdate = {
      ...previousUpdate,
      gross:
        typeof update["previous_gross"] === "number"
          ? update["previous_gross"]
          : oldSummary.previous?.gross,
      retention:
        typeof update["previous_retention"] === "number"
          ? update["previous_retention"]
          : oldSummary.previous?.retention,
      retention_pcrr:
        typeof update["previous_retention_pcrr"] === "number"
          ? update["previous_retention_pcrr"]
          : oldSummary.previous?.retention_pcrr,
      retention_dlrr:
        typeof update["previous_retention_dlrr"] === "number"
          ? update["previous_retention_dlrr"]
          : oldSummary.previous?.retention_dlrr,
      labour_element:
        typeof update["previous_labour_element"] === "number"
          ? update["previous_labour_element"]
          : oldSummary.previous?.labour_element,
    };
  }

  certToDateUpdate = {
    ...certToDate,
    retention_pcrr: update?.cert_retention_pcrr ?? certToDate.retention_pcrr,
    retention_dlrr: update?.cert_retention_dlrr ?? certToDate.retention_dlrr,
    labour_element: update?.labour_element ?? certToDate.labour_element,
  };

  const [previousUpdatedData, thisUpdatedData, certToDateUpdatedData] =
    certSummaryCalculatedValues(
      certToDateUpdate,
      previousUpdate,
      thisUpdate,
      gst,
      cisStatus,
      domestic_reverse_charge,
    );
  newState.summaryComponent.previous = previousUpdatedData;
  newState.summaryComponent.thisCert = thisUpdatedData;
  newState.summaryComponent.certToDate = certToDateUpdatedData;

  return fromJS(newState);
};

const setPreviousCertified = (state, action) => {
  let stateUpdate = { ...state.toJS() };
  let prevCertified = null;
  const gst = stateUpdate.projectlist.gst;
  let previousRef = {
    certified: "",
  };

  // since history data array is sorted, check if there's a previous/next entry
  var i =
    action.data
      ?.map((e) => {
        return e.cert_id;
      })
      .indexOf(action.currentCertId) + 1;
  if (i <= action.data.length - 1) {
    let data = action.data[i];

    // set previous cert ref title
    previousRef.certified = data?.cert_number;
    prevCertified = {
      object_id: data?.cert_id || "",
      gross: data?.gross_certified_value || 0,
      retention: data?.cert_retention || 0,
      retention_pcrr: data?.cert_pcd || 0,
      retention_dlrr: data?.cert_dlp || 0,
      // labour_element
      labour_element: data?.cert_labour_element || 0,
    };
    prevCertified["total"] =
      prevCertified.gross +
      prevCertified.retention_pcrr +
      prevCertified.retention_dlrr -
      prevCertified.retention;
    prevCertified["gst"] = prevCertified["total"] * (gst / 100);
  }
  stateUpdate.previousCertified = prevCertified;
  stateUpdate.previousRef = previousRef;

  return fromJS(stateUpdate);
};

const setVariationLineItemInfo = (state, action) => {
  const currentVariationsTable = state.get("variationsTable").toJS();
  const currentVariationCertList = state.get("variationCertList").toJS();

  // Helper function to determine updated values based on status
  const getUpdatedValues = (item) => {
    const { current_status } = action;
    const isReject = current_status === VARIATION_LINEITEM_STATUS.REJECTED;
    return {
      cert_value: isReject ? 0 : item.claim_value ?? item.cert_value ?? 0,
      cert_percent: isReject ? 0 : item.claim_percent ?? item.cert_percent ?? 0,
      cert_qty: isReject ? 0 : item.cert_qty ?? item.claim_qty ?? 0,
    };
  };

  // Update item and child items
  const updateItem = (item) => {
    if (action?.lineItemDetails) {
      return action.lineItemDetails;
    }

    const updatedValues = getUpdatedValues(item);
    const updatedChildItems = item.childitems
      ? item.childitems.map(updateItem) // Recursively update child items
      : [];
    //reset current status if defined
    const current_status = action.current_status || item.current_status;

    return {
      ...item,
      current_status: current_status,
      ...updatedValues,
      childitems: updatedChildItems,
    };
  };

  // Update variations table
  const updatedVariationsTable = currentVariationsTable.map((lineItem) =>
    lineItem.id === action.lineitemId ? updateItem(lineItem) : lineItem,
  );

  const [
    variationCertList,
    variationCertTotal,
    variationCertTotalExcludeRetention,
    variationThisCertTotal,
    variationsTotalValues,
  ] = calculateCertTotal(updatedVariationsTable);
  const updatedItemsFromVariationCertList = variationCertList.filter(
    (lineItem) =>
      lineItem.id === action.lineitemId ||
      lineItem.parent_id === action.lineitemId,
  );

  const variationCertListUpdate = currentVariationCertList.map((lineItem) => {
    // Check if the current lineItem should be updated
    const shouldUpdate = updatedItemsFromVariationCertList.some(
      (updatedItem) =>
        updatedItem.id === lineItem.id || updatedItem.parent_id === lineItem.id,
    );

    // If the item should be updated, find the corresponding updated item
    if (shouldUpdate) {
      const updatedItem = updatedItemsFromVariationCertList.find(
        (item) => item.id === lineItem.id || item.parent_id === lineItem.id,
      );
      return {
        ...lineItem,
        ...updatedItem,
      };
    }

    return lineItem;
  });

  // Then, calculate the total variation after the status change
  let variationTotalsAfterStatus = 0;
  updatedVariationsTable.forEach((lineItem) => {
    if (
      [VARIATION_LINEITEM_STATUS.APPROVED].includes(lineItem.current_status)
    ) {
      variationTotalsAfterStatus += lineItem.total;
    }
  });
  // Update the total variation in the project data
  const currentProjectData = {
    ...state.get("projectlist").toJS(),
    total_variation: variationTotalsAfterStatus,
  };

  // Return the updated state with recalculated totals
  return state.merge(
    fromJS({
      variationsTable: updatedVariationsTable,
      projectlist: currentProjectData,
      variationCertTotal: calculateSubunitToUnit(variationCertTotal),
      variationCertList: variationCertListUpdate,
      variationCertTotalExcludeRetention: calculateSubunitToUnit(
        variationCertTotalExcludeRetention,
      ),
      variationThisCertTotal: calculateSubunitToUnit(variationThisCertTotal),
      variationsTotalValues,
    }),
  );
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.REQUEST_CLAIM_INFO:
      //get initial claim info from api
      return setClaimInfo(state, action);
    case constants.REQUEST_CERT_INFO:
      return setInitialCertInfo(state, action);
    case constants.SET_INPUT_VALUE:
      return setCertList(state, action);
    case constants.SET_REASON_VALUE:
      return set_reason(state, action);
    case constants.SET_NAME_VALUE:
      return state.set(action.name, action.value);
    case constants.RESET_STAGE:
      return defaultState;
    case constants.REMOVE_EMAIL:
      return setClaimEmailList(state, action);
    case constants.ADD_EMAIL:
      return addClaimEmailList(state, action);
    case constants.SET_EMAIL_SUBJECT:
      return state.set(action.name, action.value);
    case constants.SET_ARRAY_DATA:
      return state.set(
        action.name,
        action.value ? fromJS(action.value) : fromJS([]),
      );
    case constants.SET_DATA:
      return state.set(action.payload.name, fromJS(action.payload.value));
    case constants.SET_LINE_COMMENTS:
      return setLineitemComments(state, action);
    case constants.SET_MATERIAL_INPUT:
      return setMaterialInput(state, action);
    case constants.DELETE_FILE:
      let files = state
        .get("cert_attachments")
        .filter((file) => file.get("file_id") !== action.payload);
      return state.set("cert_attachments", fromJS(files));
    case constants.CHANGE_FILE_LIST:
      return state.set(
        "uploadFiles",
        state.get("uploadFiles").concat(action.files),
      );
    case constants.INITIAL_FILE_LIST:
      if (action.fileList !== undefined && action.fileList) {
        return state.set("editFileList", fromJS(action.fileList));
      } else {
        return state.merge({
          editFileList: fromJS([]),
          uploadFiles: fromJS([]),
        });
      }
    case constants.CANCEL_ONE_FILE:
      return removeFileFromList(state, action);
    case constants.UPDATE_SUMMARY:
      return updateCertSummary(state, action);
    case constants.SET_PREVIOUS_CERTIFIED:
      return setPreviousCertified(state, action);
    case constants.SET_VARIATION_LINE_INFO:
      return setVariationLineItemInfo(state, action);
    default:
      return state;
  }
};
