import * as constants from "./constants";
import history from "../../../utils/history";
import {
  downloadFile as downloadLink,
  viewPDF,
} from "../../../utils/createDownloadLink";
import { fromJS } from "immutable";

import { actionCreators as actionCreatorsModal } from "../../../common/modal";
import { actionCreators as actionCreatorsToast } from "../../../common/toastNotification/store";
import { actionCreators as actionCreatorsUser } from "../../manageaccount/store";
import { actionCreators as actionCreatorsFile } from "../../../common/upload_file_progress/store";
import { deleteUploadingFile } from "../../../common/claim_cert_attachment/store/actionCreators";

import { toCamelCase } from "../../../utils/stringHelper";
import { roundNumber } from "../../../utils/numeralHelper";
import API from "../../../server";
import {
  RATE,
  CERT_MATERIAL_ITEM,
  CERT_VARIATION_ITEM,
} from "../../../utils/constants";

import { certSummaryCalculatedValues } from "./reducer";
import { calculateUnitToSubunit } from "utils/currencyHelper";

var numeral = require("numeral");
const getApprovalList = (proId, contractId, claimId, certId) => {
  return (dispatch) => {
    API.read_cert_workflow(proId, contractId, claimId, certId)
      .then((res) => res.data)
      .then((ele) => {
        dispatch(setArrayData("approvalList", ele.data.data));
      });
  };
};

const setArrayData = (name, value) => ({
  type: constants.SET_ARRAY_DATA,
  name,
  value,
});

// Cert Approvals Toast Notification where, the toast notification is displayed when the cert is approved in the final approval step.
export const displayCertToastNotification = (certId) => {
  return (dispatch, getState) => {
    const approveList = getState().getIn(["makeacert", "approvalList"]);
    const certWord = getState().getIn([
      "config",
      "accountConfig",
      "cert",
      "noun",
    ]);
    if (
      approveList.get("current_step_index") >=
      approveList.get("steps").size - 1
    ) {
      dispatch(
        actionCreatorsToast.addToast(certId, {
          type: "information",
          title: `${certWord} approved`,
        }),
      );
    }
  };
};

export const approveStepCert = (proId, contractId, claimId, certId) => {
  return (dispatch, getState) => {
    let approveList = getState().getIn(["makeacert", "approvalList"]),
      comment = {
        comment: getState().getIn(["makeacert", "approval_comment"]),
      };
    if (approveList.get("current_step_index") < approveList.get("steps").size) {
      let currentStep = approveList
        .get("steps")
        .find(
          (item) => item.get("step") === approveList.get("current_step_index"),
        );
      dispatch(setValue("approvingCert", true));
      API.approve_cert_step(
        proId,
        contractId,
        claimId,
        certId,
        currentStep.get("id"),
        comment,
      )
        .then((res) => res.data)
        .then(() => {
          dispatch(closeDialog());
          API.read_cert_workflow(proId, contractId, claimId, certId)
            .then((res) => res.data)
            .then((ele) => {
              dispatch(setArrayData("approvalList", ele.data.data));
              dispatch(displayCertToastNotification(certId));
            });
          dispatch(setValue("approvingCert", false));
        });
    }
  };
};

export const revertByStep = (
  proId,
  contractId,
  claimId,
  certId,
  stepId,
  comment,
) => {
  return (dispatch) => {
    API.revert_cert_workflow(proId, contractId, claimId, certId, stepId, {
      comment,
    }).then((res) => {
      dispatch(getApprovalList(proId, contractId, claimId, certId));
    });
  };
};

export const closeDialog = () => {
  return (dispatch) => {
    dispatch(setValue("approval_comment", ""));
    dispatch(setValue("leaveComment", false));
  };
};

const sendProjectOverviewInfo = (projectinfo) => ({
  type: constants.REQUEST_CLAIM_INFO,
  projectinfo: projectinfo,
});
const setInitialClaimInfo = (certInfo) => ({
  type: constants.REQUEST_CERT_INFO,
  certInfo,
});

export const readClaim = (
  contractId,
  claimId,
  contractType,
  isMakingPage,
  proId,
) => {
  return (dispatch) => {
    dispatch(getAccountReason());
    dispatch(setData("certId", ""));
    API.read_empty_cert(proId, contractId, claimId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        if (isMakingPage && res.status === "certified" && res.cert_id) {
          history.push(
            `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert/review`,
          ); // redirect to review page
        }
        if (res.status !== "issue" && !res.cert_id) {
          history.push("/error_404");
        }
        if (res.status === "issue" && !res.cert_id) {
          dispatch(sendProjectOverviewInfo(res));
        }
        if (res.has_retention) {
          dispatch(
            setData("claimRetentionValue", {
              dlp: res.dlp_release_value / 100,
              pcd: res.pcd_release_value / 100,
            }),
          );
        }
        dispatch(getPreCert(contractId, res.claim_to));
        if (
          (res.status === "issue" || res.status === "certified") &&
          res.cert_id
        ) {
          dispatch(setData("certId", res.cert_id));
          dispatch(
            readCert(contractId, claimId, contractType, res.cert_id, proId),
          );
        }
      });
  };
};
export const readCert = (contractId, claimId, contractType, certId, proId) => {
  return (dispatch, getState) => {
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    API.read_one_cert(proId, contractId, claimId, certId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        if (res.cert_status === "pending" && proId) {
          history.push({
            pathname: `/contract/${proId}/${contractType}/${contractId}`,
            search: `?account_id=${accountId}`,
            state: {
              certName: res.cert_certificate_ref,
              certId: res.cert_id,
              claimId: claimId,
            },
          });
        }
        dispatch(setInitialClaimInfo(res));
        if (contractType !== "claim") {
          dispatch(getApprovalList(proId, contractId, claimId, certId));
          // get original previous cert data from history
          API.read_payment_history(contractId)
            .then((res) => res.data.data.data)
            .then((history) => {
              dispatch(getPreviousCertifiedSummary(history, certId));
            });
        }
        dispatch(actionCreatorsUser.getAllUsers());
      });
  };
};

const getPreCert = (contractId, toDate) => {
  return (dispatch) => {
    // based on claim to date to get previous cert value
    API.read_claim_net_cert(contractId, toDate)
      .then((res) => res.data)
      .then((res) => res.data.value)
      .then((res) => {
        let preNetTotal = res.cert_value;
        dispatch(
          setData("certRetentionValue", {
            preNetTotal: preNetTotal / 100,
            maxDlp: res.cert_dlp / 100,
            maxPcd: res.cert_pcd / 100,
          }),
        );
      });
  };
};

export const getAccountReason = () => {
  return (dispatch) => {
    API.read_account_cert_reason()
      .then((res) => res.data)
      .then((res) => res.data.reason)
      .then((res) => {
        dispatch(setArrayData("reasonOption", res));
      });
  };
};

export const setInputCertValue = (
  id,
  field,
  value,
  tableName,
  loadVal,
  fromPrctQty,
) => ({
  type: constants.SET_INPUT_VALUE,
  id,
  field,
  value,
  name: tableName,
  fromLoad: loadVal,
  fromPrctQty: fromPrctQty,
});

export const set_reason = (id, reason, field) => ({
  type: constants.SET_REASON_VALUE,
  id,
  reason,
  field,
});

export const setValue = (name, value) => ({
  type: constants.SET_NAME_VALUE,
  name,
  value,
});

export const setMaterialInput = (lineId, value, onSite) => ({
  type: constants.SET_MATERIAL_INPUT,
  lineId,
  value,
  onSite,
});

const CERT_ACTION = {
  CREATE: "create",
  UPDATE: "update",
};

//update or create cert
export const addACert = (
  proId,
  contractId,
  claimId,
  certId,
  field,
  action,
  contractType,
  data,
) => {
  return (dispatch, getState) => {
    let baseList = getState().getIn(["makeacert", "baseCertList"]),
      variationList = getState().getIn(["makeacert", "variationCertList"]),
      previousType = getState().getIn(["makeacert", "previousType"]),
      lessRetention = getState().getIn(["makeacert", "lessRetention"]),
      lessPaid = getState().getIn(["makeacert", "paidValue"]),
      cert_number = getState().getIn(["makeacert", "certificate_number"]),
      retentionOption = getState().getIn(["makeacert", "retentionOption"]),
      materialList = getState().getIn(["makeacert", "materialsCertList"]),
      files = getState().getIn(["makeacert", "uploadFiles"]).toJS(); // used when saving a variation item with files
    const accountId = getState().getIn([
      "headers",
      "profile",
      "payclaim_account_id",
    ]);
    baseList = baseList.toJS();
    baseList = baseList.map((item) => {
      item.payment_value = Math.round(item.payment_value);
      item.cert_qty = roundNumber(
        (parseFloat(item?.payment_value) / parseFloat(item?.total)) *
          item?.total_item_quantity,
      );

      delete item.parent_id;
      delete item.total;
      return item;
    });

    variationList = variationList.toJS();
    variationList = variationList.map((item) => {
      item.payment_value = Math.round(item.payment_value);
      item.cert_qty = roundNumber(
        (parseFloat(item?.payment_value) / parseFloat(item?.total)) *
          item?.total_item_quantity,
      );

      delete item.parent_id;
      delete item.total;
      return item;
    });
    let dataSet = {};
    dataSet["less_retention"] = Math.round(
      numeral(lessRetention).value() * 100,
    );
    dataSet["less_previous_value"] = Math.round(
      numeral(lessPaid).value() * 100,
    );
    dataSet["paycert_lineitems"] = [];
    dataSet["paycert_lineitems"] =
      dataSet["paycert_lineitems"].concat(baseList);
    dataSet["paycert_lineitems"] =
      dataSet["paycert_lineitems"].concat(variationList);
    dataSet["certificate_number"] = cert_number;
    dataSet["less_previous_type"] = previousType;
    dataSet["retention_option"] = retentionOption;

    let projectAllInfo = getState().getIn(["makeacert", "projectlist"]);

    // New Cert Summary Component
    const prevData = getState().getIn([
      "makeacert",
      "summaryComponent",
      "previous",
    ]);
    const certToDateData = getState().getIn([
      "makeacert",
      "summaryComponent",
      "certToDate",
    ]);

    dataSet["previous_object_id"] = prevData?.get("object_id") || "";
    dataSet["previous_option"] = prevData.get("option");
    dataSet["previous_gross"] = prevData.get("gross");
    dataSet["previous_total"] = prevData.get("total");

    dataSet["has_retention"] = projectAllInfo.get("has_retention");
    if (projectAllInfo.get("has_retention")) {
      dataSet["pcd_date"] = projectAllInfo.get("pcd_date");
      dataSet["dlp_date"] = projectAllInfo.get("dlp_date");

      // New Summary Component
      dataSet["pcd_release_value"] = certToDateData.get("retention_pcrr");
      dataSet["dlp_release_value"] = certToDateData.get("retention_dlrr");

      // previous Object_id required for the new summary component
      dataSet["previous_retention"] = prevData.get("retention");
      dataSet["previous_retention_pcrr"] = prevData.get("retention_pcrr");
      dataSet["previous_retention_dlrr"] = prevData.get("retention_dlrr");
    }

    dataSet["previous_labour_element"] = prevData.get("labour_element");
    dataSet["labour_element"] = certToDateData.get("labour_element");

    let materials = materialList.toJS();
    materials = materials.map((item) => {
      item.payment_value = Math.round(item.payment_value);
      item.on_site = item.onSite;

      return item;
    });

    dataSet["paycert_lineitems"] =
      dataSet["paycert_lineitems"].concat(materials);

    const nextURL = {
      pathname: `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert/review`,
      search: `?account_id=${accountId}`,
    };
    if (action === CERT_ACTION.CREATE) {
      let nextPath = `/project/${proId}/contract/${contractId}/${contractType}/claim/${claimId}/cert?account_id=${accountId}`;
      API.create_one_cert(proId, contractId, claimId, JSON.stringify(dataSet))
        .then((res) => res.data)
        .then((res) => res.data.data.id)
        .then((certId) => {
          if (data) {
            // Add new cert type line with files
            var dataSend = new FormData();
            files.map((file) => {
              return dataSend.append("attachments", file);
            });
            let itemData = JSON.stringify(data);
            dataSend.append("data", itemData);

            API.add_cert_item(
              proId,
              contractId,
              claimId,
              certId,
              dataSend,
              field,
              { mimeType: "multipart/form-data" },
            ).then((res) => {
              dispatch(
                readClaim(contractId, claimId, contractType, true, proId),
              );
            });
          }
          //next
          field === "cert" && history.push(nextURL);
          //save draft
          field === "cert_draft" && history.push(nextPath);
          field === "cert_draft" &&
            dispatch(readClaim(contractId, claimId, contractType, true, proId));
        });
    } else {
      API.update_one_cert(proId, contractId, claimId, certId, dataSet)
        .then((res) => res.data)
        .then((res) => {
          //next
          field === "cert" && history.push(nextURL);
          //save
          field === "cert_draft" &&
            dispatch(
              actionCreatorsModal.showModal("confirm", {
                open: true,
                title: "Success!",
                message: "You have saved the cert successfully！",
                action: readCert(
                  contractId,
                  claimId,
                  contractType,
                  certId,
                  proId,
                ),
              }),
            );
          //add cert item
          if ([CERT_VARIATION_ITEM, CERT_MATERIAL_ITEM].includes(field)) {
            dispatch(
              readCert(contractId, claimId, contractType, certId, proId),
            );
          }
        });
    }
  };
};

//download the pdf
export const downloadPdf =
  (proId, contractId, claimId, certId, isDownload) => () => {
    return API.download_cert_pdf(proId, contractId, claimId, certId, {
      responseType: "blob",
    }).then((res) => {
      if (isDownload) {
        downloadLink(res);
      } else {
        viewPDF(res);
      }
    });
  };

export const resetStage = () => ({
  type: constants.RESET_STAGE,
});
export const issueCert = (
  proId,
  contractId,
  claimId,
  certId,
  isSendEmail,
  emailToList,
  subject,
  attachedEmailFiles,
  isLinked,
) => {
  let attachments = attachedEmailFiles.filter((item) =>
    item.get("is_attached_email"),
  );
  let contacts = {
    subject: subject,
    to_contacts: emailToList.toJS(),
    attachments: attachments.toJS(),
  };

  return (dispatch) => {
    if (isSendEmail && contacts.to_contacts.length < 1) {
      dispatch(
        actionCreatorsModal.showModal("alert", {
          open: true,
          title: "Error!",
          message: "Please input at least one email address!",
        }),
      );
    } else {
      dispatch(setValue("sendingEmail", true));
      let isPlural = contacts.to_contacts.length > 1;

      API.approve_one_cert(claimId, certId, contractId, proId)
        .then((res) => res.data)
        .then((res) => {
          API.send_paycert_email(
            proId,
            claimId,
            certId,
            isSendEmail,
            contractId,
            contacts,
          )
            .then((res) => res.data)
            .then((res) => {
              dispatch(setValue("sendingEmail", false));
              dispatch(
                setData(
                  "componentKey",
                  Math.random().toString(36).substring(2),
                ),
              );
              dispatch(
                actionCreatorsToast.addToast(certId, {
                  type: "information",
                  title: `Certificate issued and ${
                    isPlural ? "emails" : "email"
                  } sent`,
                }),
              );
            });

          API.approve_one_cert_integration(claimId, certId, contractId, proId)
            .then((res) => res.data)
            .then((res) => {
              if (res?.status === 201) {
                if (isLinked) {
                  dispatch(
                    actionCreatorsToast.addToast(claimId, {
                      type: "information",
                      title: `Certificate Information has been dispatched to IPM successfully!`,
                    }),
                  );
                }
              }
            });
        });
    }
  };
};

export const setEmailSubject = (name, value) => ({
  type: constants.SET_EMAIL_SUBJECT,
  name: name,
  value: value,
});

export const removeEmail = (data, field) => ({
  type: constants.REMOVE_EMAIL,
  data,
  field,
});
export const addEmail = (data, field) => ({
  type: constants.ADD_EMAIL,
  data,
  field,
});
export const addContact_Email = (entityId, contactId, field) => {
  return (dispatch) => {
    API.read_company_contact(entityId, contactId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(addEmail(res, field));
      });
  };
};

// add a cert variation
export const addVari = (
  proId,
  contractId,
  claimId,
  certId,
  data,
  table,
  contractType,
  lineItemType,
  parentId,
  callbackFunction,
) => {
  return (dispatch, getState) => {
    const files = getState().getIn(["makeacert", "uploadFiles"]).toJS();
    var dataSend = new FormData();
    files.map((file) => {
      return dataSend.append("attachments", file);
    });

    if (parentId) {
      data.parent_id = parentId;
      data["rate"] = Math.round(data.rate * 100);
      data["quantity"] =
        data.lineitem_options === RATE ? Math.round(data.quantity * 100) : 100;
      data["is_provisional"] = data.is_provisional || false;

      let itemData = JSON.stringify(data);
      dataSend.append("data", itemData);
      API.add_cert_item(
        proId,
        contractId,
        claimId,
        certId,
        dataSend,
        lineItemType,
        { mimeType: "multipart/form-data" },
      ).then((res) => {
        dispatch(
          addACert(
            proId,
            contractId,
            claimId,
            certId,
            lineItemType,
            CERT_ACTION.UPDATE,
          ),
        );
        dispatch(initialFileList());
        if (callbackFunction) {
          callbackFunction();
        }
      });
    } else {
      data["rate"] = Math.round(data.rate * 100);
      data["quantity"] =
        data.lineitem_options === RATE ? Math.round(data.quantity * 100) : 100;

      let itemData = JSON.stringify(data);
      dataSend.append("data", itemData);

      if (!certId) {
        //create first
        dispatch(
          addACert(
            proId,
            contractId,
            claimId,
            certId,
            lineItemType,
            CERT_ACTION.CREATE,
            contractType,
            data,
          ),
        );
      } else {
        API.add_cert_item(
          proId,
          contractId,
          claimId,
          certId,
          dataSend,
          lineItemType,
          { mimeType: "multipart/form-data" },
        ).then((res) => {
          dispatch(
            addACert(
              proId,
              contractId,
              claimId,
              certId,
              lineItemType,
              CERT_ACTION.UPDATE,
            ),
          );
          dispatch(initialFileList());
          if (callbackFunction) {
            callbackFunction();
          }
        });
      }
    }
  };
};
// add a cert material
export const addMaterial = (
  proId,
  contractId,
  claimId,
  certId,
  data,
  contractType,
  lineItemType,
) => {
  let dataSend = {
    label: data.label,
    description: data.description,
    detailed_description: data.detailed_description || "",
    on_site: data.onSite || false,
    exclude_retention: data.exclude_retention || false,
  };
  return (dispatch, getState) => {
    const files = getState().getIn(["makeacert", "uploadFiles"]).toJS();
    var formData = new FormData();
    files.map((file) => {
      return formData.append("attachments", file);
    });

    if (!certId) {
      //create first
      dispatch(
        addACert(
          proId,
          contractId,
          claimId,
          certId,
          lineItemType,
          CERT_ACTION.CREATE,
          contractType,
          dataSend,
        ),
      );
    } else {
      let itemData = JSON.stringify(dataSend);
      formData.append("data", itemData);
      API.add_cert_item(
        proId,
        contractId,
        claimId,
        certId,
        formData,
        lineItemType,
      ).then((res) => {
        dispatch(
          addACert(
            proId,
            contractId,
            claimId,
            certId,
            lineItemType,
            CERT_ACTION.UPDATE,
          ),
        );
        dispatch(initialFileList());
      });
    }
  };
};

export const deleteCertItem = (
  proId,
  contractId,
  claimId,
  certId,
  lineId,
  lineItemType,
) => {
  return (dispatch) => {
    API.delete_cert_item(proId, contractId, claimId, certId, lineId).then(
      (res) =>
        dispatch(
          addACert(
            proId,
            contractId,
            claimId,
            certId,
            lineItemType,
            CERT_ACTION.UPDATE,
          ),
        ),
    );
  };
};

export const updateCertItem = (
  proId,
  contractId,
  claimId,
  certId,
  lineId,
  lineItemType,
  data,
) => {
  let dataSend = {};
  if (lineItemType === CERT_VARIATION_ITEM) {
    dataSend = {
      label: data.label,
      description: data.description,
      unit: data.unit,
      rate: Math.round(data.rate * 100),
      quantity: Math.round(data.quantity * 100),
      detailed_description: data.detailed_description || "",
      variation_reference: data.variation_reference || "",
      submit_date: data.submit_date,
      approved_date: data.approved_date,
      lineitem_options: data.lineitem_options,
      exclude_retention: data.exclude_retention || false,
      is_provisional: data.is_provisional || false,
      id: lineId,
      extra_info: data?.extra_info || {},
    };
  } else {
    dataSend = {
      label: data.label,
      description: data.description,
      detailed_description: data.detailed_description || "",
      on_site: data.onSite || false,
      exclude_retention: data.exclude_retention || false,
      id: lineId,
      extra_info: data?.extra_info || {},
    };
  }
  return (dispatch) => {
    let data = new FormData();
    data.append("data", JSON.stringify(dataSend));

    API.upsert_one_lineitem(proId, contractId, data, "variations", {
      mimeType: "multipart/form-data",
    })
      .then((res) => res.data)
      .then(() => {
        dispatch(
          addACert(
            proId,
            contractId,
            claimId,
            certId,
            lineItemType,
            CERT_ACTION.UPDATE,
          ),
        );
      });
  };
};

export const sendEmail = (
  proId,
  claimId,
  certId,
  isSendEmail,
  emailToList,
  subject,
  attachedEmailFiles,
  contractId,
) => {
  let attachments = attachedEmailFiles.filter((item) =>
    item.get("is_attached_email"),
  );
  let contacts = {
    subject: subject,
    to_contacts: emailToList.toJS(),
    attachments: attachments.toJS(),
  };
  return (dispatch) => {
    dispatch(setValue("sendingEmail", true));
    let isPlural = contacts.to_contacts.length > 1;
    API.send_paycert_email(
      proId,
      claimId,
      certId,
      isSendEmail,
      contractId,
      contacts,
    )
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          actionCreatorsToast.addToast(claimId, {
            type: "information",
            title: `${isPlural ? "Emails" : "Email"} sent successfully`,
          }),
        );
        dispatch(setValue("sendingEmail", false));
        dispatch(getApprovalList(proId, contractId, claimId, certId));
      });
  };
};

export const updateStatus = (line_id, current_status, reason, field) => {
  return (dispatch) => {
    dispatch(updateVariationLineItemInfo(line_id, { current_status }));
    dispatch(set_reason(line_id, reason, field));
  };
};

const updateVariationLineItemInfo = (lineitemId, options = {}) => ({
  type: constants.SET_VARIATION_LINE_INFO,
  lineitemId,
  ...options,
});

export const updateComment = (
  contractType,
  contractId,
  claimId,
  certId,
  action,
  content,
  comment_id,
  lineId,
  proId,
) => {
  let data = {
    content: content.comment,
    is_claim: false,
    doc_uuid: certId,
    comment_id: comment_id,
    shared: content.shared,
  };

  let api;
  if (action === "post") {
    delete data.comment_id;
    api = API.create_lineitem_comment(proId, contractId, lineId, data);
  } else if (action === "delete") {
    api = API.delete_lineitem_comment(proId, contractId, lineId, data);
  } else {
    api = API.update_lineitem_comment(proId, contractId, lineId, data);
  }

  return (dispatch) => {
    api.then(() =>
      dispatch(
        readLineitemComments(claimId, certId, contractId, proId, lineId),
      ),
    );
  };
};

const readLineitemComments = (claimId, certId, contractId, proId, lineId) => {
  return (dispatch) => {
    API.read_one_cert(proId, contractId, claimId, certId)
      .then((res) => res.data.data.data)
      .then((res) =>
        dispatch(
          setLineitemComments(
            res?.baseitems,
            res?.variation_items,
            res?.materials,
            lineId,
          ),
        ),
      );
  };
};

const setLineitemComments = (
  baseItems,
  variationItems,
  materialItems,
  lineId,
) => ({
  type: constants.SET_LINE_COMMENTS,
  baseItems,
  variationItems,
  materialItems,
  lineId,
});

export const deleteFile = (
  contractType,
  contractId,
  claimId,
  certId,
  fileId,
) => {
  return (dispatch) => {
    dispatch(
      actionCreatorsModal.showModal("delete", {
        open: true,
        title: "Files",
        message: "file",
        api: {
          name: "delete_file_contract_cert",
          config: [contractId, fileId],
        },
        action: [removeFile(fileId), deleteUploadingFile(fileId)],
      }),
    );
  };
};

const removeFile = (fileId) => ({
  type: constants.DELETE_FILE,
  payload: fileId,
});

//download File
export const downloadFile = (fileId, fileName, token, accId, isDownload) => {
  const openFunc = isDownload ? downloadLink : viewPDF;
  return () => {
    if (token || accId) {
      token
        ? API.download_one_shared_file_by_token(token, {
            responseType: "blob",
          }).then((res) => {
            openFunc(res);
          })
        : API.download_one_shared_file(
            `/api/payclaim/account/${accId}/file/${fileId}`,
            { responseType: "blob" },
          ).then((res) => {
            openFunc(res);
          });
    } else {
      API.download_one_file(fileId, { responseType: "blob" }).then((res) => {
        openFunc(res, fileName);
      });
    }
  };
};

export const changeSharedFiles = (
  proId,
  contractType,
  contractId,
  claimId,
  certId,
  fileId,
  isAdd,
) => {
  return (dispatch) => {
    API.update_cert_shared_attachment(contractId, certId, {
      file_id: fileId,
      is_shared: isAdd,
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(readCert(contractId, claimId, contractType, certId, proId));
      });
  };
};

export const selectAttachedFile = (name, value, field) => {
  return (dispatch, getState) => {
    const MAX_ATTACH_FILE_SIZE = getState().getIn(["headers", "config"])
      .file_size.file_upload_size_limit;
    let fileList = getState().getIn(["makeacert", "attachedEmailFiles"]).toJS(),
      attachmentSize = 0;
    fileList.map((item) => {
      if (name === "all") {
        item[field] = value;
      } else {
        if (item.file_id === name) {
          item[field] = value;
        }
      }
      if (item.is_attached_email && item.attach_method === "file") {
        attachmentSize += item.file_size;
      }
      return item;
    });
    if (attachmentSize > MAX_ATTACH_FILE_SIZE) {
      dispatch(setData("exceedAttachLimit", true));
    } else {
      dispatch(setData("exceedAttachLimit", false));
    }
    dispatch(setArrayData("attachedEmailFiles", fileList));
  };
};

export const setAuditSetting = (proId, contractId, claimId, certId, value) => {
  return (dispatch) => {
    API.update_cert_info(proId, contractId, claimId, certId, {
      attach_audit_trail: value,
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(
          requestPartProjectInfo(
            proId,
            contractId,
            claimId,
            certId,
            "is_audit_attached",
          ),
        );
      });
  };
};

export const requestPartProjectInfo = (
  proId,
  contractId,
  claimId,
  certId,
  fieldName,
) => {
  return (dispatch) => {
    API.read_one_cert(proId, contractId, claimId, certId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setValue(toCamelCase(fieldName), res[fieldName]));
      });
  };
};

// New project and contract details (P2C)
export const setData = (name, value) => ({
  type: constants.SET_DATA,
  payload: {
    name,
    value,
  },
});

export const readProject = (proId) => {
  return (dispatch) => {
    API.read_one_project_group(proId)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setData("projectInfo", res.data));
      });
  };
};

export const setContractConfig = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract_config(proId, contractId)
      .then((res) => res.data)
      .then((res) => {
        dispatch(setData("contractConfig", res.data.data));
      });
  };
};

export const changeFilesList = (files) => ({
  type: constants.CHANGE_FILE_LIST,
  files,
});

export const initialFileList = (fileList) => {
  return {
    type: constants.INITIAL_FILE_LIST,
    fileList: fileList,
  };
};

export const getInitialVariationsItems = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract(proId, contractId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        if (res.variation_items !== undefined) {
          dispatch(initialList(res));
        } else {
          dispatch(emptyList());
        }
      });
  };
};

export const uploadAttachments = (proId, contractId, lineId, files) => {
  return (dispatch) => {
    if (files && files.size) {
      let promiseArr = [];
      files.forEach((item) => {
        let dataSend = new FormData();
        dataSend.append("attachments", item.get("file"));
        let config = {
          mimeType: "multipart/form-data",
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch(
              actionCreatorsFile.setUploadProgress(
                item.get("id"),
                percentageProgress,
              ),
            );
          },
        };

        var p1 = API.upload_one_file(
          proId,
          contractId,
          lineId,
          dataSend,
          config,
        );
        promiseArr.push(p1);
      });
      Promise.all(promiseArr)
        .then((resArr) => resArr.map((res) => res.data))
        .then((res) => {
          let fileList = [];
          for (var item of res) {
            for (var item1 of item.data.file) {
              let flag = true;
              for (var item2 of fileList) {
                if (item1.id === item2.id) {
                  flag = false;
                }
              }
              if (flag) {
                fileList.push(item1);
              }
            }
          }
          dispatch(actionCreatorsFile.clearFiles());
          dispatch(initialFileList(fileList));
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.data.errors);
          } else {
            console.log(error.message);
          }
        });
    }
  };
};

export const deleteAttachment = (proId, contractId, lineId, fileId) => {
  return (dispatch) => {
    API.delete_one_file(proId, contractId, lineId, fileId)
      .then((res) => res.data)
      .then((res) => res.data.file)
      .then((res) => {
        dispatch(initialFileList(res));
      });
  };
};

export const cancelAttachment = (name) => ({
  type: constants.CANCEL_ONE_FILE,
  fileName: name,
});

export const updateSingleCertVariationsItem = (
  proId,
  contractId,
  claimId,
  certId,
  contractType,
  lineId,
  extraInfo,
  callbackFunction,
) => {
  return (dispatch) => {
    API.update_one_lineitem(proId, contractId, lineId, extraInfo).then(
      (res) => {
        API.read_one_cert(proId, contractId, claimId, certId)
          .then((res) => res.data)
          .then((res) => res.data.data)
          .then((res) => {
            const lineItemDetails = res?.variation_items.find(
              (item) => item.id === lineId,
            );

            if (lineItemDetails) {
              dispatch(
                updateVariationLineItemInfo(lineId, { res, lineItemDetails }),
              );

              if (callbackFunction) {
                callbackFunction(lineItemDetails);
              }
            }
          });
      },
    );
  };
};

export const readContract = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract(proId, contractId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setValue("contractInfo", fromJS(res)));
        dispatch(setData("contractLinked", res.integration));
        dispatch(
          setData(
            "certifyPendingVariation",
            res.setting.certify_pending_variation,
          ),
        );
      });
  };
};

export const calculateCertSummary = (data) => {
  return (dispatch, getState) => {
    // Retrieve current state
    const oldSummary = getState()
      .getIn(["makeacert", "summaryComponent"])
      .toJS();
    const certToDate = oldSummary?.certToDate;
    const previous = oldSummary?.previous;
    const gst = getState().getIn(["makeacert", "projectlist", "gst"]);
    const domestic_reverse_charge = getState().getIn([
      "makeacert",
      "domestic_reverse_charge",
    ]);

    let thisData = {};
    let certToDateUpdate = {};

    // Extract values from data, with defaults
    const baseCertTotal = data?.baseCertTotal ?? 0;
    const variationCertTotal = data?.variationCertTotal ?? 0;
    const materialsTotal = data?.materialsTotal ?? {
      onSite: 0,
      offSite: 0,
      onSiteClaim: 0,
      offSiteClaim: 0,
    };
    const lessRetention = data?.lessRetention ?? 0;

    // Calculate certToDateGrossTotal
    let certToDateGrossTotal = calculateUnitToSubunit(
      baseCertTotal + variationCertTotal,
    );
    certToDateGrossTotal += materialsTotal.onSite + materialsTotal.offSite;

    // Update certToDateUpdate with calculated values
    certToDateUpdate = {
      ...certToDate,
      gross: certToDateGrossTotal,
      retention: calculateUnitToSubunit(lessRetention),
    };

    // Calculate updated values
    const [previousUpdatedData, thisUpdatedData, certToDateUpdatedData] =
      certSummaryCalculatedValues(
        certToDateUpdate,
        previous,
        thisData,
        gst,
        data?.cisStatus,
        domestic_reverse_charge,
      );

    // Dispatch updated summary component
    dispatch(
      setData("summaryComponent", {
        ...oldSummary,
        previous: previousUpdatedData,
        thisCert: thisUpdatedData,
        certToDate: certToDateUpdatedData,
      }),
    );
  };
};

export const updateCertSummary = (data, cisStatus) => ({
  type: constants.UPDATE_SUMMARY,
  data,
  cisStatus,
});

const getPreviousCertifiedSummary = (data, certId) => ({
  type: constants.SET_PREVIOUS_CERTIFIED,
  data: data,
  currentCertId: certId,
});
