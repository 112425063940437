import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import { ActionsButton, ActionsIconContainer } from "./styled";
import { ActionsContainer, Action } from "./components/actions_container";
import { checkIsParent } from "utils/helper";
import Dialog from "../../common/modal/component/certReasonDialog";
import {
  CONTRACT_TYPE,
  STATUS_APPROVED,
  STATUS_PREAPPROVED,
  CERT_VARIATION_ACTION_TYPES,
  LINEITEM_STATUS,
} from "utils/constants";
import CommentIcon from "common/comments_section/comment_icon";
import CommentList from "common/comments_section/table_comment_list";
import { VariationsCertStatusDisplay } from "common/table/shared_component";

const LineitemActions = (props) => {
  const {
    data,
    certInfo,
    claimInfo,
    type,
    expands,
    onTreeExpand,
    reasonInitValue,
    reasonOption,
    inputReason,
    accountConfig,
    showComment,
    showEdit,
    showAdd,
    handleExpand,
    handleEditExpand,
    tableField,
    updateStatus,
    viewOnly,
    allowEditContract,
    isEmptyMulti,
  } = props;
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [isVariError, setIsVariError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentStatus, setStatus] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const claimTerm = accountConfig.getIn(["claim"]);
  const timezone =
    type === CONTRACT_TYPE.cert
      ? certInfo?.get("contract_timezone")
      : claimInfo?.get("contract_timezone");
  const commentIconRef = useRef(null);
  const addIconRef = useRef(null);
  const editIconRef = useRef(null);
  const meatballRef = useRef(null);
  const isLinked = claimInfo?.get("integration")
    ? true
    : certInfo?.get("integration")
      ? true
      : false;

  const getCommentIcon = () => (
    <CommentIcon handleOnClick={() => handleCommentOnClick()} {...props} />
  );

  // expand tree if the line item is a parent and the tree is not expanded, otherwise open comments
  const handleCommentOnClick = () => {
    setDropdownOpen(false);
    if (checkIsParent(data) && !expands.includes(data?.id)) {
      onTreeExpand(data?.id);
    } else {
      setCommentsOpen(!commentsOpen);
    }
  };

  const handleCloseDialog = useCallback(
    (reason, otherReason) => {
      setOpenDialog(false);
      let finalReason = reason === "create_own_reason" ? otherReason : reason;
      // if we will have currentStatus then only we will fire updateStatus as we are updating default values based on status
      if (updateStatus && currentStatus && reason) {
        updateStatus(currentStatus, data.id, finalReason);
      }
    },
    [currentStatus, data.id, updateStatus],
  );

  useEffect(() => {
    setIsVariError(
      reasonInitValue?.reason_status ===
        CERT_VARIATION_ACTION_TYPES.REASON_ERROR
        ? true
        : false,
    );
  }, [reasonInitValue]);

  const handleShowVarianceReasonModal = useCallback((status) => {
    setStatus(status);
    setOpenDialog(true);
  }, []);

  // when its a cert, only want to show the edit icon and add icon for cert_vari and cert_vari children
  const isCertVariorVariChild = [
    LINEITEM_STATUS.CERT_VARI,
    LINEITEM_STATUS.CERT_VARI_CHILD,
  ].includes(data?.status);

  const canEdit =
    type !== CONTRACT_TYPE.claim ? isCertVariorVariChild && showEdit : showEdit;
  const canAdd =
    type !== CONTRACT_TYPE.claim ? isCertVariorVariChild && showAdd : showAdd;

  let commentIcon = getCommentIcon();

  return (
    <>
      {/* TODO Move VariationsCertStatusDisplay to pay_certificate_table.js, this is not the correct place */}
      {type === CONTRACT_TYPE.cert && (
        <VariationsCertStatusDisplay
          data={data}
          variTitle={tableField}
          updateStatus={updateStatus}
          allowViewContract={viewOnly}
          allowEditContract={allowEditContract}
          varianceData={reasonInitValue}
          showVarianceReasonModal={handleShowVarianceReasonModal}
          isLinked={isLinked}
          isEmptyMulti={isEmptyMulti}
        />
      )}
      {["variation", "variations"].includes(tableField) ? (
        <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
          <ActionsButton>
            <div
              className={`action-icon ${isVariError ? "vari-error" : ""} ${type !== CONTRACT_TYPE.claim ? "inline" : ""}`}
              onClick={(e) => {
                if (e.target === meatballRef.current) {
                  setDropdownOpen(!dropdownOpen);
                }
              }}
              ref={meatballRef}
            >
              <ActionsContainer open={dropdownOpen}>
                {canEdit && (
                  <Action
                    ref={editIconRef}
                    title="Edit"
                    handleClick={() => {
                      handleEditExpand(data, true);
                      setDropdownOpen(false);
                    }}
                    type="variance-edit"
                  />
                )}
                {canAdd && (
                  <Action
                    type="variance-add"
                    ref={addIconRef}
                    title="Add Item"
                    handleClick={() => {
                      handleExpand(data, true);
                      setDropdownOpen(false);
                    }}
                  />
                )}
                {(data.parent_id === "" ||
                  [STATUS_APPROVED, STATUS_PREAPPROVED].includes(
                    data.current_status,
                  )) &&
                  type !== CONTRACT_TYPE.claim && (
                    <Dialog
                      title={`Reason for Variance to Submitted ${claimTerm?.get(
                        "noun",
                      )}`}
                      lineType={reasonInitValue.reason_status}
                      width="758px"
                      height="479px"
                      initialValue={reasonInitValue}
                      inputReason={inputReason}
                      reasonOption={reasonOption}
                      data={data}
                      openModal={openDialog}
                      onClose={handleCloseDialog}
                      saveButtonText="Done"
                      closeDropdown={() => setDropdownOpen(false)}
                    />
                  )}
                {showComment && (
                  <Action
                    ref={commentIconRef}
                    type={CERT_VARIATION_ACTION_TYPES.COMMENT}
                    title="Comment"
                    handleClick={() => handleCommentOnClick()}
                    customIcon={commentIcon}
                  />
                )}
              </ActionsContainer>
            </div>
            <div className="comments-anchor">
              <CommentList
                open={commentsOpen}
                setOpen={setCommentsOpen}
                isIconClicked={(e) =>
                  commentIconRef.current?.contains(e.target) || false
                }
                timezone={timezone}
                {...props}
              />
            </div>
          </ActionsButton>
        </ClickAwayListener>
      ) : (
        <ActionsIconContainer>
          {type !== CONTRACT_TYPE.claim && (
            <Dialog
              title={`Reason for Variance to Submitted ${claimTerm?.get("noun")}`}
              width="758px"
              height="479px"
              initialValue={reasonInitValue}
              inputReason={inputReason}
              reasonOption={reasonOption}
              data={data}
              saveButtonText="Done"
            />
          )}
          {showComment && (
            <div className="comment-section">
              <CommentIcon
                ref={commentIconRef}
                handleOnClick={() => handleCommentOnClick()}
                {...props}
              />
              <div className="comments-anchor">
                <CommentList
                  open={commentsOpen}
                  setOpen={setCommentsOpen}
                  isIconClicked={(e) => {
                    let check =
                      commentIconRef.current &&
                      commentIconRef.current.contains(e.target)
                        ? true
                        : false;
                    return check;
                  }}
                  timezone={timezone}
                  {...props}
                />
              </div>
            </div>
          )}
        </ActionsIconContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.getIn(["headers", "profile"]),
  certInfo: state.getIn(["makeacert", "projectlist"]),
  claimInfo: state.getIn(["makeaclaim", "contractInfo"]),
});

export default connect(mapStateToProps, null)(LineitemActions);
