import React, { useCallback, useMemo } from "react";

import {
  VarDetailSection,
  FileWrapper,
  FileDetail,
  ImgClose,
  VariationTotalContainer,
  VariationCertStatusButtonContainer,
} from "./styled";
import { FormGroup, VariationStatusWrap } from "../form/styled";
import BootstrapTooltip from "common/toolTip";
import Input from "../form";
import { IconDropdown } from "../dropdown/icon_dropdown";
import Date from "../datePicker";
import { bytesToSize, checkIsParent } from "../../utils/helper";
import {
  ExcludeRetentionBox,
  getVariationStatus,
} from "../lineitem/sharedComponent";
import History from "./log_history";
import { VARIATION_LINEITEM_STATUS, CONTRACT_TYPE } from "utils/constants";
import VariationStatusButton from "pages/makeclaim/shared/variationStatusButton";
export const BaseDetails = ({
  data,
  downloadFile,
  proId,
  contractId,
  readOnlyExpandKeys,
  handleClose,
  hasRetention,
  timeZone,
}) => {
  return (
    <VarDetailSection>
      {handleClose ? <ImgClose onClick={handleClose} /> : null}
      <FormGroup>
        <History
          data={data}
          proId={proId}
          contractId={contractId}
          timeZone={timeZone}
        />
        <div className="form_body">
          <Input field="child" label="Base Contract Attachments">
            <FileWrapper
              className={
                readOnlyExpandKeys ? "viewDetail expanded" : "viewDetail"
              }
            >
              {data.uploaded_files !== null ? (
                data.uploaded_files.map((item) => {
                  return (
                    <li key={item.id}>
                      <FileDetail>
                        <div className="fileImg attachment" />
                        <div className="fileName">{item.file_name}</div>
                        <div className="size">
                          ({bytesToSize(item.file_size)})
                        </div>
                        {/* if file is a pdf, use the icon dropdown */}
                        {item.file_name.includes("pdf") ? (
                          <div className="delFileImg noBackground">
                            <IconDropdown
                              options={[
                                {
                                  label: "View file in new tab",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      true,
                                    ),
                                },
                                {
                                  label: "Save file to your device",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      false,
                                    ),
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          <div
                            className="delFileImg downloadFileImg"
                            value={item.file_name}
                            onClick={() =>
                              downloadFile(
                                proId,
                                contractId,
                                item.id,
                                item.file_name,
                              )
                            }
                          />
                        )}
                      </FileDetail>
                    </li>
                  );
                })
              ) : (
                <FileDetail>
                  <div className="fileName no-file">No files</div>
                </FileDetail>
              )}
            </FileWrapper>
            {!checkIsParent(data) && hasRetention && (
              <ExcludeRetentionBox
                className={"has-margin"}
                checked={data.exclude_retention}
                disabled={true}
              />
            )}
          </Input>
          <Input
            disabled
            label="Detailed Description"
            name="detailed_description"
            value={data.detailed_description || "No Description"}
            width={readOnlyExpandKeys ? "300px" : "600px"}
            field="textarea"
          />
        </div>
      </FormGroup>
    </VarDetailSection>
  );
};
export const VariationDetails = ({
  data,
  downloadFile,
  proId,
  contractId,
  readOnlyExpandKeys,
  handleClose,
  hasRetention,
  timeZone,
}) => {
  let variationStatus;
  if (!data?.parent_id) {
    variationStatus = getVariationStatus(data);
  }

  return (
    <VarDetailSection>
      {handleClose ? <ImgClose onClick={handleClose} /> : null}
      <FormGroup>
        <div className="overview-vari">
          {variationStatus && (
            <VariationStatusWrap>
              <VariationStatusButton
                expandedButtonByDefault
                status={variationStatus}
              />
            </VariationStatusWrap>
          )}
          <History
            data={data}
            proId={proId}
            contractId={contractId}
            timeZone={timeZone}
            noMargin
          />
        </div>
        <div className="form_body">
          <Input label="Submit Date" field="child">
            <Date
              disable={true}
              initialValue={data.submit_date}
              id="submit_date"
              width={readOnlyExpandKeys ? "250px" : "350px"}
            />
          </Input>
          <Input label="Approved Date" field="child">
            <Date
              disable={true}
              initialValue={data.approved_date}
              id="approved_date"
              width={readOnlyExpandKeys ? "250px" : "350px"}
            />
          </Input>
          <Input
            disabled
            label="Variation Reference"
            name="variation_reference"
            value={data.variation_reference}
            width={readOnlyExpandKeys ? "250px" : "387px"}
          />
        </div>
        <div className="form_body" style={{ marginTop: "40px" }}>
          <Input field="child" label="Variation Attachments">
            <FileWrapper
              className={
                readOnlyExpandKeys ? "viewDetail expanded" : "viewDetail"
              }
            >
              {data.uploaded_files !== null ? (
                data.uploaded_files.map((item) => {
                  return (
                    <li key={item.id}>
                      <FileDetail>
                        <div className="fileImg attachment" />
                        <div className="fileName">{item.file_name}</div>
                        <div className="size">
                          ({bytesToSize(item.file_size)})
                        </div>
                        {item.file_name.includes("pdf") ? (
                          <div className="delFileImg noBackground">
                            <IconDropdown
                              options={[
                                {
                                  label: "View file in new tab",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      true,
                                    ),
                                },
                                {
                                  label: "Save file to your device",
                                  value: () =>
                                    downloadFile(
                                      proId,
                                      contractId,
                                      item.id,
                                      item.file_name,
                                      false,
                                    ),
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          <div
                            className="delFileImg downloadFileImg"
                            value={item.file_name}
                            onClick={() =>
                              downloadFile(
                                proId,
                                contractId,
                                item.id,
                                item.file_name,
                              )
                            }
                          />
                        )}
                      </FileDetail>
                    </li>
                  );
                })
              ) : (
                <FileDetail>
                  <div className="fileName no-file">No files</div>
                </FileDetail>
              )}
            </FileWrapper>
            {!checkIsParent(data) && hasRetention && (
              <ExcludeRetentionBox
                className={"has-margin"}
                checked={data.exclude_retention}
                disabled={true}
              />
            )}
          </Input>
          <Input
            disabled
            label="Detailed Description"
            name="detailed_description"
            value={data.detailed_description}
            width={readOnlyExpandKeys ? "300px" : "600px"}
            field="textarea"
          />
        </div>
      </FormGroup>
    </VarDetailSection>
  );
};

/**
 * This component applies special formatting and displays the Total value based on the variation status,
 * depending on the status and the level (contract or claim/cert).
 *
 * Used At:
 * - Contract Level:
 *   - Contract Variations Table
 *   - Contract Overview Variations Table
 *   - Contract Overview Variations Claims Table
 * - Claim/Cert Level:
 *   - Cert Variations Table (pay_certificate_table)
 *   - Claim Variations Table (claim_percent_table)
 */
export const VariationsTotalDisplay = (props) => {
  const { data, variTitle, formattedTotal, isContractLevel } = props;

  // Check if the Title is "variation" or "variations"
  const isVariationField = ["variation", "variations"].includes(
    variTitle.toLowerCase(),
  );

  let toolTipTitle = isContractLevel
    ? "This value is not included in the total variations"
    : "This value is not included in the subTotal";

  // The list of common statuses for which special formatting is applied to the Total value.
  // These statuses are used for formatting based on the following rules:
  // - At the contract level, format the Total value in italics and underlined for:
  //   - PENDING
  //   - REJECTED
  // - At the cert/claim level, format the Total value differently for:
  //   - PENDING
  //   - REJECTED
  //
  // Exceptions to this common list are added based on the level of context:
  // - At the contract level,and the cert/claim level, the default statuses are used without additional exceptions.
  const status_List = [
    VARIATION_LINEITEM_STATUS.PENDING,
    VARIATION_LINEITEM_STATUS.REJECTED,
  ];

  if (isVariationField && status_List.includes(data.current_status)) {
    return (
      <BootstrapTooltip
        title={toolTipTitle}
        placement="bottom"
        padding="12px 12px"
      >
        <VariationTotalContainer>{formattedTotal}</VariationTotalContainer>
      </BootstrapTooltip>
    );
  }
  return formattedTotal;
};

// Cert Variations Status Button Display Component
export const VariationsCertStatusDisplay = ({
  data,
  variTitle,
  updateStatus,
  allowViewContract = false,
  allowEditContract = false,
  varianceData,
  showVarianceReasonModal,
  isLinked = false,
  isEmptyMulti = false,
}) => {
  const isVariationField = ["variation", "variations"].includes(
    variTitle.toLowerCase(),
  );

  let variationStatus = data.parent_id ? undefined : data.current_status;

  const handleSetStatus = useCallback(
    (val, reason) => {
      const status = val;
      if (
        status === VARIATION_LINEITEM_STATUS.REJECTED &&
        varianceData?.reason_status === "variance-error"
      ) {
        showVarianceReasonModal(status);
      } else {
        updateStatus(status, data.id, reason || varianceData?.reason);
      }
    },
    [updateStatus, data.id, varianceData, showVarianceReasonModal],
  );

  const isEditable = !allowViewContract || allowEditContract;
  const statusesToShow = useMemo(
    () =>
      new Set([
        VARIATION_LINEITEM_STATUS.PENDING,
        VARIATION_LINEITEM_STATUS.REJECTED,
      ]),
    [],
  );

  if (isEmptyMulti) {
    return null;
  }

  if (
    !isVariationField ||
    data.parent_id ||
    !statusesToShow.has(variationStatus)
  ) {
    return null;
  }

  // finalStatus to set undefined if no updateStatus exists
  // - if `updateStatus` exists
  // - and if is not linked (`!isLinked`)
  // - and if editable (`isEditable`)
  // - else set `finalSetStatus` to undefined
  const finalSetStatus =
    updateStatus && !isLinked && isEditable ? handleSetStatus : undefined;

  return (
    <VariationCertStatusButtonContainer className="inline">
      <VariationStatusButton
        status={variationStatus}
        setStatus={finalSetStatus}
        isVarianceRequired={data.claim_value !== data.cert_value}
      />
    </VariationCertStatusButtonContainer>
  );
};

// Claim Variations Status Button Display Component
export const VariationsClaimStatusDisplay = (props) => {
  const {
    data,
    contractType,
    variTitle,
    updateStatus,
    allowViewContract = false,
    allowEditContract = false,
    isLinked = false,
    isExpandedForm = false,
    isEmptyMulti = false,
  } = props;

  if (isEmptyMulti) {
    return null;
  }

  // Check if the Title is "variation" or "variations"
  const isVariationField = ["variation", "variations"].includes(
    variTitle.toLowerCase(),
  );

  if (!contractType || !isVariationField) return null; // Exit early if not a variation field or contractType is undefined

  const variationStatus =
    isVariationField && data.parent_id ? undefined : data.current_status;

  // Handle the status change, mapping statuses for different conditions
  const handleSetStatus = (status, lineitemId, reason) => {
    const finalStatus = status;
    updateStatus && updateStatus(lineitemId, finalStatus, reason);
  };

  // Check if the contract type is a claim
  const isClaimContract = contractType === CONTRACT_TYPE.claim;

  // if the current status is 'REJECTED'then Can Revert To Pending
  const canRevertToPending =
    variationStatus === VARIATION_LINEITEM_STATUS.REJECTED;

  // Set status based on edit is allowed or not
  const canSetStatus = !allowViewContract || allowEditContract;

  const renderVariationStatusButton = () => {
    // Conditional status handler
    const setStatusHandler = canSetStatus
      ? (status, reason) => handleSetStatus(status, data.id, reason)
      : undefined;

    // - if `updateStatus` exists and is not linked (`!isLinked`),
    // - then set `setStatusHandler` to `finalSetStatus`.
    // - else, set `finalSetStatus` to undefined.
    const finalSetStatus =
      updateStatus && !isLinked ? setStatusHandler : undefined;

    return (
      <div
        className={
          isExpandedForm ? "variation-placeholder" : "action_container"
        }
      >
        {variationStatus === VARIATION_LINEITEM_STATUS.REJECTED && (
          <VariationStatusButton
            expandedButtonByDefault={isExpandedForm}
            status={variationStatus}
            onlyRevertToPending={isClaimContract && canRevertToPending}
            setStatus={finalSetStatus}
          />
        )}
        {variationStatus === VARIATION_LINEITEM_STATUS.PENDING && (
          <VariationStatusButton
            expandedButtonByDefault={isExpandedForm}
            status={variationStatus}
            setStatus={isClaimContract ? undefined : finalSetStatus}
          />
        )}
      </div>
    );
  };

  return renderVariationStatusButton();
};
