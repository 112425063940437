export const CHANGE_FILE_LIST =
  "create-new-contract/variation/CHANGE_FILE_LIST";
export const CANCEL_ONE_FILE = "create-new-contract/variation/CANCEL_ONE_FILE";
export const INITIAL_ITEM_LIST =
  "create-new-contract/variation/INITIAL_ITEM_LIST";
export const ADD_NEW_ROW_TO_TABLE =
  "create-new-contract/variation/ADD_NEW_ROW_TO_TABLE";
export const RESET = "create-new-contract/variation/RESET";
export const EMPTY_ITEM_LIST = "create-new-contract/variation/EMPTY_ITEM_LIST";
export const INITIAL_FILE_LIST =
  "create-new-contract/variation/INITIAL_FILE_LIST";
export const UPDATE_VARIATION_LINEITEM =
  "create-new-contract/variation/UPDATE_VARIATION_LINEITEM";
export const LOADING_STATUS_CHANGE =
  "create-new-contract/variation/LOADING_STATUS_CHANGE";
export const SET_VALUE = "create-new-contract/variation/SET_VALUE";
