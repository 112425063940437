import React, { useState, Suspense, lazy, useRef } from "react";
import { connect } from "react-redux";
import { ModalContentScroll, ButtonsContainer } from "./styled";
import Modal from "common/modal/component/modalBase";
import HistoryButton from "common/audit_logs/component/historyButton";
import Loading from "common/loading";

import { DownloadDark as DownloadBtn } from "common/button";
import { actionCreators } from "../../pages/contract/store";

const LazyHistory = lazy(() => import("../audit_logs/index.js"));

const HistoryModal = ({
  open,
  closeDialog,
  item,
  proId,
  contractId,
  timeZone,
  downloadHistory,
  contractInfo,
}) => {
  const scrollContainerRef = useRef(null);

  return (
    <Modal
      open={open}
      handleClose={closeDialog}
      title={`${item?.label} - ${item?.description}`}
      width="960px"
      divider={false}
      scroll={"body"}
    >
      <ModalContentScroll ref={scrollContainerRef}>
        <ButtonsContainer>
          {/* Exporting the Logs */}
          <DownloadBtn
            title="Export"
            onClick={() =>
              downloadHistory(
                proId,
                contractId,
                item.id,
                contractInfo?.get("name"),
              )
            }
          />
        </ButtonsContainer>
        <Suspense fallback={<Loading message={"Loading Page..."} />}>
          <LazyHistory
            fetchDataApi={"read_contract_lineitem_history"}
            timeZone={timeZone}
            proId={proId}
            contractId={contractId}
            lineId={item.id}
            isModal={true}
            scrollContainerRef={scrollContainerRef}
          />
        </Suspense>
      </ModalContentScroll>
      <div></div>
    </Modal>
  );
};

function HistoryComponent(props) {
  const { data, noMargin } = props;
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <HistoryButton
        hoverMsg="Lineitem History"
        action={() => setOpenModal(true)}
        noMargin={noMargin}
      />
      <HistoryModal
        open={openModal}
        closeDialog={() => setOpenModal(false)}
        item={data}
        {...props}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  contractInfo: state.getIn(["contract", "contractInfo"]),
});

const mapDispatchToProps = (dispatch) => ({
  downloadHistory(proId, contractId, lineId, name) {
    dispatch(
      actionCreators.downloadContractLineitemHistory(
        proId,
        contractId,
        lineId,
        name,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryComponent);
