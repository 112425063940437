import { httpRequest } from "../base/httpRequest";

const CERT_URL = `/api/paycert/account/accId`;
const BASE_URL = `/api/cert/account/accId/contract_group`;

// * read/update a cert
const create_one_cert = (proId, contractId, claimId, data) =>
  httpRequest(
    "post",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert`,
    data,
  );

const approve_one_cert = (claimId, certId, contractId, proId) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/issue`,
  );

const approve_one_cert_integration = (claimId, certId, contractId, proId) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/integration/issue`,
  );
// send paycert email when issue
const send_paycert_email = (proId, claimId, certId, isSend, contractId, data) =>
  httpRequest(
    "post",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/email?has_email_service=${isSend}`,
    data,
  );
// using new update cert
const update_one_cert = (proId, contractId, claimId, certId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}`,
    data,
  );

const download_cert_pdf = (proId, contractId, claimId, certId, config) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/pdf`,
    null,
    config,
  ); //blob

const read_empty_cert = (proId, contractId, claimId) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert`,
  );

const read_one_cert = (proId, contractId, claimId) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert`,
  );

const update_cert_lineitem_info = (
  proId,
  contractId,
  claim_id,
  certId,
  lineitemId,
  data,
) =>
  httpRequest(
    "patch",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claim_id}/cert/${certId}/lineitem/${lineitemId}`,
    data,
  );

const read_cert_retention = (claimId, data) =>
  httpRequest("post", `${CERT_URL}/claim/${claimId}/retention`, data); //data

// * claimsReceived table
const read_all_claims_received = (params) =>
  httpRequest("get", `${CERT_URL}/cert/list?${params}`); //certlist;
const read_all_claims_received_multi = (data) =>
  httpRequest("post", `/api/paycert/dashboard/cert/list`, data); //certlist;

// * read one invitation project
const read_all_cert = (contractId) =>
  httpRequest("get", `${CERT_URL}/contract/${contractId}/cert/history`); //result
const read_cert_contract_summary = (contractId) =>
  httpRequest("get", `${CERT_URL}/contract/${contractId}/summary`); //result

// * dashboard cert summary
const read_cert_summary = (data, params) =>
  httpRequest("post", `${CERT_URL}/cert/summary?${params}`, data); //claimsummary
const read_cert_summary_multi = (data) =>
  httpRequest("post", `/api/paycert/dashboard/cert/summary`, data); //claimsummary;

// * upload file/attachment
const upload_file_contract_cert = (contractId, certId, data, config) =>
  httpRequest(
    "post",
    `${CERT_URL}/contract/${contractId}/cert/${certId}/upload`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const delete_file_contract_cert = (contractId, fileId) =>
  httpRequest(
    "delete",
    `${CERT_URL}/contract/${contractId}/doc/${fileId}/delete`,
  );

const update_cert_shared_attachment = (contractId, cert_id, data) =>
  httpRequest(
    "post",
    `${CERT_URL}/contract/${contractId}/cert/${cert_id}/shared`,
    data,
  );

// * contract group
const read_project_groups = () =>
  httpRequest("get", `${CERT_URL}/contract_group`);
const create_project_group = (data) =>
  httpRequest("post", `${CERT_URL}/contract_group`, data);
const update_project_group = (contractGroupId, data) =>
  httpRequest("put", `${CERT_URL}/contract_group/${contractGroupId}`, data);
const delete_project_group = (contractGroupId) =>
  httpRequest("delete", `${CERT_URL}/contract_group/${contractGroupId}`);

//#TODO current
const download_lineitem_file_contract = (contractId, fileId, config) =>
  httpRequest(
    "get",
    `${CERT_URL}/contract/${contractId}/file/${fileId}/shared`,
    null,
    config,
  );

// * new apis
// * workflow/approver
const read_cert_workflow = (proId, contractId, claimId, certId) =>
  httpRequest(
    "get",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/workflow`,
  ); //data

const update_cert_workflow = (proId, contractId, claimId, certId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/workflow`,
    data,
  ); //workflow

const approve_cert_step = (proId, contractId, claimId, certId, stepId, data) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/workflow/${stepId}/approve`,
    data,
  );

const revert_cert_workflow = (
  proId,
  contractId,
  claimId,
  certId,
  stepId,
  data,
) =>
  httpRequest(
    "put",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/workflow/${stepId}/revert`,
    data,
  ); //data

// * cert patch update
const update_cert_info = (proId, contractId, claimId, certId, data) =>
  httpRequest(
    "patch",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}`,
    data,
  );

// * cert variations routes
const add_cert_item = (
  proId,
  contractId,
  claimId,
  certId,
  data,
  lineItemType,
  config,
) =>
  httpRequest(
    "POST",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/item?type=${lineItemType}`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const delete_cert_item = (proId, contractId, claimId, certId, lineId) =>
  httpRequest(
    "DELETE",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/lineitem/${lineId}`,
  );

const update_cert_item = (proId, contractId, claimId, certId, lineId, data) =>
  httpRequest(
    "PATCH",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/lineitem/${lineId}`,
    data,
  );

const handle_pending_cert = (proId, contractId, claimId, certId, data) =>
  httpRequest(
    "PUT",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/pending?isNew=${data}`,
  );

const create_one_cert_lineitem = (
  proId,
  contractId,
  claimId,
  certId,
  config,
  data,
) =>
  httpRequest(
    "post",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/item`,
    data,
    config,
    { "Content-Type": "multipart/form-data" },
  );

const delete_one_cert_lineitem = (
  proId,
  contractId,
  claimId,
  certId,
  lineItemId,
) =>
  httpRequest(
    "delete",
    `${BASE_URL}/${proId}/contract/${contractId}/claim/${claimId}/cert/${certId}/lineitem/${lineItemId}`,
  );

export default {
  // * contract group
  read_project_groups,
  create_project_group,
  update_project_group,
  delete_project_group,
  // *  contract summary
  read_cert_contract_summary,
  upload_file_contract_cert,
  // * claims received
  read_all_claims_received,
  read_all_claims_received_multi,
  read_cert_summary,
  read_cert_summary_multi,
  download_lineitem_file_contract,
  // * send email
  send_paycert_email,
  // *  make a cert
  read_all_cert,
  read_empty_cert,
  read_one_cert,
  read_cert_retention,
  create_one_cert,
  update_one_cert,
  approve_one_cert,
  approve_one_cert_integration,

  delete_file_contract_cert,
  // * update lineitem comment
  update_cert_shared_attachment,
  download_cert_pdf,
  //update cert lineitem
  update_cert_lineitem_info,
  // * new cert api
  // * cert variations/material routes
  update_cert_info,
  add_cert_item,
  delete_cert_item,
  update_cert_item,
  handle_pending_cert,
  read_cert_workflow,
  update_cert_workflow,
  approve_cert_step,
  revert_cert_workflow,

  create_one_cert_lineitem,
  delete_one_cert_lineitem,
};
