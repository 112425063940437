import styled from "styled-components";

export const VariationTypeOptionInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 35vw;
  .label {
    font-size: 16px;
  }
  .description {
    font-size: 14px;
  }
`;
