import styled from "styled-components";
import * as theme from "../../../theme";
import { IconButton } from "../../button";
import addReasonIcon from "../../../statics/img/icon-add-reason.svg";
import addRequiredReasonIcon from "../../../statics/img/icon-add-reason-red.svg";
import supFormIcon from "../../../statics/img/icon-mail-dark-grey.svg";
import trialIcon from "../../../statics/img/trial-ending.svg";
import trialIconOver from "../../../statics/img/trial-over.svg";
import successIcon from "../../../statics/img/success-yes-green.svg";
import errorIcon from "../../../statics/img/error-no-red.svg";
import noticeIcon from "../../../statics/img/icon-information-red.svg";
import clearFileIcon from "../../../statics/img/icon-clear-medium-grey.svg";
import IconRadioOff from "statics/img/icon-radio-off.svg";
import IconRadioOn from "statics/img/icon-radio-on.svg";

export const Icon = styled.div`
  height: 30px;
  width: 30px;
  background: url(${(props) =>
      props.type === "confirm"
        ? successIcon
        : props.type === "warning"
          ? noticeIcon
          : errorIcon})
    no-repeat;
  margin-right: 16px;
  float: left;
  background-size: contain;
`;
export const ImgClose = styled(IconButton)`
  background: url(${clearFileIcon}) no-repeat;
  position: absolute;
  top: 12px;
  right: 12px;
`;
export const CloseIcon = styled(ImgClose)`
  background: url(${clearFileIcon}) no-repeat;
  position: absolute;
  right: 40px;
  top: 40px;
  margin: 8px 0;
`;

export const Container = styled.div`
  .class-cropper-modal {
    position: fixed;
    background-color: rgba(#000000, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-panel {
      width: 880px;
      height: auto;
      background: white;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);

      .button-row {
        margin-top: 40px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
      }

      .cropper-container-container {
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 100%;
        margin-left: 20px;

        .cropper-container {
          flex: 0 0 600px;
          margin-right: 20px;

          .cropper {
            width: 100%;
            height: 100%;
          }
        }

        .preview-container {
          flex: 1;
          display: flex;
          align-items: flex-end;

          .cropper-preview {
            width: 180px;
            height: 180px;
            overflow: hidden;
            border: 1px solid ${theme.palette.gunmetal};
          }
        }
      }
    }
  }
`;

export const ReasonIcon = styled(IconButton)`
  background: url(${addReasonIcon}) no-repeat;
  &.warning {
    background: url(${addRequiredReasonIcon}) no-repeat;
  }
`;
export const ReasonContainer = styled.div`
  height: 360px;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: ${theme.palette.gunmetal};
  .reason {
    height: 185px;
    width: 678px;
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.wolfGrey};
    font-size: 14px;
    color: ${theme.palette.gunmetal};
    font-weight: ${theme.font.normalFontWeight};
    padding: 15px;
    resize: none;
    box-sizing: border-box;
    margin-top: 16px;
  }
  .warning_text {
    color: ${theme.palette.watermelonRed};
    margin-top: 8px;
    display: flex;
    align-items: center;
    .warning {
      width: 24px;
      height: 24px;
      background: url(${noticeIcon});
      margin-right: 8px;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HubSpotContainer = styled.div`
  text-align: left;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: space-between;
  align-items: center;

  .c-help-hs__submit,
  .hs-submit {
    visibility: hidden;
    height: 0 !important;
  }

  .c-help-hs__loading {
    justify-content: center;
    display: flex;
    flex: 1 1 auto;
    margin: 2em;
    height: 120px;
  }

  .hs_recaptcha {
    justify-content: center;
    display: flex;
    margin: 25px 0;
  }

  .hs-error-msgs {
    list-style-type: none;
    margin-top: 2px;
    label,
    .hs-error-msg {
      font-size: 12px;
      color: ${theme.palette.watermelonRed};
      font-weight: normal;
    }
  }

  fieldset:nth-child(1) {
    display: none;
  }

  fieldset[class*="form-columns-"] {
    .hs-form-field {
      margin-top: 25px;
    }
    .hs-form-required {
      color: ${theme.palette.watermelonRed};
      margin-left: 5px;
    }

    label {
      font-size: 16px;
      font-weight: bold;
    }

    img {
      width: 84px;
    }

    .hs-input {
      width: 100% !important;
      height: 44px;
      border: solid 1px ${theme.palette.gunmetal};
      background-color: ${theme.palette.white};
      margin-top: 8px;
      font-size: 16px;
      padding: 10px;
    }

    textarea {
      height: 135px !important;
      resize: none;
      overflow-y: auto;
    }
  }
`;

export const MailIcon = styled.div`
  width: 30px;
  height: 30px;
  margin: 0 16px 0 0;
  background: url(${supFormIcon}) no-repeat;
  background-size: contain;
  float: left;
`;

export const TrialIcon = styled.div`
  background: url(${trialIcon}) no-repeat;
  height: 100px;
  width: 100px;
  &.over {
    background: url(${trialIconOver}) no-repeat;
  }
`;
export const TrialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 280px;
  justify-content: space-around;
  .title {
    font-size: 26px;
    color: ${theme.palette.gunmetal};
  }
  .content {
    width: 400px;
    .link {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

export const RadioButtonOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(props) => props.borderColor || theme.palette.wolfGrey};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  padding: 24px;
  gap: 16px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  input[type="radio"] {
    appearance: none;
    margin: 0;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background-image: url(${IconRadioOff});
    background-repeat: no-repeat;
    background-size: contain;
    &:checked {
      cursor: default;
      background-image: url(${IconRadioOn});
    }
  }
`;

export const SelectionStepBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 40px;
`;

export const RejectionModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 40px;
  width: 700px;
  max-height: 500px;
  .reason-field {
    height: 200px;
  }
`;
