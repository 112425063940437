import React from "react";
import VariationRejectionDialog from "../../../common/modal/component/variationRejectionDialog";

/**
 * Modal component for handling reason for rejection of a variation.
 *
 * @param {object} props
 * @param {() => void} props.handleClose - Callback function to be called when the modal is closed.
 * @param {(reason: string) => void} props.handleSave - Callback function to be called when the Save button is clicked, passing the reason as an argument.
 * @param {string} props.rejectionReason - Current value of the rejection reason.
 * @param {function} props.setRejectionReason - Function to update the rejection reason state.
 *
 * @returns {React.JSX.Element} The modal component for rejection reason input.
 *
 * @example
 * <ReasonForRejectionModal
 *  handleSave={(reason) => console.log(reason)}
 *  handleClose={() => console.log("closed")}
 *  rejectionReason={reason}
 *  setRejectionReason={setReason}
 * />
 */
export default function ReasonForRejectionModal({
  handleSave,
  handleClose,
  rejectionReason,
  setRejectionReason,
}) {
  // Determine if the Save button should be enabled based on whether the rejection reason is non-empty
  const isButtonEnabled = rejectionReason.trim().length > 0;

  // Handle textarea change and update the rejection reason state
  const handleTextareaChange = (value) => {
    setRejectionReason(value);
  };

  return (
    <VariationRejectionDialog
      title="Reason for Rejecting Variation"
      handleSave={handleSave}
      handleClose={handleClose}
      textareaValue={rejectionReason}
      onTextareaChange={handleTextareaChange}
      isButtonEnabled={isButtonEnabled}
    />
  );
}
