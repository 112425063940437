import React, { useEffect, useState } from "react";

import LineItems from "./lineItem";
import { fromJS } from "immutable";

import { SettingWrapper, ContractDetailWrapper } from "../styled";
import { checkIsParent } from "../../../utils/helper";
import { VARIATION_LINEITEM_STATUS } from "../../../utils/constants";

function convertToDesiredFormat(input, path = "") {
  const result = [];
  input.forEach((item) => {
    const newItem = item;
    if (path) {
      if (checkIsParent(item)) {
        newItem.subtitle = path + " / " + item.description;
      } else {
        newItem.subtitle = path;
      }
    }
    result.push(newItem);

    if (checkIsParent(item)) {
      result.push(
        ...convertToDesiredFormat(
          item.childitems,
          newItem.subtitle || item.description,
        ),
      );
    }
  });
  return result;
}

export default function Provisional(props) {
  const {
    isAdmin,
    setNavExpanded,
    contractInfo,
    hasExpand,
    updateHaveProvisionalItems,
  } = props;

  const [isProvisionalExpanded, setIsProvisionalExpanded] = useState(false);

  const setIntermediateExpanded = (value) => {
    setIsProvisionalExpanded(value);
    setNavExpanded(value);
  };

  const [sortState, setSortState] = useState({
    sortField: "created_date",
    sortSep: "asc",
  });
  let baseItems = [];
  let variationItems = [];

  if (contractInfo?.get("baseitems") !== undefined) {
    baseItems = convertToDesiredFormat(
      contractInfo?.get("baseitems").toJS(),
    ).filter((obj) => obj.is_provisional);
  }

  if (contractInfo?.get("variation_items") !== undefined) {
    variationItems = convertToDesiredFormat(
      contractInfo?.get("variation_items").toJS(),
    ).filter(
      (obj) =>
        obj.is_provisional &&
        obj.current_status === VARIATION_LINEITEM_STATUS.APPROVED,
    );
  }

  const haveProvisionalItems =
    baseItems.length > 0 || variationItems.length > 0;

  // Update haveProvisionalItems state only once after the render
  useEffect(() => {
    updateHaveProvisionalItems(haveProvisionalItems);
  }, [haveProvisionalItems, updateHaveProvisionalItems]);

  if (!haveProvisionalItems) {
    return <></>;
  }
  return (
    <ContractDetailWrapper
      className={
        "setting-page " + (isProvisionalExpanded ? "expand-table" : "")
      }
      id="nav-provisional"
    >
      <SettingWrapper>
        {baseItems.length > 0 && (
          <LineItems
            isAdmin={isAdmin}
            itemsType={"baseContract"}
            setExpanded={setIntermediateExpanded}
            noOuterWrapper={true}
            tableContent={fromJS(baseItems)}
            tableTitle="Provisional Sums"
            tableSubtitle="Base Contract"
            hasTableSubtitle
            isProvisional={true}
            externalSortOptions={{}}
            hasSort={true}
            {...props}
            readOnly={true}
            hasExpand={hasExpand}
            componentTotal={baseItems.reduce(
              (sum, item) => sum + item.total,
              0,
            )}
            setSortState={setSortState}
          />
        )}

        {variationItems.length > 0 && (
          <LineItems
            isAdmin={isAdmin}
            itemsType={"variations"}
            setExpanded={setIntermediateExpanded}
            noOuterWrapper={true}
            tableContent={fromJS(variationItems)}
            tableTitle={baseItems.length > 0 ? "" : "Provisional Sums"}
            tableSubtitle="Variations"
            hasTableSubtitle
            isProvisional={true}
            hasSort={baseItems.length > 0 ? false : true}
            {...props}
            readOnly={true}
            hasExpand={baseItems.length > 0 ? false : hasExpand}
            componentTotal={variationItems.reduce(
              (sum, item) => sum + item.total,
              0,
            )}
            sortState={sortState}
            isExpanded={isProvisionalExpanded}
          />
        )}
      </SettingWrapper>
    </ContractDetailWrapper>
  );
}
