import React, { useEffect, useState } from "react";
import * as theme from "../../theme";
import { AttachLeft, InputTableWrapper } from "./styled";
import { FormGroup } from "../form/styled";
import { DeleteBtn, AddGreenBtn, GreenWhiteBtn as SaveBtn } from "../button";
import Input from "../form";
import FileUpload from "./uploadItemFile";
import { LUMP, RATE } from "../../utils/constants";
import { usePrevious } from "./sharedComponent";

export const LineItemForm = ({ onChange, form, field }) => {
  return (
    <div className="form_body">
      <Input
        label="Ref"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name="label"
        value={form.label}
        width="88px"
      />
      <Input
        label="Description"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name="description"
        value={form.description}
        width={field === "dialog" ? "890px" : "920px"}
      />
    </div>
  );
};
const InputTable = (props) => {
  const {
    title,
    tableList,
    handleCleanItem,
    handleSaveItem,
    status,
    type,
    action,
    isBlocked,
    canDelete,
    accountConfig,
    previousLineType, // line type of latest added line item
    setEditFiles,
    field,
  } = props;
  const [lineType, setLineType] = useState(RATE);
  const prevLineType = usePrevious(lineType); // previous value of lineType
  const [formInfo, setFormInfo] = useState({
    label: "",
    description: "",
    unit: "",
    quantity: 0,
    rate: 0,
    total: 0,
    detailed_description: "",
    is_provisional: false,
  });

  useEffect(() => {
    if (action === "add") {
      // copy previous added lineitem option/type (if there's one)
      setLineType(previousLineType ? previousLineType : RATE);
      setEditFiles();
    } else {
      // initialise values
      let newInfo = {};
      for (let [key, value] of Object.entries(tableList)) {
        if (key === "lineitem_options") {
          setLineType(value);
        } else {
          newInfo[key] = value;
        }
      }
      setFormInfo(newInfo);
    }
  }, [tableList, action]); // eslint-disable-line

  useEffect(() => {
    // only works for lineType changes made by user and not from initialisation
    if (prevLineType && prevLineType !== lineType) {
      let recalculate = { ...formInfo };
      if (lineType === RATE) {
        recalculate.quantity = 1;
      } else if (lineType === LUMP) {
        recalculate.quantity = 1;
        recalculate.rate = formInfo.quantity * formInfo.rate;
      }

      setFormInfo({ ...recalculate });
    }
  }, [lineType]); // eslint-disable-line

  const handleChange = (name, value) => {
    setFormInfo({ ...formInfo, [name]: value });
  };

  return (
    <InputTableWrapper
      style={
        type === "parentItem"
          ? {
              border: `1px solid ${theme.palette.tangerineOrange}`,
              margin: "12px auto",
            }
          : { marginTop: "5px" }
      }
    >
      <FormGroup>
        <div className="form_title">{title}</div>
        <LineItemForm
          onChange={handleChange}
          form={formInfo || {}}
          field={field}
          status={status}
          isBlocked={isBlocked}
          lineitemType={lineType}
          accountConfig={accountConfig}
          isParent={tableList?.childitems?.length > 0}
        />
        <div className="form_body" style={{ margin: "40px auto" }}>
          <Input field="child">
            <AttachLeft>
              <div className="title">Attachments</div>
              <div className="uploadFileWrapper">
                <FileUpload {...props} />
              </div>
            </AttachLeft>
          </Input>
          <Input
            label="Detailed Description"
            name="detailed_description"
            value={formInfo?.detailed_description || ""}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            width={status === "overview" ? "600px" : "438px"}
            field="textarea"
            height="180px"
          />
        </div>
        <div className="form_footer">
          {/* avoid to delete parent / certified items */}
          {canDelete && (
            <DeleteBtn
              title="Delete"
              onClick={handleCleanItem}
              margin="0 30px 0 0"
            />
          )}
          {action === "add" && (
            <AddGreenBtn
              title="Add"
              type="submit"
              onClick={() => handleSaveItem(onSite, formInfo)}
            />
          )}
          {action === "save" && (
            <SaveBtn
              title="Save"
              type="submit"
              onClick={() => handleSaveItem(onSite, formInfo)}
            />
          )}
        </div>
      </FormGroup>
    </InputTableWrapper>
  );
};

export default InputTable;
