import * as constants from "./constants";
import { fromJS } from "immutable";
import API from "../../../server";
import { actionCreators as actionCreatorsFile } from "../../../common/upload_file_progress/store";
//request data based on the proId & accId
export const getInitialBaseItems = (proId, contractId) => {
  return (dispatch) => {
    API.read_contract(proId, contractId)
      .then((res) => res.data)
      .then((res) => res.data.data)
      .then((res) => {
        dispatch(setValue("contractInfo", fromJS(res)));
        if (res.baseitems) {
          dispatch(initialList(res));
        } else {
          dispatch(emptyList());
        }
      });
  };
};
//get initial line item to the table
export const initialList = (result) => ({
  type: constants.INITIAL_ITEM_LIST,
  baseItems: fromJS(result.baseitems),
  baseItemsTotal: result.total_baseitems,
});
//to empty the table when there is no data
export const emptyList = () => ({
  type: constants.EMPTY_ITEM_LIST,
});
const setLoading = (status) => ({
  type: constants.LOADING_STATUS_CHANGE,
  status,
});

//add child line item to the project and clean the input
export const createNewChildRow = (
  proId,
  contractId,
  tableList,
  fileList,
  parentItemId,
  table,
  lineitemOption,
) => {
  tableList["quantity"] = Math.round(tableList.quantity * 100);
  tableList["rate"] = Math.round(tableList.rate * 100);
  tableList["lineitem_options"] = lineitemOption;

  let itemData = tableList;
  itemData = JSON.stringify(itemData);
  var dataSend = new FormData();
  fileList.map((item) => {
    return dataSend.append("attachments", item);
  });
  dataSend.append("data", itemData);
  return (dispatch) => {
    dispatch(setLoading(true));
    API.create_one_lineitem_child(proId, contractId, parentItemId, dataSend, {
      mimeType: "multipart/form-data",
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(getInitialBaseItems(proId, contractId));
      });
  };
};

//add line item to the project and clean the input
export const createNewRow = (
  proId,
  contractId,
  tableList,
  fileList,
  table,
  lineitemOption,
) => {
  tableList["quantity"] = Math.round(tableList.quantity * 100);
  tableList["rate"] = Math.round(tableList.rate * 100);
  tableList["lineitem_options"] = lineitemOption;
  let itemData = tableList;

  itemData = JSON.stringify(itemData);
  var dataSend = new FormData();
  fileList.map((item) => {
    return dataSend.append("attachments", item);
  });
  dataSend.append("data", itemData);
  return (dispatch) => {
    dispatch(setLoading(true));
    API.upsert_one_lineitem(proId, contractId, dataSend, "base_contract", {
      mimeType: "multipart/form-data",
    })
      .then((res) => res.data)
      .then((res) => {
        dispatch(getInitialBaseItems(proId, contractId));
      });
  };
};

// delete line item
export const deleteLineItem = (proId, contractId, itemId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    API.delete_one_lineitem(proId, contractId, itemId)
      .then((res) => res.data)
      .then((res) => {
        dispatch(getInitialBaseItems(proId, contractId));
      });
  };
};

export const changeFilesList = (result) => ({
  type: constants.CHANGE_FILE_LIST,
  files: result,
});
export const cancelOneFile = (result) => ({
  type: constants.CANCEL_ONE_FILE,
  value: result,
});

export const setValue = (name, value) => ({
  type: constants.SET_VALUE,
  name,
  value,
});
export const reset = () => ({
  type: constants.RESET,
});

export const updateLineItem = (proId, contractId, lineId, value) => {
  delete value.total;
  value.quantity = Math.round(value.quantity * 100);
  value.rate = Math.round(value.rate * 100);

  //add the id of lineitem so that api server knows it's an update
  value["id"] = lineId;
  let data = new FormData();
  data.append("data", JSON.stringify(value));

  return (dispatch) => {
    dispatch(setLoading(true));
    API.upsert_one_lineitem(proId, contractId, data, "base_contract", {
      mimeType: "multipart/form-data",
    })
      .then((res) => res.data)
      .then(() => dispatch(getInitialBaseItems(proId, contractId)));
  };
};

export const initialFileList = (fileList) => {
  return {
    type: constants.INITIAL_FILE_LIST,
    fileList: fileList,
  };
};
export const deleteFile = (proId, contractId, lineId, fileId) => {
  return (dispatch) => {
    API.delete_one_file(proId, contractId, lineId, fileId)
      .then((res) => res.data)
      .then((res) => res.data.file)
      .then((res) => {
        dispatch(initialFileList(res));
        dispatch(getInitialBaseItems(proId, contractId));
      });
  };
};
export const uploadFileToDB = (proId, contractId, lineId, files) => {
  return (dispatch) => {
    if (files?.size) {
      let promiseArr = [];
      files.forEach((item) => {
        let dataSend = new FormData();
        dataSend.append("attachments", item.get("file"));
        let config = {
          mimeType: "multipart/form-data",
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch(
              actionCreatorsFile.setUploadProgress(
                item.get("id"),
                percentageProgress,
              ),
            );
          },
        };
        var p1 = API.upload_one_file(
          proId,
          contractId,
          lineId,
          dataSend,
          config,
        );
        promiseArr.push(p1);
      });
      Promise.all(promiseArr)
        .then((resArr) => resArr.map((res) => res.data))
        .then((res) => {
          let fileList = [];
          for (var item of res) {
            for (var item1 of item.data.file) {
              let flag = true;
              for (var item2 of fileList) {
                if (item1.id === item2.id) {
                  flag = false;
                }
              }
              if (flag) {
                fileList.push(item1);
              }
            }
          }
          dispatch(actionCreatorsFile.clearFiles());
          dispatch(initialFileList(fileList));
          dispatch(getInitialBaseItems(proId, contractId));
        })
        .catch((error) => {
          if (error.response) {
            alert(error?.response?.data?.data?.errors);
          } else {
            console.log(error?.message);
          }
        });
    }
  };
};
