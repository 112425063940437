import React, { useState, useEffect } from "react";
import * as theme from "../../../theme";
import { WarningWrap } from "common/config/styled";

import {
  ClaimCertSummaryWrapper,
  SummaryHeading,
  IconWrapper,
  InputContainer,
  Error,
  FieldError,
} from "./styled";
import DropDownClaimCert from "../../../common/dropdown/claim_cert_previous_select";
import RetentionInput from "./retentionInput";
import RetentionExceedPopup from "./retentionExceedPopup";
import { COUNTRY_GB } from "../../../utils/constants";
import {
  calculateSubunitToUnit,
  currencySubunitToUnit,
} from "../../../utils/currencyHelper";
import { CLAIM_CERT_SUMMARY, SUMMARY_TYPE } from "utils/constants";
import { roundNumber, checkExceedMaximum } from "utils/numeralHelper";
import ClaimCertSummaryTable, { NumberInput } from "./newClaimCertTable";
const renderIcon = (iconType) => {
  return <IconWrapper className={iconType} />;
};

const certifiedToDateKeyID = "certified_to_date_";
const claimedToDateKeyID = "claimed_to_date_";
const previousKeyID = "previous_";
const thisKeyID = "this_";

const currencySubunitToUnitWithBrackets = (
  num,
  hasSymbol,
  dropDecimal,
  moduleShorten,
) => currencySubunitToUnit(num, hasSymbol, dropDecimal, moduleShorten, true);

const processedField = (
  defaultState,
  value,
  summaryObject,
  currencyFunction = false,
) => {
  if (defaultState || summaryObject === null || value === null) {
    return <div className="grey-dash" />;
  }
  if (currencyFunction) {
    return currencyFunction(value);
  }
  return currencySubunitToUnitWithBrackets(value);
};

const RenderFieldWithCondition = (props) => {
  const { errorCondition, processedValue } = props;

  if (errorCondition) {
    return (
      <FieldError>
        <div className="errorIcon" />
        {processedValue}
      </FieldError>
    );
  }

  return processedValue;
};

const CertTotalContractValueWarningBanner = (props) => {
  const { contractInfo, errors } = props;

  const totalContractValue = currencySubunitToUnitWithBrackets(
    contractInfo?.get("total_contract_value"),
  );

  return errors?.grossCertifiedToDateValueRef ? (
    <WarningWrap type="error" className="margins">
      <div className="icon" />
      <div className="bold">Total Contract Value: {totalContractValue}</div>
    </WarningWrap>
  ) : null;
};

const ClaimCertSummaryComponent = (props) => {
  const {
    errors,
    previousOptionRef /*New Error banner Ref */,
    currentRetentionPCRRRef,
    currentRetentionDLRRRef,
    grossCertifiedToDateValueRef /*Error banner Ref for gross certified_to_date value greater than total contract value */,
    previousLabourElementRef,
    labourElementRef,
    currentRetentionPopupRef,
    performErrorCheckCurrentRetention,
    checkInputErrors,
    accountConfig,
    readOnly,
    summaryType,
    gst,
    retentionExists,
    previousRef,
    claimReleaseRetention,
    changeClaimReleaseRetentionCheckbox,
    handleDropdownChange,
    handleNumberChange,
    currentToDateData,
    previousData,
    thisCalculatedData,
    previousClaimData, // for cert - previous claimdata
    claimDetail,
    certificate_number, // for cert - current reference
    domestic_reverse_charge,
    // Cert Only Props
    showRetentionOption,
    pcdMax,
    dlpMax,
    retentionOption,
    claimRetentionValue,
    lessRetention,

    // new contract setting
    certifyPendingVariation,
  } = props;
  const calculatedCisStatus =
    typeof props?.calculatedCisStatus === "string"
      ? parseInt(props?.calculatedCisStatus) || 0
      : props?.calculatedCisStatus;
  const [newPreviousRef, setNewPreviousRef] = useState({
    claimed: "",
    certified: "",
  });
  const country = accountConfig?.getIn(["country", "code"]);
  const isGB = country === COUNTRY_GB;
  const cisPart = isGB && calculatedCisStatus !== 0 ? " and CIS" : "";
  const [newCurrentToDateData, setNewCurrentToDateData] =
    useState(currentToDateData); // x to date
  const [newThisCalculatedData, setNewThisCalculatedData] =
    useState(thisCalculatedData); // this x
  const [newPreviousData, setNewPreviousData] = useState(previousData); // previously x amount/custom
  const [newPreviousClaimData, setNewPreviousClaimData] =
    useState(previousClaimData); // claimed to date

  const currency = accountConfig?.getIn(["currency", "title"]);
  const claimWord = accountConfig?.getIn(["claim", "noun"]);
  const claimValue = accountConfig?.getIn(["claim", "value"]);
  const certWord = accountConfig?.getIn(["cert", "noun"]);
  const certValue = accountConfig?.getIn(["cert", "value"]);
  const gstTitle = accountConfig?.getIn(["gst", "title"]);
  useEffect(() => {
    if (readOnly) {
      switch (previousData?.option) {
        case CLAIM_CERT_SUMMARY.SUMMARY_CLAIMED:
          setNewPreviousRef({ claimed: previousData?.title, certified: "" });
          break;
        case CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED:
          setNewPreviousRef({ claimed: "", certified: previousData?.title });
          break;
        case CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM:
          setNewPreviousRef({ claimed: "", certified: "" });
          break;
        default:
          break;
      }
      return;
    }
    if (
      previousRef &&
      (previousRef.get("claimed") !== "" || previousRef.get("certified") !== "")
    ) {
      setNewPreviousRef({
        claimed: previousRef.get("claimed"),
        certified: previousRef.get("certified"),
      });
    } else {
      setNewPreviousRef({ claimed: "", certified: "" });
    }
  }, [previousRef, previousData, readOnly]);

  const handleClaimReleaseRetentionCheckbox = () => {
    changeClaimReleaseRetentionCheckbox(!claimReleaseRetention);
  };

  const getRetentionTotal = (retentionData) => {
    if (!retentionData) return null;

    const { retention_pcrr, retention_dlrr } = retentionData;
    const hasValidRetention =
      retention_pcrr !== null || retention_dlrr !== null;

    if (!hasValidRetention) return null;

    return (retention_pcrr || 0) + (retention_dlrr || 0);
  };

  const formattedDisplayValue = (value) => {
    const roundedLessRetention =
      lessRetention || lessRetention === 0 ? roundNumber(lessRetention) : 0;
    const claimPcd = claimRetentionValue?.get("pcd") || 0,
      claimDlp = claimRetentionValue?.get("dlp") || 0;
    if (
      roundedLessRetention < roundNumber(claimPcd + claimDlp) &&
      (retentionOption === "" || retentionOption === null)
    ) {
      return null;
    }
    return value;
  };

  const performErrorChecking = (data, key, refKey) => {
    const minVal = data.labour_element;
    const maxVal = data.gross;
    if (minVal !== undefined && maxVal !== undefined) {
      const hasError = checkExceedMaximum(minVal, maxVal);
      checkInputErrors(key, hasError, refKey);
    }
  };

  const getCISDeductionValue = (data, key) => {
    const valueFromGet = data?.cis_deduction?.get?.(key);
    const valueDirect = data?.cis_deduction?.[key];
    return valueFromGet !== undefined
      ? `-${valueFromGet}`
      : valueDirect !== undefined
        ? `-${valueDirect}`
        : "0";
  };

  useEffect(() => {
    if (currentToDateData) {
      const updatedToDateData = {
        ...currentToDateData,
        retention_total:
          summaryType === SUMMARY_TYPE.claim
            ? getRetentionTotal(currentToDateData)
            : null,
      };
      setNewCurrentToDateData(updatedToDateData);
      // Perform error checking
      checkInputErrors &&
        performErrorChecking(
          updatedToDateData,
          "labour_element",
          "labourElementRef",
        );
    } else {
      setNewCurrentToDateData(null);
    }
  }, [currentToDateData, summaryType]); //eslint-disable-line

  useEffect(() => {
    if (previousData) {
      const updatedPreviousData = {
        ...previousData,
        retention_total:
          summaryType === SUMMARY_TYPE.claim
            ? getRetentionTotal(previousData)
            : null,
      };
      setNewPreviousData(updatedPreviousData);
      // Perform error checking
      checkInputErrors &&
        performErrorChecking(
          updatedPreviousData,
          "previous_labour_element",
          "previousLabourElementRef",
        );
    } else {
      setNewPreviousData(null);
    }
  }, [previousData, summaryType]); //eslint-disable-line

  useEffect(() => {
    if (thisCalculatedData) {
      const updatedThisCalculatedData = {
        ...thisCalculatedData,
        retention_total:
          summaryType === SUMMARY_TYPE.claim
            ? getRetentionTotal(thisCalculatedData)
            : null,
      };
      setNewThisCalculatedData(updatedThisCalculatedData);
    } else {
      setNewThisCalculatedData(null);
    }
  }, [thisCalculatedData, summaryType]);

  useEffect(() => {
    if (previousClaimData) {
      setNewPreviousClaimData(previousClaimData);
    } else {
      setNewPreviousClaimData(null);
    }
  }, [previousClaimData]);
  let previousHeadingLabel = "";
  let previousHeadingSubLabel = "";

  if (readOnly) {
    previousHeadingLabel =
      newPreviousData?.option === CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM
        ? "Previous Amount"
        : newPreviousData?.option === CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED
          ? `Previously ${certValue} Amount`
          : `Previously ${claimValue} Amount`;
    previousHeadingSubLabel =
      newPreviousData?.option === CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM
        ? ""
        : newPreviousData?.option === CLAIM_CERT_SUMMARY.SUMMARY_CERTIFIED
          ? newPreviousRef?.certified
          : newPreviousRef?.claimed;
  }

  const isCustom =
    !readOnly && newPreviousData?.option === CLAIM_CERT_SUMMARY.SUMMARY_CUSTOM;
  const defaultState = !readOnly && !newPreviousData.option;
  const ClaimHeaders = [
    {
      dataKey: "previousData",
      dataKeyID: previousKeyID,
      label: previousHeadingLabel,
      subLabel: previousHeadingSubLabel,
      component: (
        <>
          <div ref={previousOptionRef} />
          <DropDownClaimCert
            type={SUMMARY_TYPE.claim}
            previousRef={newPreviousRef}
            value={newPreviousData?.option}
            accountConfig={accountConfig}
            onChange={(e) => handleDropdownChange(e?.value)}
            required={errors?.previousOptionRef}
          />
        </>
      ),
    },
    {
      dataKeyID: "separator1_",
      renderIcon: <>{renderIcon("plusOutline")}</>,
      style: {
        width: "100px",
        alignItems: "center",
      },
    },
    {
      dataKey: "thisCalculatedData",
      dataKeyID: thisKeyID,
      label: `This ${claimWord}`,
      style: {
        fontSize: "24px",
        backgroundColor: theme.palette.lightAzureGrey,
      },
    },
    {
      dataKeyID: "separator2_",
      renderIcon: <>{renderIcon("equalsToOutline")}</>,
      style: {
        width: "100px",
        alignItems: "center",
      },
    },
    {
      dataKey: "currentToDateData",
      dataKeyID: claimedToDateKeyID,
      label: `${claimValue} to Date`,
      subLabel: claimDetail?.get("claim_ref"),
    },
  ];
  const CertHeaders = [
    {
      dataKey: "previousData",
      dataKeyID: previousKeyID,
      label: previousHeadingLabel,
      subLabel: previousHeadingSubLabel,
      renderIcon: <>{renderIcon("AOutline")}</>,
      component: (
        <DropDownClaimCert
          type={SUMMARY_TYPE.cert}
          previousRef={newPreviousRef}
          value={newPreviousData?.option}
          accountConfig={accountConfig}
          onChange={(e) => handleDropdownChange(e?.value)}
        />
      ),
      style: readOnly ? { width: "200px" } : undefined,
    },
    {
      dataKey: "claimedToDate",
      dataKeyID: claimedToDateKeyID,
      label: `${claimValue} to Date`,
      subLabel: claimDetail?.get("claim_ref"),
      renderIcon: <>{renderIcon("BOutline")}</>,
      style: readOnly ? { width: "200px" } : undefined,
    },
    {
      dataKey: "thisCalculatedData",
      dataKeyID: thisKeyID,
      label: `This ${certWord}`,
      renderIcon: (
        <>
          {renderIcon("COutline")}
          {renderIcon("minusShort")}
          {renderIcon("AOutline")}
        </>
      ),
      style: {
        fontSize: "24px",
        backgroundColor: theme.palette.lightAzureGrey,
      },
    },
    {
      dataKey: "currentToDateData",
      dataKeyID: certifiedToDateKeyID,
      label: `${certValue} to Date`,
      subLabel: certificate_number,
      renderIcon: <>{renderIcon("COutline")}</>,
    },
  ];

  const ClaimData = [
    {
      label: "Gross",
      labelID: "gross",
      labelStyle: ["bold"],
      expand: false,
      height: "100px",
      id: "gross",
      subChilds: [
        {
          label: "Labour Element",
          labelID: "labour_element",
          isConditional: isGB && calculatedCisStatus !== 0,
          columns: [
            {
              height: "100px",
              previousData: () => {
                if (isCustom) {
                  return (
                    <>
                      <div ref={previousLabourElementRef} />
                      <InputContainer
                        className={`${errors?.previousLabourElementRef ? "error" : ""}`}
                      >
                        <NumberInput
                          name="previous_labour_element"
                          id={`${previousKeyID}labour_element_input`}
                          accountConfig={accountConfig}
                          onValueChange={(value) =>
                            handleNumberChange("previous_labour_element", value)
                          }
                          value={calculateSubunitToUnit(
                            newPreviousData?.labour_element,
                          )}
                          fixedDecimalScale={false}
                          currency={currency}
                          displayValue={true}
                        />
                        {errors?.previousLabourElementRef && (
                          <>
                            <Error>{errors.previousLabourElementRef}</Error>
                            <span className="error-icon"></span>
                          </>
                        )}
                      </InputContainer>
                    </>
                  );
                }
                return processedField(
                  defaultState,
                  newPreviousData?.labour_element,
                  newPreviousData,
                );
              },
            },
            {
              height: "100px",
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.labour_element,
                  newThisCalculatedData,
                );
              },
            },
            {
              height: "100px",
              currentToDateData: () => {
                if (!readOnly) {
                  return (
                    <>
                      <div ref={labourElementRef} />
                      <InputContainer
                        className={`${errors?.labourElementRef ? "error" : ""}`}
                      >
                        <NumberInput
                          name="labour_element"
                          id={`${claimedToDateKeyID}labour_element_input`}
                          accountConfig={accountConfig}
                          onValueChange={(value) =>
                            handleNumberChange("labour_element", value)
                          }
                          value={calculateSubunitToUnit(
                            newCurrentToDateData?.labour_element,
                          )}
                          fixedDecimalScale={false}
                          currency={currency}
                          DisplayValue={true}
                        />
                        {errors?.labourElementRef && (
                          <>
                            <Error>{errors.labourElementRef}</Error>
                            <span className="error-icon"></span>
                          </>
                        )}
                      </InputContainer>
                    </>
                  );
                }
                return processedField(
                  defaultState,
                  newCurrentToDateData?.labour_element,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
      ],
      columns: [
        {
          previousData: () => {
            if (isCustom) {
              return (
                <NumberInput
                  name="gross"
                  id={`${previousKeyID}gross_input`}
                  accountConfig={accountConfig}
                  onValueChange={(e) => handleNumberChange("previous_gross", e)}
                  value={calculateSubunitToUnit(newPreviousData?.gross)}
                  currency={currency}
                />
              );
            }
            return processedField(
              defaultState,
              newPreviousData?.gross,
              newPreviousData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              defaultState,
              newThisCalculatedData?.gross,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            return processedField(
              false,
              newCurrentToDateData?.gross,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      label: "Retention",
      labelID: "retention",
      isShow: retentionExists,
      labelStyle: ["bold"],
      columns: [
        {
          previousData: () => {
            if (isCustom) {
              return (
                <NumberInput
                  name="retention"
                  id={`${previousKeyID}retention_input`}
                  accountConfig={accountConfig}
                  onValueChange={(e) =>
                    handleNumberChange("previous_retention", e)
                  }
                  value={calculateSubunitToUnit(newPreviousData?.retention)}
                  displayInBrackets={true}
                  currency={currency}
                />
              );
            }

            return processedField(
              defaultState,
              newPreviousData?.retention == null
                ? null
                : -newPreviousData?.retention,
              newPreviousData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              defaultState,
              newThisCalculatedData?.retention == null
                ? null
                : -newThisCalculatedData?.retention,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            return processedField(
              false,
              newCurrentToDateData?.retention == null
                ? null
                : -newCurrentToDateData?.retention,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      label: `${claimWord} Retention Release`,
      labelID: "claim_retention_release",
      labelStyle: [],
      icon: "checkbox",
      columns: [],
      showCheckbox: true,
      isConditional: retentionExists,
      combinedChildren: [
        {
          label: "Retention Release",
          labelID: "rentention_release",
          columns: [
            {
              previousData: () => {
                return processedField(
                  defaultState,
                  newPreviousData?.retention_total,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.retention_total,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.retention_total,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
      ],
      children: [
        {
          label: "Practical Completion Retention Release",
          labelID: "pcrr",
          columns: [
            {
              previousData: () => {
                if (isCustom) {
                  return (
                    <NumberInput
                      name="retention_pcrr"
                      id={`${previousKeyID}pcrr_input`}
                      accountConfig={accountConfig}
                      onValueChange={(e) =>
                        handleNumberChange("previous_retention_pcrr", e)
                      }
                      value={calculateSubunitToUnit(
                        newPreviousData?.retention_pcrr,
                      )}
                      currency={currency}
                    />
                  );
                }
                return processedField(
                  defaultState,
                  newPreviousData?.retention_pcrr,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.retention_pcrr,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                if (!readOnly) {
                  return (
                    <>
                      <div ref={currentRetentionPCRRRef} />
                      <RetentionInput
                        id={`${claimedToDateKeyID}pcrr_input`}
                        displayValue={newCurrentToDateData?.retention_pcrr}
                        maximum={newCurrentToDateData?.maxPcd}
                        accountConfig={accountConfig}
                        handleValue={(e) =>
                          handleNumberChange("claimPcdReleaseValue", e)
                        }
                        setErrorCheck={(isError) =>
                          performErrorCheckCurrentRetention("pcrr", isError)
                        }
                        summaryType={summaryType}
                      />
                    </>
                  );
                }
                return processedField(
                  false,
                  newCurrentToDateData?.retention_pcrr,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Defects Liability Retention Release",
          labelID: "dlrr",
          columns: [
            {
              previousData: () => {
                if (isCustom) {
                  return (
                    <NumberInput
                      name="retention_dlrr"
                      id={`${previousKeyID}dlrr_input`}
                      accountConfig={accountConfig}
                      onValueChange={(e) =>
                        handleNumberChange("previous_retention_dlrr", e)
                      }
                      value={calculateSubunitToUnit(
                        newPreviousData?.retention_dlrr,
                      )}
                      currency={currency}
                    />
                  );
                }
                return processedField(
                  defaultState,
                  newPreviousData?.retention_dlrr,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.retention_dlrr,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                if (!readOnly) {
                  return (
                    <>
                      <div ref={currentRetentionDLRRRef} />
                      <RetentionInput
                        id={`${claimedToDateKeyID}dlrr_input`}
                        displayValue={newCurrentToDateData?.retention_dlrr}
                        maximum={newCurrentToDateData?.maxDlp}
                        accountConfig={accountConfig}
                        handleValue={(e) =>
                          handleNumberChange("claimDlpReleaseValue", e)
                        }
                        setErrorCheck={(isError) =>
                          performErrorCheckCurrentRetention("dlrr", isError)
                        }
                        summaryType={summaryType}
                      />
                    </>
                  );
                }
                return processedField(
                  false,
                  newCurrentToDateData?.retention_dlrr,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
      ],
    },
    {
      label: "Total",
      labelID: "total",
      labelStyle: ["bold"],
      columns: [
        {
          previousData: () => {
            return (
              <b>
                {processedField(defaultState, newPreviousData?.total, true)}
              </b>
            );
          },
        },
        {
          thisCalculatedData: () => {
            return (
              <b>
                {processedField(
                  defaultState,
                  newThisCalculatedData?.total,
                  true,
                )}
              </b>
            );
          },
        },
        {
          currentToDateData: () => {
            return (
              <b>
                {processedField(
                  false,
                  newCurrentToDateData?.total,
                  newCurrentToDateData,
                )}
              </b>
            );
          },
        },
      ],
    },
    {
      label: `${gstTitle} (${gst}%)${domestic_reverse_charge ? " Reverse charge applies" : ""}`,
      labelID: "gst",
      labelStyle: [`bold[0:${`${gstTitle}`.length}]`],
      columns: [
        {
          previousData: () => {
            return processedField(
              domestic_reverse_charge ? true : defaultState,
              newPreviousData?.gst,
              true,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              domestic_reverse_charge ? true : defaultState,
              newThisCalculatedData?.gst,
              true,
            );
          },
        },
        {
          currentToDateData: () => {
            return processedField(
              domestic_reverse_charge ? true : false,
              newCurrentToDateData?.gst,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      label:
        calculatedCisStatus && calculatedCisStatus > 0
          ? `CIS Deduction (${calculatedCisStatus}%)`
          : "CIS Deduction",
      labelID: "cis_deduction",
      expand: true,
      tooltiptext:
        "Under the Construction Industry Scheme (CIS), contractors deduct money from a subcontractor’s payments and pass it to HM Revenue and Customs (HMRC).",
      isConditional: isGB && calculatedCisStatus !== 0,
      icon: "hoverIcon",
      prefix: "collapsed",
      handler: true,
      labelStyle: [`bold`],
      columns: [
        {
          previousData: () => {
            const formattedValue = getCISDeductionValue(
              newPreviousData,
              "value",
            );
            return processedField(
              defaultState,
              formattedValue,
              newPreviousData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              defaultState,
              newThisCalculatedData?.cis_deduction?.value == null
                ? null
                : -newThisCalculatedData?.cis_deduction?.value,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            const formattedValue = getCISDeductionValue(
              newCurrentToDateData,
              "value",
            );
            return processedField(false, formattedValue, newCurrentToDateData);
          },
        },
      ],
      subChilds: [
        {
          label: "This calculation is based on the following labour elements",
          labelID: "cis_deduction_labour_elements",
          columns: [],
          description: true,
        },
        {
          label: "Gross",
          labelID: "cis_deduction_gross",
          columns: [
            {
              previousData: () => {
                return processedField(
                  defaultState,
                  newPreviousData?.cis_deduction?.get?.("gross") ??
                    newPreviousData?.cis_deduction?.gross ??
                    0,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.gross ?? 0,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.cis_deduction?.get?.("gross") ??
                    newCurrentToDateData?.cis_deduction?.gross ??
                    0,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Retention",
          labelID: "cis_deduction_retention",
          isShow: retentionExists,
          columns: [
            {
              previousData: () => {
                const formattedValue = getCISDeductionValue(
                  newPreviousData,
                  "retention",
                );
                return processedField(
                  defaultState,
                  formattedValue,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.retention == null
                    ? null
                    : -newThisCalculatedData?.cis_deduction?.retention,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                const formattedValue = getCISDeductionValue(
                  newCurrentToDateData,
                  "retention",
                );
                return processedField(
                  false,
                  formattedValue,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Practical Completion Retention Release",
          labelID: "cis_deduction_pcrr",
          isShow: retentionExists,
          columns: [
            {
              previousData: () => {
                if (defaultState) {
                  return <div className="grey-dash" />;
                }
                return processedField(
                  defaultState,
                  newPreviousData?.cis_deduction?.get?.("pcrr") ??
                    newPreviousData?.cis_deduction?.pcrr ??
                    0,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.pcrr ?? 0,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.cis_deduction?.get?.("pcrr") ??
                    newCurrentToDateData?.cis_deduction?.pcrr ??
                    0,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Defects Liability Retention Release",
          labelID: "cis_deduction_dlrr",
          isShow: retentionExists,
          columns: [
            {
              previousData: () => {
                if (defaultState) {
                  return <div className="grey-dash" />;
                }
                return processedField(
                  defaultState,
                  newPreviousData?.cis_deduction?.get?.("dlrr") ??
                    newPreviousData?.cis_deduction?.dlrr ??
                    0,
                  newPreviousData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.dlrr ?? 0,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.cis_deduction?.get?.("dlrr") ??
                    newCurrentToDateData?.cis_deduction?.dlrr ??
                    0,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
      ],
    },
    {
      label: `This ${claimWord} (including ${gstTitle} ${cisPart})`,
      labelID: `final_claim_including_gst`,
      labelStyle: [`bold[0:${`This ${claimWord}`.length}]`, "fontSize:20px"],
      rowStyle: {
        borderTop: "4px solid #e3e6e8",
        textWrap: "nowrap",
      },
      columns: [
        {
          thisCalculatedData: () => {
            if (defaultState) {
              return <div className="grey-dash total-grey-dash" />;
            }
            return (
              <b>
                {currencySubunitToUnitWithBrackets(
                  newThisCalculatedData?.value,
                )}
              </b>
            );
          },
          style: {
            backgroundColor: theme.palette.tealBlue,
            fontColor: theme.palette.white,
            fontSize: "20px",
          },
        },
      ],
    },
  ];
  const CertData = [
    {
      label: "Gross",
      labelID: "gross",
      labelStyle: ["bold"],
      expand: false,
      height: "100px",
      subChilds: [
        {
          label: "Labour Element",
          labelID: "labour_element",
          isConditional: isGB && calculatedCisStatus !== 0,
          columns: [
            {
              height: "100px",
              previousData: () => {
                if (isCustom) {
                  return (
                    <>
                      <div ref={previousLabourElementRef} />
                      <InputContainer
                        className={`${errors?.previousLabourElementRef ? "error" : ""}`}
                      >
                        <NumberInput
                          name="previous_labour_element"
                          id={`${previousKeyID}labour_element_input`}
                          accountConfig={accountConfig}
                          onValueChange={(value) =>
                            handleNumberChange("previous_labour_element", value)
                          }
                          value={calculateSubunitToUnit(
                            newPreviousData?.labour_element,
                          )}
                          displayValue={newPreviousData?.labour_element}
                          fixedDecimalScale={false}
                          currency={currency}
                          DisplayValue={true}
                        />
                        {errors?.previousLabourElementRef && (
                          <>
                            <Error>{errors.previousLabourElementRef}</Error>
                            <span className="error-icon"></span>
                          </>
                        )}
                      </InputContainer>
                    </>
                  );
                }
                return processedField(
                  defaultState,
                  newPreviousData?.labour_element,
                  newPreviousData,
                );
              },
            },
            {
              height: "100px",
              claimedToDate: () => {
                return processedField(
                  false,
                  newPreviousClaimData?.labour_element,
                  newPreviousClaimData,
                );
              },
            },
            {
              height: "100px",
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.labour_element,
                  newThisCalculatedData,
                );
              },
            },
            {
              height: "100px",
              currentToDateData: () => {
                if (!readOnly) {
                  return (
                    <>
                      <div ref={labourElementRef} />
                      <InputContainer
                        className={`${errors?.labourElementRef ? "error" : ""}`}
                      >
                        <NumberInput
                          name="labour_element"
                          id={`${certifiedToDateKeyID}labour_element_input`}
                          accountConfig={accountConfig}
                          onValueChange={(value) =>
                            handleNumberChange("labour_element", value)
                          }
                          value={calculateSubunitToUnit(
                            newCurrentToDateData?.labour_element,
                          )}
                          fixedDecimalScale={false}
                          currency={currency}
                          DisplayValue={true}
                        />
                        {errors?.labourElementRef && (
                          <>
                            <Error>{errors.labourElementRef}</Error>
                            <span className="error-icon"></span>
                          </>
                        )}
                      </InputContainer>
                    </>
                  );
                }
                return processedField(
                  defaultState,
                  newCurrentToDateData?.labour_element,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
      ],
      columns: [
        {
          previousData: () => {
            if (isCustom) {
              return (
                <NumberInput
                  name="gross"
                  id={`${previousKeyID}gross_input`}
                  accountConfig={accountConfig}
                  onValueChange={(e) => handleNumberChange("previous_gross", e)}
                  value={calculateSubunitToUnit(newPreviousData?.gross)}
                  fixedDecimalScale={false}
                  currency={currency}
                  DisplayValue={true}
                />
              );
            }
            return processedField(
              false,
              newPreviousData?.gross,
              newPreviousData,
            );
          },
        },
        {
          claimedToDate: () => {
            return processedField(
              false,
              newPreviousClaimData?.gross,
              newPreviousClaimData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              false,
              newThisCalculatedData?.gross,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            const processedValue = processedField(
              false,
              newCurrentToDateData?.gross,
              newCurrentToDateData,
            );
            return (
              <>
                <div ref={grossCertifiedToDateValueRef} />
                <RenderFieldWithCondition
                  errorCondition={
                    certifyPendingVariation &&
                    errors?.grossCertifiedToDateValueRef
                  }
                  processedValue={processedValue}
                />
              </>
            );
          },
        },
      ],
    },
    {
      label: "Retention",
      labelID: "retention",
      isShow: retentionExists,
      labelStyle: ["bold"],
      columns: [
        {
          previousData: () => {
            if (isCustom) {
              return (
                <NumberInput
                  name="retention"
                  id={`${previousKeyID}retention_input`}
                  accountConfig={accountConfig}
                  onValueChange={(e) =>
                    handleNumberChange("previous_retention", e)
                  }
                  value={calculateSubunitToUnit(newPreviousData?.retention)}
                  displayInBrackets={true}
                  currency={currency}
                />
              );
            }
            return processedField(
              false,
              newPreviousData?.retention == null
                ? null
                : -newPreviousData?.retention,
              newPreviousData,
            );
          },
        },
        {
          claimedToDate: () => {
            return processedField(
              false,
              newPreviousClaimData?.retention == null
                ? null
                : -newPreviousClaimData?.retention,
              newPreviousClaimData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              false,
              newThisCalculatedData?.retention == null
                ? null
                : -newThisCalculatedData?.retention,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            return processedField(
              false,
              newCurrentToDateData?.retention == null
                ? null
                : -newCurrentToDateData?.retention,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      label: "Practical Completion Retention Release",
      labelID: "pcrr",
      isShow: retentionExists,
      columns: [
        {
          previousData: () => {
            if (isCustom) {
              return (
                <NumberInput
                  name="retention_pcrr"
                  id={`${previousKeyID}pcrr_input`}
                  accountConfig={accountConfig}
                  onValueChange={(e) =>
                    handleNumberChange("previous_retention_pcrr", e)
                  }
                  value={calculateSubunitToUnit(
                    newPreviousData?.retention_pcrr,
                  )}
                  currency={currency}
                />
              );
            }
            return processedField(
              false,
              newPreviousData?.retention_pcrr,
              newPreviousData,
            );
          },
        },
        {
          claimedToDate: () => {
            return processedField(
              false,
              newPreviousClaimData?.retention_pcrr,
              newPreviousClaimData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              false,
              newThisCalculatedData?.retention_pcrr,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            if (!readOnly) {
              return (
                <>
                  <div ref={currentRetentionPCRRRef} />
                  <RetentionInput
                    id={`${certifiedToDateKeyID}pcrr_input`}
                    displayValue={formattedDisplayValue(
                      newCurrentToDateData?.retention_pcrr,
                    )}
                    maximum={pcdMax}
                    accountConfig={accountConfig}
                    showOptions={!showRetentionOption}
                    handleValue={(e) =>
                      handleNumberChange("cert_retention_pcrr", e)
                    }
                    setErrorCheck={(isError) =>
                      performErrorCheckCurrentRetention("pcrr", isError)
                    }
                    summaryType={summaryType}
                  />
                </>
              );
            }
            return processedField(
              false,
              newCurrentToDateData?.retention_pcrr,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      label: "Defects Liability Retention Release",
      labelID: "dlrr",
      isShow: retentionExists,
      columns: [
        {
          previousData: () => {
            if (isCustom) {
              return (
                <NumberInput
                  name="retention_dlrr"
                  id={`${previousKeyID}dlrr_input`}
                  accountConfig={accountConfig}
                  onValueChange={(e) =>
                    handleNumberChange("previous_retention_dlrr", e)
                  }
                  value={calculateSubunitToUnit(
                    newPreviousData?.retention_dlrr,
                  )}
                  currency={currency}
                />
              );
            }
            return processedField(
              false,
              newPreviousData?.retention_dlrr,
              newPreviousData,
            );
          },
        },
        {
          claimedToDate: () => {
            return processedField(
              false,
              newPreviousClaimData?.retention_dlrr,
              newPreviousClaimData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              false,
              newThisCalculatedData?.retention_dlrr,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            if (!readOnly) {
              return (
                <>
                  <div ref={currentRetentionDLRRRef} />
                  <RetentionInput
                    id={`${certifiedToDateKeyID}dlrr_input`}
                    displayValue={formattedDisplayValue(
                      newCurrentToDateData?.retention_dlrr,
                    )}
                    maximum={dlpMax}
                    accountConfig={accountConfig}
                    showOptions={!showRetentionOption}
                    handleValue={(e) =>
                      handleNumberChange("cert_retention_dlrr", e)
                    }
                    setErrorCheck={(isError) =>
                      performErrorCheckCurrentRetention("dlrr", isError)
                    }
                    summaryType={summaryType}
                  />
                </>
              );
            }
            return processedField(
              false,
              newCurrentToDateData?.retention_dlrr,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      isShow: showRetentionOption,
      isCustomColumns: true,
      borderTop: "0px",
      columns: [
        {
          width: "350px",
          renderRow: () => {
            return <></>;
          },
        },
        {
          width: "650px",
          height: "auto",
          renderRow: () => {
            return (
              <>
                <div ref={currentRetentionPopupRef} />
                <RetentionExceedPopup
                  changeRetentionCalculations={(pcrr, dlrr) => {
                    handleNumberChange("cert_retention_pcrr", pcrr);
                    handleNumberChange("cert_retention_dlrr", dlrr);
                  }}
                  pcdMax={pcdMax}
                  dlpMax={dlpMax}
                  {...props}
                />
              </>
            );
          },
        },
      ],
    },
    {
      label: "Total",
      labelID: "total",
      labelStyle: ["bold"],
      columns: [
        {
          previousData: () => {
            return (
              <b>
                {processedField(false, newPreviousData?.total, newPreviousData)}
              </b>
            );
          },
        },
        {
          claimedToDate: () => {
            return (
              <b>
                {processedField(
                  false,
                  newPreviousClaimData?.total,
                  newPreviousClaimData,
                )}
              </b>
            );
          },
        },
        {
          thisCalculatedData: () => {
            return (
              <b>
                {processedField(
                  false,
                  newThisCalculatedData?.total,
                  newThisCalculatedData,
                )}
              </b>
            );
          },
        },
        {
          currentToDateData: () => {
            return (
              <b>
                {processedField(
                  false,
                  newCurrentToDateData?.total,
                  newCurrentToDateData,
                )}
              </b>
            );
          },
        },
      ],
    },
    {
      label: `${gstTitle} (${gst}%)${domestic_reverse_charge ? " Reverse charge applies" : ""}`,
      labelID: "gst",
      labelStyle: [`bold[0:${`${gstTitle}`.length}]`],
      columns: [
        {
          previousData: () => {
            return processedField(
              domestic_reverse_charge ? true : false,
              newPreviousData?.gst,
              newPreviousData,
            );
          },
        },
        {
          claimedToDate: () => {
            return processedField(
              domestic_reverse_charge ? true : false,
              newPreviousClaimData?.gst,
              newPreviousClaimData,
            );
          },
        },
        {
          thisCalculatedData: () => {
            return processedField(
              domestic_reverse_charge ? true : false,
              newThisCalculatedData?.gst,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            return processedField(
              domestic_reverse_charge ? true : false,
              newCurrentToDateData?.gst,
              newCurrentToDateData,
            );
          },
        },
      ],
    },
    {
      label:
        calculatedCisStatus && calculatedCisStatus > 0
          ? `CIS Deduction (${calculatedCisStatus}%)`
          : "CIS Deduction",
      labelID: "cis_deduction",
      expand: true,
      tooltiptext:
        "Under the Construction Industry Scheme (CIS), contractors deduct money from a subcontractor’s payments and pass it to HM Revenue and Customs (HMRC).",
      isConditional: isGB && calculatedCisStatus !== 0,
      icon: "hoverIcon",
      prefix: "collapsed",
      handler: true,
      labelStyle: [`bold`],
      width: "360px",
      columns: [
        {
          previousData: () => {
            const formattedValue = getCISDeductionValue(
              newPreviousData,
              "value",
            );
            return processedField(
              defaultState,
              formattedValue,
              newPreviousData,
            );
          },
        },
        {
          claimedToDate: () => {
            const formattedValue = getCISDeductionValue(
              newPreviousClaimData,
              "value",
            );
            return processedField(false, formattedValue, newPreviousClaimData);
          },
        },
        {
          thisCalculatedData: () => {
            const formattedValue = getCISDeductionValue(
              newThisCalculatedData,
              "value",
            );
            return processedField(
              defaultState,
              formattedValue,
              newThisCalculatedData,
            );
          },
        },
        {
          currentToDateData: () => {
            const formattedValue = getCISDeductionValue(
              newCurrentToDateData,
              "value",
            );
            return processedField(false, formattedValue, newCurrentToDateData);
          },
        },
      ],
      subChilds: [
        {
          label: "This calculation is based on the following labour elements",
          labelID: "cis_deduction_labour_elements",
          columns: [],
          description: true,
        },
        {
          label: "Gross",
          labelID: "cis_deduction_gross",
          columns: [
            {
              previousData: () => {
                return processedField(
                  defaultState,
                  newPreviousData?.cis_deduction?.get?.("gross") ??
                    newPreviousData?.cis_deduction?.gross ??
                    0,
                  newPreviousData,
                );
              },
            },
            {
              claimedToDate: () => {
                return processedField(
                  false,
                  newPreviousClaimData?.cis_deduction?.get?.("gross") ??
                    newPreviousClaimData?.cis_deduction?.gross ??
                    0,
                  newPreviousClaimData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.gross ?? 0,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.cis_deduction?.get?.("gross") ??
                    newCurrentToDateData?.cis_deduction?.gross ??
                    0,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Retention",
          labelID: "cis_deduction_retention",
          isShow: retentionExists,
          columns: [
            {
              previousData: () => {
                const formattedValue = getCISDeductionValue(
                  newPreviousData,
                  "retention",
                );
                return processedField(
                  defaultState,
                  formattedValue,
                  newPreviousData,
                );
              },
            },
            {
              claimedToDate: () => {
                const formattedValue = getCISDeductionValue(
                  newPreviousClaimData,
                  "retention",
                );
                return processedField(
                  false,
                  formattedValue,
                  newPreviousClaimData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                const formattedValue = getCISDeductionValue(
                  newThisCalculatedData,
                  "retention",
                );
                return processedField(
                  defaultState,
                  formattedValue,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                const formattedValue = getCISDeductionValue(
                  newCurrentToDateData,
                  "retention",
                );
                return processedField(
                  false,
                  formattedValue,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Practical Completion Retention Release",
          labelID: "cis_deduction_pcrr",
          isShow: retentionExists,
          columns: [
            {
              previousData: () => {
                if (defaultState) {
                  return <div className="grey-dash" />;
                }
                return processedField(
                  defaultState,
                  newPreviousData?.cis_deduction?.get?.("pcrr") ??
                    newPreviousData?.cis_deduction?.pcrr ??
                    0,
                  newPreviousData,
                );
              },
            },
            {
              claimedToDate: () => {
                return processedField(
                  false,
                  newPreviousClaimData?.cis_deduction?.get?.("pcrr") ??
                    newPreviousClaimData?.cis_deduction?.pcrr ??
                    0,
                  newPreviousClaimData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.pcrr ?? 0,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.cis_deduction?.get?.("pcrr") ??
                    newCurrentToDateData?.cis_deduction?.pcrr ??
                    0,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
        {
          label: "Defects Liability Retention Release",
          labelID: "cis_deduction_dlrr",
          isShow: retentionExists,
          columns: [
            {
              previousData: () => {
                if (defaultState) {
                  return <div className="grey-dash" />;
                }
                return processedField(
                  defaultState,
                  newPreviousData?.cis_deduction?.get?.("dlrr") ??
                    newPreviousData?.cis_deduction?.dlrr ??
                    0,
                  newPreviousData,
                );
              },
            },
            {
              claimedToDate: () => {
                return processedField(
                  false,
                  newPreviousClaimData?.cis_deduction?.get?.("dlrr") ??
                    newPreviousClaimData?.cis_deduction?.dlrr ??
                    0,
                  newPreviousClaimData,
                );
              },
            },
            {
              thisCalculatedData: () => {
                return processedField(
                  defaultState,
                  newThisCalculatedData?.cis_deduction?.dlrr ?? 0,
                  newThisCalculatedData,
                );
              },
            },
            {
              currentToDateData: () => {
                return processedField(
                  false,
                  newCurrentToDateData?.cis_deduction?.get?.("dlrr") ??
                    newCurrentToDateData?.cis_deduction?.dlrr ??
                    0,
                  newCurrentToDateData,
                );
              },
            },
          ],
        },
      ],
    },
    {
      label: `This ${certWord} (including ${gstTitle}${cisPart})`,
      labelID: `final_cert_including_gst`,
      labelStyle: [`bold[0:${`This ${certWord}`.length}]`, "fontSize:20px"],
      rowStyle: {
        borderTop: "4px solid #e3e6e8",
        textWrap: "nowrap",
      },
      columns: [
        {
          thisCalculatedData: () => {
            return (
              <b>
                {currencySubunitToUnitWithBrackets(
                  newThisCalculatedData?.value,
                )}
              </b>
            );
          },
          style: {
            backgroundColor: theme.palette.tealBlue,
            fontColor: theme.palette.white,
            fontSize: "20px",
          },
        },
      ],
    },
  ];

  const data = summaryType === SUMMARY_TYPE.claim ? ClaimData : CertData;
  const headings =
    summaryType === SUMMARY_TYPE.claim ? ClaimHeaders : CertHeaders;
  const disclaimer = domestic_reverse_charge
    ? `Customer to account to HMRC for the reverse charge output tax on the ${gstTitle} exclusive price of items marked ‘reverse charge’ at the relevant ${gstTitle} rate as shown above.`
    : null;
  return (
    <ClaimCertSummaryWrapper className="review">
      <SummaryHeading>Summary</SummaryHeading>
      <div className="dataWrapper">
        <ClaimCertSummaryTable
          headers={headings}
          data={data}
          summaryType={summaryType}
          claimReleaseRetention={claimReleaseRetention}
          handleClaimReleaseRetentionCheckbox={
            handleClaimReleaseRetentionCheckbox
          }
          readOnly={readOnly}
          disclaimer={disclaimer}
        />
        {certifyPendingVariation && summaryType === SUMMARY_TYPE.cert && (
          <CertTotalContractValueWarningBanner {...props} />
        )}
      </div>
    </ClaimCertSummaryWrapper>
  );
};

export default ClaimCertSummaryComponent;
