import * as theme from "../../theme";
import styled from "styled-components";
import editActionIcon from "../../statics/img/icon-edit-medium-grey.svg";
import clearIcon from "../../statics/img/icon-clear-medium-grey.svg";
import deleteIcon from "../../statics/img/icon-delete-medium-grey.svg";
import { IconButton } from "../button";
import greenYesIcon from "../../statics/img/icon-tick-green.svg";
import downloadFileIcon from "../../statics/img/download-file-medium-grey.svg";
import attachIcon from "../../statics/img/icon-file-pin-light-grey.svg";
import warningIcon from "../../statics/img/icon-information-red.svg";
import infoIcon from "../../statics/img/icon-information-transparent.svg";
import shareIcon from "../../statics/img/icon-group-person.svg";
import notSharedIcon from "../../statics/img/icon-group-off.svg";
const normal = theme.font.mainFontWeight;

export const CommentContainer = styled.div`
  width: 624px;
  max-height: 90vh;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.104);
  z-index: 200;
  position: absolute;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  bottom: 44px;
  right: -26px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  flex-direction: column;
  transition:
    opacity 0.3s ease 0s,
    transform 0.2s ease 0s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  font-size: 16px;

  .title {
    width: 100%;
    padding: 24px;

    .header {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 24px;
        font-weight: ${theme.font.mainFontWeight};
      }

      .cancelIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url(${clearIcon}) no-repeat;
        background-size: contain;

        &:hover {
          background-color: rgb(240, 242, 243);
          border-radius: 50%;
        }
      }
    }
  }

  .comments {
    width: 100%;
    max-height: calc(90vh - 230px);
    overflow-y: auto;

    .divider {
      width: 100%;
      height: 1px;
      background-color: ${theme.palette.wolfGrey};
    }

    .banner {
      height: 47px;
      width: 100%;
      padding: 12px 24px;
      border: 1px solid ${theme.palette.lightWolfGrey};
      border-left: 0px;
      border-right: 0px;
      background-color: ${theme.palette.greyWhite};
      font-size: 16px;
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .party-name {
        font-size: 12px;
        padding: 4px 14px;
        margin-left: 8px;
        border: solid 1px ${theme.palette.warmLightGrey};
        border-radius: 4px;
        background-color: ${theme.palette.warmLightGrey};
      }
    }
  }

  .tail {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid ${theme.palette.white};

    position: relative;
    top: 10px;
    left: 265px;
  }
`;

export const CommentStyle = styled.div`
  width: 100%;
  padding: 24px;
  display: grid;
  grid-template-rows: 24px auto 24px;
  grid-row-gap: 16px;

  .container {
    display: flex;
    justify-content: space-between;

    &.top {
      height: 24px;
      padding: 0px;

      .userName {
        height: 100%;
        width: max-content;
        font-weight: 700;
        line-height: 1.5;
      }
    }
    &.bottom {
      color: ${theme.palette.veryMediumGrey};
    }

    &.action {
      width: 64px;
    }
  }
`;

export const CommentAction = styled(IconButton)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  padding: 0px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.editIcon {
      background: url(${editActionIcon}) no-repeat;
    }
    &.deleteIcon {
      background: url(${deleteIcon}) no-repeat;
    }
  }

  &.shareToggle {
    border-radius: 0%;
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }

    &.noEdit {
      .icon {
        cursor: default;
      }
    }

    .icon {
      mask: url(${notSharedIcon}) no-repeat;
      background-color: ${theme.palette.wolfGrey};

      &.shared {
        mask: url(${shareIcon}) no-repeat;
        background-color: ${theme.palette.gunmetal};
      }
    }
  }
`;

export const InputCommentSection = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .inputSection {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .inputField {
      height: 32px;
      border-radius: 4px;
      width: 390px;
      &.error {
        border-color: ${theme.palette.watermelonRed};
        &:focus-visible {
          outline-color: ${theme.palette.watermelonRed};
        }
      }
    }

    .buttons {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 166px;

      .button {
        height: 32px;
        width: 79px;
        padding: 0px;
      }
    }
  }

  .errorMsg {
    margin-top: 8px;
    color: ${theme.palette.watermelonRed};
    font-size: 14px;
  }

  .visibility {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 44px auto;
    grid-column-gap: 12px;

    .description {
      display: flex;
      align-items: center;
    }
  }
`;

export const ShareButton = styled.div`
  height: 40px;
  width: 44px;
  border: 1px solid ${theme.palette.gunmetal};
  border-radius: 4px;
  padding: 8px 10px;
  cursor: pointer;
  &.shared {
    background-color: ${theme.palette.gunmetal};
  }

  .icon {
    height: 24px;
    width: 24px;
    mask: url(${notSharedIcon}) no-repeat;
    background-color: ${theme.palette.gunmetal};
    background-size: 24px 24px;

    &.shared {
      mask: url(${shareIcon}) no-repeat;
      background-color: ${theme.palette.white};
    }
  }
`;

export const CommentImg = styled.div`
  padding: 2px 8px;
  font-size: 14px;
  color: white;
  width: 24px;
  height: 24px;
  background: url(${(props) => props.size}) no-repeat;

  position: absolute;
`;

export const ClaimTableWrapper = styled.div`
  overflow: visible;
  .input {
    height: 26px;
    float: left;
    border: 1px solid ${theme.palette.wolfGrey};
    font-family: ${theme.font.mainFont};
    box-sizing: border-box;
    color: ${theme.palette.gunmetal};
    font-size: 14px;
    outline: none;
    &:disabled {
      background-color: ${theme.palette.greyWhite};
      color: ${theme.palette.veryMediumGrey};
    }
    &.valueInput {
      width: 100px;
      margin: 0 12px 0 15px;
      text-align: right;
      padding-right: 16px;
    }
    &.percentInput {
      width: 48px;
      text-align: center;
      &.percent {
        width: 50px;
        background-color: ${theme.palette.veryWarmLightGrey};
        padding: 4px;
        border-left: none;
      }
      &.percentQty {
        width: 50px;
        background-color: ${theme.palette.veryWarmLightGrey};
        border-left: none;
      }
      .MuiButtonBase-root {
        padding: 0px;
        opacity: 1;
      }
    }
  }

  .claimLabel,
  .certLabel {
    font-weight: bold;
    padding: 8px 15px;
  }

  .claimValue {
    .value_container {
      // width: 425px;
    }
    .percentAndQty {
      display: grid;
      grid-template-columns: 50% 50%;
      height: 100%;
      div {
        text-align: center;
        cursor: pointer;
        margin: 2px;
        line-height: 19px;
      }
      div[disabled] {
        pointer-events: none;
        opacity: 0.7;
      }
      .selected {
        background-color: ${theme.palette.gunmetal};
        color: ${theme.palette.white};
        line-height: 19px;
        border-radius: 4px;
      }
    }
  }

  .header_table {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    letter-spacing: 0.25px;
    .label-claim {
      height: 44px;
      width: 290px;
      color: ${theme.palette.tealBlue};
      text-align: center;
      background-color: ${theme.palette.lightPastelBlue};
      padding: 14px 0;
      font-weight: bold;
    }
  }

  table {
    width: 1360px;
  }

  thead th {
    text-align: left;
    &.group_header {
      text-align: center;
      padding: 0;
      &.grey {
        background-color: ${theme.palette.veryMediumGrey};
      }
    }
  }
  tbody td {
    &.group_input {
      text-align: center;
      padding: 0 20px;
      .value_container {
        display: inline-block;
      }
    }
    &.net-claim,
    &.claim-actions,
    &.cert-actions {
      .inlineDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .action_container {
          position: relative;
          width: 24px;
          height: 24px;
        }
        &.baseinline {
          justify-content: center;
        }
      }
    }
  }

  tfoot {
    td {
      background-color: ${theme.palette.tealBlue};
      &.empty {
        background-color: ${theme.palette.white};
      }
      &.empty-end {
        background-color: ${theme.palette.white};
      }
      &.title {
        width: 210px;
      }
      &.rate {
        width: 290px;
      }
      &.approve {
        background-color: ${theme.palette.turquoiseBlue};
      }
      &.claim {
        background-color: ${theme.palette.turquoiseBlue};
        width: 290px;
      }
      &.net {
        background-color: ${theme.palette.veryMediumGrey};
        width: 140px;
      }
      &.claim-actions {
        background-color: ${theme.palette.white};
        width: 70px;
      }

      &.footer {
        color: ${theme.palette.white};
        font-weight: bold;
        padding: 8px 15px;
        text-align: right;
      }
    }
  }
  .inputWrapper {
    padding-top: 10px;
    border: 1px solid ${theme.palette.tangerineOrange};
    border-top: none;
    margin-bottom: 20px;
    margin-right: -1px;
  }
`;

export const TableToolbar = styled.div``;
export const ApprovedTableWrapper = styled.div`
  table {
    thead {
      .so-table-center {
        background-color: ${theme.palette.turquoiseBlue};
        .approvedHeader {
          width: 100%;
          padding: 12px 16px;
          text-align: center;
          display: inline-block;
        }
      }
    }

    tbody {
      td {
        &.approveComment {
          padding: 0;
          .inlineDiv {
            display: flex;
            align-items: center;
            gap: 8px;
            .action_container {
              position: relative;
              width: 24px;
              height: 24px;
              padding: 0px 10px;
            }
          }
        }
      }
    }
  }
  tfoot {
    td {
      border-bottom: 0px;
      background-color: ${theme.palette.tealBlue};
      &.blank {
        background-color: ${theme.palette.white};
        &.first {
          width: 308px;
        }
        &.last {
          width: 62px;
        }
      }
      &.title {
        width: 158px;
      }
      &.total {
        width: 244px;
      }
      &.claimed {
        width: 288px;
      }
      &.approve {
        width: 200px;
      }
    }
    &.materials_footer {
      .blank.first {
        width: 558px;
      }
    }
  }

  .header_table {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    letter-spacing: 0.25px;
    text-transform: uppercase;
    .label-claim {
      height: 44px;
      width: 288px;
      color: ${theme.palette.brightGreen};
      text-align: center;
      background-color: ${theme.palette.lightPastelGreen};
      padding: 14px 0;
      font-weight: bold;
    }
    .label-certificate {
      height: 44px;
      width: 262px;
      color: ${theme.palette.tealBlue};
      text-align: center;
      background-color: ${theme.palette.lightPastelBlue};
      padding: 14px 0;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }

  .percent_qty {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: right;
  }
`;

export const PayCertTableWrapper = styled.div`
  .input {
    height: 26px;
    float: left;
    border: 1px solid ${theme.palette.wolfGrey};
    font-family: ${theme.font.mainFont};
    box-sizing: border-box;
    color: ${theme.palette.gunmetal};
    font-size: 12px;
    outline: none;
    &:disabled {
      background-color: ${theme.palette.greyWhite};
      color: ${theme.palette.veryMediumGrey};
    }
    &.valueInput {
      width: 100px;
      margin: 0 0 0 16px;
      text-align: right;
      padding-right: 16px;
    }
    &.percentInput {
      width: 48px;
      text-align: center;
      &.percent {
        width: 50px;
        background-color: ${theme.palette.veryWarmLightGrey};
        padding: 4px;
        border-left: none;
      }
      &.percentQty {
        width: 50px;
        background-color: ${theme.palette.veryWarmLightGrey};
        border-left: none;
      }
    }
  }

  .certValue {
    .value_container {
      // width: 425px;
    }
    .percentAndQty {
      display: grid;
      grid-template-columns: 50% 50%;
      height: 100%;
      div {
        text-align: center;
        cursor: pointer;
        margin: 2px;
        line-height: 19px;
      }
      div[disabled] {
        pointer-events: none;
        opacity: 0.7;
      }
      .selected {
        background-color: ${theme.palette.gunmetal};
        color: ${theme.palette.white};
        line-height: 19px;
        border-radius: 4px;
      }
    }
  }

  .inlineDiv {
    display: flex;
    justify-content: flex-end;
  }

  table {
    font-size: 12px;
    width: 1408px;
  }
  thead th {
    text-align: left;
    padding: 0px 10px 0 15px;
    &.emptyColumn {
      background-color: ${theme.palette.white};
    }
    &.group_header {
      text-align: center;
      &.grey {
        background-color: ${theme.palette.veryMediumGrey};
      }
    }
  }
  .group_value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .percent_value {
      width: 54px;
      text-align: center;
    }
  }
  tbody td {
    padding: 8px 15px 8px 5px;
    &.emptyColumn {
      background-color: ${theme.palette.white};
    }
    &.group_input {
      padding: 0 10px;
      .value_container {
        display: inline-block;
      }
    }
    &.reason {
      .inlineDiv {
        display: flex;
        align-items: center;
        .reason_button_wrap {
          display: flex;
          align-items: center;
          .warning_label {
            margin-left: 5px;
            color: ${theme.palette.watermelonRed};
          }
        }
        .reason_label {
          text-align: left;
          padding: 5px 10px;
          width: 80%;
          height: auto;
        }
        .action_container {
          position: relative;
          width: 44px;
          height: 24px;
          padding: 0px 10px;
          margin-right: -15px;
        }
      }
    }
  }
  .header_table {
    font-weight: ${theme.font.mainFontWeight};
    table-layout: fixed;
    letter-spacing: 0.25px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    .header_label {
      height: 44px;
      text-align: center;
      &.progress {
        background-color: ${theme.palette.veryColdGrey};
        color: ${theme.palette.gunmetal};
        width: 510px;
      }
      &.cert {
        background-color: ${theme.palette.lightPastelBlue};
        color: ${theme.palette.tealBlue};
        width: 354px;
      }
    }
  }
  tfoot {
    td {
      &.value {
        width: 113px;
      }
      background-color: ${theme.palette.tealBlue};
      &.light_blue {
        background-color: ${theme.palette.turquoiseBlue};
        &.claim {
          width: 113px;
        }
        &.value {
          width: 240px;
        }
      }
      &.grey {
        background-color: ${theme.palette.veryMediumGrey};
        width: 168px;
      }
      &.white {
        background-color: ${theme.palette.white};
        width: 53px;
        &.between {
          width: 8px;
          padding: 0;
        }
      }
      &.subtotal {
        &.total {
          width: 113px;
        }
        &.value {
          width: 114px;
        }
      }
    }
  }

  .certLabel {
    font-weight: bold;
    padding: 8px 15px;
  }
  .inputWrapper {
    padding-top: 10px;
    border: 1px solid ${theme.palette.tangerineOrange};
    border-top: none;
    margin-bottom: 20px;
    margin-right: -1px;
  }
`;

export const TableWarning = styled.div`
  width: 500px;
  height: 56px;
  margin-top: 16px;
  padding: 12px 25px 12px 16px;
  border-radius: 4px;
  background-color: ${theme.palette.lightPastelPink};
  float: right;
  display: flex;
  align-items: center;
  line-height: 18px;
  .warningIcon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background-image: url(${warningIcon});
  }
  span {
    max-width: 420px;
  }
`;

export const Loading = styled.div`
  width: 200px;
  height: 120px;
  border-radius: 4px;
  margin-bottom: 40px;
  background-color: ${theme.palette.lightWolfGrey};
  .labelS {
    font-size: 16px;
    font-weight: ${theme.font.mainFontWeight};
    font-family: ${theme.font.mainFont};
    color: ${theme.palette.white};
  }
`;
export const ImgDirection = styled(IconButton)`
  height: 20px;
  width: 20px;
  background: url(${(props) => props.icon}) no-repeat;
  background-size: contain;
`;

export const PageBar = styled.div`
  float: right;
  margin-top: 30px;
  height: 36px;
  width: 260px;
  &.big {
    width: 500px;
  }
  &.account {
    width: 438px;
    margin-top: 0;
  }
  background-color: ${theme.palette.white};
  border-radius: 4px;
  font-family: ${theme.font.mainFont};
  color: ${theme.palette.gunmetal};
  .buttonWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    padding: 8px 20px;
    box-sizing: border-box;
    .select_div {
      height: 44px;
      width: 201px;
      border: 1px solid ${theme.palette.wolfGrey};
      box-sizing: border-box;
      border-radius: 4px;
      margin: -12px;
      .sortTxt {
        text-align: center;
        line-height: 42px;
        width: 39px;
        height: 42px;
        margin-left: 16px;
        font-size: 14px;
        font-weight: ${theme.font.mainFontWeight};
        vertical-align: center;
        position: absolute;
      }
    }
  }
  .number_count {
    width: 110px;
    margin-right: 8px;
    font-size: 14px;
    line-height: 20px;
  }
  .btn {
    font-weight: ${theme.font.mainFontWeight};
    color: ${theme.palette.veryMediumGrey};
    height: 20px;
    width: 20px;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

export const HistoryTableStyle = styled.div`
  font-family: ${theme.font.mainFont};
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  width: 654px;

  .title {
    font-size: 20px;
    color: ${theme.palette.gunmetal};
    font-family: ${theme.font.mainFont};
    font-weight: ${theme.font.mainFontWeight};
    margin-bottom: 30px;
  }
  .table {
    table-layout: fixed;
    width: 574px;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    border-spacing: 1px;
    background-color: ${theme.palette.greyWhite};
    tr:nth-child(even) {
      background-color: ${theme.palette.warmLightGrey};
    }
  }
  .thead {
    height: 32px;
    background-color: ${theme.palette.veryMediumGrey};
    text-align: right;
    color: ${theme.palette.white};
  }

  .tdTitleItem {
    padding: 0 15px 0 0;
    font-size: 14px;
    font-weight: ${theme.font.mainFontWeight};
    box-sizing: border-box;
    position: relative;
    &.claim {
      width: 136px;
      text-align: left;
      padding: 0 15px;
      height: 32px;
    }
    &.date {
      width: 110px;
      text-align: left;
      padding: 0 0 0 15px;
      height: 32px;
    }

    &.percent {
      width: 82px;
      text-align: center;
      padding: 0;
      height: 32px;
    }
    &.total {
      width: 136px;
      text-align: center;
      padding: 0;
      height: 32px;
    }
  }
  .tbody {
    color: ${theme.palette.gunmetal};
    text-align: right;
    box-sizing: border-box;
  }
  .tdItem {
    height: 32px;
    padding: 0 15px;

    &.desItem {
      text-align: left;
      padding: 4px 15px;
      box-sizing: border-box;
    }
    &.actions {
      text-align: center;
      padding: 4px 15px;
      box-sizing: border-box;
    }
    &.total {
      text-align: right;
      padding: 4px 15px;
      box-sizing: border-box;
    }
  }
`;

export const TableStyle = styled.div`
  .inputWrapper {
    padding-top: 10px;
    border: 1px solid ${theme.palette.tangerineOrange};
    border-top: none;
    margin-bottom: 20px;
    margin-left: -1px;
    cursor: default;
  }
  //tables in general
  table {
    th {
      text-align: left;
    }
    thead th.blue_box {
      background-color: ${theme.palette.turquoiseBlue};
      border-left: none;
      text-align: right;
    }
  }

  //project/claims overview base and variations table specific
  .so-table {
    margin-top: 20px;

    thead {
      .item-not-show,
      .file-not-show {
        height: 0px;
      }
      .otherEmptyTr {
        height: 100%;
        width: 100%;
        background-color: ${theme.palette.white};
      }
      .updateS {
        height: 100%;
        background-color: ${theme.palette.turquoiseBlue};
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        .dateInline {
          font-weight: bold;
          margin-left: 5px;
        }
      }
      .hoverIcon {
        width: 24px;
        height: 24px;
        background: url(${infoIcon}) no-repeat;
        float: left;
      }
    }
    .item-not-show {
      border-right: none;
      padding: 0 0 0 15px;
      text-align: left;
    }
    .file-not-show {
      border-left: none;
      padding: 0;
    }
    .space {
      width: 16px;
      display: inline-block;
    }
  }

  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }

  //totals table
  tfoot {
    .hoverIcon {
      width: 20px;
      height: 20px;
      background: url(${infoIcon}) no-repeat;
      float: left;
      margin-top: -2px;
    }

    td {
      background-color: ${theme.palette.tealBlue};
      &.blank {
        width: 200px;
        background-color: ${theme.palette.white};
      }
      &.blank-extended {
        width: 468px;
        background-color: ${theme.palette.white};
      }
      &.title {
        width: 280px;
      }
      &.value {
        width: 280px;
      }
      &.claimedTotal {
        background-color: ${theme.palette.turquoiseBlue};
        width: 260px;
      }
    }
  }
`;

export const VarDetailSection = styled.div`
  background-color: ${theme.palette.veryColdGrey};
  padding: 40px;
  position: relative;

  // Style for lineitem_options based on lump
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }
`;

export const FileWrapper = styled.div`
  width: 100%;
  background-color: ${theme.palette.white};
  max-height: 200px;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: auto;
  float: left;
  border: 1px solid ${theme.palette.wolfGrey};
  border-top: none;
  color: ${theme.palette.gunmetal};
  &.viewDetail {
    border: 1px solid ${theme.palette.wolfGrey};
    color: ${theme.palette.veryMediumGrey};
    background-color: ${theme.palette.greyWhite};
    width: 493px;

    &.expanded {
      padding: 0 5px;
      width: 450px;
    }
  }
  li {
    list-style-type: none;
    border-bottom: 1px solid ${theme.palette.lightWolfGrey};
  }
  li:last-child {
    border: none;
  }
`;

export const FileDetail = styled.div`
  height: 44px;
  box-sizing: border-box;
  padding: 10px 0;
  font-size: 14px;

  .fileImg {
    height: 24px;
    width: 24px;
    background: url(${greenYesIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: left;
    margin-right: 10px;
    &.attachment {
      background: url(${attachIcon}) no-repeat;
    }
  }
  .fileName {
    font-weight: ${theme.font.mainFontWeight};
    margin-right: 8px;
    line-height: 24px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
    &.no-file {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      width: 100%;
    }
    &.adjust_size {
      width: 70%;
      max-width: 70%;
      width: auto;
    }
  }
  .size {
    font-weight: ${theme.font.normalFontWeight};
    float: left;
    line-height: 24px;
    color: ${theme.palette.veryMediumGrey};
  }

  .delFileImg {
    height: 24px;
    width: 24px;
    background: url(${clearIcon}) no-repeat;
    background-size: contain;
    display: block;
    float: right;
    cursor: pointer;
    border: none;
    &.downloadFileImg {
      background: url(${downloadFileIcon}) no-repeat;
    }
    &:hover {
      background-color: ${theme.palette.veryWarmLightGrey};
      border-radius: 50%;
    }
    &:active {
      background-color: ${theme.palette.wolfGrey};
      border-radius: 50%;
    }
    &.noBackground {
      background: none;
    }
  }
`;

export const SummaryTableStyle = styled.div`
  width: 100%;
  height: auto;
  margin-top: 34px;
  table {
    height: 100%;
    width: 1360px;
    border-spacing: 1px;
    table-layout: fixed;
  }
  tfoot {
    td {
      color: ${theme.palette.white};
      text-align: right;
      padding: 4px 15px;
      box-sizing: border-box;
      height: 44px;
      font-weight: ${normal};
      text-overflow: ellipsis;
      overflow: hidden;
      &.total {
        width: 196px;
        background-color: ${theme.palette.tealBlue};
      }
      &.total_value {
        width: 245px;
        background-color: ${theme.palette.tealBlue};
      }
      &.date {
        width: 235px;
        background-color: ${theme.palette.turquoiseBlue};
      }
      &.date_value {
        width: 156px;
        background-color: ${theme.palette.veryMediumGrey};
      }
    }
  }
`;

export const ImgClose = styled(IconButton)`
  background: url(${clearIcon}) no-repeat;
  position: absolute;
  top: 12px;
  right: 12px;
  line-height: 15px;
`;

export const NetClaim = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .center {
    text-align: center;
  }
`;

export const NoticeIconTransparent = styled.div`
  height: 24px;
  width: 24px;
  background: url(${infoIcon}) no-repeat;
  background-size: contain;
`;

export const CellSubtitle = styled.div`
  color: ${theme.palette.veryMediumGrey};
`;

export const MaterialsReviewTables = styled.div`
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 20px;

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 24px;
    }
  }

  .tables {
    width: 100%;
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 16px;

    .table {
      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .claimed-total {
        padding: 0 15px;
      }

      .claimed {
        width: 200px;
        background-color: ${theme.palette.tealBlue};
      }
    }
  }
`;

export const ModalContent = styled.div`
  width: max-content;
  background-color: ${theme.palette.white};
`;

export const ModalContentScroll = styled.div`
  width: max-content;
  background-color: ${theme.palette.white};
  overflow-y: auto;
  max-height: 80vh;
`;

export const ButtonsContainer = styled.div`
  padding-right: 40px;
`;

export const VariationTotalContainer = styled.div`
  color: ${theme.palette.veryMediumGrey};
  font-style: italic;
  text-decoration: underline dashed;

  &:hover {
    cursor: pointer;
  }
`;

export const VariationCertStatusButtonContainer = styled.div`
  margin-right: 10px;
  &.inline {
    display: inline-block;
  }
`;
