import SingleSelectionStepDialog from "../../../common/modal/component/singleSelectionStepDialog";
import React from "react";
import { VariationTypeOptionInformation } from "./styled";
import { VARIATION_LINEITEM_TYPES_NEW_OPTIONS } from "utils/constants";

/**
 * @typedef Option
 * @type {object}
 * @property {string} label - The label to display on top part of the option.
 * @property {string} description - The description to display below the label.
 * @property {string} value - The value which will be passed on to handleContinue function.
 */

/**
 * Modal to render the variation modal.
 *
 * @param {object} props
 * @param {Option[]} [props.optionsList] - A list of options to display. By default displays built-in options list of basic and multi variation.
 * @param {() => void} props.handleClose - Trigger function for when user clicks the close button on top right.
 * @param {(value: string) => void} props.handleContinue - Function on pressing the continue button. Passes the value of the option as the argument.
 * @param {boolean} [props.isOpen] - Show the modal. Default is false.
 *
 * @returns {React.JSX.Element}
 *
 * @example
 * <NewVariationLineItemModal
 *  optionsList={[
 *    {
 *      label: "Basic Variation",
 *      description: "Test Description.",
 *      value: "basic",
 *    },
 *    {
 *      label: "Multi-line Variation",
 *      description: "Test Description.",
 *      value: "multi",
 *    },
 *  ]}
 *  handleContinue={(val) => console.log(val)}
 *  handleClose={() => console.log("closed")}
 *  isOpen
 * />
 */
export default function NewVariationLineItemModal(props) {
  const {
    optionsList = VARIATION_LINEITEM_TYPES_NEW_OPTIONS,
    handleContinue = (_) => {},
    handleClose = () => {},
    isOpen = false,
  } = props;

  if (!isOpen) {
    return <></>;
  }

  const optionRenderComponents = optionsList.map((option) => (
    <VariationTypeOptionInformation key={option.value}>
      <span className="label">
        <b>{option.label}</b>
      </span>
      <span className="description">{option.description}</span>
    </VariationTypeOptionInformation>
  ));

  /**
   * Base handler for Selection Step Dialog handleContinue.
   *
   * @type {(index: number) => void}
   */
  const handleContinueInComponent = (index) => {
    const { value } = optionsList[index];
    if (value) handleContinue(value);
  };

  return (
    <SingleSelectionStepDialog
      title="Select Variation Type"
      subTitle="Select a variation type to continue."
      optionsList={optionRenderComponents}
      handleClose={handleClose}
      handleContinue={handleContinueInComponent}
      defaultOptionIndex={optionsList.length > 0 ? 0 : -1}
      wholeOptionComponentUpdatesSelected
    />
  );
}
