import React, { useEffect, useState } from "react";
import { ErrorWrapper } from "../styled";
import { WarningWrap } from "common/config/styled";

const ErrorStripBanner = (props) => {
  const {
    errors,
    claimDetailRef,
    previousOptionRef,
    currentRetentionPCRRRef,
    currentRetentionDLRRRef,
    currentRetentionPopupRef,
    previousLabourElementRef,
    labourElementRef,
    grossCertifiedToDateValueRef,
    variationItemRefs,
  } = props;
  const [refErrors, setRefErrors] = useState({});

  useEffect(() => {
    // flatten errors
    let refErrs = { ...errors, ...errors?.variErrors };
    delete refErrs.variErrors;
    setRefErrors(refErrs);
  }, [errors]);

  const scrollToComponent = (key) => {
    const refs = {
      claim_ref: claimDetailRef,
      claim_period_to: claimDetailRef,
      previousOptionRef: previousOptionRef,
      currentRetentionPCRRRef: currentRetentionPCRRRef,
      currentRetentionDLRRRef: currentRetentionDLRRRef,
      currentRetentionPopupRef: currentRetentionPopupRef,
      previousLabourElementRef: previousLabourElementRef,
      labourElementRef: labourElementRef,
      grossCertifiedToDateValueRef: grossCertifiedToDateValueRef,
      ...variationItemRefs,
    };

    if (refs[key] && refs[key].current) {
      refs[key].current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {Object.keys(refErrors).length > 0 && (
        <ErrorWrapper>
          <WarningWrap type="error" className="margins">
            <div className="icon" />
            <div>
              <div>To proceed, please fill in the following information:</div>
              <div>
                {Object.keys(refErrors).map((key) => (
                  <div key={key} className="error_item">
                    <a onClick={() => scrollToComponent(key)}>
                      {refErrors[key]}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </WarningWrap>
        </ErrorWrapper>
      )}
    </>
  );
};

export default ErrorStripBanner;
