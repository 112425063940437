import styled from "styled-components";
import * as theme from "theme";

import arrow from "statics/img/icon-dropdown-down-dark-grey.svg";
import error from "statics/img/icon-information-red.svg";
import checkIcon from "../../../statics/img/icon-check-box-check-dark-grey.svg";
import uncheckIcon from "../../../statics/img/icon-check-box-un-check-dark-grey.svg";
import plusOutlineIcon from "../../../statics/img/claim-cert-summary-plus-icon-circle-outline.svg";
import equalsToOutlineIcon from "../../../statics/img/claim-cert-summary-equalsto-icon-circle-outline.svg";
import AOutlineIcon from "../../../statics/img/claim-cert-summary-A-icon-circle.svg";
import BOutlineIcon from "../../../statics/img/claim-cert-summary-B-icon-circle.svg";
import COutlineIcon from "../../../statics/img/claim-cert-summary-C-icon-circle.svg";
import minusShortIcon from "../../../statics/img/claim-cert-summary-minus-icon-short.svg";
import infoIcon from "../../../statics/img/icon-information-white.svg";
import noticeIcon from "../../../statics/img/icon-information-gray-white.svg";
import listIcon from "../../../statics/img/expand.svg";
import collapse from "../../../statics/img/collapse.svg";
export const ValueInput = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  width: 200px;
  padding: 10px 12px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  background-color: white;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.error ? theme.palette.watermelonRed : theme.palette.turquoiseBlue};
  .errorIcon {
    width: 24px;
    height: 24px;
    background: url(${error}) no-repeat;
  }
  input {
    border: none;
    outline: none;
    text-align: right;
  }
  .icon {
    width: 24px;
    height: 24px;
    background: url(${arrow}) no-repeat;
    cursor: pointer;
  }

  .number-style {
    width: 112px;
    border: none;
    outline: none;
    height: 37px;
    padding: 2px 4px;
    margin: 0px 8px;
  }
`;

export const Wrap = styled.div`
  margin: 5px 0;
  position: relative;
  width: 200px;

  .dropdown {
    position: absolute;
    text-wrap: nowrap;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    background-color: white;
    right: 0;
    top: 40;
    min-width: 200px;
    z-index: 500;
  }
  .option {
    &.error {
      color: ${theme.palette.watermelonRed};
    }
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
    align-self: stretch;
    .text {
      font-weight: bold;
      margin: 4px 0px;
    }
    &:hover {
      background-color: ${theme.palette.veryWarmLightGrey};
      cursor: pointer;
    }
  }
`;

// ClaimCertSummary Styles

export const ClaimCertSummaryWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${theme.palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.104);
  display: table;
  &.claim {
    margin-bottom: 56px;
  }
  &.review {
    box-shadow: none;
  }
  .dataWrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const SummaryHeading = styled.div`
  width: 100%;
  line-height: 24px;
  font-size: 24px;
  font-weight: ${theme.font.mainFontWeight};
  margin-bottom: 20px;
  &.sub_title {
    font-size: 20px;
    line-height: 20px;
  }
  &.no_margin {
    margin: 0;
  }
`;

// ClaimCertSummary Table Styles

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputContainer = styled.div`
  position: relative;
  &.error input {
    border: 1px solid ${theme.palette.watermelonRed};
  }
`;

export const Error = styled.div`
  color: ${theme.palette.watermelonRed};
  position: absolute;
  top: 41px;
  font-size: 11px;
  text-align: right;
  width: 100%;
`;

export const FieldError = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.palette.watermelonRed};

  .errorIcon {
    width: 24px;
    height: 24px;
    background: url(${error}) no-repeat;
  }
`;

export const HeaderCell = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  padding-right: 15px;
  align-items: ${(props) => props.alignItems || "flex-end"};
  justify-content: flex-end;
  width: ${(props) => props.width || "250px"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  height: fit-content;
  gap: 10px;

  &.claim {
    width: 410px;
    &.readonly {
      width: 300px;
    }
  }

  &.cert {
    width: 360px;
    &.readonly {
      width: 275px;
    }
  }
`;

export const HeaderRenderIcon = styled.div`
  align-self: flex-end;
  margin-bottom: 5px;
`;

export const HeaderContent = styled.div`
  height: 80px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
`;

export const DataRow = styled.div`
  display: flex;
  border-top: ${(props) => props.borderTop || "1px solid #e3e6e8"};
  height: 100%;

  &.expandedRow {
    cursor: pointer;
  }

  &.description {
    height: 30px;
  }
  text-wrap: ${(props) => props.textWrap || "wrap"};
`;

export const ChildDataRow = styled(DataRow)`
  border-top: none;
`;

export const Label = styled.div`
  color: ${theme.palette.gunmetal};
  font-size: ${(props) => props.fontSize || "16px"};
  text-align: right;
`;

export const RowLabel = styled.div`
  color: ${theme.palette.gunmetal};
  font-size: ${(props) => props.fontSize || "16px"};
  text-align: left;
  flex-grow: 1;
`;

export const SubLabel = styled.div`
  color: ${theme.palette.veryMediumGrey};
  font-size: 14px;
  text-align: right;
`;

const dataCellHeight = "52px";

export const DataCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  color: ${(props) => props.fontColor || theme.palette.gunmetal};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${theme.font.normalFontWeight};
  font-family: ${theme.font.mainFont};
  width: ${(props) => props?.width || "250px"};
  height: ${(props) => props?.height || dataCellHeight};
  background-color: ${(props) => props.backgroundColor || "transparent"};

  .inputFrame {
    height: 40px;
    width: 200px;
    padding: 0 16px 0 0;
    text-align: right;
    font-size: 16px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid ${theme.palette.turquoiseBlue};
    outline: none;
  }

  .error-icon {
    width: 26px;
    height: 16px;
    border: none;
    background: url(${error}) no-repeat;
    background-size: contain;
    margin-left: 6px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    left: -4px;
  }
  .grey-dash {
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: grey;
  }

  .total-grey-dash {
    background-color: ${theme.palette.white};
  }
`;

export const RowItem = styled.div`
  display: flex;
  width: 300px;
  height: ${(props) => props?.height || dataCellHeight};
  align-items: center;
  padding-left: 50px;
  &.conditional {
    padding-left: 0px;
    width: 410px;
    &.child {
      padding-left: 10px;
    }

    &.readonly {
      padding-left: 10px;
      width: 300px;
    }
  }

  &.subChildItem {
    padding-left: 90px;
  }

  &.claim {
    padding-left: 48px;
    width: 410px;
    &.readonly {
      width: 300px;
    }
  }

  &.cert {
    padding-left: 48px;
    width: 360px;
    &.readonly {
      width: 275px;
    }
  }

  &.subChilddescription {
    height: 30px;
    .label {
      font-size: 13px;
      font-style: italic;
    }
  }

  &.conditional {
    padding-left: 0px;
    width: ${(props) => props?.width || "410px"};
    &.child {
      padding-left: 48px;
    }

    &.readonly {
      padding-left: 48px;
      width: 300px;
    }
  }

  .subChildItemLabel {
    padding-left: 30px;
  }

  .subChilddescriptionLabel {
    text-wrap: nowrap;
  }

  .collapsed {
    width: 35px;
    height: 26px;
    border: none;
    background: url(${listIcon}) no-repeat;
    background-size: contain;
    margin-left: 13px;
    cursor: pointer;
    &.readonly {
      margin-left: -33px;
    }
  }
  .expanded {
    width: 35px;
    height: 26px;
    border: none;
    background: url(${collapse}) no-repeat;
    background-size: contain;
    margin-left: 13px;
    cursor: pointer;
    &.readonly {
      margin-left: -33px;
    }
  }

  .checkBox {
    -webkit-appearance: none;
    height: 34px;
    width: 34px;
    background: url(${uncheckIcon}) no-repeat;
    background-size: contain;
    display: block;
    cursor: pointer;
    margin-right: 10px;
    border: none !important;
  }

  .checkBox:checked {
    background: url(${checkIcon}) no-repeat;
    background-size: contain;
  }

  .hoverIcon {
    width: 16px;
    height: 16px;
    border: none;
    background: url(${noticeIcon}) no-repeat;
    background-size: contain;
    margin-left: 6px;
    cursor: pointer;
  }
  .label-container {
    display: flex;
    align-items: center;
  }

  .row-description {
    padding-left: 50px;
    font-size: 12px;
    flex-grow: 1;
  }
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: ${theme.palette.wolfGrey};
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  &.plusOutline {
    mask-image: url(${plusOutlineIcon});
  }
  &.equalsToOutline {
    mask-image: url(${equalsToOutlineIcon});
  }
  &.AOutline {
    mask-image: url(${AOutlineIcon});
  }
  &.BOutline {
    mask-image: url(${BOutlineIcon});
  }
  &.COutline {
    mask-image: url(${COutlineIcon});
  }
  &.minusShort {
    mask-image: url(${minusShortIcon});
  }
`;

export const RetentionOverloadWrapper = styled.div`
  .addition-bar {
    margin: 14px 0 16px 0;
    padding: 24px;
    justify-content: center;
    width: 100%;
    background: linear-gradient(
        0deg,
        ${theme.palette.lightPastelBlue},
        ${theme.palette.lightPastelBlue}
      ),
      ${theme.palette.greyWhite};
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    color: ${theme.palette.gunmetal};
    line-height: 20px;
    position: relative;
    .arrow {
      &:before {
        position: absolute;
        content: "";
        right: 40px;
        border: 18px solid transparent;
        border-bottom: 24px solid ${theme.palette.lightPastelBlue};
        pointer-events: none;
        top: -40px;
        filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.09));
      }
    }
    .info-icon {
      width: 24px;
      height: 24px;
      mask: url(${infoIcon}) no-repeat center;
      background-color: ${theme.palette.tealBlue};
      margin-right: 16px;
    }
    .container {
      display: flex;
      margin-bottom: 8px;
    }
    .message {
      width: fit-content;
    }
  }
`;

export const Disclaimer = styled.div`
  padding: 45px 0 0 18px;
`;

export const VariationStatusButtonWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: ${(props) => props.buttonZIndex || 0};
  .button {
    border-radius: 4px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    font-size: 12px;
    right: 0;
    top: 0;
    height: 24px;
    width: 94px;
    user-select: none;
    background-color: ${(props) =>
      props.buttonBackgroundColor || "transparent"};
    .title {
      font-weight: bold;
      width: 100%;
      text-align: center;
    }
    &.chip {
      background-image: ${(props) => props.chipBackgroundImage || "none"};
      background-size: 24px 24px;
      background-repeat: no-repeat;
      width: 24px;
    }
    &:has(img.dropdown-icon) {
      cursor: pointer;
    }
  }
  .dropdown {
    position: absolute;
    width: 184px;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 4px;
    background-color: ${theme.palette.white};
    box-shadow: 0px 2px 6px 0px #0000004d;
    .option {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      user-select: none;
      padding: 6px 8px;
      &:hover {
        background-color: ${theme.palette.warmLightGrey};
      }
      span {
        height: fit-content;
      }
    }
  }
`;
